@use '../../common/mixins' as mixins;
@use '../utility/color/utility-colors.scss' as utility;
@use '../utility/color/common-colors.scss' as common;

.lmnt {
    --lmnt-text-bubble--small-font-size: 16px;
    --lmnt-text-bubble--small-font-weight: 700;
    --lmnt-text-bubble--small-line-height: 24px;
    --lmnt-text-bubble--small-size: 32px;
    --lmnt-text-bubble--font-family: var(--lmnt-theme-primary-font);
    --lmnt-text-bubble--font-size: 1rem;
    --lmnt-text-bubble--font-weight: 700;
    --lmnt-text-bubble--line-height: 24px;
    --lmnt-text-bubble--size: 40px;
}

.lmnt-text-bubble {
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-family: var(--lmnt-text-bubble--font-family);
    font-size: var(--lmnt-text-bubble--font-size);
    font-weight: var(--lmnt-text-bubble--font-weight);
    height: var(--lmnt-text-bubble--size);
    justify-content: center;
    line-height: var(--lmnt-text-bubble--line-height);
    margin: 0;
    max-height: var(--lmnt-text-bubble--size);
    max-width: 40px;
    min-height: var(--lmnt-text-bubble--size);
    min-width: 40px;
    padding: 0;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 40px;
    word-spacing: 0;
}

.lmnt-text-bubble--small {
    font-size: var(--lmnt-text-bubble--small-font-size);
    height: var(--lmnt-text-bubble--small-size);
    max-height: var(--lmnt-text-bubble--small-size);
    max-width: var(--lmnt-text-bubble--small-size);
    min-height: var(--lmnt-text-bubble--small-size);
    min-width: var(--lmnt-text-bubble--small-size);
    width: var(--lmnt-text-bubble--small-size);
}

@each $color, $value in utility.$utilityColorMap {
    @include mixins.mapUtilityColors(
        $class: '.lmnt-text-bubble__theme-color--',
        $color: $color,
        $value: $value
    );
}

@each $color, $value in common.$commonColorMap {
    @include mixins.mapCommonColors(
        $class: '.lmnt-text-bubble__theme-color--',
        $color: $color,
        $value: $value
    );
}

// danger-alt is special and needs it's own styles 🙄
.lmnt-text-bubble__theme-color--danger-alt {
    background-color: var(--lmnt-theme-surface);
    color: var(--lmnt-theme-on-danger-alt);
}

.lmnt-text-bubble__theme-color--background {
    background-color: var(--lmnt-theme-surface);
    color: var(--lmnt-on-surface-active);
}

.lmnt-text-bubble--outlined {
    background-color: unset;
    border-style: solid;
    border-width: 1px;
}

@each $color, $value in utility.$utilityColorMap {
    @include mixins.mapOutlineColors(
        $class: '.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--',
        $color: $color,
        $value: $value
    );
}

@each $color, $value in common.$commonColorMap {
    @include mixins.mapOutlineColors(
        $class: '.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--',
        $color: $color,
        $value: $value
    );
}

// danger-alt is special and needs it's own styles 🙄
.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--danger-alt {
    border-color: var(--lmnt-theme-on-surface-stroke);
    color: var(--lmnt-theme-on-danger-alt);
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--background {
    border-color: var(--lmnt-theme-on-surface-stroke);
    color: var(--lmnt-on-surface-active);
}
