////////////////////////////////
/////  Utility: Elevation  /////
////////////////////////////////
@use '@material/elevation/elevation' as elevation;

@include elevation.core-styles;

// NOTE: the values for elevation come from the Element Design Kit :the_more_you_know:
$black: #000;

.lmnt {
    --lmnt-elevation--outlined-border-color: #{rgba($black, 0.12)};
    --lmnt-elevation--outlined-border-color-on-primary: var(--lmnt-theme-on-primary);
    --lmnt-elevation--border-radius: 8px;
}

$box-shadow-color: #000;
$elevation-01dp: (
    0 1px 1px 0 rgba($box-shadow-color, 0.14),
    0 2px 1px -1px rgba($box-shadow-color, 0.12),
    0 1px 3px 0 rgba($box-shadow-color, 0.2)
);
$elevation-02dp: (
    0px 3px 1px -2px rgba($box-shadow-color, 0.12),
    0px 2px 2px rgba($box-shadow-color, 0.14),
    0px 1px 5px rgba($box-shadow-color, 0.2)
);
$elevation-03dp: (
    0px 3px 3px -2px rgba($box-shadow-color, 0.12),
    0px 3px 4px rgba($box-shadow-color, 0.14),
    0px 1px 8px rgba($box-shadow-color, 0.2)
);
$elevation-04dp: (
    0px 2px 4px -1px rgba($box-shadow-color, 0.2),
    0px 4px 5px rgba($box-shadow-color, 0.14),
    0px 1px 10px rgba($box-shadow-color, 0.12)
);
$elevation-06dp: (
    0px 3px 5px -1px rgba($box-shadow-color, 0.2),
    0px 6px 10px rgba($box-shadow-color, 0.14),
    0px 1px 18px rgba($box-shadow-color, 0.12)
);
$elevation-08dp: (
    0px 8px 10px 1px rgba($box-shadow-color, 0.14),
    0px 3px 14px 2px rgba($box-shadow-color, 0.12),
    0px 5px 5px -3px rgba($box-shadow-color, 0.2)
);
$elevation-09dp: (
    0px 9px 12px 1px rgba($box-shadow-color, 0.14),
    0px 3px 16px 2px rgba($box-shadow-color, 0.12),
    0px 5px 6px -3px rgba($box-shadow-color, 0.2)
);
$elevation-12dp: (
    0px 12px 17px 2px rgba($box-shadow-color, 0.14),
    0px 5px 22px 4px rgba($box-shadow-color, 0.12),
    0px 7px 8px -4px rgba($box-shadow-color, 0.2)
);
$elevation-16dp: (
    0px 16px 24px 2px rgba($box-shadow-color, 0.14),
    0px 6px 30px 5px rgba($box-shadow-color, 0.12),
    0px 8px 10px -5px rgba($box-shadow-color, 0.2)
);
$elevation-24dp: (
    0px 24px 38px 3px rgba($box-shadow-color, 0.14),
    0px 9px 46px 8px rgba($box-shadow-color, 0.12),
    0px 11px 15px -7px rgba($box-shadow-color, 0.2)
);

.lmnt-theme-elevation-00dp {
    box-shadow: none;
}

.lmnt-theme-elevation-01dp {
    box-shadow: $elevation-01dp;
}

.lmnt-theme-elevation-02dp {
    box-shadow: $elevation-02dp;
}

.lmnt-theme-elevation-03dp {
    box-shadow: $elevation-03dp;
}

.lmnt-theme-elevation-04dp {
    box-shadow: $elevation-04dp;
}

.lmnt-theme-elevation-06dp {
    box-shadow: $elevation-06dp;
}

.lmnt-theme-elevation-08dp {
    box-shadow: $elevation-08dp;
}

.lmnt-theme-elevation-09dp {
    box-shadow: $elevation-09dp;
}

.lmnt-theme-elevation-12dp {
    box-shadow: $elevation-12dp;
}

.lmnt-theme-elevation-16dp {
    box-shadow: $elevation-16dp;
}

.lmnt-theme-elevation-24dp {
    box-shadow: $elevation-24dp;
}

.lmnt-elevation--padding-standard {
    padding: 1rem;
}

.lmnt-elevation--padding-dense {
    padding: 0.5rem;
}

.lmnt-elevation--padding-airy {
    padding: 1.5rem;
}

.lmnt-elevation--rounded {
    border-radius: var(--lmnt-elevation--border-radius);
}

.lmnt-elevation--outlined {
    border-color: var(--lmnt-elevation--outlined-border-color);
    border-style: solid;
    border-width: 1px;
}
