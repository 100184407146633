@use '../../common/element.core' as element;
@use '@material/icon-button';

@include icon-button.core-styles;

$emphasis-low: 0.38;

.lmnt {
    --lmnt-icon-button-size: 40px;
    --lmnt-icon-button-padding: 8px;
}

.lmnt-icon-button.mdc-icon-button {
    width: var(--lmnt-icon-button-size);
    height: var(--lmnt-icon-button-size);
    padding: var(--lmnt-icon-button-padding);
    border-radius: 50%;

    // Icon Button Variants: danger, danger-alt, primary, secondary, on-dark

    &.lmnt-icon-button--fill-danger {
        background-color: var(--lmnt-theme-danger);
        color: var(--lmnt-theme-on-danger);

        // NOTE: colors the ripple and hover state
        &::before,
        &::after {
            background-color: currentColor;
        }

        &:disabled {
            background-color: rgba(element.$lmnt-theme-dark, 0.12);
        }
    }

    &.lmnt-icon-button--fill-danger-alt {
        background-color: var(--lmnt-theme-danger-alt);
        color: var(--lmnt-theme-on-danger-alt);

        &::before,
        &::after {
            background-color: currentColor;
        }

        &:disabled {
            background-color: rgba(element.$lmnt-theme-dark, 0.12);
        }
    }

    &.lmnt-icon-button--fill-primary {
        background-color: var(--lmnt-theme-primary);
        color: var(--lmnt-theme-on-primary);

        &::before,
        &::after {
            background-color: currentColor;
        }

        &:disabled {
            background-color: rgba(element.$lmnt-theme-dark, 0.12);
        }
    }

    &.lmnt-icon-button--fill-secondary {
        background-color: var(--lmnt-theme-secondary);
        color: var(--lmnt-theme-on-secondary);

        &::before,
        &::after {
            background-color: currentColor;
        }

        &:disabled {
            background-color: rgba(element.$lmnt-theme-dark, 0.12);
        }
    }

    &.lmnt-icon-button--color-on-dark {
        background-color: transparent;
        color: element.$lmnt-theme-light;

        &::before,
        &::after {
            background-color: currentColor;
        }
    }

    &.lmnt-icon-button--color-primary {
        background-color: transparent;
        color: var(--lmnt-theme-primary);

        &::before,
        &::after {
            background-color: currentColor;
        }
    }

    &.lmnt-icon-button--color-secondary {
        background-color: transparent;
        color: var(--lmnt-theme-secondary);

        &::before,
        &::after {
            background-color: currentColor;
        }
    }
}

.lmnt-icon-button.mdc-icon-button:disabled {
    color: rgba(element.$lmnt-theme-dark, $emphasis-low);

    .material-icons-outlined,
    .material-icons-round,
    .material-icons-two-tone,
    .material-icons-sharp,
    .material-icons {
        color: rgba(element.$lmnt-theme-dark, $emphasis-low);
    }

    &.lmnt-icon-button--on-dark {
        color: rgba(element.$lmnt-theme-light, $emphasis-low);

        .material-icons-outlined,
        .material-icons-round,
        .material-icons-two-tone,
        .material-icons-sharp,
        .material-icons {
            color: rgba(element.$lmnt-theme-light, $emphasis-low);
        }
    }
}

.lmnt-icon-button--fill.mdc-icon-button.lmnt-icon-button--fill-danger-alt:disabled {
    background-color: rgba(element.$lmnt-theme-light, 0.24);
    color: rgba(element.$lmnt-theme-light, $emphasis-low);
}

.mdc-icon-button.lmnt-icon-button--dense {
    @include icon-button.density(-4);
}

.lmnt-icon-button--notification-badge {
    &.lmnt-badge--notification {
        position: relative;
        top: -27px;
        right: -15px;
        color: element.$lmnt-theme-light;
    }
}

.lmnt {
    &.lmnt-badge {
        &.lmnt-icon-button--notification-badge:not(:empty) {
            &.lmnt-badge--notification {
                padding: unset;
                top: -30px;
                max-height: unset;
                padding-left: 6px;
                padding-right: 6px;
            }
        }
    }
}
