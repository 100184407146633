@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab/mdc-tab';
@use '../badge/notification-badge.scss';
@use '../icon/icon.scss';
@use '../typography/typography.scss';
@use '../../common/element.core';
@use '../../common/elevation';

// NOTE: Material uses "primary" to highlight active Tab states with Ripple and text color
// NOTE: Material uses "secondary" to highlight Tab Indicator Icons

// Surface/Default Variant
.mdc-tab-bar--surface {
    background-color: var(--lmnt-theme-surface);
    --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);

    .mdc-tab {
        --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
    }
    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: var(--lmnt-theme-primary-on-surface);
    }
}

// Elevated Modifier
.mdc-tab-bar--elevated {
    box-shadow: elevation.$elevation-02dp;
}

// Primary Variant
.mdc-tab-bar--primary {
    --mdc-theme-primary: var(--lmnt-theme-on-primary);
    --mdc-theme-secondary: var(--lmnt-theme-secondary);
    background-color: var(--lmnt-theme-primary);

    .mdc-tab {
        --mdc-theme-primary: var(--lmnt-theme-on-primary);

        .mdc-tab__icon {
            --mdc-theme-primary: var(--lmnt-theme-on-primary);
        }
    }
    // Text color for inactive Tabs
    .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
        color: var(--lmnt-theme-on-primary);
        opacity: 0.6;
    }

    // Icon color for inactive Tabs
    .mdc-tab:not(.mdc-tab--active) .mdc-tab__icon {
        color: var(--lmnt-theme-on-primary);
        fill: currentColor;
        opacity: var(--lmnt-theme-emphasis-icon-in-component);
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: var(--lmnt-theme-on-primary);
    }
}

// Secondary Variant
.mdc-tab-bar--secondary {
    --mdc-theme-primary: var(--lmnt-theme-on-secondary);
    --mdc-theme-secondary: var(--lmnt-theme-primary);
    background-color: var(--lmnt-theme-secondary);

    .mdc-tab {
        --mdc-theme-primary: var(--lmnt-theme-on-secondary);

        .mdc-tab__icon {
            --mdc-theme-primary: var(--lmnt-theme-on-secondary);
        }
    }

    // Text color for inactive Tabs
    .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
        color: var(--lmnt-theme-on-secondary);
        opacity: 0.6;
    }

    // Icon color for inactive Tabs
    .mdc-tab:not(.mdc-tab--active) .mdc-tab__icon {
        color: var(--lmnt-theme-on-secondary);
        fill: currentColor;
        opacity: var(--lmnt-theme-emphasis-icon-in-component);
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: var(--lmnt-theme-on-secondary);
    }
}
.lmnt {
    .mdc-tab {
        height: 48px;
        .lmnt-tab-bar--notification-badge {
            margin-left: 14px;
        }
    }

    &.mdc-tab-bar--surface {
        .mdc-tab {
            &.mdc-button:disabled {
                color: var(--lmnt-theme-on-surface-disabled);

                .mdc-tab__text-label {
                    color: var(--lmnt-theme-on-surface-disabled);
                }
            }
        }
    }
    &.mdc-tab-bar--primary {
        .mdc-tab {
            &.mdc-button:disabled {
                color: var(--lmnt-theme-on-primary-disabled);

                .mdc-tab__text-label {
                    color: var(--lmnt-theme-on-primary-disabled);
                }
            }
        }
    }
    &.mdc-tab-bar--secondary {
        .mdc-tab {
            &.mdc-button:disabled {
                color: var(--lmnt-theme-on-secondary-disabled);

                .mdc-tab__text-label {
                    color: var(--lmnt-theme-on-secondary-disabled);
                }
            }
        }
    }
}
