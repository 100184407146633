// File used to combat issues when mixing Element with either old versions of Element or other MDC based libraries
// (RMWC specifically). We can't delete the conflict file. Teams may still be using it.

.lmnt {
    .mdc-line-ripple {
        height: unset;
        opacity: unset;
        position: unset;
        transform: unset;
        transition: unset;
        width: unset;
        z-index: unset;
    }

    .mdc-select .mdc-floating-label {
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .mdc-select__dropdown-icon {
        bottom: unset;
    }
}
