@use '../../common/element.core';
@use '@material/drawer';
@use '@material/list';

@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
// @include list.core-styles;

.mdc-drawer .mdc-list-item--selected,
.mdc-drawer .mdc-list-item--activated {
    --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}

.mdc-drawer {
    .mdc-list-item--selected,
    .mdc-list-item--activated {
        color: var(--lmnt-theme-primary-on-surface);

        .mdc-list-item__graphic {
            color: var(--lmnt-theme-primary-on-surface);
        }
    }

    // NOTE: Needed for correct ripple color to show
    .mdc-list-item__ripple {
        .mdc-list-item__ripple::before,
        :not(.mdc-list-item--disabled).mdc-list-item--activated,
        .mdc-list-item__ripple::after {
            background-color: var(--lmnt-theme-primary);
        }
    }
}

// TODO: refactor magic numbers on `height` and `margin-bottom`
// NOTE: custom for avatar image in drawer header
.mdc-drawer__header-avatar {
    border-radius: 50%;
    height: 35px;
    margin-top: 12px;
    width: auto;
}

.mdc-drawer.lmnt-drawer--permanent:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
    margin-left: 256px;
}

.lmnt-drawer--permanent {
    position: fixed;
}
