@use '../../common/element.core' as theme;
@use '../../common/mixins';

@forward '@material/dialog/mdc-dialog';

// TODO: refactor `.mdc-pagination` with BEM naming conventions
// TODO: refactor magic numbers

.lmnt {
    --lmnt-pagination--button-color: #{theme.$white};
    --lmnt-pagination--button-color-active: var(--lmnt-theme-primary-300);
    --lmnt-pagination--button-color-hover: var(--lmnt-theme-primary-50);
    --lmnt-pagination--button-color-selected: var(--lmnt-theme-primary-400);
    --lmnt-pagination__button-height: 2.5rem;
}

.mdc-pagination-container {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: row;
    height: 60px;
    user-select: none;
    width: 100%;
}

.mdc-data-table--dense {
    .mdc-pagination-container {
        height: 40px;
    }
}

.mdc-data-table--tall {
    .mdc-pagination-container {
        height: 72px;
    }
}

.mdc-pagination-row-per-page {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.mdc-pagination-nav-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.mdc-pagination-dropdown-button {
    align-items: center;
    background-color: transparent;
    border-width: 0;
    color: rgba(theme.$black, theme.$emphasis-med);
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    height: 28px;
    letter-spacing: 0.4px;
    line-height: 2.34;
    outline-width: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    text-align: left;

    .icon {
        font-size: 18px;
    }
}

// TODO: refactor `z-index` into Element theme system
.mdc-pagination-dropdown-content {
    background-color: theme.$white;
    border-radius: 2px;
    box-shadow: 0 8px 10px 0 rgba(theme.$black, 0.2);
    cursor: pointer;
    max-height: 256px;
    min-width: 120px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 100%;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */

    z-index: 1;
}

.mdc-pagination-dropdown-content-items {
    background-color: theme.$white;
    border-radius: 2px;
    box-shadow: 0 8px 10px 0 rgba(theme.$black, 0.2);
    cursor: pointer;
    max-height: 256px;
    min-width: 70px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    z-index: 1;
}

.lmnt-pagination--number {
    background-color: var(--lmnt-pagination--button-color);
    border-color: theme.$white;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.063rem;
    color: rgba(theme.$black, theme.$emphasis-high);
    cursor: pointer;
    font-size: 0.75rem;
    height: var(--lmnt-pagination__button-height);
    line-height: 2.5;
    text-align: center;
    vertical-align: middle;
    width: var(--lmnt-pagination__button-height);

    &:hover,
    &:focus {
        background-color: var(--lmnt-pagination--button-color-hover);
        color: theme.$black;
    }

    &:active {
        background-color: var(--lmnt-pagination--button-color-active);
        color: theme.$white;
    }
}

.lmnt-pagination-number--selected,
.lmnt-pagination-number--selected:hover,
.lmnt-pagination-number--selected:focus {
    background-color: var(--lmnt-pagination--button-color-selected);
    color: theme.$white;
}

.lmnt-data-table__pagination-select {
    min-width: 90px;
}
