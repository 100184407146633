.notifyframe {
  display: block;
  position: relative;
}
.unreadmsgs {
  top: -18px;
  right: -18px;
  color: #fff;
  display: block;
  cursor: pointer;
  background: red;
  padding: 2px 8px;
  font-weight: bold;
  position: absolute;
  border-radius: 10px;
  border: 2px solid #fff;
}
.notifications {
  margin: 0;
  padding: 0;
  top: 40px;
  z-index: 2;
  right: 12px;
  width: 240px;
  list-style: none;
  position: absolute;
  background: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.notifications li {
  display: flex;
  gap: 5px;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 6px 10px;
  font-weight: normal;
  border-bottom: 1px solid #ccc;
}
.notificationicon {
  display: block;
  float: left;
  margin: 0 7px 0 0;
}
.notificationicon img {
  max-width: 18px;
  margin: 5px auto 0;
}
.btnclearnotify {
  width: 90%;
  border: 0;
  margin: 5px 5%;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
}
.btnclearnotify.active {
  color: #000;
  background-color: #40baff;
  border: 1px solid #40baff;
}
.btnclearnotify.disabled {
  color: #e0e0e0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}
.notificationclose {
  cursor: pointer;
  padding: 8px 0 0;
  margin-left: auto;
  color: rgba(0 0 0 / 0.5);
}
.nomsg {
  min-height: 80px;
  font-size: 14px;
}
