@use '@material/chips/deprecated/variables' as chipsVariables;
@use '../../common/element.core' as element;

$lmnt-chip-selected-fill-color: chipsVariables.$fill-color-default;

:root,
.lmnt {
    --lmnt-chip-selected-fill-color: #{$lmnt-chip-selected-fill-color};
    --lmnt-chip-ripple-shape-radius: 16px;
    --lmnt-chip-ripple-before-after-shape-radius: 50%;
    --lmnt-chip-shape-radius: 16px;
    --lmnt-chip-outlined-color: var(--lmnt-theme-black, #000000);
}
