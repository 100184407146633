@use '../../common/element.core';
@use '@material/snackbar/mdc-snackbar';

.lmnt {
    --lmnt-theme-on-snackbar: var(--lmnt-theme-primary-200);

    &.lmnt-snackbar {
        .mdc-snackbar__action:not(:disabled) {
            color: var(--lmnt-theme-on-snackbar);
        }
    }

    &.lmnt-snackbar-secondary {
        .mdc-snackbar__action:not(:disabled) {
            color: var(--lmnt-theme-on-snackbar-variant);
        }
    }
}
