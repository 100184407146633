///////////////////////
//////  Badge  ///////
///////////////////////

@use '../../common/element.core';
@use '../../common/mixins';
@use '../../common/functions' as functions;
@use './badge-base';

//////////////////////
//////  Colors  //////
//////////////////////

.lmnt {
    &.lmnt-badge {
        &.lmnt-badge--notification {
            --lmnt-badge-notification-border-color: var(--lmnt-theme-surface);
            --lmnt-badge-padding-left: 6px;
            --lmnt-badge-padding-right: 6px;
            --lmnt-badge-padding-top: 0;
            --lmnt-badge-padding-bottom: 0;
            --lmnt-badge-shape-radius: 12px;
            border: 2px solid var(--lmnt-badge-notification-border-color);
            padding: 0;

            &.lmnt-badge--notification-badge-counter {
                padding-left: var(--lmnt-badge-padding-left);
                padding-right: var(--lmnt-badge-padding-right);
                padding-top: var(--lmnt-badge-padding-top);
                padding-bottom: var(--lmnt-badge-padding-bottom);
                --lmnt-badge-min-height: 16px;
                --lmnt-badge-max-height: unset;
            }

            &.lmnt-badge--notification-primary {
                background-color: var(--lmnt-theme-primary);
                color: var(--lmnt-theme-on-primary);
            }

            &.lmnt-badge--notification-secondary {
                background-color: var(--lmnt-theme-secondary);
                color: var(--lmnt-theme-on-secondary);
            }

            &.lmnt-badge--notification-danger {
                background-color: var(--lmnt-theme-danger);
                color: var(--lmnt-theme-on-danger);
            }

            &.lmnt-badge--notification-success {
                background-color: var(--lmnt-theme-success);
                color: var(--lmnt-theme-on-success);
            }
        }
    }
}
