.lmnt {
    --lmnt-padding--dense: 0.5rem;
    --lmnt-padding--dense-top: var(--lmnt-padding--dense);
    --lmnt-padding--dense-right: var(--lmnt-padding--dense);
    --lmnt-padding--dense-bottom: var(--lmnt-padding--dense);
    --lmnt-padding--dense-left: var(--lmnt-padding--dense);
    --lmnt-padding--standard: 1rem;
    --lmnt-padding--standard-top: var(--lmnt-padding--standard);
    --lmnt-padding--standard-right: var(--lmnt-padding--standard);
    --lmnt-padding--standard-bottom: var(--lmnt-padding--standard);
    --lmnt-padding--standard-left: var(--lmnt-padding--standard);
    --lmnt-padding--airy: 1.5rem;
    --lmnt-padding--airy-top: var(--lmnt-padding--airy);
    --lmnt-padding--airy-right: var(--lmnt-padding--airy);
    --lmnt-padding--airy-bottom: var(--lmnt-padding--airy);
    --lmnt-padding--airy-left: var(--lmnt-padding--airy);
}

.lmnt-padding--dense {
    padding-bottom: var(--lmnt-padding--dense-bottom);
    padding-left: var(--lmnt-padding--dense-left);
    padding-right: var(--lmnt-padding--dense-right);
    padding-top: var(--lmnt-padding--dense-top);
}

.lmnt-padding--standard {
    padding-bottom: var(--lmnt-padding--standard-bottom);
    padding-left: var(--lmnt-padding--standard-left);
    padding-right: var(--lmnt-padding--standard-right);
    padding-top: var(--lmnt-padding--standard-top);
}

.lmnt-padding--airy {
    padding-bottom: var(--lmnt-padding--airy-bottom);
    padding-left: var(--lmnt-padding--airy-left);
    padding-right: var(--lmnt-padding--airy-right);
    padding-top: var(--lmnt-padding--airy-top);
}
