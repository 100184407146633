///////////////////////////////
///////  Utility: Color ///////
///////////////////////////////

// Element Theme Defaults - Primary
.lmnt-theme-primary {
    color: var(--lmnt-theme-primary);
}

.lmnt-theme-on-primary {
    color: var(--lmnt-theme-on-primary);
}

.lmnt-theme-primary-variant {
    color: var(--lmnt-theme-primary-variant);
}

.lmnt-theme-on-primary-variant {
    color: var(--lmnt-theme-on-primary-variant);
}

.lmnt-theme-primary-on-surface {
    color: var(--lmnt-theme-primary-on-surface);
}

// Element Theme Defaults - Secondary
.lmnt-theme-secondary {
    color: var(--lmnt-theme-secondary);
}

.lmnt-theme-on-secondary {
    color: var(--lmnt-theme-on-secondary);
}

.lmnt-theme-secondary-variant {
    color: var(--lmnt-theme-secondary-variant);
}

.lmnt-theme-on-secondary-variant {
    color: var(--lmnt-theme-on-secondary-variant);
}

.lmnt-theme-secondary-on-surface {
    color: var(--lmnt-theme-secondary-on-surface);
}

// Element Theme Defaults - Background
.lmnt-theme-background {
    color: var(--lmnt-theme-background);
}

.lmnt-theme-on-background {
    color: var(--lmnt-theme-on-background);
}

// Element Theme Defaults - Surface
.lmnt-theme-surface {
    color: var(--lmnt-theme-surface);
}

.lmnt-theme-on-surface {
    color: var(--lmnt-theme-on-surface);
}

.lmnt-theme-surface-variant {
    color: var(--lmnt-theme-surface-variant);
}

// Element Theme Defaults - Danger
.lmnt-theme-danger {
    color: var(--lmnt-theme-danger);
}

.lmnt-theme-on-danger {
    color: var(--lmnt-theme-on-danger);
}

.lmnt-theme-danger-on-surface {
    color: var(--lmnt-theme-danger-on-surface);
}

.lmnt-theme-danger-variant {
    color: var(--lmnt-theme-danger-variant);
}

// Element Theme Defaults - Light
.lmnt-theme-light {
    color: var(--lmnt-theme-light);
}

.lmnt-theme-on-unknown-light {
    color: var(--lmnt-theme-on-unknown-light);
}

// Element Theme Defaults - Dark
.lmnt-theme-dark {
    color: var(--lmnt-theme-dark);
}

.lmnt-theme-on-unknown-dark {
    color: var(--lmnt-theme-on-unknown-dark);
}
