/////////////////////////
///////  Utility  ///////
/////////////////////////

@use '../../common/element.core';
@use '../../common/background-color';
@use '../../common/color';
@use '../../common/elevation';
@use '../../common/font';
@use '../../common/screen-reader-only';
@use './color/utility-colors';

body {
    color: var(--lmnt-theme-on-surface);
    font-family: var(--lmnt-theme-primary-font, Roboto, sans-serif);
    background-color: var(--lmnt-theme-background);
}

*:focus-visible {
    outline: none;
}
