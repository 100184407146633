.lmnt.lmnt-file-upload .lmnt-file-upload__file-url {
  display: none;
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor {
  height: 55px !important;
  min-height: 55px !important;
}
.doctypeselect {
  width: 20vw !important;
}
.uploadedfilename {
  margin: 0 5px;
  display: inline-block;
}
.nextbtn {
  color: #23668c;
  padding: 6px 10px;
  margin: 10px 0 0 20px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #23668c;
}
.form_upload {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.lmnt .lmnt-text-field {
  width: 100%;
}
.uploadbtnframe {
  height: 65px;
  gap: 1rem;
}

@media only screen and (max-width: 500px) {
  .doctypeselect {
    width: 70vw !important;
  }
}

@media only screen and (max-width: 400px) {
  .form_section {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .form_section_elements {
    width: 98%;
    margin: 5px 1% 10px;
  }
  .uploadbtnframe {
    height: 80px;
  }
}
