//////////////////////////////
///////  Utility: Font ///////
//////////////////////////////

.lmnt-theme-primary-font {
    font-family: var(--lmnt-theme-primary-font);
}

.lmnt-theme-secondary-font {
    font-family: var(--lmnt-theme-secondary-font);
}
