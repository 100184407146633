@use '../../common/element.core';
@use '../../common/mixins';

.lmnt {
    &.lmnt-file-upload {
        .lmnt-file-upload__input {
            cursor: pointer;
            filter: alpha(opacity=0);
            font-size: 20px;
            height: 36px;
            margin: 0;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }

        .lmnt-file-upload__file-url {
            background-color: transparent;
            border: none;
            color: var(--lmnt-theme-on-surface);
            overflow: hidden;
            padding-left: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 250px;
        }

        &.lmnt-file-upload--secondary {
            .lmnt-file-upload__file-url {
                color: #fff;

                &::placeholder {
                    color: rgba(#fff, 0.87);
                }
            }
        }
    }
}
