@use 'sass:map';
@use 'sass:math';
@use '@material/theme/mdc-theme';
@use 'sass-hex-rgb' as rgb;
@use 'functions' as functions;
@use 'mixins' as mixins;
@use '@material/elevation' as elevation;

$assetsPath: '..' !default;

///////////////////////////
//////  Font Stacks  //////
///////////////////////////

// NOTE: double-quote string wrapper needed to preserve font-family single-quotes
$Roboto: "'Roboto', 'Arial Narrow', helvetica, arial, sans-serif";

//////////////////////
//////  Colors  //////
//////////////////////

$athens-gray: #f4f6f8;
$black: #000;
$dark-charcoal: #4d4d4d;
$dusty-gray: #9a9a9a;
$mine-shaft: #333;
$white: #fff;
$wild-sand: #f5f5f5;

$lmnt-black-rgb: rgb.toValues($black);
$lmnt-white-rgb: rgb.toValues($white);
$lmnt-theme-dark: $black;
$lmnt-theme-light: $white;
////////////////////////
//////  Emphasis  //////
////////////////////////

$emphasis-active-black: 0.87;
$emphasis-active: 1;
$emphasis-completed: 0.6;
$emphasis-disabled: 0.38;
$emphasis-dragged: 0.08;
$emphasis-focus--alt: 0.24;
$emphasis-focus: 0.12;
$emphasis-high-black: 0.87;
$emphasis-high: 1;
$emphasis-highlighted: 0.24;
$emphasis-hover--alt: 0.08;
$emphasis-hover: 0.04;
$emphasis-inactive: 0.6;
$emphasis-low: 0.38;
$emphasis-med: 0.6;
$emphasis-pressed--alt: 0.32;
$emphasis-pressed: 0.16;
$emphasis-resting: 0.08;
$emphasis-selected: 0.08;
$emphasis-stroke: 0.12;
$emphasis-stroke-dark: 0.24;
$emphasis-icon-in-component: 0.54;

// these are the reverse of the above
$emphasis-on-surface-active-black: calc(1 - #{$emphasis-active-black});
$emphasis-on-surface-active: calc(1 - #{$emphasis-active});
$emphasis-on-surface-completed: calc(1 - #{$emphasis-completed});
$emphasis-on-surface-disabled: calc(1 - #{$emphasis-disabled});
$emphasis-on-surface-dragged: calc(1 - #{$emphasis-dragged});
$emphasis-on-surface-focus--alt: calc(1 - #{$emphasis-focus--alt});
$emphasis-on-surface-focus: calc(1 - #{$emphasis-focus});
$emphasis-on-surface-high-black: calc(1 - #{$emphasis-high-black});
$emphasis-on-surface-high: calc(1 - #{$emphasis-high});
$emphasis-on-surface-highlighted: calc(1 - #{$emphasis-highlighted});
$emphasis-on-surface-hover-alt: calc(1 - #{$emphasis-hover--alt});
$emphasis-on-surface-hover: calc(1 - #{$emphasis-hover});
$emphasis-on-surface-inactive: calc(1 - #{$emphasis-inactive});
$emphasis-on-surface-low: calc(1 - #{$emphasis-low});
$emphasis-on-surface-med: calc(1 - #{$emphasis-med});
$emphasis-on-surface-pressed--alt: calc(1 - #{$emphasis-pressed--alt});
$emphasis-on-surface-pressed: calc(1 - #{$emphasis-pressed});
$emphasis-on-surface-resting: calc(1 - #{$emphasis-resting});
$emphasis-on-surface-selected: calc(1 - #{$emphasis-selected});
$emphasis-on-surface-stroke: calc(1 - #{$emphasis-stroke});
$emphasis-on-surface-stroke-dark: calc(1 - #{$emphasis-stroke-dark});
$emphasis-on-unknown-white: 0.54;

/////////////////////
//////  Theme  //////
/////////////////////

$primaryMap: (
    primary-50: #f1edf8,
    primary-100: #c7b7e4,
    primary-200: #b9a5de,
    primary-300: #9381d1,
    primary-400: #825dc4,
    primary-500: #673ab7,
    primary-600: #553096,
    primary-700: #4b2b86,
    primary-800: #422575,
    primary-900: #261643
) !default;
$secondaryMap: (
    secondary-50: #edf8ff,
    secondary-100: #caecff,
    secondary-200: #b9e5ff,
    secondary-300: #96d9ff,
    secondary-400: #74ccff,
    secondary-500: #40baff,
    secondary-600: #3599d1,
    secondary-700: #2f88ba,
    secondary-800: #23668c,
    secondary-900: #18445d
) !default;
$successMap: (
    success-50: #eeffef,
    success-100: #d3ffd7,
    success-200: #a9f6af,
    success-300: #6fef7a,
    success-400: #29e539,
    success-500: #13c545,
    success-600: #0ea01a,
    success-700: #01830c,
    success-800: #075a0e,
    success-900: #053709
) !default;
$dangerMap: (
    danger-50: #ffeaed,
    danger-100: #ffccce,
    danger-200: #ee9894,
    danger-300: #e3716a,
    danger-400: #ec5145,
    danger-500: #ef4227,
    danger-600: #e13727,
    danger-700: #cf2d22,
    danger-800: #c2261b,
    danger-900: #b3190d
) !default;
$ancillaryMap: (
    ancillary-100: #000,
    ancillary-200: #000,
    ancillary-300: #000,
    ancillary-400: #000,
    ancillary-500: #000,
    ancillary-600: #000,
    ancillary-700: #000,
    ancillary-800: #000,
    ancillary-900: #000
) !default;
$dataVizComparativeMap: (
    dataVizComparative-50: #240f67,
    dataVizComparative-100: #2d72c4,
    dataVizComparative-200: #89c9b7,
    dataVizComparative-300: #785e9f,
    dataVizComparative-400: #3b8ec8,
    dataVizComparative-500: #b7dbbc,
    dataVizComparative-600: #4153af,
    dataVizComparative-700: #47a3c4,
    dataVizComparative-800: #dfeccb,
    dataVizComparative-900: #6332ad
);
$dataVizSequential1Map: (
    dataVizSequential1-50: #261643,
    dataVizSequential1-100: #422575,
    dataVizSequential1-200: #6540aa,
    dataVizSequential1-300: #7e52d0,
    dataVizSequential1-400: #9a75dd,
    dataVizSequential1-500: #af8bef,
    dataVizSequential1-600: #c6a6ff,
    dataVizSequential1-700: #d8c1ff,
    dataVizSequential1-800: #e8dbff,
    dataVizSequential1-900: #f5efff
);
$dataVizSequential2Map: (
    dataVizSequential2--50: #052d44,
    dataVizSequential2--100: #08517b,
    dataVizSequential2--200: #1774aa,
    dataVizSequential2--300: #319bd8,
    dataVizSequential2--400: #40baff,
    dataVizSequential2--500: #75cdff,
    dataVizSequential2--600: #97e0ff,
    dataVizSequential2--700: #a9f0ff,
    dataVizSequential2--800: #c3fbff,
    dataVizSequential2--900: #ddfffd
);

@include mixins.colorRamp($primaryMap);
@include mixins.colorRamp($secondaryMap);
@include mixins.colorRamp($ancillaryMap);
@include mixins.colorRamp($dataVizComparativeMap);
@include mixins.colorRamp($dataVizSequential1Map);
@include mixins.colorRamp($dataVizSequential2Map);

$lmnt-theme-primary: #673ab7 !default;
$lmnt-theme-primary-variant: #4b2b86 !default;
$lmnt-theme-secondary: #40baff !default;
$lmnt-theme-secondary-variant: #2f88ba !default;
$lmnt-theme-danger: #cf2d22 !default;
$lmnt-theme-danger-variant: #b3190d !default;
$lmnt-theme-danger-alt: $wild-sand !default;
$lmnt-theme-success: #0ea01a !default;
$lmnt-theme-success-variant: #13c545 !default;
$lmnt-theme-dark: #000000 !default;
$lmnt-theme-light: #ffffff !default;

$onClassesMap: (
    on-background: functions.color-contrast-value-check($white),
    on-surface: functions.color-contrast-value-check($white),
    on-primary: functions.color-contrast-value-check($lmnt-theme-primary),
    on-primary-variant: functions.color-contrast-value-check($lmnt-theme-primary-variant),
    primary-on-surface: $lmnt-theme-primary,
    on-secondary: functions.color-contrast-value-check($lmnt-theme-secondary),
    on-secondary-variant:
        functions.color-contrast-value-check($lmnt-theme-secondary-variant),
    secondary-on-surface: #23668c,
    on-danger: functions.color-contrast-value-check($lmnt-theme-danger),
    on-danger-alt: #cf2d22,
    on-danger-variant: functions.color-contrast-value-check($lmnt-theme-danger-variant),
    danger-on-surface: #cf2d22,
    success-on-surface: #01830c,
    on-success: functions.color-contrast-value-check($lmnt-theme-success),
    on-unknown-dark: functions.color-contrast-value-check($lmnt-theme-dark),
    on-unknown-light: functions.color-contrast-value-check($lmnt-theme-light)
) !default;
$lmnt-primary-on-surface-rgb: rgb.toValues(map.get($onClassesMap, 'primary-on-surface'));

$onSurfaceMap: (
    on-surface-active: rgba(map.get($onClassesMap, 'on-surface'), $emphasis-high-black),
    on-surface-inactive: rgba(map.get($onClassesMap, 'on-surface'), $emphasis-inactive),
    on-surface-disabled: rgba(map.get($onClassesMap, 'on-surface'), $emphasis-disabled),
    on-surface-stroke: rgba(map.get($onClassesMap, 'on-surface'), $emphasis-stroke),
    on-surface-base: rgba(map.get($onClassesMap, 'on-surface'), $emphasis-high)
) !default;

$onPrimaryMap: (
    on-primary-active: rgba(map.get($onClassesMap, 'on-primary'), $emphasis-high-black),
    on-primary-inactive: rgba(map.get($onClassesMap, 'on-primary'), $emphasis-inactive),
    on-primary-disabled: rgba(map.get($onClassesMap, 'on-primary'), $emphasis-disabled),
    on-primary-stroke: rgba(map.get($onClassesMap, 'on-primary'), $emphasis-stroke),
    on-primary-base: rgba(map.get($onClassesMap, 'on-primary'), $emphasis-high)
) !default;

$onSecondaryMap: (
    on-secondary-active:
        rgba(map.get($onClassesMap, 'on-secondary'), $emphasis-high-black),
    on-secondary-inactive:
        rgba(map.get($onClassesMap, 'on-secondary'), $emphasis-inactive),
    on-secondary-disabled:
        rgba(map.get($onClassesMap, 'on-secondary'), $emphasis-disabled),
    on-secondary-stroke: rgba(map.get($onClassesMap, 'on-secondary'), $emphasis-stroke),
    on-secondary-base: rgba(map.get($onClassesMap, 'on-secondary'), $emphasis-high)
) !default;

$onSuccessMap: (
    on-success-active: rgba(map.get($onClassesMap, 'on-success'), $emphasis-high-black),
    on-success-inactive: rgba(map.get($onClassesMap, 'on-success'), $emphasis-inactive),
    on-success-disabled: rgba(map.get($onClassesMap, 'on-success'), $emphasis-disabled),
    on-success-stroke: rgba(map.get($onClassesMap, 'on-success'), $emphasis-stroke),
    on-success-base: rgba(map.get($onClassesMap, 'on-success'), $emphasis-high)
) !default;

$onDangerMap: (
    on-danger-active: rgba(map.get($onClassesMap, 'on-danger'), $emphasis-high-black),
    on-danger-inactive: rgba(map.get($onClassesMap, 'on-danger'), $emphasis-inactive),
    on-danger-disabled: rgba(map.get($onClassesMap, 'on-danger'), $emphasis-disabled),
    on-danger-stroke: rgba(map.get($onClassesMap, 'on-danger'), $emphasis-stroke),
    on-danger-base: rgba(map.get($onClassesMap, 'on-danger'), $emphasis-high)
) !default;

$onUnknownBlackClassesMap: (
    on-unknown-black-active: rgba(0, 0, 0, $emphasis-high-black),
    on-unknown-black-inactive: rgba(0, 0, 0, $emphasis-inactive),
    on-unknown-black-disabled: rgba(0, 0, 0, $emphasis-disabled),
    on-unknown-black-stroke: rgba(0, 0, 0, $emphasis-stroke),
    on-unknown-black-base: rgba(0, 0, 0, $emphasis-high)
) !default;

$onUnknownWhiteClassesMap: (
    on-unknown-white-active: rgba(255, 255, 255, $emphasis-high),
    on-unknown-white-inactive: rgba(255, 255, 255, $emphasis-on-unknown-white),
    on-unknown-white-disabled: rgba(255, 255, 255, $emphasis-disabled),
    on-unknown-white-stroke: rgba(255, 255, 255, $emphasis-stroke),
    on-unknown-white-base: rgba(255, 255, 255, 1)
) !default;

//////////////////
/// GRADIENTS ///
/////////////////

$gradient-colors: (
    'primary-to-bottom': $lmnt-theme-primary,
    'primary-dark-to-bottom': map.get($primaryMap, 'primary-900'),
    'primary-darker-to-bottom': darken(map.get($primaryMap, 'primary-900'), 10),
    'secondary-to-bottom': $lmnt-theme-secondary,
    'secondary-dark-to-bottom': map.get($secondaryMap, 'secondary-900'),
    'secondary-darker-to-bottom': darken(map.get($secondaryMap, 'secondary-900'), 10),
    'primary-to-top': $lmnt-theme-primary,
    'primary-dark-to-top': map.get($primaryMap, 'primary-900'),
    'primary-darker-to-top': darken(map.get($primaryMap, 'primary-900'), 10),
    'secondary-to-top': $lmnt-theme-secondary,
    'secondary-dark-to-top': map.get($secondaryMap, 'secondary-900'),
    'secondary-darker-to-top': darken(map.get($secondaryMap, 'secondary-900'), 10),
    'black-to-bottom': $black,
    'black-to-top': $black,
    'white-to-bottom': $white,
    'white-to-top': $white
) !default;

@include mixins.gradientRamp($gradient-colors);

////////////////////
/// Breakpoints ///
///////////////////

$breakpointsMap: (
    'mobile-min': 0,
    'mobile-max': 599px,
    'tablet-sm-min': 600px,
    'tablet-sm-max': 719px,
    'tablet-lg-min': 720px,
    'tablet-lg-max': 839px,
    'desktop-sm-min': 840px,
    'desktop-sm-max': 1023px,
    'desktop-lg-min': 1024px,
    'desktop-lg-max': 1439px,
    'desktop-xl-min': 1440px,
    'content-max-width-md': 776px,
    'content-max-width-lg': 1052px,
    'content-max-width-xl': 1176px
) !default;

:root,
.lmnt {
    --lmnt-theme-on-background: #{map.get($onClassesMap, 'on-background')};
    --lmnt-theme-on-surface: #{map.get($onClassesMap, 'on-surface')};
    --lmnt-theme-primary-font: #{$Roboto};

    // Element Theme Defaults - Background
    --lmnt-theme-background: #{$white};

    // Element Theme Defaults - Surface
    --lmnt-theme-surface: #{$white};
    --lmnt-theme-surface-variant: #{$wild-sand};

    --lmnt-theme-dark: #{$lmnt-theme-dark};
    --lmnt-theme-light: #{$lmnt-theme-light};
    --lmnt-theme-on-unknown-light: #{$lmnt-theme-dark};
    --lmnt-theme-on-unknown-dark: #{$lmnt-theme-light};
}

.lmnt {
    // Element Theme Defaults - Font
    --lmnt-theme-primary-font: #{$Roboto};
    --lmnt-theme-secondary-font: #{$Roboto};

    --lmnt-black-rgb: #{$lmnt-black-rgb};
    --lmnt-white-rgb: #{$lmnt-white-rgb};
    // Element Theme Defaults - Primary
    --lmnt-theme-primary: #{$lmnt-theme-primary};
    --lmnt-theme-on-primary: #{map.get($onClassesMap, 'on-primary')};
    --lmnt-theme-primary-variant: #{$lmnt-theme-primary-variant};
    --lmnt-theme-on-primary-variant: #{map.get($onClassesMap, 'on-primary-variant')};
    --lmnt-theme-primary-on-surface: #{map.get($onClassesMap, 'primary-on-surface')};
    --lmnt-primary-on-surface-rgb: #{$lmnt-primary-on-surface-rgb};

    // Element Theme Defaults - Secondary
    --lmnt-theme-secondary: #{$lmnt-theme-secondary};
    --lmnt-theme-on-secondary: #{map.get($onClassesMap, 'on-secondary')};
    --lmnt-theme-secondary-variant: #2f88ba;
    --lmnt-theme-on-secondary-variant: #{map.get($onClassesMap, 'on-secondary-variant')};
    --lmnt-theme-secondary-on-surface: #{map.get($onClassesMap, 'secondary-on-surface')};

    // Element Theme Defaults - Danger
    --lmnt-theme-danger: #{$lmnt-theme-danger};
    --lmnt-theme-on-danger: #{map.get($onClassesMap, 'on-danger')};
    --lmnt-theme-danger-alt: #{$wild-sand};
    --lmnt-theme-on-danger-alt: #{map.get($onClassesMap, 'on-danger-alt')};
    --lmnt-theme-danger-variant: #{$lmnt-theme-danger-variant};
    --lmnt-theme-on-danger-variant: #{map.get($onClassesMap, 'on-danger-variant')};
    --lmnt-theme-danger-on-surface: #{map.get($onClassesMap, 'danger-on-surface')};

    // Element Theme Defaults - Success
    --lmnt-theme-success: #{$lmnt-theme-success};
    --lmnt-theme-on-success: #{map.get($onClassesMap, 'on-success')};
    --lmnt-theme-success-variant: #{$lmnt-theme-success-variant};
    --lmnt-theme-success-on-surface: #{map.get($onClassesMap, 'success-on-surface')};

    // On surface rgb
    $lmnt-primary-on-surface-rgb: rgb.toValues(
        map.get($onClassesMap, 'primary-on-surface')
    );
    --lmnt-primary-on-surface-rgb: #{$lmnt-primary-on-surface-rgb};

    $lmnt-secondary-on-surface-rgb: rgb.toValues(
        map.get($onClassesMap, 'secondary-on-surface')
    );
    --lmnt-secondary-on-surface-rgb: #{$lmnt-secondary-on-surface-rgb};

    $lmnt-danger-on-surface-rgb: rgb.toValues(
        map.get($onClassesMap, 'danger-on-surface')
    );
    --lmnt-danger-on-surface-rgb: #{$lmnt-danger-on-surface-rgb};

    $lmnt-success-on-surface-rgb: rgb.toValues(
        map.get($onClassesMap, 'danger-on-surface')
    );
    --lmnt-success-on-surface-rgb: #{$lmnt-success-on-surface-rgb};

    // On color rgb
    $lmnt-on-primary-rgb: rgb.toValues(map.get($onClassesMap, 'on-primary'));
    --lmnt-on-primary-rgb: #{$lmnt-on-primary-rgb};

    $lmnt-on-secondary-rgb: rgb.toValues(map.get($onClassesMap, 'on-secondary'));
    --lmnt-on-secondary-rgb: #{$lmnt-on-secondary-rgb};

    $lmnt-on-danger-rgb: rgb.toValues(map.get($onClassesMap, 'on-danger'));
    --lmnt-on-danger-rgb: #{$lmnt-on-danger-rgb};

    $lmnt-on-success-rgb: rgb.toValues(map.get($onClassesMap, 'on-success'));
    --lmnt-on-success-rgb: #{$lmnt-on-success-rgb};

    // Element Breakpoints
    @each $name, $point in $breakpointsMap {
        --lmnt-theme-breakpoint-#{$name}: #{$point};
    }

    // Element Primary Color Ramp
    @each $name, $color in $primaryMap {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Secondary Color Ramp
    @each $name, $color in $secondaryMap {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Success Color Ramp
    @each $name, $color in $successMap {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Danger Color Ramp
    @each $name, $color in $dangerMap {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Ancillary Color Ramp
    @each $name, $color in $ancillaryMap {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Data Viz Sequential 1 Color Ramp
    @each $name, $color in $dataVizSequential1Map {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Data Viz Sequential 2 Color Ramp
    @each $name, $color in $dataVizSequential2Map {
        --lmnt-theme-#{$name}: #{$color};
    }
    // Element Data Viz Comparative Color Ramp
    @each $name, $color in $dataVizComparativeMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element on Colors

    // Element On Surface Color Ramp
    @each $name, $color in $onSurfaceMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element On Primary Color Ramp
    @each $name, $color in $onPrimaryMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element On Secondary Color Ramp
    @each $name, $color in $onSecondaryMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element On Success Color Ramp
    @each $name, $color in $onSuccessMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element On Danger Color Ramp
    @each $name, $color in $onDangerMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element On Unknown Black Color Ramp
    @each $name, $color in $onUnknownBlackClassesMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // Element On Unknown White Color Ramp
    @each $name, $color in $onUnknownWhiteClassesMap {
        --lmnt-theme-#{$name}: #{$color};
    }

    // white/black
    --lmnt-theme-black: #{$black};
    --lmnt-theme-white: #{$white};
    --lmnt-theme-icon-in-component: rgba(#{$black}, #{$emphasis-icon-in-component});

    // Emphasis
    --lmnt-theme-emphasis-active-black: #{$emphasis-active-black};
    --lmnt-theme-emphasis-active: #{$emphasis-active};
    --lmnt-theme-emphasis-completed: #{$emphasis-completed};
    --lmnt-theme-emphasis-disabled: #{$emphasis-disabled};
    --lmnt-theme-emphasis-dragged: #{$emphasis-dragged};
    --lmnt-theme-emphasis-focus: #{$emphasis-focus};
    --lmnt-theme-emphasis-high-black: #{$emphasis-high-black};
    --lmnt-theme-emphasis-high: #{$emphasis-high};
    --lmnt-theme-emphasis-highlighted: #{$emphasis-highlighted};
    --lmnt-theme-emphasis-hover--alt: #{$emphasis-hover--alt};
    --lmnt-theme-emphasis-hover: #{$emphasis-hover};
    --lmnt-theme-emphasis-inactive: #{$emphasis-inactive};
    --lmnt-theme-emphasis-low: #{$emphasis-low};
    --lmnt-theme-emphasis-med: #{$emphasis-med};
    --lmnt-theme-emphasis-pressed--alt: #{$emphasis-pressed--alt};
    --lmnt-theme-emphasis-pressed: #{$emphasis-pressed};
    --lmnt-theme-emphasis-resting: #{$emphasis-resting};
    --lmnt-theme-emphasis-focus--alt: #{$emphasis-focus--alt};
    --lmnt-theme-emphasis-selected: #{$emphasis-selected};
    --lmnt-theme-emphasis-stroke: #{$emphasis-stroke};
    --lmnt-theme-emphasis-stroke-dark: #{$emphasis-stroke-dark};
    --lmnt-theme-emphasis-icon-in-component: #{$emphasis-icon-in-component};

    // these are the reverse of the above
    --lmnt-theme-emphasis-on-surface-active-black: #{$emphasis-on-surface-active-black};
    --lmnt-theme-emphasis-on-surface-active: #{$emphasis-on-surface-active};
    --lmnt-theme-emphasis-on-surface-completed: #{$emphasis-on-surface-completed};
    --lmnt-theme-emphasis-on-surface-disabled: #{$emphasis-on-surface-disabled};
    --lmnt-theme-emphasis-on-surface-dragged: #{$emphasis-on-surface-dragged};
    --lmnt-theme-emphasis-on-surface-focus--alt: #{$emphasis-on-surface-focus--alt};
    --lmnt-theme-emphasis-on-surface-focus: #{$emphasis-on-surface-focus};
    --lmnt-theme-emphasis-on-surface-high-black: #{$emphasis-on-surface-high-black};
    --lmnt-theme-emphasis-on-surface-high: #{$emphasis-on-surface-high};
    --lmnt-theme-emphasis-on-surface-highlighted: #{$emphasis-on-surface-highlighted};
    --lmnt-theme-emphasis-on-surface-hover-alt: #{$emphasis-on-surface-hover-alt};
    --lmnt-theme-emphasis-on-surface-hover: #{$emphasis-on-surface-hover};
    --lmnt-theme-emphasis-on-surface-inactive: #{$emphasis-on-surface-inactive};
    --lmnt-theme-emphasis-on-surface-low: #{$emphasis-on-surface-low};
    --lmnt-theme-emphasis-on-surface-med: #{$emphasis-on-surface-med};
    --lmnt-theme-emphasis-on-surface-pressed-alt: #{$emphasis-on-surface-pressed--alt};
    --lmnt-theme-emphasis-on-surface-resting: #{$emphasis-on-surface-resting};
    --lmnt-theme-emphasis-on-surface-selected: #{$emphasis-on-surface-selected};
    --lmnt-theme-emphasis-on-surface-stroke: #{$emphasis-on-surface-stroke};
    --lmnt-theme-emphasis-on-surface-stroke-dark: #{$emphasis-on-surface-stroke-dark};

    ///////////////////////////
    //////  Material   ////////
    ///////////////////////////

    // MIGRATION: these CSS variables will be included in all component stylesheets
    // QUESTION: are MDC side-effects acceptable in all contexts?
    // POTENTIAL: move this stuff to theme.scss ?
    // POTENTIAL: add top level class wrapper for Element and avoid .lmnt

    // MDC Theme CSS Variables
    --mdc-typography-font-family: var(--lmnt-theme-primary-font);
    --mdc-theme-primary: var(--lmnt-theme-primary);
    --mdc-theme-on-primary: var(--lmnt-theme-on-primary);
    --mdc-theme-secondary: var(--lmnt-theme-secondary);
    --mdc-theme-on-secondary: var(--lmnt-theme-on-secondary);
    --mdc-theme-background: var(--lmnt-theme-background);
    --mdc-theme-surface: var(--lmnt-theme-surface);
    --mdc-theme-on-surface: var(--lmnt-theme-on-surface);
    --mdc-theme-error: var(--lmnt-theme-danger);
    --mdc-theme-on-error: var(--lmnt-theme-on-danger);

    // Default Button Elevation
    --lmnt-button-box-shadow: #{elevation.elevation-box-shadow(2)};

    // Default Card Elevation & Shape Radius
    --lmnt-theme--shape-radius: 4px;
    --lmnt-card-box-shadow: #{elevation.elevation-box-shadow(1)};

    --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);
    --mdc-theme-text-primary-on-dark: var(--lmnt-theme-on-unknown-white);
    --mdc-theme-text-primary-on-light: var(--lmnt-theme-on-unknown-black);
    --mdc-theme-text-secondary-on-background: rgba(var(--lmnt-black-rgb), 0.54);
    --mdc-theme-text-disabled-on-background: var(--lmnt-theme-on-unknown-black-disabled);
    --mdc-theme-text-disabled-on-dark: var(--lmnt-theme-on-unknown-white-disabled);
    --mdc-theme-text-disabled-on-light: var(--lmnt-theme-on-unknown-black-disabled);
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
    --mdc-theme-text-icon-on-dark: var(--lmnt-theme-on-unknown-white);
    --mdc-theme-text-icon-on-light: var(--lmnt-theme-on-unknown-black);

    // NOTE: These Material CSS variables exist and have no direct Element counterparts yet
    // --mdc-theme-text-secondary-on-dark: ;
    // --mdc-theme-text-secondary-on-light: ;
    // --mdc-theme-text-hint-on-background: ;
    // --mdc-theme-text-hint-on-dark: ;
    // --mdc-theme-text-hint-on-light: ;
}
