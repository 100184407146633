.expansion-demo {
  &__container {
    box-shadow: inherit;
    padding: 10px;
    border: 1px solid black;
    margin-bottom: 20px;
  }
  &__trigger {
    padding: 10px;
    i {
      float: left;
    }
  }
}
.buttonsframe {
  padding: 10px;
  height: 140px;
  border: 1px solid #000;
  border-top: 0;
  button {
    margin: 0 0 10px 0;
  }
}

.redactions_frame {
  padding: 0 0 10px;

  h3 {
    margin: 8px 0;
    padding: 8px;
    color: #23668c;
    font-weight: 400;
  }
}

// latest

.sidebar {
  gap: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
}
.tabhead {
  height: 30px;
  padding: 10px;
  cursor: pointer;
  line-height: 31px;
  border-radius: 3px;
  margin: 10px 0 0;
  border: 1px solid #5a5a5a;

  span {
    font-size: 0.9rem;
    margin: 0 0 0 5px;
    display: inline-block;
  }
  i {
    display: block;
    margin: 2px 0 0;
    float: left;
  }
}
.tabhead.active {
  border-radius: 3px 3px 0 0;
}
.tabbody {
  margin: 0 0 10px;
  border-radius: 0 0 3px 3px;
  border: 1px solid #5a5a5a;
  border-top: 0;
}
.pad10 {
  padding: 10px;
}
.lightgreycolor {
  color: #acacac;
}
.innertab {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.innertabhead {
  margin: 0;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  line-height: 31px;
  border-radius: 3px;
  border: 1px solid #c6c6c6;

  span {
    margin: 0 0 0 5px;
    font-size: 0.9rem;
    display: inline-block;
  }
  i {
    float: right;
    display: block;
    margin: 2px 0 0;
  }
}
.innertabhead.active {
  border-radius: 3px 3px 0 0;
}
.innertabbody {
  font-size: 0.85rem;
  margin: 0 0 5px;
  border-radius: 0 0 3px 3px;

  p {
    margin: 7px 0 5px;
  }
  hr {
    border: 0;
    margin-top: 10px;
  }
}

.innertabsections {
  padding: 10px 0;
  min-height: 18px;

  .bttnframe {
    gap: 10px;
    display: flex;
    text-align: right;
    justify-content: end;
  }
}
.innertabbtnsections {
  padding: 10px 0;
  min-height: 30px;
  line-height: 30px;

  .colorpalette {
    margin-top: 7px;
  }
}

.divtext {
  float: left;

  .mdc-checkbox {
    padding: 8px;
  }
  .mdc-form-field {
    padding: 0;
    margin: 0;
    width: 32px;
    height: 18px;
  }
}

.colorpalette {
  float: left;
  padding: 0 5px;
  position: relative;

  .selectedcolor {
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
  }
}

.fontselect {
  gap: 5px;
  display: flex;

  .mdc-checkbox {
    margin: 0;
    padding: 8px;
  }
}
.fontstyle1 {
  width: 80%;
}
.alignbtns {
  height: 30px;

  a {
    height: 25px;
    padding: 3px;
    margin: 0 4px;
    color: #000;
    cursor: pointer;
    background: #fff;
    display: inline-block;
    border: 1px solid #9e9e9e;
  }
  a.active {
    color: #10384f !important;
    background: #b9def3 !important;
    border-color: #10384f !important;
  }
}

.halfcontent {
  display: flex;
  margin: 4px 0;

  .halfcont {
    width: 50%;
  }
}

.halfboxcont {
  display: flex;
  margin: 4px 0;
  gap: 10px;
  justify-content: center;

  .halfbox {
    width: 40%;
    padding: 20px;
    margin: 10px auto;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
}

.disabledcode {
  color: #9e9e9e;
}

.notelist {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
}
.notelistcheck {
  .mdc-checkbox {
    padding: 8px;
  }
  .mdc-form-field {
    padding: 0;
    margin: 0;
  }
}
.notelistquote {
  padding-right: 10px;
  padding-bottom: 5px;
}
.notelistquote,
.notelistdelete {
  word-break: break-all;
  padding-top: 10px;
  font-size: 14px;
  cursor: pointer;
}
.notelistdelete {
  margin-left: auto;
  margin-right: 10px;

  a {
    color: #000;
  }
}
.notebtns {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  text-align: center;
  justify-content: center;

  button {
    width: 80%;
  }
}

.bulkredact input[type="text"] {
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url("../assets/Search.svg");
}
.redactcaption {
  color: #9e9e9e;
  text-align: right;
}
.bulkredactbtns {
  gap: 10px;
  display: flex;
  margin: 10px 0 20px;
  justify-content: center;

  button {
    width: 40%;
    font-size: 88%;
  }
}
.innertabframe {
  padding: 0 10px;
}
.redactbtns {
  gap: 15px;
  display: flex;
  margin: 0 0 10px;

  button {
    width: 100px;
    color: #000 !important;
  }
}

.manualredacttab {
  gap: 6%;
  display: flex;
  justify-content: center;

  button {
    width: 44%;
    color: #000 !important;
  }
}

.viewerframe {
  height: 92vh;
}
.redactoverlayppd:before {
  content: "PPD";
  color: #000000;
}
.redactoverlaycci:before {
  content: "CCI";
  color: #ff0000;
}
