.lmnt-expansion-panel__content {
    overflow: hidden;
    transition-duration: 175ms;
    transition-property: max-height;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.lmnt-expansion-panel__trigger {
    cursor: pointer;
}
