@use '../../common/element.core' as element;
@use '@material/slider';
@use '@material/ripple';

@use '@material/slider/styles';

.mdc-slider.mdc-slider--secondary {
    --mdc-theme-primary: var(--lmnt-theme-secondary, --mdc-theme-secondary);
}

.lmnt-slider-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;

    .lmnt-slider-inner {
        flex-grow: 4;

        .mdc-slider {
            margin: 0 20px;
        }
    }
}

.lmnt-slider-info {
    flex-grow: 0;
}

.lmnt-slider-info--primary {
    color: var(--lmnt-theme-primary);
}

.lmnt-slider-info--secondary {
    color: var(--lmnt-theme-secondary);
}

.lmnt-slider-info-with-input {
    width: 4.375rem;
}

// Slider Scale disabled styles
// Colors icons gray at the start and end of slider
.lmnt-slider--disabled {
    .lmnt-slider-info {
        color: rgba(#000000, element.$emphasis-disabled);
    }
}
