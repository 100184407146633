@use '@material/form-field';
@use '../../common/element.core' as theme;
@use '../../common/mixins';

@include form-field.core-styles;

.lmnt {
    --form-field-color-on-primary: var(--lmnt-theme-on-primary);
    --form-field-color-on-secondary: var(--lmnt-theme-on-secondary);
}

.mdc-input-label {
    cursor: pointer;
}

@include mixins.on-primary {
    .mdc-form-field.lmnt-form-field {
        --mdc-theme-text-primary-on-background: var(--form-field-color-on-primary);
    }
}

@include mixins.on-secondary {
    .mdc-form-field.lmnt-form-field {
        --mdc-theme-text-primary-on-background: var(--form-field-color-on-secondary);
    }
}
