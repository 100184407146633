@use '@material/dialog';
@use '../../common/element.core';

@include dialog.core-styles;

.lmnt {
    --lmnt-dialog-shape-radius: var(--lmnt-theme--shape-radius);
    --lmnt-dialog__surface--max-width: 600px;
    --lmnt-dialog__surface--min-width: 600px;

    .lmnt-dialog.mdc-dialog {
        z-index: 9;
        .mdc-dialog__surface {
            border-radius: var(--lmnt-dialog-shape-radius);
        }
    }

    .mdc-dialog__surface {
        max-width: var(--lmnt-dialog__surface--max-width);
        min-width: var(--lmnt-dialog__surface--min-width);
    }

    .lmnt {
        .mdc-dialog__title + .mdc-dialog__content,
        .mdc-dialog__header + .mdc-dialog__content {
            padding-top: 8px;
        }
    }
}
