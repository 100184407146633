.st-demo-group {
    gap: 12px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.st-demo-group > div {
    width: 250px;
}
