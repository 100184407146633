///////////////////////
//////  Badge  ///////
///////////////////////

@use '../../common/element.core';
@use '../../common/mixins';
@use '../../common/functions' as functions;
@use '../utility/color/utility-colors.scss' as utility;
@use './badge-base';
@use './_badge-mixins' as bMixins;
//////////////////////
//////  Colors  //////
//////////////////////

.lmnt {
    &.lmnt-badge {
        &.lmnt-badge--label {
            --lmnt-badge-padding-left: 8px;
            --lmnt-badge-padding-right: 8px;
            --lmnt-badge-padding-top: 0;
            --lmnt-badge-padding-bottom: 0;
            --lmnt-label-badge-icon-margin: 4px;
            --lmnt-label-badge-icon-width: 12px;
            --lmnt-label-badge-icon-height: 12px;
            --lmnt-badge-shape-radius: 20px;
            --lmnt-badge-min-height: 16px;
            --lmnt-badge-max-height: unset;
            --lmnt-label-badge-default-bg-color: var(--lmnt-theme-primary-100);
            --lmnt-label-badge-default-color: var(--lmnt-theme-primary-900);

            background-color: var(--lmnt-label-badge-default-bg-color);
            color: var(--lmnt-label-badge-default-color);

            &.lmnt-badge--label-black {
                background-color: var(--lmnt-theme-black);
                color: var(--lmnt-theme-white);
            }

            &.lmnt-badge--label-primary-variant {
                background-color: var(--lmnt-theme-primary-variant);
                color: var(--lmnt-theme-on-primary-variant);
            }

            &.lmnt-badge--label-primary {
                background-color: var(--lmnt-theme-primary-100);
                color: var(--lmnt-theme-primary-900);
            }

            &.lmnt-badge--label-secondary {
                background-color: var(--lmnt-theme-secondary-100);
                color: var(--lmnt-theme-secondary-900);
            }

            &.lmnt-badge--label-danger {
                background-color: var(--lmnt-theme-danger-100);
                color: var(--lmnt-theme-danger-900);
            }

            &.lmnt-badge--label-success {
                background-color: var(--lmnt-theme-success-100);
                color: var(--lmnt-theme-success-900);
            }

            @each $color, $value in utility.$utilityColorMap {
                @include bMixins.labelUtilityBadgeClasses($color: $color, $value: $value);
            }

            &.lmnt-badge--label-trailing-icon {
                --lmnt-badge-padding-right: 6px;
            }

            &.lmnt-badge--label-leading-icon {
                --lmnt-badge-padding-left: 6px;
            }

            &.lmnt-badge--label-trailing-icon,
            &.lmnt-badge--label-leading-icon {
                display: flex;
                align-items: center;

                .lmnt-badge-label__leading-icon {
                    width: var(--lmnt-label-badge-icon-width);
                    height: var(--lmnt-label-badge-icon-height);
                    margin-right: var(--lmnt-label-badge-icon-margin);
                }

                .lmnt-badge-label__trailing-icon {
                    width: var(--lmnt-label-badge-icon-width);
                    height: var(--lmnt-label-badge-icon-height);
                    margin-left: var(--lmnt-label-badge-icon-margin);
                }
            }

            .lmnt-badge-label-icon {
                width: var(--lmnt-label-badge-icon-width);
                height: var(--lmnt-label-badge-icon-height);
            }

            .lmnt-badge--label__label {
                display: flex;
            }
        }
    }
}
