@use 'functions' as functions;
@use 'sass:map';

$white: rgba(#fff, 1);
$black: rgba(#000, 0.87);
///////////////////////////
///////  Media Query ///////
////////////////////////////

// TODO: document breakpoint rationale

// Dev discussion on GitHub
// https://github.platforms.engineering/element/element-core/issues/169

/* stylelint-disable scss/media-feature-value-dollar-variable */
@mixin for-size($size) {
    @if $size == phone-only {
        @media (max-width: 599px) {
            @content;
        }
    } @else if $size == tablet-portrait-up {
        @media (min-width: 600px) {
            @content;
        }
    } @else if $size == tablet-landscape-up {
        @media (min-width: 720px) {
            @content;
        }
    } @else if $size == desktop-up {
        @media (min-width: 840px) {
            @content;
        }
    } @else if $size == mid-desktop-up {
        @media (min-width: 1024px) {
            @content;
        }
    } @else if $size == big-desktop-up {
        @media (min-width: 1440px) {
            @content;
        }
    }
}
/* stylelint-enable scss/media-feature-value-dollar-variable */

//////////////////////////////
///////  Theme Utility ///////
//////////////////////////////

@mixin on-primary() {
    .lmnt-theme-primary-bg {
        @content;
    }
}

@mixin on-secondary() {
    .lmnt-theme-secondary-bg {
        @content;
    }
}

@mixin on-danger() {
    .lmnt-theme-danger-bg {
        @content;
    }
}

//////////////////////////////////////
///////  On Background Classes ///////
//////////////////////////////////////

// Note: utility classes for `background-color` and `color`
// Note: foreground `color` is determined by `color-contrast-value-check`
@mixin onBackgroundColorClasses(
    $color,
    $value,
    $themeBlack: $black,
    $themeWhite: $white
) {
    .lmnt-theme-#{$color}--bg, // Keeping both class names until v6.  For consistency with the DK kit we will have the double hyphen
    .lmnt-theme-#{$color}-bg {
        background-color: $value;
        color: functions.color-contrast-value-check(
            $value,
            $blackText: $themeBlack,
            $whiteText: $themeWhite
        );
    }

    .lmnt-theme-#{$color} {
        color: $value;
    }
}

@mixin onClasses($color, $value) {
    @if str-index($color, '-stroke') {
        .lmnt-theme-#{$color} {
            border-color: $value;
            border-bottom-color: $value;
        }
    } @else {
        .lmnt-theme-#{$color} {
            color: $value;
        }
    }
}

@mixin colorRamp($map, $themeBlack: $black, $themeWhite: $white) {
    @each $color, $value in $map {
        @include onBackgroundColorClasses(
            $color: $color,
            $value: $value,
            $themeBlack: $themeBlack,
            $themeWhite: $themeWhite
        );
    }
}

@mixin mapUtilityColors($class, $color, $value) {
    #{$class}#{$color} {
        background-color: #{map.get($value, 'surface')};
        color: #{map.get($value, 'on-surface')};
    }
}

@mixin mapCommonColors($class, $color, $value) {
    #{$class}#{$color} {
        background-color: #{map.get($value, 'surface')};
        color: #{map.get($value, 'on-color')};
    }
}

@mixin mapOutlineColors($class, $color, $value) {
    #{$class}#{$color} {
        border-color: #{map.get($value, 'on-surface')};
        color: #{map.get($value, 'on-surface')};
    }
}

@mixin gradientRamp($gradient-colors) {
    @each $colorName, $colorVal in $gradient-colors {
        @if str-index($colorName, 'to-top') {
            .lmnt-theme--bg-linear-gradient-#{$colorName} {
                background: linear-gradient(
                    0deg,
                    hsla(0, 0, 0, 0) 0,
                    rgba($colorVal, 0.8) 100%
                );
                color: functions.color-contrast-value-check(rgba($colorVal, 0.8));
            }
        } @else {
            .lmnt-theme--bg-linear-gradient-#{$colorName} {
                background: linear-gradient(
                    180deg,
                    hsla(0, 0, 0, 0) 0,
                    rgba($colorVal, 0.8) 100%
                );
                color: functions.color-contrast-value-check(rgba($colorVal, 0.8));
            }
        }
    }
}
