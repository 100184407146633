@use '../list/list.scss';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';

.lmnt {
    --lmnt-menu-shape-radius: var(--lmnt-theme--shape-radius);
    &.mdc-menu-surface.lmnt-menu-container,
    &.mdc-menu.lmnt-menu-container,
    &.mdc-menu-container.mdc-menu-surface--open {
        margin-bottom: 8px;
        margin-top: 8px;
        overflow-y: auto;
        border-radius: var(--lmnt-menu-shape-radius);

        &.lmnt-menu-open-up {
            margin-bottom: 8px;
            margin-top: 0;
        }

        &.lmnt-menu--flush {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &.mdc-menu-surface.lmnt-menu-container.lmnt-menu-surface--fullScreen {
        --lmnt-menu-shape-radius: 0;
        --mdc-menu-max-width: 100%;

        box-shadow: none;
        height: 100%;
        left: 0 !important; // Have to override Material's inline styles
        max-height: 100% !important; // Have to override Material's inline styles
        width: 100%;
    }
}
