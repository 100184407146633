@use '@material/dialog' as dialog;
@use '@material/icon-button/mdc-icon-button';
@use '../../common/element.core';

@include dialog.core-styles;

.lmnt {
    --lmnt-modal-shape-radius: var(--lmnt-theme--shape-radius);
}

.lmnt.lmnt-modal {
    .lmnt-modal__container {
        .lmnt-modal__surface {
            max-width: unset;
            min-width: unset;
            border-radius: var(--lmnt-modal-shape-radius);
        }

        &.mdc-dialog--fullscreen {
            .mdc-dialog__surface {
                max-width: 100vw;
                width: 100vw;
                max-height: 100vh;
                height: 100vh;
                border-radius: 0;
            }
        }

        &.lmnt-modal__max {
            margin: 40px;
            width: 100%;

            .lmnt-modal__surface {
                width: 100%;
            }
        }

        &.lmnt-modal__xl {
            .lmnt-modal__surface {
                width: 960px;
            }
        }

        &.lmnt-modal__lg {
            .lmnt-modal__surface {
                width: 720px;
            }
        }

        &.lmnt-modal__md {
            .lmnt-modal__surface {
                width: 560px;
            }
        }

        &.lmnt-modal__sm {
            .lmnt-modal__surface {
                width: 420px;
            }
        }

        &.lmnt-modal__dialog {
            .lmnt-modal__surface {
                min-width: 280px;
                max-height: calc(100% - 32px);
                max-width: 560px;
            }
        }

        .lmnt-modal__title {
            padding-left: 8px;
            padding-right: 8px;
            margin-top: 8px;
            display: flex;
            align-items: center;

            &.lmnt-modal--no-close-icon {
                padding-left: 24px;
            }

            .lmnt-modal__title-text {
                margin-left: 5px;
                height: 48px;
                display: flex;
                align-items: center;
            }

            .lmnt-dialog__title-actions {
                margin-left: auto;

                .lmnt-dialog__title-actions--icon-btn-group {
                    display: flex;
                }
            }
        }
    }

    .lmnt-dialog__actions__directional {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .lmnt-modal__button:last-child {
            justify-self: flex-end;
        }
    }

    .lmnt-modal__actions--supplemental-btn {
        justify-content: flex-start;

        .lmnt-modal_actions_button {
            margin-left: auto;
            margin-right: 16px;
        }
    }
}

.lmnt-modal__actions.mdc-dialog__actions {
    margin-bottom: 16px;
}

@media (max-width: 592px) {
    .lmnt.lmnt-modal {
        .lmnt-modal__container {
            &.lmnt-modal__max {
                .lmnt-modal__surface {
                    max-width: calc(100vw - 32px);
                }
            }

            &.lmnt-modal__xl {
                .lmnt-modal__surface {
                    max-width: calc(100vw - 32px);
                }
            }

            &.lmnt-modal__lg {
                .lmnt-modal__surface {
                    max-width: calc(100vw - 32px);
                }
            }

            &.lmnt-modal__md {
                .lmnt-modal__surface {
                    max-width: calc(100vw - 32px);
                }
            }

            &.lmnt-modal__sm {
                .lmnt-modal__surface {
                    max-width: calc(100vw - 32px);
                }
            }
        }
    }

    .lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions {
        flex-direction: column-reverse;
        align-items: flex-end;
        align-content: stretch;

        &.lmnt-modal__actions--supplemental-btn {
            flex-direction: row;
            justify-content: flex-start;
        }

        .lmnt-modal_actions_button,
        .lmnt-dialog__actions__supplemental,
        .mdc-touch-target-wrapper,
        .mdc-button {
            width: 100%;
        }
    }
}
.lmnt.lmnt-modal-no-actions {
    .lmnt-modal__content {
        .lmnt.mdc-circular-progress {
            overflow: hidden;
        }
    }
}

@media (max-width: 592px) {
    .lmnt.lmnt-modal {
        .lmnt-modal__container {
            &.lmnt-modal__xl {
                .lmnt-modal__surface {
                    max-width: 560px;
                }
            }
        }
    }
}

.lmnt-badge--modal {
    margin-left: auto;
    margin-right: 1em;
}
