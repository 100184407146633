@use 'sass:map';
// Used to override breakpoints in Material Grid
$breakpoints: (
    desktop: 840px,
    tablet: 600px,
    phone: 0px
) !default;
@use '@material/layout-grid/mdc-layout-grid';
@use '../../common/element.core' as core;

// TODO: refactor media queries into Element system
// TODO: refactor use of `!important`
// `!important` should only be used to override inline styles for third parties
// needed to override the padding variables because material mixins are not working https://github.com/material-components/material-components-web/issues/1879
.lmnt-layout-grid.mdc-grid-cell-wrapper-airy-tablet {
    padding: 24px 32px !important;
}
.lmnt-layout-grid.mdc-grid-cell-wrapper-none-tablet {
    padding: 0 !important;
}
.lmnt-layout-grid.mdc-grid-cell-wrapper-none-desktop {
    padding: 0 !important;
}
.lmnt-layout-grid.mdc-grid-cell-wrapper-none-phone {
    padding: 0 !important;
}

.lmnt-layout-grid__cell--horizontal-align-left {
    justify-content: flex-start;
}

.lmnt-layout-grid__cell--horizontal-align-right {
    justify-content: flex-end;
}

.lmnt-layout-grid__cell--horizontal-align-center {
    justify-content: center;
}

.lmnt-layout-grid .mdc-layout-grid__cell.lmnt-layout-grid__cell {
    display: flex;

    &.lmnt-layout-grid__cell--vertical-align-top {
        align-items: flex-start;
    }

    &.lmnt-layout-grid__cell--vertical-align-middle {
        align-items: center;
    }

    &.lmnt-layout-grid__cell--vertical-align-bottom {
        align-items: flex-end;
    }
}

.lmnt-layout-grid--full-width {
    width: 100%;
}

.lmnt-layout-grid--full-height {
    height: 100%;
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'tablet-lg-min')) and (max-width: map.get(core.$breakpointsMap, 'tablet-lg-max')) {
    .lmnt-layout-grid--with-max-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
    }
    // adjust gutter for tablet when breakpoint is between 720-839
    .lmnt {
        --mdc-layout-grid-gutter-tablet: 24px;
    }

    .mdc-grid-cell-wrapper-airy-tablet {
        padding: 24px 32px !important;
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-sm-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-sm-max')) {
    .lmnt-layout-grid--with-max-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
    }
    .mdc-grid-cell-wrapper-airy-desktop {
        padding: 24px 48px !important;
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-lg-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-lg-max')) {
    .lmnt-layout-grid--with-max-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
    }

    .mdc-grid-cell-wrapper-airy-desktop {
        padding: 24px 64px !important;
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-xl-min')) {
    .lmnt-layout-grid--with-max-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-xl');
    }

    .mdc-grid-cell-wrapper-airy-desktop {
        padding: 24px 80px !important;
    }
}
