@use '@material/floating-label/mdc-floating-label';
@use '@material/line-ripple/mdc-line-ripple';
@use '@material/notched-outline/mdc-notched-outline';
@use '@material/textfield';
@use '../../common/element.core';
@use './textfield-conflict.scss';
// TODO: deprecate styling based on `.lmnt-theme-primary-bg` in favor of variant styling
@use './textfield--on-primary' as on-primary;

// TODO: deprecate styling based on `.lmnt-theme-secondary-bg` in favor of variant styling
@use './textfield--on-secondary' as on-secondary;

@use './_textfieldVariables' as variables;

/////////////////////////
//////  Material  //////
///////////////////////

// Include Material styles first in source order
@include textfield.core-styles;

.lmnt {
    .lmnt-text-field.mdc-text .lmnt-text-field-inline-container {
        display: inline-block;
    }

    .lmnt-text-field--container-full-width {
        width: 100%;
    }
    .lmnt-text-field {
        border-radius: var(--lmnt-filled-text-field-shape-radius);
        &.mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: var(--lmnt-textfield-fill-color);

            + .mdc-text-field-helper-line .mdc-text-field-helper-text {
                color: var(--lmnt-helper-text-color);
            }

            + .mdc-text-field-helper-line
                .mdc-text-field-helper-text.mdc-text-field-helper-text--validation-msg {
                color: var(--lmnt-theme-danger);
            }
        }
    }
    .lmnt-text-field {
        &.mdc-text-field--outlined {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__trailing {
                --mdc-shape-small: var(--lmnt-text-field-shape-radius);
            }
        }
        &.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
            + .mdc-text-field-helper-line .mdc-text-field-helper-text {
                color: var(--lmnt-helper-text-color);
            }
            + .mdc-text-field-helper-line
                .mdc-text-field-helper-text.mdc-text-field-helper-text--validation-msg {
                color: var(--lmnt-theme-danger);
            }
        }
    }
    // NOTE: Disables the default "primary" colors Material puts in place for floating labels
    .lmnt-text-field {
        &.mdc-text-field--focused:not(.mdc-text-field--disabled) {
            .mdc-floating-label--float-above {
                color: var(--lmnt-text-field-primary);
            }
        }

        .mdc-text-field--focused {
            &:not(.mdc-text-field--disabled) {
                &:not(.mdc-text-field--required) {
                    /* stylelint-disable-next-line max-nesting-depth */
                    .mdc-floating-label {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }
    }

    ///////////////////////////////////////////
    //////  Improved counter alignment  //////
    /////////////////////////////////////////
    .lmnt-text-field--container-to-content-width {
        width: fit-content;
    }

    //////////////////////////
    //////  FullWidth  //////
    ////////////////////////
    .lmnt-text-field--full-width {
        width: 100%;
    }

    /////////////////////
    //////  Icons  //////
    /////////////////////

    // NOTE: Icon features were added by Element
    .lmnt-text-field--with-icon {
        .lmnt-text-field__icon {
            color: var(--lmnt-theme-icon-in-component);
        }

        // Outlined Textfield
        &.mdc-text-field--outlined {
            align-items: center;
        }

        // Danger / Validation
        &.mdc-text-field--invalid {
            .lmnt-text-field__icon {
                color: var(--lmnt-theme-danger);
            }
        }

        .lmnt-text-field__icon-button--leading {
            margin-left: 12px;
        }

        .lmnt-text-field__icon-button--trailing {
            margin-right: 12px;
        }

        .mdc-text-field__icon--trailing {
            padding-right: 16px;
        }
    }

    //////////////////////////////
    //////  Embedded Style  //////
    //////////////////////////////
    .lmnt-text-field--embedded {
        @extend .mdc-text-field--filled;
        @extend .lmnt-text-field--full-width;

        &:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
            background-color: initial;
        }
    }

    ////////////////////////////
    //////  Dense Style  //////
    //////////////////////////
    .lmnt-text-field--dense {
        @include textfield.density(-4);

        // These may or may not be needed, I don't see
        // any changes with or without these specified
        // but the mdc docs are unclear and the textarea
        // class below IS definitely required.
        &.mdc-text-field--outlined {
            @include textfield.outlined-density(-4);

            &.mdc-text-field--with-leading-icon {
                @include textfield.outlined-with-leading-icon-density(-4);
            }
        }

        &.mdc-text-field--textarea {
            @include textfield.outlined-textarea-density(-4);
        }
    }
}

//////////////////////////////////
//////  Type Enhancements  //////
////////////////////////////////
.lmnt-text-field__input--color {
    height: 1.5em;
    width: 1.8em;
}

/////////////////////////////////
//////  Legacy: On Colors  //////
/////////////////////////////////

// MIGRATION: Legacy "on-primary" and "on-secondary" styles must be included last in source order
// TODO: deprecate styling based on `.lmnt-theme-primary-bg` in favor of variant styling
// TODO: deprecate styling based on `.lmnt-theme-secondary-bg` in favor of variant styling
@include on-primary.core-styles;
@include on-secondary.core-styles;
