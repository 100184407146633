@use '../../common/element.core';
@use '@material/card';
@use '@material/typography';

@include card.core-styles;

.mdc-product-card {
    @include typography.typography($style: body2);
    background-color: var(--lmnt-theme-surface);
    color: var(--lmnt-theme-on-surface);
    max-width: 100%;
    min-width: 15rem;
    overflow: hidden;
}

.mdc-product-card__box {
    padding: 10px 10px 10px 16px;
    position: relative;

    &:first-child,
    &:last-child {
        background-color: var(--lmnt-theme-primary);
        color: var(--lmnt-theme-on-primary);
    }
}

.mdc-product-card__actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    * {
        margin: 0 auto;
    }
}

.mdc-product-card__tagline {
    background-color: var(--lmnt-theme-secondary);
    color: var(--lmnt-theme-on-secondary);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1;
    padding: 0.125em 0.2em;
    position: absolute;
    right: 12px;
    text-transform: uppercase;
    top: 0;
}

.mdc-product-card__avatar {
    border-radius: 50%;
    height: 40px;
    overflow: hidden;
    width: 40px;
}

.mdc-product-card__avatar-img {
    display: block;
    height: auto;
    width: 100%;

    @supports (object-fit: cover) {
        height: 100%;
        object-fit: cover;
    }
}

// product title parts
.mdc-product-card__header {
    padding-bottom: 14px;
    padding-top: 16px;

    .mdc-product-card__avatar {
        position: absolute;
        right: 10px;
        top: 23px;
    }
}

.mdc-product-card__identity {
    margin: 0;
    word-break: break-all;
}

.mdc-product-card__crop-type {
    @include typography.typography($style: overline);

    // color: $lmnt-product-card-on-header--overline;

    opacity: 0.38;
}

.mdc-product-card__sku-name {
    @include typography.typography($style: headline6);

    // color: $lmnt-product-card-on-header--title;

    opacity: 0.87;
}

.mdc-product-card__subhead {
    @include typography.typography($style: body2);

    // color: $lmnt-product-card-on-header--subtitle;

    opacity: 0.6;
}

.mdc-product-card__crop-type,
.mdc-product-card__sku-name,
.mdc-product-card__subhead {
    display: block;
}

// features use the same label class and style
.mdc-product-card__label {
    @include typography.typography($style: overline);

    // color: $lmnt-product-on-card-label;

    font-size: 12px;
    line-height: 1.333;
    margin: 0;
    opacity: 0.6;
}

.mdc-product-card__feature {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 10px 16px 16px;

    .mdc-product-card__avatar {
        margin: -6px 0;
    }
}

// content in these boxes come from RTE / WYSIWYG sources
.mdc-product-card__editor-content {
    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    ul {
        padding: 0 0 0 1.1em;
    }

    table {
        width: 100%;
    }

    td {
        padding: 0.5em 0;

        &:nth-child(odd) {
            padding-right: 1em;
        }

        &:nth-child(even) {
            padding-left: 1em;
        }
    }
}
