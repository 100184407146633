@use '@material/textfield/variables' as materialVariables;

:root,
.lmnt {
    --lmnt-helper-text-color: #{materialVariables.$helper-text-color};
    --lmnt-textfield-fill-color: #{materialVariables.$background};
    --lmnt-text-field-primary: var(--lmnt-theme-primary-on-surface);
    --lmnt-text-field-shape-radius: var(--lmnt-theme--shape-radius);
    --lmnt-filled-text-field-shape-radius: 0;
}
