@use '@material/checkbox'; // Required only for data table with row selection.
@use '@material/icon-button'; // Required only for data table with column sorting.
@use '@material/data-table/data-table';
@use '@material/data-table' as table;
@use '../../common/element.core' as theme;

// Start MDC 14 conflict styles

.lmnt .mdc-data-table__cell,
.lmnt .mdc-data-table__header-cell {
    border-bottom-color: unset;
    border-bottom-style: unset;
    border-bottom-width: unset;
}

.lmnt .mdc-data-table__pagination {
    border-top-color: unset;
    border-top-style: unset;
    border-top-width: unset;
    min-height: unset;
}

.lmnt .mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom: none;
}

.lmnt .mdc-data-table__header-cell {
    color: unset;
}

.lmnt .mdc-data-table__pagination-total,
.lmnt .mdc-data-table__pagination-rows-per-page-label,
.lmnt .mdc-data-table__cell {
    color: unset;
}

.lmnt .mdc-data-table__row {
    height: unset;
}

.lmnt .mdc-data-table__header-row {
    height: unset;
}

// End MDC 14 conflict styles

@include checkbox.core-styles;
@include icon-button.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

$virtual-font-size: 0.875rem;
$virtual-cell-padding-y: 0.75rem;
$virtual-cell-padding-x: 1rem;
$virtual-header-cell-padding: 0.5rem;
$virtual-border: 1px solid #000;

:root {
    --table-bg-color: #fff;
    --table-bg-color-hover: #f5f5f5;
    --table-bg-color-striped: #eee;
    --lmnt-table__header-color: rgba(0, 0, 0, #{theme.$emphasis-high});
}

//
// Shared Styles
//
.lmnt-table {
    @include table.density(-1);
}

.lmnt-table--tall {
    @include table.density(0);
}

.lmnt-table--dense {
    @include table.density(-3);
}

.lmnt-table--full-width {
    width: 100%;
}

.lmnt-table__top-bar {
    &--standard-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .lmnt-table__top-bar__title {
            padding: 16px 24px;
        }

        &--action-items {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0 24px;

            .lmnt-text-field--embedded {
                margin-right: 16px;
            }
        }
    }

    &--filter-bar {
        height: 64px;
    }
}

//
// Sorting
//
.lmnt-table__sort-icon-button--visible {
    opacity: 1;
}

.lmnt-table__header-cell {
    color: var(--lmnt-table__header-color);
    font-size: 12px;
    font-weight: bold;
}

// HACK: The header border does not show up in virtualized mode for some reason. These two rules fake, though they are not perfect.
.lmnt-table--virtualized .lmnt-table__header-row {
    border-bottom-color: var(--lmnt-theme-on-surface-stroke);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.lmnt-table--virtualized .mdc-data-table__row.lmnt-table__row:first-child {
    border-top-width: 0;
}

.lmnt-table__header-cell--dragging {
    border: solid 1px #ddd;
    box-shadow: 3px 3px 4px -3px rgba(0, 0, 0, 0.36);
    z-index: 8;
}

.lmnt .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    background-color: var(--table-bg-color-hover);
}

//
// Builder Specific Styles
//

.lmnt-table__resize-handle {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23d9d9d9' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    cursor: col-resize;
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.3em;
}

.lmnt-table____sticky-border {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    border-right: none;
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
}

.lmnt-table__table--row-striped {
    .mdc-data-table__row:nth-child(odd),
    .lmnt-table__row:nth-child(odd) {
        background-color: var(--table-bg-color-striped);
    }
}

.lmnt-table__table--column-striped {
    .mdc-data-table__cell:nth-child(odd),
    .lmnt-table__cell:nth-child(odd) {
        background-color: var(--table-bg-color-striped);
    }
}

.lmnt-table__leading-fixed-cell {
    // display: inline-flex;
    background-color: inherit;
    left: 0;
    position: sticky;
    z-index: 3 !important;
}

// TODO: I'm not sure why the space before the after works here.
.lmnt-table__leading-fixed-cell .lmnt-table__cell-content::after {
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
}

.lmnt-table__leading-fixed-cell--trailing-border .lmnt-table__cell-content::after {
    border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mdc-data-table__header-row {
    background-color: var(--table-bg-color);
    z-index: 4 !important;

    .mdc-data-table__header-cell {
        &.lmnt-table__leading-fixed-cell {
            z-index: 5 !important;
        }
    }
}

// HACK: I wish I had a better way to vertically center the content in virtualized mode, but this seems to
// work without screwing up virtualized layout. The downside is it does require an extra div during render.
// To keep things simple, I'm just leaving the div in all the time, virtualized or not.
.lmnt-table__cell-content {
    align-items: center;
    display: flex;
    height: 100%;
}

.lmnt-table__trailing-fixed-cell {
    background-color: inherit;
    position: sticky;
    z-index: 3;
}

// Override the way row hovering works to allow for sticky columns
.mdc-data-table__row {
    background-color: var(--table-bg-color);
}


// not used?
// disable hover effect
.lmnt-table--no-hover .mdc-data-table__row:hover {
    background-color: inherit;
}

.lmnt-table--no-hover {
    .mdc-data-table__row:hover {
        background-color: inherit;
    }

    .lmnt-table__leading-fixed-cell,
    .lmnt-table__trailing-fixed-cell {
        background-color: var(--table-bg-color);
    }
}

.lmnt-table__leading-fixed-column-shadow {
    box-shadow: 3px 0 4px -1px rgba(0, 0, 0, 0.12);
    height: 100%;
    padding-right: 6px;
    position: sticky;
    width: 0;
    z-index: 8;
}

.lmnt-table__trailing-fixed-column-shadow {
    box-shadow: -3px 0 4px -1px rgba(0, 0, 0, 0.12);
    height: 100%;
    padding-left: 6px;
    position: sticky;
    width: 0;
    z-index: 8;
}

.lmnt-table__resize-indicator {
    cursor: col-resize;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity linear 75ms;
    width: 0;
    z-index: 3;

    &::after {
        background-image: url('data:image/svg+xml; utf8, <svg viewBox="0 0 3.8 50" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><line x1="1.9" y1="0" x2="1.9" y2="50" stroke="black" stroke-width="1" stroke-dasharray="2 1" /></svg>');
        background-size: 100%;
        content: '';
        height: 100%;
        left: -0.5rem;
        position: absolute;
        top: 0;
        transform: scaleX(1);
        transition: transform linear 75ms;
        width: 1rem;
    }

    &.lmnt-table__resize-indicator--last {
        justify-content: flex-end;
        width: 0.4rem;
    }
}

// Theme integration

.lmnt-table__header-theme--primary .lmnt-table__header-cell {
    background-color: var(--lmnt-theme-primary);
    color: var(--lmnt-theme-on-primary);
}

.lmnt-table__header-theme--secondary .lmnt-table__header-cell {
    background-color: var(--lmnt-theme-secondary);
    color: var(--lmnt-theme-on-secondary);
}

.lmnt-table__header-theme--primary-variant .lmnt-table__header-cell {
    background-color: var(--lmnt-theme-primary-variant);
    color: var(--lmnt-theme-on-primary-variant);
}

.lmnt-table__header-theme--secondary-variant .lmnt-table__header-cell {
    background-color: var(--lmnt-theme-secondary-variant);
    color: var(--lmnt-theme-on-secondary-variant);
}

.lmnt-table__header-divider--primary .lmnt-table__header-cell {
    border-bottom-color: var(--lmnt-theme-primary);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.lmnt-table__header-divider--secondary .lmnt-table__header-cell {
    border-bottom-color: var(--lmnt-theme-secondary);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.lmnt-table__header-divider--primary-variant .lmnt-table__header-cell {
    border-bottom-color: var(--lmnt-theme-primary-variant);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.lmnt-table__header-divider--secondary-variant .lmnt-table__header-cell {
    border-bottom-color: var(--lmnt-theme-secondary-variant);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.lmnt-table__header-divider--tall .lmnt-table__header-cell {
    border-bottom-width: 4px;
}

.lmnt-table__cell--nestable.mdc-data-table__cell {
    padding-left: 0;
    padding-right: 0;
}

.lmnt-table--nested {
    border-width: 0;
}

.lmnt-table--fixed-width {
    .lmnt-table__header {
        overflow: hidden;
    }

    .mdc-data-table__content {
        overflow-y: auto;
    }
}

.lmnt-table__no-content {
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: center;
}

.lmnt-table__hide-horizontal-scrollbar {
    ::-webkit-scrollbar {
        background-color: transparent;
        height: 0;
        width: 0;
    }
}

.lmnt-table__block-fixed-header {
    display: flex;
    flex-direction: column;
    height: 100%;

    thead {
        display: block;
        flex-basis: max-content;
        flex-grow: 0;
        flex-shrink: 1;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            background: transparent;
            display: none;
            width: 0;
        }
    }

    tbody {
        display: block;
        flex: 1;
        overflow-y: auto;
    }

    tr {
        display: flex;
        width: 100%;

        td,
        th {
            display: block;
            flex: 1;
        }
    }
}

// TODO: These can probably be combined with the block rule above
.lmnt-table__flex-fixed-header {
    display: flex;
    flex-direction: column;
    height: 100%;

    thead {
        display: block;
        flex-basis: max-content;
        flex-grow: 0;
        flex-shrink: 1;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            background: transparent;
            display: none;
            width: 0;
        }
    }

    tbody {
        // display: block;
        flex: 1;
        overflow-y: auto;
    }

    // tr {
    //     display: flex;
    //     width: 100%;

    //     td,
    //     th {
    //         display: block;
    //         flex: 1;
    //     }
    // }
}
