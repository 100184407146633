@use '@material/feature-targeting/feature-targeting';
@use '@material/list/mdc-list';
@use '@material/select' as select;
@use '@material/select/styles';
@use '@material/select/select-theme' as select-theme;
@use '@material/select/select-icon-theme' as select-icon-theme;
@use '@material/floating-label/mixins' as floating-label-mixins;
@use '@material/floating-label/variables' as floating-label-variables;
@use '@material/rtl/mixins' as rtl-mixins;
@use '@material/notched-outline/mixins' as notched-outline-mixins;
@use '@material/notched-outline/variables' as notched-outline-variables;
@use '../../common/element.core' as element;
@use '../list/list.scss';
@use '../chips/chipsVariables' as chips;
@use '../textfield/textfieldVariables' as textfield;
@use '../menu/menu.scss';

$select-color: rgba(0, 0, 0, 0.6);

$feat-structure: feature-targeting.create-target(feature-targeting.all(), structure);
:root,
.lmnt {
    --lmnt-select-fill-color: var(--lmnt-textfield-fill-color);
    --lmnt-select-shape-radius: var(--lmnt-theme--shape-radius);
    --lmnt-filled-select-shape-radius: 0;
}

// BUG: This breaks any select with a value
// .lmnt.lmnt-select-value-container.lmnt__value-container.lmnt__value-container--has-value {
//     overflow: scroll;
// }

.lmnt-select-container {
    position: relative;
    width: 100%;

    .lmnt-select-inner-container {
        width: 100%;
    }

    &.lmnt.lmnt-select--outlined.lmnt-select--dense {
        .lmnt.lmnt-select-multi-value-container {
            line-height: 14px;
        }

        .lmnt-select--multi.lmnt-select--value-display-pills {
            min-height: 40px;

            &.lmnt-select--has-value {
                .mdc-select__anchor {
                    .lmnt-select-value-container,
                    .lmnt-select-leading-icon,
                    .lmnt-select-indicators-container {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
        background-color: var(--lmnt-select-fill-color);
    }
}

.lmnt-select-label {
    color: $select-color;
}

.lmnt-select {
    .lmnt-select-control-container {
        width: 100%;
    }

    .lmnt-select-indicators-container {
        align-self: center;
        height: 24px;
        margin-top: 2px;
    }

    .lmnt-select-leading-icon {
        height: 24px;
    }

    .lmnt.lmnt-select-value-container {
        padding-left: 0px;
        margin-left: -2px;
    }

    .mdc-select__anchor {
        min-height: 56px;
        width: unset;
        border-radius: var(--lmnt-filled-select-shape-radius);
    }

    .lmnt-select-dropdown-container {
        padding: 0;
        padding-right: 8px;
        margin-bottom: 4px;

        .mdc-select__dropdown-icon {
            margin: 0;
            color: $select-color;
        }
    }

    .lmnt-select-clear--icon {
        color: $select-color;
    }

    &.lmnt-select--multi.lmnt-select--value-display-text {
        .mdc-select__anchor {
            & > .lmnt-select-value-container {
                align-items: center;
                box-sizing: border-box;
                display: flex;
                flex: 1;
                flex-wrap: unset;
                overflow: hidden;
                padding: 2px 0px;
                position: relative;
            }

            &.lmnt-select__anchor--extended-height {
                & > .lmnt-select-value-container {
                    padding-bottom: 10px;
                    padding-top: 10px;
                }
            }
        }

        &.lmnt-select--fixed-height {
            height: 56px;

            .mdc-select__anchor {
                height: 56px;
                .lmnt-select-value-container {
                    height: 56px;
                }
            }
        }
    }

    &.lmnt-select--multi.lmnt-select--value-display-pills {
        &.lmnt-select--fixed-height {
            .mdc-select__anchor {
                overflow-x: auto;

                .lmnt-select-value-container-scroll-wrapper {
                    overflow-x: auto;
                }

                .lmnt-select-value-container {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    align-items: flex-end;
                    margin-bottom: 8px;

                    .lmnt-select-value-container {
                        flex-wrap: wrap;
                    }

                    .lmnt.lmnt-select-multi-value-container {
                        min-width: fit-content;
                    }
                }

                .lmnt-select-indicators-container {
                    margin-left: auto;
                }
            }
        }

        .mdc-select__anchor {
            align-items: center;
        }
    }
}

.lmnt.lmnt-select--filled {
    &.mdc-select--filled {
        .mdc-select__anchor {
            height: unset;
        }
    }

    &.lmnt-select--dense {
        @include select.filled-density(-4);

        .mdc-select__anchor {
            min-height: 40px;
            align-items: center;
        }

        .mdc-floating-label--float-above {
            transform: none;
            display: none;
        }
    }

    &.lmnt-select--value-display-pills.lmnt-select--multi {
        min-height: 68px;

        &.lmnt-select--has-value {
            .lmnt-select__anchor--extended-height {
                padding-top: 23px;
                transition: padding-top 150ms cubic-bezier(0.4, 0, 0.2, 1);

                .lmnt-select-indicators-container {
                    align-self: flex-end;
                    margin-bottom: 12px;
                    margin-top: 0;
                }
            }
        }

        &.lmnt-select--fixed-height {
            height: 68px;

            .mdc-select__anchor {
                height: 68px;
                .lmnt-select-value-container {
                    height: 68px;
                }
            }
        }

        .lmnt-select-label {
            @include feature-targeting.targets($feat-structure) {
                top: 35px;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }

        .mdc-select__anchor {
            min-height: 68px;
        }

        &.lmnt-select--has-value {
            .mdc-select__anchor {
                .lmnt-select-indicators-container,
                .lmnt-select-leading-icon {
                    height: 32px;
                }

                .lmnt-select-leading-icon {
                    margin-top: 24px;
                }

                & > .lmnt-select-value-container {
                    align-self: flex-end;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }

                .lmnt-select-indicators-container {
                    margin-top: 16px;
                }

                &.lmnt-select__anchor--extended-height .lmnt-select-leading-icon {
                    align-self: flex-end;
                    height: unset;
                    margin-bottom: 12px;
                }
            }
        }
    }

    &.lmnt-select--value-display-text {
        .lmnt-select__anchor--extended-height {
            padding-bottom: 8px;

            .lmnt-select-leading-icon {
                align-self: flex-end;
            }

            .lmnt-select-indicators-container {
                align-self: flex-end;
            }
        }
    }

    .lmnt-select-label {
        $feat-structure: feature-targeting.create-target(
            feature-targeting.all(),
            structure
        );
        @include feature-targeting.targets($feat-structure) {
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }

        left: 16px;
    }
    &.lmnt-select--with-leading-icon {
        @include select-theme.leading-icon-floating-label-position(
            select-icon-theme.$icon-size
        );

        @include select-theme.truncate-floating-label-max-width(
            $leading-icon-size: select-icon-theme.$dense-icon-size,
            $dropdown-icon-size: select-icon-theme.$dense-icon-size
        );
    }

    @include select-theme.truncate-floating-label-max-width(
        $leading-icon-size: 0,
        $dropdown-icon-size: select-icon-theme.$dense-icon-size
    );

    .lmnt-select-label.mdc-floating-label--float-above {
        top: 8px;
        transform: scale(0.75);
        transition: top 150ms cubic-bezier(0.4, 0, 0.2, 1),
            color 150ms cubic-bezier(0.4, 0, 0.2, 1);
        line-height: 16px;
    }

    .lmnt-select-dropdown-container {
        margin-bottom: 0;
    }
}

.lmnt.lmnt-select--filled {
    .lmnt.lmnt-select-multi-value-container {
        align-items: center;
        color: $select-color;
        background-color: var(--lmnt-theme-surface);
        border-radius: var(--lmnt-chip-shape-radius);
        padding: 6px 8px 6px 12px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        font-size: 14px;
        line-height: 20px;
        margin: 2px;

        .lmnt.lmnt-select-multi-value-container {
            padding: 0;
            border: none;
            margin: 0;
        }

        .lmnt-select-multi-value-remove--icon {
            color: var(--lmnt-theme-icon-in-component);
            font-size: 15px;
            height: 15px;
            padding-left: 4px;
            width: 15px;

            &:hover {
                color: rgba(0, 0, 0, 0.62);
            }

            &:focus,
            &:active {
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

.lmnt.lmnt-select--outlined {
    border-radius: var(--lmnt-select-shape-radius);
    &.lmnt-select--dense {
        @include select.outlined-density(-4);

        .mdc-select__anchor {
            min-height: 40px;
        }
    }

    @include select-theme.truncate-notched-outline-max-width(
        $leading-icon-size: 0,
        $dropdown-icon-size: select-icon-theme.$icon-size
    );

    &.lmnt-select--with-leading-icon {
        @include select-theme.outlined-with-leading-icon-floating-label-position-animation(
            select-theme.$height,
            select-icon-theme.$icon-size
        );

        @include select-theme.truncate-notched-outline-max-width(
            $leading-icon-size: select-icon-theme.$icon-size,
            $dropdown-icon-size: select-icon-theme.$icon-size
        );

        .mdc-floating-label.mdc-floating-label--float-above {
            position: absolute;
            left: 16px;
        }
    }

    @include select-theme.outline-color(
        (
            default: select-theme.$outlined-idle-border,
            hover: select-theme.$outlined-hover-border,
            focus: primary,
            disabled: select-theme.$disabled-outline-color
        )
    );

    .lmnt-select-label {
        $feat-structure: feature-targeting.create-target(
            feature-targeting.all(),
            structure
        );
        @include feature-targeting.targets($feat-structure) {
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    @include floating-label-mixins.shake-animation(select-outlined);
    @include notched-outline-mixins.floating-label-float-position-absolute(
        select-theme.$outlined-label-position-y
    );
    @include notched-outline-mixins.notch-offset(notched-outline-variables.$border-width);

    .mdc-select__anchor {
        @include select-theme.center-aligned();

        @include feature-targeting.targets($feat-structure) {
            display: flex;
            align-items: center;
            overflow: visible;
            height: unset;
            min-height: 56px;
        }
    }

    &.lmnt-select--value-display-pills.lmnt-select--multi {
        .lmnt-select__anchor--extended-height {
            padding-top: 10px;
            transition: padding-top 150ms cubic-bezier(0.4, 0, 0.2, 1);

            .lmnt-select-indicators-container {
                align-self: flex-end;
                margin-bottom: 12px;
            }
        }

        &.lmnt-select--has-value {
            .mdc-select__anchor {
                align-items: flex-end;
                height: unset;

                .lmnt-select-indicators-container,
                .lmnt-select-leading-icon {
                    height: 32px;
                }

                & > .lmnt-select-value-container {
                    margin-bottom: 5px;
                    margin-top: 5px;
                }

                .lmnt-select-leading-icon {
                    margin-bottom: 9px;
                }
            }
        }
    }

    .lmnt-select-menu {
        top: 100%;
    }

    .lmnt.lmnt-select-multi-value-container {
        background-color: chips.$lmnt-chip-selected-fill-color;
        border-color: transparent;
        border-style: solid;
        border-width: 1px;
        color: $select-color;
        border-radius: 16px;
        padding: 6px 8px 6px 12px;
        font-size: 14px;
        line-height: 20px;
        margin: 2px;

        .lmnt.lmnt-select-multi-value-container {
            padding: 0;
            border: none;
            margin: 0;
        }

        .lmnt-select-multi-value-remove--icon {
            color: var(--lmnt-theme-icon-in-component);
            font-size: 15px;
            height: 15px;
            padding-left: 4px;
            width: 15px;
            &:hover {
                color: rgba(0, 0, 0, 0.62);
            }

            &:focus,
            &:active {
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

.lmnt-select-menu {
    .lmnt-list.mdc-list {
        padding: 0;
    }

    .lmnt-select__confirmation {
        display: flex;
        justify-content: flex-end;
        padding: 8px 8px;

        .lmnt-select__cancel-button {
            margin-right: 8px;
        }
    }

    .lmnt-select__option-filter {
        display: flex;
        padding: 0 16px;

        .mdc-list-item__ripple {
            &:before,
            &:after {
                content: unset;
            }
        }

        .lmnt-select__option-filter-switch {
            margin-left: auto;
        }
    }

    .lmnt-select__select-all-checkbox {
        padding-left: 8px;
        height: 56px;

        .mdc-form-field {
            vertical-align: bottom;

            > label {
                padding-left: 24px;
            }
        }
    }

    .lmnt-select__menu-item {
        &.lmnt-select__menu-item--has-focus {
            background-color: rgba(0, 0, 0, 0.12);
        }

        height: auto;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .lmnt-select-hidden-item {
        display: none;
    }
}

.lmnt-select--is-disabled {
    .lmnt-select-control-container {
        background-color: transparent;
    }

    .lmnt-select-label {
        color: var(--lmnt-theme-on-surface-disabled, rgba(0, 0, 0, 0.38));
    }

    .lmnt__single-value.lmnt__single-value--is-disabled {
        color: var(--lmnt-theme-on-surface-disabled, rgba(0, 0, 0, 0.38));
    }
}

.lmnt-select__content {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #fff;
}

.lmnt-select__content--trailing {
    padding-bottom: 16px;
    padding-top: 8px;
}

.lmnt-select__content--leading {
    padding-bottom: 8px;
    padding-top: 16px;
}

.lmnt__menu-portal {
    transition: top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.lmnt.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled)
    .mdc-notched-outline__leading,
.lmnt.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled)
    .mdc-notched-outline__notch,
.lmnt.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled)
    .mdc-notched-outline__trailing {
    border-color: var(--mdc-theme-error, #b00020);
}
.lmnt .mdc-notched-outline__leading,
.lmnt .mdc-notched-outline__trailing {
    --mdc-shape-small: var(--lmnt-select-shape-radius);
    padding-left: 16px;
}

// React Select Styles
.lmnt-select-container {
    .lmnt-select-indicators-container {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
        border-radius: var(--lmnt-select-shape-radius);
    }

    .lmnt-select-menu {
        border-radius: var(--lmnt-select-shape-radius);
        box-shadow: 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%),
            0px 2px 4px -1px rgb(0 0 0 / 20%);
        margin-bottom: 8px;
        margin-top: 0;
        position: absolute;
        width: 100%;
        z-index: 11;
        box-sizing: border-box;
        left: 0;
        border: none;
        height: fit-content;
    }

    .lmnt__multi-value {
        box-sizing: border-box;
        display: flex;
        min-width: 0;
    }

    .lmnt__multi-value__label {
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lmnt__multi-value__remove {
        align-items: center;
        box-sizing: border-box;
        display: flex;
    }

    .lmnt__group {
        padding-bottom: 8px;
        padding-top: 8px;
        box-sizing: border-box;
    }

    .lmnt__menu-notice--no-options {
        color: $select-color;
        padding: 8px 12px;
        text-align: center;
        box-sizing: border-box;
    }

    .lmnt__single-value {
        .lmnt__single-value--is-disabled {
            color: rgba(0, 0, 0, 0.38);
        }
        color: hsl(0, 0%, 20%);
        margin-left: 2px;
        margin-right: 2px;
        max-width: calc(100% - 8px);
        position: absolute;
        white-space: nowrap;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
    }

    .lmnt__value-container {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        overflow: hidden;
        padding: 2px 8px;
        padding-left: 0px;
        position: relative;

        input:read-only {
            background: 0;
            border: 0;
            font-size: inherit;
            outline: 0;
            padding: 0;
            width: 1px;
            color: transparent;
            left: -100px;
            opacity: 0;
            position: relative;
            transform: scale(0);
        }
    }
}

/** VUE SELECT styles */
.lmnt-vue-select.lmnt.lmnt-select.mdc-select {
    --min-height: 56px;
    --min-height-dense: 40px;

    // getting deeper
    // .multiselect:deep(.multiselect__select) {
    .multiselect {
        min-height: var(--min-height);
        border-radius: 5px;
        box-sizing: content-box;
        display: block;
        position: relative;
        width: 100%;
        text-align: left;
        color: #35495e;

        // chevron
        .multiselect__select {
            height: var(--min-height);
            line-height: 16px;
            box-sizing: border-box;
            display: block;
            margin: 0;
            text-decoration: none;
            cursor: pointer;
            position: absolute;
            width: 40px;
            right: 1px;
            top: 1px;
            padding: 4px 8px;
            text-align: center;
            transition: transform 0.2s ease, -webkit-transform 0.2s ease;
            &:before {
                position: relative;
                right: 0;
                top: 65%;
                color: #999;
                margin-top: 4px;
                border-style: solid;
                border-width: 5px 5px 0 5px;
                border-color: #999999 transparent transparent transparent;
                content: '';
            }
        }

        .multiselect__tags {
            border: none; /** Hide original border */
            display: flex;
            align-items: center;
            justify-content: flex-start; /** ensure the option's where it should be */
            padding: 0;
            margin-bottom: 0;
            min-height: var(--min-height);
            background: transparent;

            /** CONTAINER FOR A SINGLE OPTION */
            .multiselect__single {
                /** reset original container left padding */
                padding: 0 0 0 16px;
                background-color: transparent;
                margin-bottom: 0;
                width: unset;
            }

            /** CONTAINER FOR MULTIUPLE OPTIONS */
            .multiselect__tags-wrap {
                padding: 0 0 0 8px;
                display: flex;
                flex-wrap: wrap;
            }

            /** Hide original placeholder */
            .multiselect__placeholder {
                display: none;
            }

            .multiselect__input::placeholder {
                opacity: 0;
            }
        }

        .multiselect__content-wrapper {
            position: absolute;
            display: block;
            max-height: 240px;
            width: 100%;
            z-index: 50;
            border: none;
            box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
                0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
            border-radius: 5px;
            background-color: var(--lmnt-select-fill-color);
            overflow-y: auto;

            .multiselect__content {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                width: 100%;
                padding: 8px 0;
                margin: 0;

                .multiselect__element {
                    display: flex;
                    position: relative;
                    height: 48px;
                    align-items: center;
                    align-items: stretch;
                    justify-content: flex-start;
                    padding-left: 0;
                    overflow: hidden;
                    cursor: pointer;

                    /** styling the "option items" */
                    .multiselect__option {
                        display: flex;
                        position: relative;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                        margin: auto;
                        padding: 0;
                        padding-left: 0;
                        line-height: unset;
                        color: black;

                        /** GENTLY DESTYLING SELECT OPTIONS: getting rid of the original green/red option backgrounds */
                        background: unset;

                        &.multiselect__option--highlight {
                            background: rgba(
                                0,
                                0,
                                0,
                                0.11
                            ); /** this is what makes an option _active_ */
                            color: black;
                        }

                        .lmnt.mdc-list-item {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.multiselect--active {
            // chevron flip on open
            .multiselect__select {
                transform: rotate(180deg);
            }
        }
    }

    /** FILLED SELECT CONTAINER */
    &.lmnt-select--filled {
        border-top-left-radius: var(--mdc-shape-small, 4px);
        border-top-right-radius: var(--mdc-shape-small, 4px);
        background-color: var(--lmnt-theme-surface);

        .multiselect.lmnt-select--multi.lmnt-select--value-display-pills {
            --min-height: 68px;
        }

        .multiselect:not(.mdc-select--disabled) {
            background-color: var(--lmnt-select-fill-color);
        }

        /** FILLED SELECTED OPTION vertical positioning */
        /** ... for single select */

        .mdc-select:not(.lmnt-select--value-display-pills):not(.lmnt-select--dense)
            .multiselect__tags {
            align-items: baseline;
        }

        .mdc-select .multiselect__tags::before {
            display: inline-block;
            width: 0;
            height: 40px;
            content: '';
            vertical-align: 0;
        }

        /** ... for multiselect select */
        .lmnt-select--multi.mdc-select {
            .multiselect__tags-wrap::before {
                display: inline-block;
                width: 0;
                height: 46px;
                content: '';
                vertical-align: 0;
            }
            .multiselect__tags-wrap {
                align-items: baseline;

                /** OPTION PILLS */
                .lmnt__multi-value {
                    /** prevent double-click text selection */
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                }
            }

            /* ...the filled multiselect is a bit higher, the list should be a bit lower to reveal the ripple-line */
            .multiselect__content-wrapper {
                margin-top: 8px;
            }
        }
    }

    &:not(.lmnt-select--is-disabled) {
        /** Pointer cursor */
        &:hover {
            cursor: pointer;
        }
        &.mdc-select--focused {
            /** Have the label change color on focus, besides floating */
            .mdc-floating-label {
                color: var(--lmnt-select-active-colo, #6200ee);
            }
            .multiselect {
                /** Have the anchor/chevron/arrow pseudo-element of active color on focus */
                .multiselect__select:before {
                    border-color: var(--mdc-theme-primary, #6200ee) transparent
                        transparent;
                    color: var(--mdc-theme-primary, #6200ee);
                }
            }
        }
    }

    // dense styles
    &.lmnt-select--dense {
        // general, height-focused styles
        .multiselect {
            min-height: var(--min-height-dense);
            .multiselect__select {
                height: var(--min-height-dense);
            }
            .multiselect__tags {
                min-height: var(--min-height-dense);
                align-items: center;
                .multiselect__tags-wrap::before {
                    content: unset;
                }
            }
        }
    }
}

// vue specific
.lmnt-select--searchable .multiselect__tags input {
    /* stolen from textfield for laziness */
    appearance: none;
    background: none;
    border-radius: 0;
    border: none;
    caret-color: var(--mdc-theme-primary);
    color: rgba(0, 0, 0, 0.87);
    font-family: var(
        --mdc-typography-subtitle1-font-family,
        var(--mdc-typography-font-family, Roboto, sans-serif)
    );
    font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
    font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
    height: 28px;
    letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
    padding: 0 0 0 16px;
    text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
    text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
    transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}

.lmnt-vue-select .lmnt-select--icon-clear {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-35%);
}

.lmnt-vue-select.lmnt-select--scroll-class .multiselect__tags {
    margin-left: 16px;
    margin-right: 16px;
    overflow-x: scroll;
    padding-left: 0;
    padding-right: 0;
    /* this is requiring way too much subtracted from the right side */
    width: calc(100% - 80px);
}

.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--scroll-class
    .multiselect
    .multiselect__tags
    .multiselect__tags-wrap {
    /* min-width must be set, or else it doesn't work */
    height: 68px;
    min-width: max-content;
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

.lmnt.lmnt-select--scroll-class .lmnt.lmnt-select-multi-value-container {
    margin-left: 0;
    margin-right: 4px;
}
