.metrictitle {
  width: 96%;
  margin: 0 auto;
  color: #23668c;
  font-weight: 600;
}
.metriccaption {
  width: 96%;
  font-size: 13px;
  margin: 5px auto;
  font-weight: bold;
}
.metriccontent {
  width: 96%;
  display: flex;
  margin: 0 auto;

  .count {
    width: 10%;
    text-align: center;
  }
  .data {
    width: 80%;
    display: flex;
    margin-top: 25px;
    // border: 1px solid #000;
  }
  .data1,
  .data2 {
    height: 15px;
  }
}
.colorscheme {
  text-align: center;
  padding: 5px 0 20px;
  span {
    margin: 0 5px;
    display: inline-block;
  }
}
.schemeblock {
  width: 12px;
  height: 12px;
}
