.mainDiv {
  height: 1rem;
  width: 500px;
  margin: 1rem;
  background-color: #d30f4b;
}
.childDiv {
  height: 100%;
  background-color: #66b512;
}
.dash_holder {
  gap: 20px;
  width: 600px;
  display: flex;
  margin: 20px auto;
  background: #eeeeee;
  flex-direction: column;
}
.dash_frames {
  width: 600px;
  display: flex;
}

.linechartframe {
  display: flex;
}
.linechartyaxis {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  margin-left: 10px;
  width: 20px;
}
.linechartcontainer {
  margin-left: -25px;
  padding: 0 10px 0 0;
  width: calc(100% - 20px);
}
.cardhead {
  display: flex;
  margin: 20px 0;
}
.cardtitle {
  flex-grow: 1;

  label {
    font-size: 21px;
    margin: 0 0 10px;
    color: #23668c;
    padding: 5px 10px 10px;
  }
}
.cardright {
  text-align: right;
  padding-right: 10px;
}
.colorblock {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  display: inline-block;
}
