@use '../../common/element.core' as element;
@use '../utility/color/utility-colors.scss' as utility;
@use './_icon-mixins' as mixins;
@use '../badge/notification-badge.scss';

$emphasis-low: 0.38;

.lmnt-icon-pointer {
    cursor: pointer;
}

.lmnt-icon--fill {
    border-radius: 50%;
    padding: 8px;
}

.lmnt-icon--fill-danger-alt {
    background-color: var(--lmnt-theme-danger-alt);
    color: var(--lmnt-theme-on-danger-alt);
}

.lmnt-icon--fill-danger {
    background-color: var(--lmnt-theme-danger);
    color: var(--lmnt-theme-on-danger);
}

.lmnt-icon--fill-primary {
    background-color: var(--lmnt-theme-primary);
    color: var(--lmnt-theme-on-primary);
}

.lmnt-icon--fill-secondary {
    background-color: var(--lmnt-theme-secondary);
    color: var(--lmnt-theme-on-secondary);
}

.lmnt-icon--color-primary {
    color: var(--lmnt-theme-primary);
}

.lmnt-icon--color-secondary {
    color: var(--lmnt-theme-secondary);
}

.lmnt-icon--color-on-dark {
    color: element.$lmnt-theme-light;
}

.lmnt-icon--fill-muted {
    background-color: rgba(element.$lmnt-theme-dark, 0.12);
    color: var(--lmnt-theme-on-secondary);
}

@each $color, $value in utility.$utilityColorMap {
    @include mixins.iconUtilityClasses($color: $color, $value: $value);
}

.lmnt {
    .lmnt-icon--medium {
        font-size: 24px;
        height: 24px;
        width: 24px;
    }
}

.lmnt {
    .lmnt-icon--xlarge {
        font-size: 48px;
        height: 48px;
        width: 48px;
    }
}

.lmnt {
    .lmnt-icon--large {
        font-size: 36px;
        height: 36px;
        width: 36px;
    }
}

.lmnt {
    .lmnt-icon--small {
        font-size: 18px;
        height: 18px;
        width: 18px;
    }
}

.lmnt {
    .lmnt-icon--xsmall {
        font-size: 12px;
        height: 12px;
        width: 12px;
    }
}

.lmnt-icon--notification-badge {
    position: relative;

    .lmnt-badge--notification {
        position: absolute;
        top: -3px;
        left: 17px;

        &.lmnt-badge--notification-badge-counter {
            top: -6px;
            left: 15px;
        }
    }
}
