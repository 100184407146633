@use 'sass:map';
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/top-app-bar/variables' as topAppBar;
@use '@material/animation/variables' as animation-variables;
@use '../../common/element.core' as core;
@use '../../common/functions' as functions;
@use '../../common/background-color' as bg;
@use '../icon-button/icon-button.scss';
@use './topAppBarVariables';

.lmnt.lmnt-top-app-bar {
    transition: background-color 225ms ease;
    color: var(--lmnt-theme-on-primary);
    position: unset;
    top: 0;
    z-index: 7;

    .mdc-top-app-bar__row {
        height: var(--lmnt-top-app-bar-height-sm);
        transition: height 20ms linear;
    }

    .lmnt-top-app-bar__action-item--link {
        color: var(--lmnt-theme-on-primary);
    }

    &.mdc-top-app-bar--fixed {
        position: fixed;
    }

    &.lmnt-top-app-bar--secondary {
        background-color: var(--lmnt-theme-secondary);
        color: var(--lmnt-theme-on-secondary);

        .lmnt.mdc-button,
        .lmnt-top-app-bar__action-item--link,
        .mdc-top-app-bar__navigation-icon,
        .mdc-top-app-bar__action-item {
            color: var(--lmnt-theme-on-secondary);
        }

        .mdc-top-app-bar__navigation-icon::before,
        .mdc-top-app-bar__navigation-icon::after,
        .mdc-top-app-bar__action-item::before,
        .mdc-top-app-bar__action-item::after {
            background-color: var(--lmnt-theme-on-secondary);
        }
    }

    &.lmnt-top-app-bar--surface {
        background-color: var(--lmnt-theme-surface);
        color: var(--lmnt-theme-on-surface);

        .lmnt-top-app-bar__action-item--link,
        .mdc-top-app-bar__navigation-icon,
        .mdc-top-app-bar__action-item {
            color: var(--lmnt-theme-on-surface);
        }

        .mdc-top-app-bar__navigation-icon::before,
        .mdc-top-app-bar__navigation-icon::after,
        .mdc-top-app-bar__action-item::before,
        .mdc-top-app-bar__action-item::after {
            background-color: rgba(var(--lmnt-black-rgb), core.$emphasis-med);
        }
    }

    &.lmnt-top-app-bar--contextual {
        &.lmnt-top-app-bar--contextual-dark {
            transition: background-color 225ms ease;
            background-color: var(--lmnt-theme-black);
            color: var(--lmnt-theme-white);

            .mdc-top-app-bar__navigation-icon,
            .mdc-top-app-bar__action-item {
                color: var(--lmnt-theme-white);
            }

            .mdc-top-app-bar__navigation-icon::before,
            .mdc-top-app-bar__navigation-icon::after,
            .mdc-top-app-bar__action-item::before,
            .mdc-top-app-bar__action-item::after {
                color: var(--lmnt-theme-white);
            }
        }

        &.lmnt-top-app-bar--contextual-light {
            background-color: var(--lmnt-theme-white);
            color: var(--lmnt-theme-black);

            .mdc-top-app-bar__navigation-icon,
            .mdc-top-app-bar__action-item {
                color: var(--lmnt-theme-black);
            }

            .mdc-top-app-bar__navigation-icon::before,
            .mdc-top-app-bar__navigation-icon::after,
            .mdc-top-app-bar__action-item::before,
            .mdc-top-app-bar__action-item::after {
                color: var(--lmnt-theme-black);
            }
        }
    }

    &.mdc-top-app-bar--prominent {
        .mdc-top-app-bar__title {
            white-space: normal;
            align-self: flex-start;
            padding-top: functions.toRem(12);
            padding-bottom: 0;

            &.mdc-top-app-bar__title--with-subtitle {
                padding-top: functions.toRem(2);
            }
        }

        .lmnt-top-app-bar__logo {
            align-self: flex-start;
            padding-top: functions.toRem(4);
        }
    }

    .lmnt-top-app-bar__children {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .mdc-top-app-bar__title {
        line-height: functions.toRem(24);

        &.mdc-top-app-bar__title--with-subtitle {
            display: flex;
            flex-direction: column;
        }
    }

    .lmnt-top-app-bar__logo {
        overflow: hidden;

        &.lmnt-top-app-bar__logo--small {
            height: functions.toRem(40);
            width: functions.toRem(40);
        }

        &.lmnt-top-app-bar__logo--medium {
            height: functions.toRem(40);
            width: functions.toRem(80);
        }

        &.lmnt-top-app-bar__logo--large {
            height: functions.toRem(40);
            width: functions.toRem(120);
        }

        &.lmnt-top-app-bar__logo--large-square {
            height: 48px;
            width: 48px;
        }
    }

    .lmnt-top-app-bar__action-item--link {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: functions.toRem(48);
        justify-content: center;
        line-height: 1.4285714285714286; // 20px
        padding: 0 functions.toRem(12);
    }

    .lmnt-top-app-bar__section--tab-actions {
        padding: 0;
    }

    .lmnt-top-app-bar__section--tab-actions {
        .mdc-tab {
            height: topAppBar.$row-height;
        }
    }

    &.mdc-top-app-bar--dense {
        .lmnt-top-app-bar__section--tab-actions {
            .mdc-tab {
                height: topAppBar.$dense-row-height;
            }
        }

        &.mdc-top-app-bar--prominent {
            .mdc-top-app-bar__row {
                padding-top: functions.toRem(8);
            }
        }
    }

    &.mdc-top-app-bar--prominent {
        .lmnt-top-app-bar__section--tab-actions {
            .mdc-tab {
                height: topAppBar.$prominent-row-height;

                &__content {
                    align-items: flex-end;

                    .mdc-tab__text-label {
                        padding-bottom: functions.toRem(24);
                    }
                }
            }
        }

        .mdc-top-app-bar__section--align-end {
            align-items: flex-start;
        }

        &.mdc-top-app-bar--prominent-tabs {
            .mdc-top-app-bar__row {
                height: 100%;
                flex-direction: column;
            }

            .lmnt-top-app-bar__title-and-actions {
                box-sizing: border-box;
                display: flex;
                position: relative;
                width: 100%;
            }

            .mdc-tab {
                height: topAppBar.$row-height;
            }

            &.mdc-top-app-bar--dense {
                .mdc-tab {
                    height: topAppBar.$dense-row-height;
                }
            }
        }
    }

    a {
        color: inherit;
    }
}

.lmnt-top-app-bar-open-banner--fixed-adjust {
    transition: padding-top 300ms ease;
    padding-top: 0;
}

.lmnt-top-app-bar__action-item-badge {
    position: relative;

    .lmnt-top-app-bar--notification-badge {
        &.lmnt-badge--notification {
            position: absolute;
            top: 4px;
            left: -10px;
        }
    }
}

.lmnt-top-app-bar__logo-title-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    min-width: 0;
    order: -1;
    padding: 8px 12px;
    z-index: 1;
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-sm-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-sm-max')) {
    .lmnt-top-app-bar-with-max-content-width {
        align-items: center;
        .lmnt-top-app-bar__content {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
            width: 100%;
        }
    }

    .lmnt-top-app-bar-fixed-adjust-with-max-content-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
        width: 100%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-lg-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-lg-max')) {
    .lmnt-top-app-bar-with-max-content-width {
        align-items: center;
        .lmnt-top-app-bar__content {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
            width: 100%;
        }
    }

    .lmnt-top-app-bar-fixed-adjust-with-max-content-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
        width: 100%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-xl-min')) {
    .lmnt-top-app-bar-with-max-content-width {
        align-items: center;
        .lmnt-top-app-bar__content {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-xl');
            width: 100%;
        }
    }

    .lmnt-top-app-bar-fixed-adjust-with-max-content-width {
        max-width: map.get(core.$breakpointsMap, 'content-max-width-xl');
        width: 100%;
        margin: 0 auto;
    }
}
