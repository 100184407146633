.dashtable {
  padding: 5px 10px 10px;
}
.tableframe {
  table {
    width: 100%;
  }
  td,
  th {
    padding: 4px;
  }
}
.filterbtn {
  border: 0;
  background: #fff;
  cursor: pointer;
}
