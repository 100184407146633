///////////////////////
//////  Badge  ///////
///////////////////////

@use '../../common/element.core';
@use '../../common/mixins';
@use '../../common/functions' as functions;
@use '../typography/typography.scss';

//////////////////////
//////  Colors  //////
//////////////////////

.lmnt {
    --lmnt-badge-padding-left: 8px;
    --lmnt-badge-padding-right: 8px;
    --lmnt-badge-padding-top: 0;
    --lmnt-badge-padding-bottom: 0;
    --lmnt-badge-min-width: 8px;
    --lmnt-badge-min-height: 8px;
    --lmnt-badge-max-height: 8px;
    --lmnt-badge-font-weight: 700;

    &.lmnt-badge {
        align-items: center;
        border-radius: var(--lmnt-badge-shape-radius);
        display: flex;
        font-weight: var(--lmnt-badge-font-weight);
        max-height: var(--lmnt-badge-max-height);
        min-height: var(--lmnt-badge-min-height);
        min-width: var(--lmnt-badge-min-width);
        padding-bottom: var(--lmnt-badge-padding-top);
        padding-left: var(--lmnt-badge-padding-left);
        padding-right: var(--lmnt-badge-padding-right);
        padding-top: var(--lmnt-badge-padding-top);
        width: fit-content;
    }
}
