@use '../../common/element.core' as element;
@use '../divider/divider.scss';
@use '@material/card';
@use '@material/card/mixins' as card-mixins;
@use '@material/typography';
@use '@material/elevation/mixins' as elevation-mixins;
@use '@material/elevation' as elevation;

@include card.core-styles;

////////////////////////
//////  Colors   ///////
////////////////////////

// --lmnt-card-outline-color: card.variables.$outline-color;

.lmnt {
    --lmnt-card-shape-radius: var(--lmnt-theme--shape-radius);
    --lmnt-card-header: var(--lmnt-theme-surface);
    --lmnt-card-on-header: var(--lmnt-theme-on-surface);
}

.lmnt.lmnt-card {
    &.mdc-card {
        --mdc-shape-medium: var(--lmnt-card-shape-radius);
        --mdc-elevation: var(--lmnt-card-box-shadow);
        border-color: var(--lmnt-card-border-color);
        border-style: var(--lmnt-card-border-style);
        border-width: var(--lmnt-card-border-width);
        box-shadow: var(--lmnt-card-box-shadow);
        width: 100%;
    }

    &.mdc-card--outlined {
        @include card.outline($color: rgba(#000, 0.12), $thickness: 1px);
        box-shadow: none;
    }

    &.lmnt-card--flat {
        box-shadow: none;
        border: none;
    }

    &.lmnt-card--raised {
        box-shadow: elevation.elevation-box-shadow(1);
        border: none;
    }

    &.lmnt-card--left-top {
        .lmnt-card-body {
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;
        }
    }

    &.lmnt-card--left-center {
        .lmnt-card-body {
            align-items: center;
            display: flex;
            justify-content: flex-start;
        }
    }

    &.lmnt-card--left-bottom {
        .lmnt-card-body {
            align-items: flex-end;
            display: flex;
            justify-content: flex-start;
        }
    }

    &.lmnt-card--centered {
        .lmnt-card-body {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }

    &.lmnt-card--right-top {
        .lmnt-card-body {
            align-items: flex-start;
            display: flex;
            justify-content: flex-end;
        }
    }

    &.lmnt-card--right-center {
        .lmnt-card-body {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }
    }

    &.lmnt-card--right-bottom {
        .lmnt-card-body {
            align-items: flex-end;
            display: flex;
            justify-content: flex-end;
        }
    }

    &.lmnt-card--loading {
        .mdc-card__content {
            .lmnt-card-body {
                align-items: center;
                display: flex;
                justify-content: center;
                padding: 50px;
            }
        }
    }

    &.lmnt-theme-transparent-bg {
        background-color: transparent;
    }

    .lmnt-card-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;

        &.lmnt-card-overlay__dark {
            background-color: var(--lmnt-theme-on-unknown-black-inactive);
        }

        &.lmnt-card-overlay__light {
            background-color: var(--lmnt-theme-on-unknown-white-inactive);
        }

        &.lmnt-card-overlay__leading {
            width: 50%;
            justify-self: flex-start;
        }

        &.lmnt-card-overlay__trailing {
            width: 50%;
            justify-self: flex-end;
        }
    }

    .lmnt-card__media-inner-content {
        z-index: 2;
    }
}

.lmnt {
    .lmnt-card-title {
        align-items: center;
        display: flex;
        min-height: 48px;
        padding: 12px 16px;

        &.lmnt-card-title--top-placement {
            border-top-left-radius: var(--lmnt-card-shape-radius);
            border-top-right-radius: var(--lmnt-card-shape-radius);
        }

        &.lmnt-card-title--bottom-placement {
            border-bottom-left-radius: var(--lmnt-card-shape-radius);
            border-bottom-right-radius: var(--lmnt-card-shape-radius);
        }

        &.lmnt-card-title--stacked {
            flex-direction: column;
            align-items: flex-start;

            .lmnt-card-title__trailingBlock,
            .lmnt-card-title__leadingBlock {
                width: 100%;
            }

            .lmnt-card-title__leadingBlock {
                margin-bottom: 8px;
            }

            .lmnt-card-title__overlineText {
                margin-bottom: 4px;
            }

            .lmnt-card-title-secondaryText-container {
                margin-top: 4px;
            }
        }

        .lmnt-card-title__leadingBlock {
            &.lmnt-card-title__leadingBlock--avatar {
                border-radius: 50%;
                margin-right: 16px;

                img,
                svg {
                    height: 40px;
                    width: 40px;
                }
            }

            &.lmnt-card-title__leadingBlock--thumbnail {
                margin-right: 16px;

                img {
                    height: 40px;
                    width: 40px;
                }
            }

            &.lmnt-card-title__leadingBlock--icon {
                margin-right: 23px;
            }

            &.lmnt-card-title__leadingBlock--icon-with-background {
                margin-right: 15px;

                i {
                    width: 24px;
                }
            }
        }

        .lmnt-card-title__text {
            @include typography.typography($style: headline6);
            flex: 1;

            .lmnt-card-title__overlineText {
                @include typography.typography($style: overline);
            }

            .lmnt-card-title__secondaryText {
                @include typography.typography($style: body2);
                color: var(
                    --lmnt-on-surface-inactive,
                    rgba(0, 0, 0, element.$emphasis-med)
                );
            }
        }

        .lmnt-card-title__trailingBlock {
            &.lmnt-card-title__trailingBlock--avatar {
                border-radius: 50%;

                img,
                svg {
                    height: 40px;
                    width: 40px;
                }
            }

            &.lmnt-card-title__trailingBlock--thumbnail {
                img {
                    height: 40px;
                    width: 40px;
                }
            }
        }

        &.lmnt-theme-primary-bg {
            .lmnt-card-title__text {
                .lmnt-card-title__overlineText,
                .lmnt-card-title__secondaryText {
                    color: rgba(var(--lmnt-theme-on-primary), element.$emphasis-med);
                }
            }
        }

        &.lmnt-theme-secondary-bg {
            .lmnt-card-title__text {
                .lmnt-card-title__overlineText,
                .lmnt-card-title__secondaryText {
                    color: rgba(var(--lmnt-theme-on-secondary), element.$emphasis-med);
                }
            }
        }
    }

    .mdc-card__content {
        display: flex;
        flex-direction: column;

        .lmnt-card-body {
            padding-left: 16px;
            padding-right: 16px;
            overflow: auto;
        }

        .lmnt-card-body,
        .mdc-card__primary-action {
            flex-grow: 1;
        }
    }

    .lmnt-card__media--4-3 {
        @include card-mixins.media-aspect-ratio(4, 3);
    }

    .lmnt-card__media--4-5 {
        @include card-mixins.media-aspect-ratio(4, 5);
    }

    .lmnt-card__media--2-1 {
        @include card-mixins.media-aspect-ratio(2, 1);
    }

    .mdc-card__media-content {
        padding: 8px;
        display: flex;

        &.lmnt-card__content--bottom {
            align-items: flex-end;
        }

        &.lmnt-card__content--top {
            align-items: flex-start;
        }
    }

    .lmnt-card__actions--center {
        justify-content: center;
    }

    .lmnt-card__actions--trailing {
        justify-content: flex-end;
    }

    .lmnt-card__filters {
        display: flex;

        &.lmnt-card__filters--chips {
            justify-content: flex-start;
            padding: 8px 16px;
        }

        &.lmnt-card__filters--select {
            padding: 8px 16px;
            justify-content: center;
        }

        &.lmnt-card__filters--select-2 {
            justify-content: space-evenly;
            padding: 8px 16px;

            > div,
            > span {
                width: 50%;
                &:first-child {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-left: 5px;
                }
            }
        }
    }
}

.mdc-card__media.lmnt-card__leading-media:first-child {
    border-top-left-radius: var(--lmnt-card-shape-radius);
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--lmnt-card-shape-radius);
    border-bottom-right-radius: 0;
}

.mdc-card__media.lmnt-card__leading-media-rtl:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: var(--lmnt-card-shape-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--lmnt-card-shape-radius);
}

.lmnt-card__leading-media--square {
    width: 50%;
}

.lmnt-card__leading-media--9-16 {
    width: 28.125%;
}

.lmnt-card__leading-media--4-5 {
    width: 50%;
}

.lmnt-card__leading-media--2-1 {
    width: 28.125%;
}

.lmnt-card-title__items {
    display: flex;
    column-gap: 0.5em;
}
