@use '@material/list/evolution-mixins' as list;
@use '../../common/functions' as functions;
@use '../../common/element.core';

//TODO Use core-styles when material releases them.
// @include list.core-styles;

// List

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
    --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}

.lmnt-list {
    --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);

    &.lmnt-list--dense {
        .mdc-list-item--with-one-line {
            @include list.one-line-item-density(-4);
        }
    }

    .mdc-list-item {
        .mdc-form-field {
            vertical-align: inherit;
        }

        .mdc-checkbox--touch {
            margin: 0;
        }
    }

    .mdc-list-item__overline-text {
        font-size: 12px;
        line-height: 1.33333;
        font-weight: 500;
        letter-spacing: 2px;
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .mdc-list-item--with-trailing-image,
    .mdc-list-item--with-trailing-video,
    .mdc-list-item--with-trailing-icon,
    .mdc-list-item--with-trailing-thumbnail,
    .mdc-list-item--with-trailing-avatar,
    .mdc-list-item--with-trailing-checkbox,
    .mdc-list-item--with-trailing-switch,
    .mdc-list-item--with-trailing-radio,
    .mdc-list-item--with-trailing-avatar,
    .mdc-list-item--with-trailing-meta,
    .mdc-list-item--with-trailing-badge,
    .mdc-list-item--with-leading-icon,
    .mdc-list-item--with-leading-thumbnail,
    .mdc-list-item--with-leading-avatar,
    .mdc-list-item--with-leading-checkbox,
    .mdc-list-item--with-leading-switch,
    .mdc-list-item--with-leading-radio,
    .mdc-list-item--with-leading-avatar,
    .mdc-list-item--with-leading-image,
    .mdc-list-item--with-leading-video {
        &.mdc-list-item--with-three-lines {
            .mdc-list-item__start {
                align-self: center;
                margin-top: 0;
            }

            .mdc-list-item__end {
                align-self: center;
                margin-top: 0;
            }
        }
    }

    .mdc-list-item--with-leading-avatar {
        .mdc-list-item__start {
            img {
                height: 40px;
                width: 40px;
            }
        }
    }

    .mdc-list-item--with-trailing-avatar {
        .mdc-list-item__end {
            height: 40px;
            img {
                height: 40px;
                width: 40px;
            }
        }
    }

    .mdc-list-item--with-leading-image {
        .mdc-list-item__start {
            img {
                height: 56px;
                width: 56px;
            }
        }
    }

    .mdc-list-item--with-trailing-image {
        .mdc-list-item__end {
            height: 56px;
            img {
                height: 56px;
                width: 56px;
            }
        }
    }

    .mdc-list-item--with-leading-video {
        .mdc-list-item__start {
            padding-left: 16px;

            img {
                height: 56px;
                width: 100px;
            }
        }
    }

    .mdc-list-item--with-trailing-video {
        .mdc-list-item__end {
            height: 56px;
            img {
                height: 56px;
                width: 100px;
            }
        }
    }

    .mdc-list-item--with-leading-thumbnail {
        .mdc-list-item__start {
            img {
                height: 40px;
                width: 40px;
            }
        }
    }

    .mdc-list-item--with-trailing-thumbnail {
        .mdc-list-item__end {
            height: 40px;
            img {
                height: 40px;
                width: 40px;
            }
        }
    }

    .lmnt-list-item--wrap,
    .lmnt-list-item--wrap .mdc-list-item__content > * {
        white-space: normal;
    }

    .lmnt-list-item--truncate,
    .lmnt-list-item--truncate .mdc-list-item__content > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lmnt-list-item--no-wrap,
    .lmnt-list-item--no-wrap .mdc-list-item__content > * {
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
    }

    .mdc-list-item--with-two-lines,
    .mdc-list-item--with-three-lines {
        &.lmnt-list-item--wrap {
            height: auto;
        }
        .mdc-list-item__content {
            padding-bottom: 10px;
        }
    }

    .lmnt-list-item__no-hover {
        pointer-events: none;

        &:hover,
        &:focus {
            background-color: inherit;
            cursor: default;
        }
    }

    .mdc-list-item--with-one-line {
        &.mdc-list-item--with-trailing-icon,
        &.mdc-list-item--with-trailing-thumbnail,
        &.mdc-list-item--with-trailing-avatar,
        &.mdc-list-item--with-trailing-checkbox,
        &.mdc-list-item--with-trailing-switch,
        &.mdc-list-item--with-trailing-radio,
        &.mdc-list-item--with-trailing-avatar,
        &.mdc-list-item--with-trailing-meta,
        &.mdc-list-item--with-trailing-badge,
        &.mdc-list-item--with-leading-icon,
        &.mdc-list-item--with-leading-thumbnail,
        &.mdc-list-item--with-leading-avatar,
        &.mdc-list-item--with-leading-checkbox,
        &.mdc-list-item--with-leading-switch,
        &.mdc-list-item--with-leading-radio,
        &.mdc-list-item--with-leading-avatar {
            height: 48px;
        }
    }
}

hr.lmnt-list-divider {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.lmnt-list-divider {
    &.lmnt-list-divider--with-padding {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.lmnt-list-divider--with-inset {
        &.mdc-list-divider--with-leading-icon,
        &.mdc-list-divider--with-leading-thumbnail,
        &.mdc-list-divider--with-leading-avatar,
        &.mdc-list-divider--with-leading-checkbox,
        &.mdc-list-divider--with-leading-switch,
        &.mdc-list-divider--with-leading-radio,
        &.mdc-list-divider--with-leading-avatar {
            padding-left: 72px;
        }

        &.mdc-list-divider--with-leading-image {
            padding-left: 88px;
        }

        &.mdc-list-divider--with-leading-video {
            padding-left: 132px;
        }
    }
}

//Groups

.mdc-list-group__subheader.lmnt-list-group__subheader .mdc-list-item__primary-text {
    color: rgba(#000, 0.6);
    margin-bottom: 0;
}

.mdc-menu-surface.lmnt-theme-primary-bg,
.mdc-menu-surface.lmnt-theme-secondary-bg,
.mdc-list.lmnt-theme-primary-bg,
.mdc-list.lmnt-theme-secondary-bg,
.mdc-list-item.lmnt-theme-primary-bg,
.mdc-list-item.lmnt-theme-secondary-bg {
    .mdc-list-item__overline-text,
    .mdc-list-item__secondary-text,
    .mdc-list-item__primary-text {
        color: inherit;
    }
}

// Nested Groups

.lmnt-nested-list {
    padding: 0;

    .mdc-list-group__subheader.lmnt-list-group__subheader {
        margin-top: 0;
    }

    .mdc-list {
        padding: 0;

        .mdc-list-item {
            padding: 0 28px;
        }
    }

    .lmnt-list-group__subheader {
        height: fit-content;
    }

    .lmnt-list-group__category-header {
        font-weight: bold;
        margin: 12px 16px 0;
    }
}

// List Badge

.lmnt {
    &.lmnt-badge {
        &.lmnt-list--label-badge {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }
}
