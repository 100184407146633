///////////////////////
//////  Button  ///////
///////////////////////

@use '@material/button';
@use '@material/button/styles';
@use '@material/elevation' as elevation;
@use '../../common/element.core' as element;
@use '../../common/mixins';
@use './button-conflict.scss';
@use '../../common/functions' as functions;
//////////////////////
//////  Colors  //////
//////////////////////

// MIGRATION: add Button level CSS Variables for theme hooks as needed
// MIGRATION: discuss TODOs

// TODO: pass in CSS Variables via SCSS Variables? Or just write them in like below?
// TODO: change `primary` to filled?
// TODO: change `secondary` to outlined?
// TODO: change `tertiary` to text?
// TODO: change `mdc` to `lmnt` namespace?
// TODO: change `lmnt` to `mdc` namespace?

//////////////////////
//////  Themes  //////
//////////////////////

.lmnt {
    --lmnt-button-shape-radius: var(--lmnt-theme--shape-radius);
    --lmnt-button-font-style: normal;
    --lmnt-button-filled-bg: var(--lmnt-theme-primary);
    --lmnt-button-filled-text: var(--lmnt-theme-on-primary);
    --lmnt-button-outlined-text-color: var(--lmnt-theme-primary-on-surface);
    --lmnt-button-outlined-border-color: #{rgba(element.$lmnt-theme-dark, 0.12)};
    --lmnt-button-text-color: var(--lmnt-theme-primary-on-surface);

    --lmnt-button-xsmall-vertical-padding: 6px;
    --lmnt-button-xsmall-horizontal-padding: 12px;

    --lmnt-button-small-vertical-padding: 8px;
    --lmnt-button-small-horizontal-padding: 14px;

    --lmnt-button-medium-horizontal-padding: 16px;
    --lmnt-button-medium-vertical-padding: 10px;

    --lmnt-button-default-horizontal-padding: 16px;
    --lmnt-button-default-vertical-padding: 10px;

    --lmnt-button-large-vertical-padding: 12px;
    --lmnt-button-large-horizontal-padding: 16px;

    --lmnt-button-xlarge-vertical-padding: 16px;
    --lmnt-button-xlarge-horizontal-padding: 14px;

    --lmnt-button-line-height: inherit;

    // TODO: deprecate styling based on `.lmnt-theme-primary-bg` in favor of variant styling
    --lmnt-button-filled-bg--on-primary: var(--lmnt-theme-secondary);
    --lmnt-button-filled-text--on-primary: var(--lmnt-theme-on-secondary);
    --lmnt-button-outlined-text-color--on-primary: var(--lmnt-theme-on-primary);
    --lmnt-button-outlined-border-color--on-primary: var(--lmnt-theme-on-primary);
    --lmnt-button-text-color--on-primary: var(--lmnt-theme-on-primary);

    // TODO: deprecate styling based on `.lmnt-theme-secondary-bg` in favor of variant styling
    --lmnt-button-filled-bg--on-secondary: var(--lmnt-theme-primary);
    --lmnt-button-filled-text--on-secondary: var(--lmnt-theme-on-primary);

    &.mdc-button {
        --mdc-shape-small: var(--lmnt-button-shape-radius);
        font-style: var(--lmnt-button-font-style);
        height: unset;
        line-height: var(--lmnt-button-line-height);
        padding: var(--lmnt-button-default-vertical-padding)
            var(--lmnt-button-default-horizontal-padding);

        &.lmnt-button--xsmall {
            padding: var(--lmnt-button-xsmall-vertical-padding)
                var(--lmnt-button-xsmall-horizontal-padding);
        }

        &.lmnt-button--small {
            padding: var(--lmnt-button-small-vertical-padding)
                var(--lmnt-button-small-horizontal-padding);
        }

        &.lmnt-button--medium {
            padding: var(--lmnt-button-medium-vertical-padding)
                var(--lmnt-button-medium-horizontal-padding);
        }

        &.lmnt-button--large {
            padding: var(--lmnt-button-large-vertical-padding)
                var(--lmnt-button-large-horizontal-padding);
        }

        &.lmnt-button--xlarge {
            padding: var(--lmnt-button-xlarge-vertical-padding)
                var(--lmnt-button-xlarge-horizontal-padding);
        }

        &.mdc-button--outlined {
            height: unset;
        }

        &.mdc-button--raised {
            box-shadow: var(--lmnt-button-box-shadow);
            height: unset;

            &.lmnt-button--elevated {
                box-shadow: elevation.elevation-box-shadow(2);
            }

            &.lmnt-button--flat {
                box-shadow: elevation.elevation-box-shadow(0);
            }
        }
    }

    //////////////////////
    //////  Filled  //////
    //////////////////////

    // Filled: background-color: primary and elevated
    &.mdc-button.lmnt-button--filled-primary {
        --mdc-theme-primary: var(--lmnt-button-filled-bg);
        --mdc-theme-on-primary: var(--lmnt-button-filled-text);
    }

    // Filled Alt: background-color: secondary and elevated
    &.mdc-button.lmnt-button--filled-secondary {
        --mdc-theme-primary: var(--lmnt-theme-secondary);
        --mdc-theme-on-primary: var(--lmnt-theme-on-secondary);
    }
    &.mdc-button.lmnt-button--filled-danger {
        --mdc-theme-primary: #{element.$lmnt-theme-light};
        --mdc-theme-on-primary: var(--lmnt-theme-on-danger);
        background-color: var(--lmnt-theme-danger);
        &:disabled {
            color: rgba(var(--lmnt-on-secondary-rgb), #{element.$emphasis-disabled});
            background-color: rgba(
                var(--lmnt-on-secondary-rgb),
                element.$emphasis-stroke
            );
        }
    }

    &.mdc-button.lmnt-button--filled-success {
        --mdc-theme-primary: #{element.$lmnt-theme-light};
        --mdc-theme-on-primary: var(--lmnt-theme-on-success);
        background-color: var(--lmnt-theme-success);
        &:disabled {
            color: rgba(var(--lmnt-on-secondary-rgb), #{element.$emphasis-disabled});
            background-color: rgba(
                var(--lmnt-on-secondary-rgb),
                element.$emphasis-stroke
            );
        }
    }

    // On Colors
    &.mdc-button.lmnt-button--filled-on-primary {
        --mdc-theme-primary: var(--lmnt-theme-on-primary);
        --mdc-theme-on-primary: var(--lmnt-theme-primary);

        &.mdc-button--raised:disabled {
            color: rgba(var(--lmnt-on-primary-rgb), #{element.$emphasis-disabled});
            background-color: rgba(
                var(--lmnt-on-primary-rgb),
                #{element.$emphasis-disabled}
            );
        }
    }

    &.mdc-button.lmnt-button--filled-on-secondary {
        --mdc-theme-primary: var(--lmnt-theme-on-secondary);
        --mdc-theme-on-primary: var(--lmnt-theme-secondary);

        &.mdc-button--raised:disabled {
            color: rgba(var(--lmnt-on-secondary-rgb), #{element.$emphasis-disabled});
            background-color: rgba(
                var(--lmnt-on-secondary-rgb),
                element.$emphasis-stroke
            );
        }
    }

    &.mdc-button.lmnt-button--filled-on-success {
        --mdc-theme-primary: var(--lmnt-theme-on-success);
        --mdc-theme-on-primary: var(--lmnt-theme-success-on-surface);

        &.mdc-button--raised:disabled {
            color: rgba(var(--lmnt-on-success-rgb), #{element.$emphasis-disabled});
            background-color: rgba(
                var(--lmnt-on-success-rgb),
                #{element.$emphasis-disabled}
            );
        }
    }

    &.mdc-button.lmnt-button--filled-on-danger {
        --mdc-theme-primary: var(--lmnt-theme-on-danger);
        --mdc-theme-on-primary: var(--lmnt-theme-danger-on-surface);

        &.mdc-button--raised:disabled {
            color: rgba(var(--lmnt-on-danger-rgb), #{element.$emphasis-disabled});
            background-color: rgba(
                var(--lmnt-on-danger-rgb),
                #{element.$emphasis-disabled}
            );
        }
    }

    &.mdc-button.lmnt-button--filled-on-success {
        --mdc-theme-primary: var(--lmnt-theme-on-success);
        --mdc-theme-on-primary: var(--lmnt-theme-success-on-surface);

        &.mdc-button--raised:disabled {
            color: rgba(var(--lmnt-on-success-rgb), #{element.$emphasis-stroke});
            background-color: rgba(
                var(--lmnt-on-success-rgb),
                #{element.$emphasis-stroke}
            );
        }
    }

    &.mdc-button.lmnt-button--filled-on-unknown-white {
        --mdc-theme-primary: var(--lmnt-theme-surface);
        --mdc-theme-on-primary: rgba(
            var(--lmnt-black-rgb),
            #{element.$emphasis-active-black}
        );

        &.mdc-button--raised .mdc-button__ripple::before,
        &.mdc-button--raised .mdc-button__ripple::after {
            background-color: var(--lmnt-theme-black);
        }

        &.mdc-button--raised:disabled {
            color: var(--lmnt-theme-on-unknown-white-disabled);
            background-color: rgba(
                var(--lmnt-white-rgb),
                #{element.$emphasis-stroke-dark}
            );
        }
    }

    &.mdc-button.lmnt-button--filled-on-unknown-black {
        --mdc-theme-primary: var(--lmnt-theme-black);
        --mdc-theme-on-primary: rgba(
            var(--lmnt-white-rgb),
            #{element.$emphasis-active-black}
        );

        &.mdc-button--raised .mdc-button__ripple::before,
        &.mdc-button--raised .mdc-button__ripple::after {
            background-color: var(--lmnt-theme-white);
        }

        &.mdc-button--raised:disabled {
            color: var(--lmnt-theme-on-unknown-black-disabled);
            background-color: rgba(
                var(--lmnt-black-rgb),
                #{element.$emphasis-stroke-dark}
            );
        }
    }

    ////////////////////////
    //////  Outlined  //////
    ////////////////////////

    // Outlined: text color primary, no bg, dark border
    &.mdc-button.lmnt-button--outlined-primary {
        --mdc-theme-primary: var(--lmnt-button-outlined-text-color);

        &:not(:disabled) {
            border-color: var(--lmnt-button-outlined-border-color);
        }
    }
    // Outlined Alt: text color secondary, no bg, white border
    &.mdc-button.lmnt-button--outlined-secondary {
        --mdc-theme-primary: var(--lmnt-theme-secondary-on-surface);
        &:not(:disabled) {
            border-color: var(--lmnt-button-outlined-border-color);
        }
    }
    &.mdc-button.lmnt-button--outlined-on-primary {
        --mdc-theme-primary: var(--lmnt-theme-on-primary);
        background-color: var(--lmnt-theme-primary);
        &:not(:disabled) {
            border-color: var(--mdc-theme-primary);
        }
        &:disabled {
            border-color: rgba(var(--lmnt-on-primary-rgb), #{element.$emphasis-disabled});
            color: rgba(var(--lmnt-on-primary-rgb), #{element.$emphasis-disabled});
        }
    }
    &.mdc-button.lmnt-button--outlined-on-secondary {
        --mdc-theme-primary: var(--lmnt-theme-on-secondary);
        background-color: var(--lmnt-theme-secondary);
        &:not(:disabled) {
            border-color: var(--lmnt-theme-on-secondary);
        }
    }

    &.mdc-button.lmnt-button--outlined-on-unknown-white {
        --mdc-theme-primary: var(--lmnt-theme-on-unknown-white-active);

        &:not(:disabled) {
            border-color: #{element.$lmnt-theme-light};
        }
    }

    &.mdc-button.lmnt-button--outlined-on-unknown-black {
        --mdc-theme-primary: var(--lmnt-theme-on-unknown-black-active);

        &:not(:disabled) {
            border-color: #{element.$lmnt-theme-dark};
        }
    }

    ////////////////////
    //////  Text  //////
    ////////////////////

    // Text: text color primary, no bg, no border
    &.mdc-button.lmnt-button--text-primary {
        --mdc-theme-primary: var(--lmnt-button-text-color);
    }
    &.mdc-button.lmnt-button--text-danger {
        --mdc-theme-primary: var(--lmnt-theme-danger);
    }
    &.mdc-button.lmnt-button--text-success {
        --mdc-theme-primary: var(--lmnt-theme-success);
    }
    &.mdc-button.lmnt-button--text-secondary {
        --mdc-theme-primary: var(--lmnt-theme-secondary-on-surface);
    }
    &.mdc-button.lmnt-button--text-on-primary {
        --mdc-theme-primary: var(--lmnt-theme-on-primary);
        &:disabled {
            color: rgba(var(--lmnt-on-primary-rgb), #{element.$emphasis-disabled});
        }
    }
    &.mdc-button.lmnt-button--text-on-secondary {
        --mdc-theme-primary: var(--lmnt-theme-on-secondary);
    }

    &.mdc-button.lmnt-button--text-on-unknown-white {
        --mdc-theme-primary: var(--lmnt-theme-on-unknown-white-active);
    }

    &.mdc-button.lmnt-button--text-on-unknown-black {
        --mdc-theme-primary: var(--lmnt-theme-on-unknown-black-active);
    }

    //////////////////////
    //////  Danger  //////
    //////////////////////

    // Danger: elevated, danger bg, on-danger color text
    &.mdc-button.lmnt-button--danger-primary {
        --mdc-theme-primary: var(--lmnt-theme-danger);
        --mdc-theme-on-primary: var(--lmnt-theme-on-danger);
    }

    // Danger: elevated, white bg, danger color text
    &.mdc-button.lmnt-button--danger-secondary {
        --mdc-theme-primary: var(--lmnt-theme-on-danger);
        --mdc-theme-on-primary: var(--lmnt-theme-danger);
    }

    // Success: elevated, success bg, on-success color text

    &.mdc-button.lmnt-button--success-primary {
        --mdc-theme-primary: var(--lmnt-theme-success);
        --mdc-theme-on-primary: var(--lmnt-theme-on-success);
    }

    // Success: elevated, white bg, success color text

    &.mdc-button.lmnt-button--success-secondary {
        --mdc-theme-primary: var(--lmnt-theme-on-success);
        --mdc-theme-on-primary: var(--lmnt-theme-success);
    }

    ////////////////////////
    //////  Disabled  //////
    ////////////////////////

    &.mdc-button:disabled.lmnt-button--text-primary {
        color: rgba(element.$lmnt-theme-dark, 0.38);
    }

    &.mdc-button--raised:disabled.lmnt-button--danger-secondary {
        background-color: rgba(element.$lmnt-theme-light, 0.24);
        color: rgba(element.$lmnt-theme-light, 0.38);
    }
    &.mdc-button--raised:disabled.lmnt-button--success-secondary {
        background-color: rgba(element.$lmnt-theme-dark, 0.12);

        color: rgba(element.$lmnt-theme-light, 0.38);
    }

    &.mdc-button:disabled.lmnt-button--outlined-secondary {
        color: rgba(element.$lmnt-theme-dark, 0.38);
    }

    &.mdc-button:disabled.lmnt-button--text-secondary {
        background-color: rgba(element.$lmnt-theme-dark, 0.12);
        color: rgba(element.$lmnt-theme-light, 0.38);
    }

    //////////////////////////
    //////  Full Width  //////
    //////////////////////////

    // TODO: rename this class with lmnt namespace?
    &.lmnt-button--full-width {
        width: 100%;
    }

    /////////////////////////////////////
    //////  Make Icons Look Purdy  //////
    //////////////////////////-//////////
    .lmnt-button {
        .lmnt-icon {
            display: inline-block;
            font-size: 18px;
            height: 18px;
            margin-left: 0;
            margin-right: 8px;
            vertical-align: top;
            width: 18px;
        }

        .lmnt-icon {
            margin-left: -4px;
            margin-right: 8px;
        }

        .mdc-button__label + .lmnt-icon {
            margin-left: 8px;
            margin-right: -4px;
        }
    }

    .mdc-button__label + .lmnt-icon {
        margin-left: 0;
        margin-right: 8px;
    }

    /////////////////////////////////////////
    ///////////  Button Group  //////////////
    /////////////////////////////////////////

    &.lmnt-button-group {
        &.lmnt-button-group--dense {
            .lmnt-button {
                margin-left: functions.toRem(4);
                margin-right: functions.toRem(4);
            }
        }

        &.lmnt-button-group--standard {
            .lmnt-button {
                margin-left: functions.toRem(8);
                margin-right: functions.toRem(8);
            }
        }

        &.lmnt-button-group--airy {
            .lmnt-button {
                margin-left: functions.toRem(12);
                margin-right: functions.toRem(12);
            }
        }

        .lmnt-button {
            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

/////////////////////////////////////////
//////  On Primary: Button States  //////
/////////////////////////////////////////

// TODO: deprecate styling based on `.lmnt-theme-primary-bg` in favor of variant styling

@include mixins.on-primary {
    .lmnt {
        &.lmnt-button--filled-primary {
            --mdc-theme-primary: var(--lmnt-button-filled-bg--on-primary);
            --mdc-theme-on-primary: var(--lmnt-button-filled-text--on-primary);
        }

        &.lmnt-button--outlined-primary {
            --mdc-theme-primary: var(--lmnt-button-outlined-text-color--on-primary);

            &:not(:disabled) {
                border-color: var(--lmnt-button-outlined-border-color--on-primary);
            }
        }
        &.lmnt-button--outlined-secondary {
            --mdc-theme-primary: var(--lmnt-button-outlined-text-color--on-primary);

            &:not(:disabled) {
                border-color: var(--lmnt-button-outlined-border-color--on-primary);
            }
        }

        &.lmnt-button--text-primary {
            --mdc-theme-primary: var(--lmnt-button-text-color--on-primary);
        }
    }
}

///////////////////////////////////////////
//////  On Secondary: Button States  //////
///////////////////////////////////////////

// TODO: deprecate styling based on `.lmnt-theme-secondary-bg` in favor of variant styling

@include mixins.on-secondary {
    .lmnt {
        &.lmnt-button--filled-primary {
            --mdc-theme-primary: var(--lmnt-button-filled-bg--on-secondary);
            --mdc-theme-on-primary: var(--lmnt-button-filled-text--on-secondary);
        }

        &.lmnt-button--outlined-primary {
            --mdc-theme-primary: var(--lmnt-theme-on-secondary);

            &:not(:disabled) {
                border-color: var(--lmnt-theme-on-secondary);
            }
        }

        &.lmnt-button--text-primary {
            --mdc-theme-primary: var(--lmnt-theme-on-secondary);
        }
    }
}
