@use '@material/checkbox';
@use '../form-field/form-field.scss';
@use '../../common/element.core';
@use '../../common/mixins';

@import '../form-field/form-field';

@include checkbox.core-styles;

// NOTE: Material uses "secondary" for checkbox, but Element uses "primary" by default

.lmnt {
    // primary
    --lmnt-checkbox--primary: var(--lmnt-theme-primary-on-surface);
    --lmnt-checkbox--on-primary: var(--lmnt-theme-on-primary);
    --lmnt-checkbox--on-primary-ink: var(--lmnt-theme-primary-on-surface);

    // secondary
    --lmnt-checkbox--secondary: var(--lmnt-theme-secondary-on-surface);
    --lmnt-checkbox--on-secondary: var(--lmnt-theme-on-secondary);
    --lmnt-checkbox--on-secondary-ink: var(--lmnt-theme-secondary-on-surface);
}

.lmnt-checkbox--secondary,
.mdc-checkbox.lmnt-checkbox, // TODO: remove in future, this is only kept to avoid breaking radio with older markup
.mdc-checkbox.lmnt-checkbox.lmnt-checkbox--secondary {
    --mdc-theme-secondary: var(--lmnt-checkbox--primary);
}

.lmnt-checkbox--secondary,
.mdc-checkbox.lmnt-checkbox.lmnt-checkbox--secondary {
    --mdc-theme-secondary: var(--lmnt-checkbox--secondary);
}

@include mixins.on-primary {
    .mdc-checkbox.lmnt-checkbox {
        --mdc-theme-secondary: var(--lmnt-checkbox--on-primary);
        --mdc-checkbox-ink-color: var(--lmnt-checkbox--on-primary-ink);
        --mdc-checkbox-unchecked-color: var(--lmnt-checkbox--on-primary);
    }
}

@include mixins.on-secondary {
    .mdc-checkbox.lmnt-checkbox {
        --mdc-theme-secondary: var(--lmnt-checkbox--on-secondary);
        --mdc-checkbox-ink-color: var(--lmnt-checkbox--on-secondary-ink);
        --mdc-checkbox-unchecked-color: var(--lmnt-checkbox--on-secondary);
    }
}
