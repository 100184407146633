@use '@material/switch';
@use '../../common/element.core';
@use '../../common/mixins';
@use '../../common/screen-reader-only';

@import '../form-field/form-field';

@include switch.core-styles;

// NOTE: Material uses "secondary" for Switches, but Element uses "primary" by default
// NOTE: Material uses "on surface" to color the Switch track

.lmnt {
    // primary
    --lmnt-switch--primary: var(--lmnt-theme-primary-on-surface);
    --lmnt-switch--on-primary: var(--lmnt-theme-secondary);
    --lmnt-switch--on-primary-surface: var(--lmnt-theme-on-primary);

    // secondary
    --lmnt-switch--secondary: var(--lmnt-theme-secondary-on-surface);
    --lmnt-switch--on-secondary: var(--lmnt-theme-primary);
    --lmnt-switch--on-secondary-surface: var(--lmnt-theme-on-secondary);
}

.lmnt-switch--primary,
.mdc-switch.lmnt-switch, // TODO: remove in future, this is only kept to avoid breaking radio with older markup
.mdc-switch.lmnt-switch.lmnt-switch--primary {
    --mdc-theme-secondary: var(--lmnt-switch--primary);
}

.lmnt-switch--secondary,
.mdc-switch.lmnt-switch.lmnt-switch--secondary {
    --mdc-theme-secondary: var(--lmnt-theme-secondary);
}

.mdc-form-field .lmnt-switch-label {
    margin-left: 10px;
    padding-left: 0;
    user-select: none;
}

@include mixins.on-primary {
    .mdc-switch.lmnt-switch {
        --mdc-theme-secondary: var(--lmnt-switch--on-primary);
        --mdc-theme-on-surface: var(--lmnt-switch--on-primary-surface);
    }
}

@include mixins.on-secondary {
    .mdc-switch.lmnt-switch {
        --mdc-theme-secondary: var(--lmnt-switch--on-secondary);
        --mdc-theme-on-surface: var(--lmnt-switch--on-secondary-surface);
    }
}
