.th_head {
  display: flex;
}
.doc_tblheadline {
  font-size: 1.17em;
  font-weight: bold;
  padding: 0.75rem;
}
.documentlistheading {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  gap: 1rem;
}
// .documentlistheading > div {
//   flex: 1;
// }
.doc_tblfilterbtn {
  flex: 1 1;
  padding-right: 0.55rem;

  button {
    border: 0;
    padding: 6px;
    float: right;
    cursor: pointer;
    background: #fff;
    margin: 10px 10px 0 0;
  }
}
.searchtxt {
  border: 0;
  height: 24px;
  float: right;
  margin: 10px 0 0;
  background-size: 20px;
  padding: 7px 5px 4px 28px;
  background-repeat: no-repeat;
  background-position: 5px center;
}
.searchtxt:focus {
  margin: 0;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 14px 5px 14px 30px;
}
.more_actions {
  position: relative;
}
.more_actions ul {
  padding: 0;
  top: 31px;
  margin: 0;
  left: 27px;
  display: none;
  list-style: none;
  z-index: 10000000;
  position: absolute;
  box-shadow: 2px 2px 10px 2px #ccc;
}
.more_actions:hover ul {
  display: block;
}
.more_actions ul li a {
  color: #000;
  width: 115px;
  padding: 3px 5px;
  text-align: left;
  background: #fff;
  display: inline-block;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 8px 6px -6px rgb(230, 230, 230);
}
.updownarrows i {
  height: 5px;
  line-height: 5px;
}
.doc_tblfiltertype {
  width: 225px;
  float: right;
  margin: 0 20px;
}
