@use '../../common/element.core' as element;
@use '../typography/typography.scss';

// media query
// - smaller screen style tweaks at 480px (30rem)
$lmnt-tooltip__media-query--min-for-smaller-screen: 30rem;

// z-index
$lmnt-tooltip__z-index: 1;

//////////////////////
//////  Colors  //////
//////////////////////

$dark-charcoal: #4d4d4d;

//////////////////////
//////  Themes  //////
//////////////////////

.lmnt {
    --lmnt-tooltip-border-radius: var(--lmnt-theme--shape-radius);
    --lmnt-tooltip: #{rgba($dark-charcoal, 0.9)};
    --lmnt-tooltip-text-color: #{element.$lmnt-theme-light};
}

.lmnt-tooltip {
    position: relative;
}

.lmnt-tooltip--block {
    display: block;
}
.lmnt-tooltip--inline {
    display: inline-block;
}

.lmnt-tooltip__text-content {
    background-color: var(--lmnt-tooltip);
    border-radius: var(--lmnt-tooltip-border-radius);
    color: var(--lmnt-tooltip-text-color);
    display: none;
    max-width: 208px;
    padding: 8px 16px;
    position: absolute;
    white-space: normal;
    width: max-content;
    z-index: $lmnt-tooltip__z-index;
}

.lmnt-tooltip__text-content[data-show] {
    display: block;
}

@media only screen and (min-width: $lmnt-tooltip__media-query--min-for-smaller-screen) {
    .lmnt-tooltip__text-content {
        max-width: 224px;
        padding: 4px 8px;
    }
}
