@use '../../common/element.core';
@use '@material/circular-progress';

@include circular-progress.core-styles;

.lmnt-circular-progress--primary {
    @include circular-progress.color(var(--lmnt-theme-primary));
}

.lmnt-circular-progress--primary-variant {
    @include circular-progress.color(var(--lmnt-theme-primary-variant));
}

.lmnt-circular-progress--secondary {
    @include circular-progress.color(var(--lmnt-theme-secondary));
}

.lmnt-circular-progress--secondary-variant {
    @include circular-progress.color(var(--lmnt-theme-secondary-variant));
}
.lmnt-circular-progress--dark {
    @include circular-progress.color(var(--lmnt-theme-dark));
}
.lmnt-circular-progress--light {
    @include circular-progress.color(var(--lmnt-theme-light));
}

.lmnt-circular-progress--on-primary {
    @include circular-progress.color(var(--lmnt-theme-on-primary));
}
.lmnt-circular-progress--on-secondary {
    @include circular-progress.color(var(--lmnt-theme-on-secondary));
}
.lmnt-circular-progress--on-success {
    @include circular-progress.color(var(--lmnt-theme-on-success));
}
.lmnt-circular-progress--on-danger {
    @include circular-progress.color(var(--lmnt-theme-on-danger));
}
.lmnt-circular-progress--on-unknown-dark {
    @include circular-progress.color(var(--lmnt-theme-light));
}
.lmnt-circular-progress--on-unknown-light {
    @include circular-progress.color(var(--lmnt-theme-dark));
}

.lmnt-circular-progress--two-color {
    @include circular-progress.indeterminate-colors(
        (
            var(--lmnt-theme-primary),
            var(--lmnt-theme-secondary),
            var(--lmnt-theme-primary),
            var(--lmnt-theme-secondary)
        )
    );
}

.lmnt-circular-progress--two-color-variant {
    @include circular-progress.indeterminate-colors(
        (
            var(--lmnt-theme-primary-variant),
            var(--lmnt-theme-secondary-variant),
            var(--lmnt-theme-primary-variant),
            var(--lmnt-theme-secondary-variant)
        )
    );
}
.lmnt-circularProgress--fill-primary {
    background-color: var(--lmnt-theme-primary);
    color: var(--lmnt-theme-on-primary);
}
.lmnt-circularProgress--fill-secondary {
    background-color: var(--lmnt-theme-secondary);
    color: var(--lmnt-theme-on-secondary);
}
.lmnt-circularProgress--fill-success {
    background-color: var(--lmnt-theme-success);
    color: var(--lmnt-theme-on-success);
}
.lmnt-circularProgress--fill-danger {
    background-color: var(--lmnt-theme-danger);
    color: var(--lmnt-theme-on-danger);
}
