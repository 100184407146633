.about {
    width: 400px;
    padding: 20px;
    height: auto;
    background-color: white;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14);

    &_header {
        width: 380px;
        height: 250px;
        background-image: url("../assets/AbtHeader.png");
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &_title {
        color: var(--on-colors-on-surface-active-high, rgba(0, 0, 0, 0.87));
        text-align: center;
        /* Display/Display6 */
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 120% */
        letter-spacing: 0.25px;
        &_sub{
            background-color: #CAECFF;
            color: #18445D;
        }
    }

    &_content {
        color: var(--on-colors-on-surface-active-high, rgba(0, 0, 0, 0.87));
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
}