/////////////////////////
///////  Patterns  ///////
/////////////////////////

html,
body,
#root,
.story-wrapper {
    color: var(--lmnt-theme-on-surface);
    font-family: var(--lmnt-theme-primary-font, Roboto, sans-serif);
    height: 100%;
}
