@use '../../common/element.core';

.lmnt {
    --lmnt-stepper-indicator__active-color: var(--lmnt-theme-primary-on-surface);
    --lmnt-stepper-indicator__active-emphasis: var(--lmnt-theme-emphasis-high);
    --lmnt-stepper-indicator__inactive-emphasis: var(--lmnt-theme-emphasis-highlighted);
    --lmnt-stepper-indicator__size: 12px;
    --lmnt-stepper-indicator__margin: 8px;

    &.lmnt-stepper-indicator__container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }

    .lmnt-stepper-indicator__item {
        background-color: element.$black;
        border-radius: 50%;
        height: var(--lmnt-stepper-indicator__size);
        margin: var(--lmnt-stepper-indicator__margin);
        opacity: var(--lmnt-stepper-indicator__inactive-emphasis);
        width: var(--lmnt-stepper-indicator__size);
    }

    .lmnt-stepper-indicator__item--active {
        background-color: var(--lmnt-stepper-indicator__active-color);
        opacity: var(--lmnt-stepper-indicator__Active-emphasis);
    }
}
