.lmnt {
    --lmnt-stepper--rule: solid 1px var(--lmnt-theme-on-surface-stroke);
    --lmnt-stepper--vertical-width: 360px;
    --lmnt-stepper__button-container--width: var(--lmnt-stepper__content--width);
    --lmnt-stepper__content--width: 600px;
    --lmnt-stepper__step--dense-height: 56px;
    --lmnt-stepper__step--font-family: var(--lmnt-theme-primary-font);
    --lmnt-stepper__step--height: 64px;
    --lmnt-stepper__step--shape-radius: var(--lmnt-theme--shape-radius);
    --lmnt-stepper__step-divider--margin: 4px;
    --lmnt-stepper__step-indicator--dense-margin: 12px 12px;
    --lmnt-stepper__step-indicator--margin: 12px 16px;
    --lmnt-stepper__step-text--dense-margin-rtl: 12px 0 12px 12px;
    --lmnt-stepper__step-text--dense-margin: 12px 12px 12px 0;
    --lmnt-stepper__step-text--margin-rtl: 12px 0 12px 16px;
    --lmnt-stepper__step-text--margin: 12px 16px 12px 0;
    --lmnt-stepper__step-title--font-weight: 700;
}

//
// Root component
//
.lmnt-stepper {
    align-items: center;
    border-bottom: var(--lmnt-stepper--rule);
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    padding: 8px;
}

.lmnt-stepper--rtl {
    flex-direction: row-reverse;
}

.lmnt-stepper--vertical {
    align-items: flex-start;
    border-bottom: unset;
    border-right: var(--lmnt-stepper--rule);
    flex-direction: column;
    height: 100%;
    min-width: var(--lmnt-stepper--vertical-width);
    width: fit-content;
}

.lmnt-stepper--rtl.lmnt-stepper--vertical {
    align-items: flex-end;
    border-left: var(--lmnt-stepper--rule);
    border-right: unset;
}

.lmnt-stepper--un-bordered {
    border: unset;
}

//
// Sub-component: Stepper Container > Stepper
//
.lmnt-stepper__container--vertical {
    align-items: stretch;
    display: flex;
    padding: none;
    text-align: center;
}

.lmnt-stepper__container--rtl {
    flex-direction: row-reverse;
}

//
// Sub-component: Stepper > Step
//
.lmnt-stepper__step {
    background-color: unset;
    border: none;
    border-radius: var(--lmnt-stepper__step--shape-radius);
    color: unset;
    display: flex;
    font-family: var(--lmnt-stepper__step--font-family);
    font-weight: unset;
    height: var(--lmnt-stepper__step--height);
    margin: 0;
    padding: 0;
    text-align: unset;
}

.lmnt-stepper--rtl .lmnt-stepper__step {
    flex-direction: row-reverse;
}

.lmnt-stepper--dense .lmnt-stepper__step {
    height: var(--lmnt-stepper__step--dense-height);
}

// Step interaction

.lmnt-stepper--non-linear .lmnt-stepper__step {
    cursor: pointer;
}

.lmnt-stepper--non-linear .lmnt-stepper__step:hover {
    background-color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-hover));
}

.lmnt-stepper--non-linear .lmnt-stepper__step:focus {
    background-color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-focus));
}

.lmnt-stepper--non-linear .lmnt-stepper__step:active {
    background-color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-pressed));
}

// Step variations

.lmnt-stepper__step--active {
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-active-black));
}

.lmnt-stepper__step--completed {
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-completed));
}

.lmnt-stepper__step--inactive {
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-inactive));
}

.lmnt-stepper__step--incomplete {
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-med));
}

.lmnt-stepper__step--danger {
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-high-black));
}

//
// Sub-component: Stepper > Step > Step Indicator
//
.lmnt-stepper__step-indicator {
    margin: var(--lmnt-stepper__step-indicator--margin);
}

.lmnt-stepper--dense .lmnt-stepper__step-indicator {
    margin: var(--lmnt-stepper__step-indicator--dense-margin);
}

//
// Sub-component: Stepper > Step > Step Text
//
.lmnt-stepper__step-text {
    align-self: center;
    flex-wrap: nowrap;
    margin: var(--lmnt-stepper__step-text--margin);
}

.lmnt-stepper--rtl .lmnt-stepper__step-text {
    flex-direction: row-reverse;
    margin: var(--lmnt-stepper__step-text--margin-rtl);
}

.lmnt-stepper--dense .lmnt-stepper__step-text {
    margin: var(--lmnt-stepper__step-text--dense-margin);
}

.lmnt-stepper--rtl.lmnt-stepper--dense .lmnt-stepper__step-text {
    margin: var(--lmnt-stepper__step-text--dense-margin-rtl);
}

//
// Sub-component: Stepper > Step > Step Title
//
.mdc-typography--subtitle1.lmnt-stepper__step-title {
    font-weight: var(--lmnt-stepper__step-title--font-weight);
    white-space: nowrap;
}

//
// Sub-component:  Stepper > Step Divider
//
.lmnt-stepper__step-divider {
    align-self: center;
    flex-grow: 1;
    margin: 0 var(--lmnt-stepper__step-divider--margin);
    width: auto;
}

//
// Sub-component: Stepper > Stepper Content Container
//
.lmnt-stepper__content-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: none;
    width: 100%;
}

//
// Sub-component:  Stepper > Step Content
//
.lmnt-stepper__step-content {
    margin: 0 auto;
    text-align: center;
    width: var(--lmnt-stepper__content--width);
}

.lmnt-stepper__step-content--hidden {
    display: none;
}

.lmnt-stepper__step-content--full-width {
    box-sizing: border-box;
    width: 100%;
}

//
// Sub-component:  Stepper > Step Button
//
.lmnt-stepper__step-button--hidden {
    display: none;
}

//
// Sub-component:  Stepper > Stepper Button Container
//
.lmnt-stepper__button-container {
    border-top: var(--lmnt-stepper--rule);
}

.lmnt-stepper__button-container--un-bordered {
    border: unset;
}

.lmnt-stepper__button-container-inner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0 auto;
    text-align: right;
    width: var(--lmnt-stepper__button-container--width);
}

.lmnt-stepper__button-container--full-width .lmnt-stepper__button-container-inner {
    box-sizing: border-box;
    width: 100%;
}

.lmnt-stepper__container--rtl .lmnt-stepper__button-container-inner {
    flex-direction: row;
}
