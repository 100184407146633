//////////////////////////////
////////// Hero ///////////
//////////////////////////////

.lmnt.lmnt-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    position: relative;

    z-index: 0;

    &.lmnt-hero__align-left-top {
        align-items: flex-start;
        justify-content: flex-start;
    }

    &.lmnt-hero__align-left-center {
        align-items: center;
        justify-content: flex-start;
    }

    &.lmnt-hero__align-left-bottom {
        align-items: flex-end;
        justify-content: flex-start;
    }

    &.lmnt-hero__align-center-bottom {
        align-items: flex-end;
        justify-content: center;
    }

    &.lmnt-hero__align-center-top {
        align-items: flex-start;
        justify-content: center;
    }

    &.lmnt-hero__align-centered {
        align-items: center;
        justify-content: center;
    }

    &.lmnt-hero__align-right-top {
        align-items: flex-start;
        justify-content: flex-end;
    }

    &.lmnt-hero__align-right-center {
        align-items: center;
        justify-content: flex-end;
    }

    &.lmnt-hero__align-right-bottom {
        align-items: flex-end;
        justify-content: flex-end;
    }

    .lmnt-hero-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &.lmnt-hero-overlay__dark {
            background-color: var(--lmnt-theme-on-unknown-black-inactive);
        }

        &.lmnt-hero-overlay__light {
            background-color: var(--lmnt-theme-on-unknown-white-inactive);
        }

        &.lmnt-hero-overlay__leading {
            width: 50%;
            justify-self: flex-start;
        }

        &.lmnt-hero-overlay__trailing {
            width: 50%;
            justify-self: flex-end;
        }
    }

    .lmnt-hero-content {
        z-index: 2;
    }
}
