@use '../../common/element.core';
@use '../chips/chips.scss';
@use '../textfield/textfield.scss';
@use '../select/select.scss';

// TODO: refactor class names to require fewer
// TODO: figure out what textfield input should look like
// NOTE: double / triple class specificity required
.lmnt-input-chip-set-wrapper,
.lmnt-input-chip-container {
    display: flex;
    height: auto;

    // NOTE: similar mdc-chip-set-scrollable-wrapper in chips.scss
    .mdc-input-chip-set-wrapper-scrollable {
        max-width: calc(100% - 8em);
        overflow-x: auto;
        overflow-y: hidden;
    }

    // NOTE: Hides scrollbar
    ::-webkit-scrollbar {
        width: 0;
    }
}

.lmnt-input-chip--contained {
    .lmnt-select-clear--icon-container {
        margin-right: 8px;
    }
}

.lmnt-helper-text--invalid {
    color: var(--lmnt-theme-danger);
}
