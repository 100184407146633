//////////////////////////////
////////// Divider ///////////
//////////////////////////////

.lmnt {
    --lmnt-divider-height-baseline: 1px;
    --lmnt-divider-height-dense: 1px;
    --lmnt-divider-height-tall: 4px;
    --lmnt-divider-margin-dense: 4px;
    --lmnt-divider-margin-standard: 8px;
    --lmnt-divider-margin-airy: 16px;
}

$height-baseline: var(--lmnt-divider-height-baseline);
$height-dense: var(--lmnt-divider-height-dense);
$height-tall: var(--lmnt-divider-height-tall);

.lmnt-divider {
    border-bottom-width: $height-baseline;
    border-color: currentColor;
    border-left-width: 0;
    border-right-width: 0;
    border-style: solid;
    border-top-width: 0;
    height: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    width: 100%;
}

// use this modifier to trump all other color assignments in the local context
.lmnt-divider--color-important {
    // stylelint-disable-next-line declaration-empty-line-before, declaration-no-important
    border-color: currentColor !important;
}

// border height
.lmnt-divider--dense {
    border-bottom-width: $height-dense;
}

.lmnt-divider--tall {
    border-bottom-width: $height-tall;
}

.lmnt-card-divider {
    border-color: #e0e0e0;
    color: #e0e0e0;
}

.lmnt-divider--margin-dense {
    margin-bottom: var(--lmnt-divider-margin-dense);
    margin-top: var(--lmnt-divider-margin-dense);
}

.lmnt-divider--margin-standard {
    margin-bottom: var(--lmnt-divider-margin-standard);
    margin-top: var(--lmnt-divider-margin-standard);
}

.lmnt-divider--margin-airy {
    margin-bottom: var(--lmnt-divider-margin-airy);
    margin-top: var(--lmnt-divider-margin-airy);
}

// border style
$borderStyles: dotted, dashed, solid, double, groove, ridge, inset, outset;

@each $style in $borderStyles {
    .lmnt-divider--#{$style} {
        border-style: $style;
    }
}

// border color
$colorMap: (
    primary: var(--lmnt-theme-primary),
    primary-variant: var(--lmnt-theme-primary-variant),
    primary-50: var(--lmnt-theme-primary-50),
    primary-100: var(--lmnt-theme-primary-100),
    primary-200: var(--lmnt-theme-primary-200),
    primary-300: var(--lmnt-theme-primary-300),
    primary-400: var(--lmnt-theme-primary-400),
    primary-500: var(--lmnt-theme-primary-500),
    primary-600: var(--lmnt-theme-primary-600),
    primary-700: var(--lmnt-theme-primary-700),
    primary-800: var(--lmnt-theme-primary-800),
    primary-900: var(--lmnt-theme-primary-900),
    secondary: var(--lmnt-theme-secondary),
    secondary-variant: var(--lmnt-theme-secondary-variant),
    secondary-50: var(--lmnt-theme-secondary-50),
    secondary-100: var(--lmnt-theme-secondary-100),
    secondary-200: var(--lmnt-theme-secondary-200),
    secondary-300: var(--lmnt-theme-secondary-300),
    secondary-400: var(--lmnt-theme-secondary-400),
    secondary-500: var(--lmnt-theme-secondary-500),
    secondary-600: var(--lmnt-theme-secondary-600),
    secondary-700: var(--lmnt-theme-secondary-700),
    secondary-800: var(--lmnt-theme-secondary-800),
    secondary-900: var(--lmnt-theme-secondary-900),
    danger: var(--lmnt-theme-danger),
    danger-variant: var(--lmnt-theme-danger-variant),
    danger-50: var(--lmnt-theme-danger-50),
    danger-100: var(--lmnt-theme-danger-100),
    danger-200: var(--lmnt-theme-danger-200),
    danger-300: var(--lmnt-theme-danger-300),
    danger-400: var(--lmnt-theme-danger-400),
    danger-500: var(--lmnt-theme-danger-500),
    danger-600: var(--lmnt-theme-danger-600),
    danger-700: var(--lmnt-theme-danger-700),
    danger-800: var(--lmnt-theme-danger-800),
    danger-900: var(--lmnt-theme-danger-900),
    success: var(--lmnt-theme-success),
    success-variant: var(--lmnt-theme-success-variant),
    success-50: var(--lmnt-theme-success-50),
    success-100: var(--lmnt-theme-success-100),
    success-200: var(--lmnt-theme-success-200),
    success-300: var(--lmnt-theme-success-300),
    success-400: var(--lmnt-theme-success-400),
    success-500: var(--lmnt-theme-success-500),
    success-600: var(--lmnt-theme-success-600),
    success-700: var(--lmnt-theme-success-700),
    success-800: var(--lmnt-theme-success-800),
    success-900: var(--lmnt-theme-success-900),
    ancillary-100: var(--lmnt-theme-ancillary-100),
    ancillary-200: var(--lmnt-theme-ancillary-200),
    ancillary-300: var(--lmnt-theme-ancillary-300),
    ancillary-400: var(--lmnt-theme-ancillary-400),
    ancillary-500: var(--lmnt-theme-ancillary-500),
    ancillary-600: var(--lmnt-theme-ancillary-600),
    ancillary-700: var(--lmnt-theme-ancillary-700),
    ancillary-800: var(--lmnt-theme-ancillary-800),
    ancillary-900: var(--lmnt-theme-ancillary-900),
    on-surface-stroke: var(--lmnt-theme-on-surface-stroke)
);

@mixin dividerBorderColorClasses($color, $value) {
    .lmnt-divider--#{$color} {
        border-color: $value;
        color: $value;
    }
}

@each $color, $value in $colorMap {
    @include dividerBorderColorClasses($color: $color, $value: $value);
}
