@use 'sass:map';
@use '@material/typography/_typography' as typo;
@use '../../common/element.core' as core;

.lmnt {
    --lmnt-footer__cols-sm: 1;
    --lmnt-footer__cols-md: 3;
    --lmnt-footer__cols-lg: 3;
    --lmnt-footer__bottom-cols-sm: 1;
    --lmnt-footer__bottom-cols-md: 3;
    --lmnt-footer__bottom-cols-lg: 3;
}

.lmnt-footer {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
}

.lmnt-footer__content-wrapper {
    padding-bottom: 64px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 64px;
    width: 100%;
}

.lmnt-footer__content {
    align-content: center;
    display: grid;
    grid-template-columns: repeat(var(--lmnt-footer__cols-lg), 1fr);
    justify-content: center;
}

.lmnt-footer__list {
    width: fit-content;
}

.lmnt-footer__list-title {
    margin: 0;
}

.lmnt-footer__list-item {
    margin: 0;
    padding-top: 24px;
    list-style-type: none;
}

// left for legacy support
.lmnt-footer__bottom-content {
    font-size: 14px;
    padding-left: 2.5em;
}

//! HACK: Fallback for older footer list item support. We wouldn't normally target
//! a tag directly like this, but I want to avoid mutating children in code.
.lmnt-footer__list dd:not(.lmnt-footer__list-item) {
    @include typo.typography('subtitle2');
    @extend .lmnt-footer__list-item;
}

.lmnt-footer hr,
.lmnt-footer__divider {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0;
}

.lmnt-footer__bottom {
    align-items: center;
    display: grid;
    gap: 64px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    padding-left: 32px;
    padding-right: 32px;
}

.lmnt-footer__bottom-group {
    display: flex;
    flex: 1;
    font-size: 14px;
    gap: 35px;
    justify-content: center;
    margin-bottom: 32px;
    margin-top: 32px;
    white-space: nowrap;
    width: 100%;
}

.lmnt-footer__bottom-group--margin-dense {
    margin-bottom: 16px;
    margin-top: 16px;
}

.lmnt-footer__bottom-group--margin-airy {
    margin-bottom: 64px;
    margin-top: 64px;
}

.lmnt-footer__bottom-group--start {
    justify-content: start;
}

.lmnt-footer__bottom-group--end {
    justify-content: end;
}

.lmnt-footer__bottom-group--gap-none {
    gap: 0;
}

.lmnt-footer__bottom-group--gap-dense {
    gap: 8px;
}

.lmnt-footer__bottom-group--gap-standard {
    gap: 16px;
}

.lmnt-footer__bottom-group--gap-airy {
    gap: 24px;
}

@media only screen and (min-width: 0) and (max-width: map.get(core.$breakpointsMap, 'tablet-sm-max')) {
    .lmnt-footer__content {
        grid-template-columns: repeat(var(--lmnt-footer__cols-sm), 1fr);
        justify-items: start;
    }

    .lmnt-footer__bottom {
        gap: 0;
        grid-template-columns: repeat(var(--lmnt-footer__bottom-cols-sm), 1fr);
    }

    .lmnt-footer__bottom-group {
        align-items: center;
        flex-direction: column;
        justify-items: center;
    }

    .lmnt-footer__content-wrapper {
        padding-left: 16px;
        padding-right: 16px;
    }

    @for $i from 1 through 10 {
        .lmnt-footer__column--sm-span-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'tablet-sm-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-lg-max')) {
    .lmnt-footer__content {
        grid-template-columns: repeat(var(--lmnt-footer__cols-md), 1fr);
    }

    .lmnt-footer__bottom {
        gap: 0;
        grid-template-columns: repeat(var(--lmnt-footer__bottom-cols-md), 1fr);
    }

    @for $i from 1 through 10 {
        .lmnt-footer__column--md-span-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-xl-min')) {
    .lmnt-footer__content {
        grid-template-columns: repeat(var(--lmnt-footer__cols-lg), 1fr);
    }

    .lmnt-footer__bottom {
        grid-template-columns: repeat(var(--lmnt-footer__bottom-cols-lg), 1fr);
    }

    @for $i from 1 through 10 {
        .lmnt-footer__column--lg-span-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-sm-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-sm-max')) {
    .lmnt-footer-with-max-content-width {
        align-items: center;

        .lmnt-footer__content-wrapper {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
        }
        // TODO: remove "lmnt-footer_bottom-wrapper" typo'd selector in future version
        .lmnt-footer_bottom-wrapper,
        .lmnt-footer__bottom-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .lmnt-footer__bottom,
        .lmnt-footer__content {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
            width: 100%;
        }
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-lg-min')) and (max-width: map.get(core.$breakpointsMap, 'desktop-lg-max')) {
    .lmnt-footer-with-max-content-width {
        align-items: center;

        .lmnt-footer__content-wrapper {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
        }

        // TODO: remove "lmnt-footer_bottom-wrapper" typo'd selector in future version
        .lmnt-footer_bottom-wrapper,
        .lmnt-footer__bottom-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .lmnt-footer__bottom,
        .lmnt-footer__content {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-lg');
            width: 100%;
        }
    }
}

@media only screen and (min-width: map.get(core.$breakpointsMap, 'desktop-xl-min')) {
    .lmnt-footer-with-max-content-width {
        align-items: center;

        .lmnt-footer__content-wrapper {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-xl');
        }

        // TODO: remove "lmnt-footer_bottom-wrapper" typo'd selector in future version
        .lmnt-footer_bottom-wrapper,
        .lmnt-footer__bottom-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .lmnt-footer__bottom,
        .lmnt-footer__content {
            max-width: map.get(core.$breakpointsMap, 'content-max-width-xl');
            width: 100%;
        }
    }
}
