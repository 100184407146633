@use '../../common/element.core' as theme;
@use '../../common/elevation';
@use 'sass:map';

.lmnt-datepicker {
    position: relative;
    text-align: left;
}

$lmnt-datepicker-elevation: 2;

.lmnt {
    --lmnt-datepicker---base-color: var(--lmnt-theme-primary);
    --lmnt-datepicker--border-radius: var(--lmnt-theme--shape-radius);
    --lmnt-datepicker--border-width: 1px;
    --lmnt-datepicker--cell-border-radius: 50%;
    --lmnt-datepicker--side-content-width: var(--lmnt-datepicker--width);
    --lmnt-datepicker--width: 334px;
    --lmnt-datepicker-surface: var(--lmnt-theme-surface);
    --lmnt-datepicker__cell-height: 40px;
    --lmnt-datepicker__cell-width: 40px;
    --lmnt-datepicker__day--highlighted: var(--lmnt-theme-primary-50);
}

//
// Datepicker > Calendar
//
.lmnt-datepicker__calendar {
    background-color: var(--lmnt-datepicker-surface);
    border-color: var(--lmnt-theme-on-surface-stroke);
    border-radius: var(--lmnt-datepicker--border-radius);
    border-style: solid;
    border-width: var(--lmnt-datepicker--border-width);
    box-shadow: elevation.$elevation-08dp;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    user-select: none;
    width: var(--lmnt-datepicker--width);
    z-index: $lmnt-datepicker-elevation;
}

.lmnt-datepicker__calendar--full-width {
    max-width: unset;
    min-width: unset;
    width: 100%;
}

.lmnt-datepicker__calendar.lmnt-datepicker--floating {
    border-top-left-radius: 0;
    position: absolute;
}

//
// Datepicker > Calendar > Content
//
.lmnt-datepicker__content {
    width: var(--lmnt-datepicker--width);
}

//
// Datepicker > Calendar > Header
//
.lmnt-datepicker__calendar-header {
    align-items: center;
    border: unset;
    border-bottom-color: var(--lmnt-theme-on-surface-stroke);
    border-bottom-style: solid;
    border-bottom-width: var(--lmnt-datepicker--border-width);
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    gap: 6px;
    justify-content: center;
    margin: unset;
    padding: unset;
    padding-bottom: 12px;
    padding-top: 12px;
}

// additional padding for month-year dialog
.lmnt-datepicker__calendar-header--month-year {
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 0;
}

//
// Datepicker > Calendar > Header > Month Select
//
.lmnt-select-container.lmnt-datepicker-calender__month-select {
    width: 140px;
}

//
// Datepicker > Calendar > Header > Year Select
//
.lmnt-select-container.lmnt-datepicker-calender__year-select {
    width: 98px;
}

//
// Datepicker > Calendar > Surface
//
.lmnt-datepicker__calendar-surface {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 4px;
    width: 280px;
}

//
// Datepicker > Calendar > Surface > Day Header
//
.lmnt-datepicker__day-header.mdc-typography--caption {
    align-items: center;
    background-color: unset;
    border-color: transparent;
    border-radius: var(--lmnt-datepicker--cell-border-radius);
    border-style: solid;
    border-width: 1.5px;
    box-sizing: border-box;
    color: var(--lmnt-on-surface-disabled);
    cursor: default;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    height: var(--lmnt-datepicker__cell-height);
    justify-content: center;
    line-height: 16px;
    margin: unset;
    padding: unset;
    pointer-events: none;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    width: var(--lmnt-datepicker__cell-width);
}

//
// Datepicker > Calendar > Surface > Cell
//
.lmnt-datepicker__cell {
    background-color: unset;
    border-color: transparent;
    border-radius: var(--lmnt-datepicker--cell-border-radius);
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;
    color: #{rgba(0, 0, 0, theme.$emphasis-on-surface-resting)};
    cursor: pointer;
    font-size: 12px;
    height: var(--lmnt-datepicker__cell-height);
    line-height: 2.5;
    position: relative; // to enable color overlays using ::after
    text-align: center;
    vertical-align: middle;
    width: var(--lmnt-datepicker__cell-width);
}

@mixin cell-overlay {
    border-radius: var(--lmnt-datepicker--cell-border-radius);
    box-sizing: border-box;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@mixin cell-overlay-border {
    height: 40px;
    left: -1px;
    position: absolute;
    top: -1px;
    width: 40px;
}

@mixin selected-fx {
    @include cell-overlay;
    align-items: center;
    content: attr(data-day);
    color: inherit;
    display: flex;
    justify-content: center;
}

// Cell: default
.lmnt-datepicker__cell:hover::after,
.lmnt-datepicker__cell--hover::after {
    @include cell-overlay;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell:active::after,
.lmnt-datepicker__cell--active::after {
    @include cell-overlay;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell:focus::after,
.lmnt-datepicker__cell--focus::after {
    @include cell-overlay;
    background-color: var(--lmnt-datepicker-surface);
    opacity: var(--lmnt-theme-emphasis-focus--alt);
}

// Cell: today
.lmnt-datepicker__cell--today {
    background-color: unset;
    border-width: 1px;
    border-color: var(--lmnt-theme-on-surface-stroke);
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-on-surface-highlighted));
}

.lmnt-datepicker__cell--today:hover::after,
.lmnt-datepicker__cell--today--hover::after {
    @include cell-overlay;
    @include cell-overlay-border;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-focus);
}

.lmnt-datepicker__cell--today:active::after,
.lmnt-datepicker__cell--today--active::after {
    @include cell-overlay;
    @include cell-overlay-border;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--today:focus::after,
.lmnt-datepicker__cell--today--focus::after {
    @include cell-overlay;
    @include cell-overlay-border;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-focus--alt);
}

// Cell: selected
.lmnt-datepicker__cell--selected {
    background-color: var(--lmnt-datepicker---base-color);
    border: unset;
    color: var(--lmnt-theme-on-primary);
}

.lmnt-datepicker__cell--selected:hover::after,
.lmnt-datepicker__cell--selected--hover::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell--selected:active::after,
.lmnt-datepicker__cell--selected--active::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--selected:focus::after,
.lmnt-datepicker__cell--selected--focus::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-focus--alt);
}

// Cell: highlighted
.lmnt-datepicker__cell--highlighted {
    background-color: var(--lmnt-datepicker__day--highlighted);
    border-radius: 0;
    border-width: 0;
}

.lmnt-datepicker__cell--highlight-start {
    border-bottom-left-radius: var(--lmnt-datepicker--cell-border-radius);
    border-top-left-radius: var(--lmnt-datepicker--cell-border-radius);
}

.lmnt-datepicker__cell--highlight-end {
    border-bottom-right-radius: var(--lmnt-datepicker--cell-border-radius);
    border-top-right-radius: var(--lmnt-datepicker--cell-border-radius);
}

.lmnt-datepicker__cell--highlighted:hover::after,
.lmnt-datepicker__cell--highlighted--hover::after {
    @include cell-overlay;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-focus);
}

.lmnt-datepicker__cell--highlighted:active::after,
.lmnt-datepicker__cell--highlighted--active::after {
    @include cell-overlay;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--highlighted:focus::after,
.lmnt-datepicker__cell--highlighted--focus::after {
    @include cell-overlay;
    background-color: var(--lmnt-datepicker---base-color);
    opacity: var(--lmnt-theme-emphasis-focus--alt);
}

// Cell: selected + today
.lmnt-datepicker__cell.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today:before {
    @include cell-overlay;
    border: solid 2px rgba(0, 0, 0, var(--lmnt-theme-emphasis-stroke-dark));
}

.lmnt-datepicker__cell.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today:hover::after,
.lmnt-datepicker__cell.lmnt-datepicker__cell--selected--hover.lmnt-datepicker__cell--today--hover::after {
    @include cell-overlay;
    @include cell-overlay-border;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today:active::after,
.lmnt-datepicker__cell--selected--active.lmnt-datepicker__cell--today--active::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--selected:focus.lmnt-datepicker__cell--today::after,
.lmnt-datepicker__cell--selected--focus.lmnt-datepicker__cell--today--focus::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-focus--alt);
}

// Cell: highlighted + selected
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected::before {
    @include selected-fx;
    background-color: var(--lmnt-datepicker---base-color);
    border: solid 2px rgba(0, 0, 0, var(--lmnt-theme-emphasis-stroke-dark));
    color: var(--lmnt-theme-on-primary);
}

.lmnt-datepicker__cell.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected:hover::after,
.lmnt-datepicker__cell.lmnt-datepicker__cell--highlighted--hover.lmnt-datepicker__cell--selected--hover::after {
    @include cell-overlay;
    @include cell-overlay-border;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected:active::after,
.lmnt-datepicker__cell--highlighted--active.lmnt-datepicker__cell--selected--active::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--highlighted:focus.lmnt-datepicker__cell--selected::after,
.lmnt-datepicker__cell--highlighted--focus.lmnt-datepicker__cell--selected--focus::after {
    @include cell-overlay;
    background-color: var(--lmnt-theme-on-primary);
    opacity: var(--lmnt-theme-emphasis-focus--alt);
}

// Cell: highlighted + today
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today::before {
    @include selected-fx;
    border: solid 1px var(--lmnt-theme-on-surface-stroke);
    color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-on-surface-highlighted));
}

// Cell: highlighted + selected + today
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today::before {
    @include selected-fx;
    background-color: var(--lmnt-datepicker---base-color);
    border: solid 1px var(--lmnt-theme-on-surface-stroke);
    color: var(--lmnt-theme-on-primary);
}

// Cell: blank
.lmnt-datepicker__cell--blank,
.lmnt-datepicker__cell--blank:hover,
.lmnt-datepicker__cell--blank:hover::after,
.lmnt-datepicker__cell--blank:active,
.lmnt-datepicker__cell--blank:active::after,
.lmnt-datepicker__cell--blank:focus,
.lmnt-datepicker__cell--blank:focus::after {
    background-color: unset;
    color: unset;
    cursor: default;
}

// Cell: disabled

@mixin disabled-fx($class: '', $border: 0) {
    #{$class}.lmnt-datepicker__cell--disabled::after,
    #{$class}.lmnt-datepicker__cell--disabled:hover::after,
    #{$class}.lmnt-datepicker__cell--disabled:focus::after,
    #{$class}.lmnt-datepicker__cell--disabled:active::after {
        @include cell-overlay;
        background-color: var(--lmnt-theme-on-primary);
        cursor: default;
        opacity: var(--lmnt-theme-emphasis-on-surface-disabled);

        @if $border == 1 {
            height: 40px;
            left: -1px;
            top: -1px;
            width: 40px;
        }
    }
}

@include disabled-fx;
@include disabled-fx($class: '.lmnt-datepicker__cell--highlighted');
@include disabled-fx($class: '.lmnt-datepicker__cell--today', $border: 1);
@include disabled-fx($class: '.lmnt-datepicker__cell--selected');
@include disabled-fx(
    $class: '.lmnt-datepicker__cell--today.lmnt-datepicker__cell--selected',
    $border: 0
);
@include disabled-fx(
    $class: '.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today',
    $border: 0
);
@include disabled-fx(
    $class:
        '.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today',
    $border: 0
);

//
// Datepicker > Calendar > Side Content
//
.lmnt-datepicker__calendar.lmnt-datepicker__calendar--with-side-content {
    box-sizing: border-box;
    max-width: calc(
        var(--lmnt-datepicker--width) + var(--lmnt-datepicker--side-content-width)
    );
    min-width: calc(
        var(--lmnt-datepicker--width) + var(--lmnt-datepicker--side-content-width)
    );
    width: calc(
        var(--lmnt-datepicker--width) + var(--lmnt-datepicker--side-content-width)
    );
}

.lmnt-datepicker__side-content {
    max-width: var(--lmnt-datepicker--side-content-width);
    min-width: var(--lmnt-datepicker--side-content-width);
    width: var(--lmnt-datepicker--side-content-width);
}

.lmnt-datepicker--fullWidth {
    width: 100%;
}
