////////////////////////////
//////  Typography   ///////
////////////////////////////
@use 'sass:math';
@use '@material/typography/mdc-typography';
@use '@material/typography/_typography' as typo;
@use '../../common/mixins';
@use '../../common/functions' as functions;
@use '../../common/element.core';

// MIGRATION: move `font` utility features to this file?

//////////////////////
//////  Themes  //////
//////////////////////
:root,
.lmnt {
    // Text Link Styles
    --lmnt-typography-link-color: var(--lmnt-theme-primary-variant);
    --lmnt-typography-link-font-weight: bold;
    --lmnt-typography-link-text-decoration: none;

    // TODO: deprecate styling based on .lmnt-theme-primary-bg or `.lmnt-theme-secondary-bg` in favor of variant styling
    --lmnt-typography-link-color-on-primary: inherit;
    --lmnt-typography-link-color-on-secondary: inherit;

    // NOTE: Material defaults listed here for reference. Used in themes primarily. Update only as needed.

    // Display 1
    // --mdc-typography-headline1-font-family: Roboto;
    // --mdc-typography-headline1-font-size: 6rem; // 96px
    // --mdc-typography-headline1-font-weight: 300;
    --mdc-typography-headline1-letter-spacing: #{functions.toRem(-1.25)};
    --mdc-typography-headline1-line-height: #{functions.toRem(116)};
    // --mdc-typography-headline1-text-decoration: inherit;
    // --mdc-typography-headline1-text-transform: inherit;

    // Display 2
    // --mdc-typography-headline2-font-family: Roboto;
    // --mdc-typography-headline2-font-size: 3.75rem; // 60px
    // --mdc-typography-headline2-font-weight: 300;
    // --mdc-typography-headline2-letter-spacing: -0.0083333333em; // -0.5px
    --mdc-typography-headline2-line-height: #{functions.toRem(72)};
    // --mdc-typography-headline2-text-decoration: inherit;
    // --mdc-typography-headline2-text-transform: inherit;

    // Display 3
    // --mdc-typography-headline3-font-family: Roboto;
    // --mdc-typography-headline3-font-size: 3rem; // 48px
    // --mdc-typography-headline3-font-weight: 400;
    // --mdc-typography-headline3-letter-spacing: normal;
    --mdc-typography-headline3-line-height: #{functions.toRem(56)};
    // --mdc-typography-headline3-text-decoration: inherit;
    // --mdc-typography-headline3-text-transform: inherit;

    // Display 4
    // --mdc-typography-headline4-font-family: Roboto;
    // --mdc-typography-headline4-font-size: 2.125rem; // 34px
    // --mdc-typography-headline4-font-weight: 400;
    // --mdc-typography-headline4-letter-spacing: 0.0073529412em;
    // --mdc-typography-headline4-line-height: 2.5rem; // 40px
    // --mdc-typography-headline4-text-decoration: inherit;
    // --mdc-typography-headline4-text-transform: inherit;

    // Display 5
    // --mdc-typography-headline5-font-family: Roboto;
    // --mdc-typography-headline5-font-size: 1.5rem; // 24px
    // --mdc-typography-headline5-font-weight: 400;
    // --mdc-typography-headline5-letter-spacing: normal;
    --mdc-typography-headline5-line-height: #{functions.toRem(28)};
    // --mdc-typography-headline5-text-decoration: inherit;
    // --mdc-typography-headline5-text-transform: inherit;

    // Display 6
    // --mdc-typography-headline6-font-family: Roboto;
    // --mdc-typography-headline6-font-size: 1.25rem; // 20px
    // --mdc-typography-headline6-font-weight: 500;
    // --mdc-typography-headline6-letter-spacing: 0.0125em; // 0.25px
    --mdc-typography-headline6-line-height: #{functions.toRem(24)};
    // --mdc-typography-headline6-text-decoration: inherit;
    // --mdc-typography-headline6-text-transform: inherit;

    // Subtitle 1
    // --mdc-typography-subtitle1-font-family: Roboto;
    // --mdc-typography-subtitle1-font-size: 1rem; // 16px
    // --mdc-typography-subtitle1-font-weight: 400;
    --mdc-typography-subtitle1-letter-spacing: normal;
    --mdc-typography-subtitle1-line-height: #{functions.toRem(24)};
    // --mdc-typography-subtitle1-text-decoration: inherit;
    // --mdc-typography-subtitle1-text-transform: inherit;
    --lmnt-typography--subtitle1-bold-font-weight: 700;

    // Subtitle 2
    // --mdc-typography-subtitle2-font-family: Roboto;
    // --mdc-typography-subtitle2-font-size: 0.875rem; // 14px
    // --mdc-typography-subtitle2-font-weight: 500;
    --mdc-typography-subtitle2-letter-spacing: normal;
    --mdc-typography-subtitle2-line-height: #{functions.toRem(20)};
    // --mdc-typography-subtitle2-text-decoration: inherit;
    // --mdc-typography-subtitle2-text-transform: inherit;
    --lmnt-typography--subtitle2-bold-font-weight: 700;

    // Body 1
    // --mdc-typography-body1-font-family: Roboto;
    // --mdc-typography-body1-font-size: 1rem; // 16px
    // --mdc-typography-body1-font-weight: 400;
    --mdc-typography-body1-letter-spacing: normal;
    --mdc-typography-body1-line-height: #{functions.toRem(28)};
    // --mdc-typography-body1-text-decoration: inherit;
    // --mdc-typography-body1-text-transform: inherit;
    --lmnt-typography--body1-bold-font-weight: 700;

    // Body 2
    // --mdc-typography-body2-font-family: Roboto;
    // --mdc-typography-body2-font-size: 0.875rem; // 14px
    // --mdc-typography-body2-font-weight: 400;
    // --mdc-typography-body2-letter-spacing: 0.0178571429em; // 0.25px
    // --mdc-typography-body2-line-height: 1.25rem; // 20px
    // --mdc-typography-body2-text-decoration: inherit;
    // --mdc-typography-body2-text-transform: inherit;
    --lmnt-typography--body2-bold-font-weight: 700;

    // Caption
    // --mdc-typography-caption-font-family: Roboto;
    // --mdc-typography-caption-font-size: 0.75rem; // 12px
    // --mdc-typography-caption-font-weight: 400;
    // --mdc-typography-caption-letter-spacing: 0.0333333333em; // 0.4px
    --mdc-typography-caption-line-height: #{functions.toRem(16)}; //16px
    // --mdc-typography-caption-text-decoration: inherit;
    // --mdc-typography-caption-text-transform: inherit;
    --lmnt-typography--caption-bold-font-weight: 500;

    // Button
    // --mdc-typography-button-font-family: Roboto;
    // --mdc-typography-button-font-size: 0.875rem; // 14px
    // --mdc-typography-button-font-weight: 500;
    // --mdc-typography-button-letter-spacing: 0.0892857143em; // 1.25px
    --mdc-typography-button-line-height: #{functions.toRem(16)};
    // --mdc-typography-button-text-decoration: none;
    // --mdc-typography-button-text-transform: uppercase;

    // Overline
    // --mdc-typography-overline-font-family: Roboto;
    // --mdc-typography-overline-font-size: 0.75rem; // 12px
    // --mdc-typography-overline-font-weight: 500;
    // --mdc-typography-overline-letter-spacing: 0.1666666667em; // 2px
    --mdc-typography-overline-line-height: #{functions.toRem(16)}; // 16px
    // --mdc-typography-overline-text-decoration: none;
    // --mdc-typography-overline-text-transform: uppercase;
}

.mdc-typography--subtitle1-bold {
    @include typo.typography('subtitle1');
    font-weight: var(--lmnt-typography--subtitle1-bold-font-weight);
}

.mdc-typography--subtitle2-bold {
    @include typo.typography('subtitle2');
    font-weight: var(--lmnt-typography--body1-bold-font-weight);
}

.mdc-typography--body1-bold {
    @include typo.typography('body1');
    font-weight: var(--lmnt-typography--subtitle2-bold-font-weight);
}

.mdc-typography--body2-bold {
    @include typo.typography('body2');
    font-weight: var(--lmnt-typography--body2-bold-font-weight);
}

.mdc-typography--caption-bold {
    @include typo.typography('caption');
    font-weight: var(--lmnt-typography--caption-bold-font-weight);
}

.lmnt-theme-link,
a.mdc-typography,
a {
    color: var(--lmnt-typography-link-color);
    font-weight: var(--lmnt-typography-link-font-weight);
    cursor: pointer;
    text-decoration: var(--lmnt-typography-link-text-decoration);
}

// TODO: deprecate styling based on `.lmnt-theme-primary-bg` in favor of variant styling
@include mixins.on-primary {
    a {
        color: var(--typography-link-color-on-primary);
    }
}

// TODO: deprecate styling based on `.lmnt-theme-secondary-bg` in favor of variant styling
@include mixins.on-secondary {
    a {
        color: var(--lmnt-typography-link-color-on-secondary);
    }
}
