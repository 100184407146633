.login_heading {
  color: #40baff;
  margin: 5px auto;
}
.redactlogocont {
  height: 100px;
}
.card_img_media {
  width: 60%;
  background-size: cover;
  background-position: center;
}
.logintxt_content {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: justify;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgb(0, 0, 0);
}
.blueradio {
  display: block;
  justify-content: center;

  .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: #40baff !important;
  }
  .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: #40baff !important;
  }
}

@media only screen and (max-width: 1100px) {
  .redactlogocont {
    height: 60px;
  }
}

@media only screen and (max-width: 996px) {
  .card_img_media {
    display: none;
  }
}
