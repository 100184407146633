/////////////////////////////////////////////
///////  Utility: Screen Reader Only  ///////
/////////////////////////////////////////////

// Make content visually hidden but still available for screen readers.

// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

.sr-only {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

// Make content visually hidden but still available for screen readers.
// Additionally display content when it is hovered or focused.
// Useful for "Skip to main content"

// See: https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1

.sr-only-focusable {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus,
    &:active {
        clip: auto;
        clip-path: none;
        height: auto;
        overflow: visible;
        position: static;
        white-space: normal;
        width: auto;
    }
}
