.custommodal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000000;
  background: rgba(0, 0, 0, 0.6);
}
.customsmallmodalbody {
  width: 300px;
  margin: auto;
  padding: 20px;
  background: #fff;
}
.custommodalbody {
  width: 90vw;
  margin: auto;
  padding: 20px;
  background: #fff;
  overflow-y: scroll;
  max-height: 80vh !important;
}
