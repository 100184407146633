.lmnt {
    --lmnt-breadcrumbs-color-link: var(--mdc-theme-text-secondary-on-background);
    --lmnt-breadcrumbs-color-active: var(--mdc-theme-text-primary-on-background);
}

.lmnt-breadcrumbs__container {
    display: flex;
    align-items: center;
}

.lmnt-breadcrumbs__breadcrumb {
    display: flex;
    align-items: center;
    color: var(--lmnt-breadcrumbs-color-link);
}

.lmnt-breadcrumbs__breadcrumb a {
    color: unset;
    font-weight: unset;
    font-family: unset;
}

.lmnt-breadcrumbs__icon {
    pointer-events: none;
    margin: 0 16px;
}

.lmnt-breadcrumbs__active-page {
    color: var(--lmnt-breadcrumbs-color-active);
}
