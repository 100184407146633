//////////////////////////////////////////
///////  Utility: Background Color ///////
//////////////////////////////////////////
@use 'element.core' as element;
@use 'mixins' as mixins;

// Element Theme Defaults - Background
.lmnt-theme-background-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-background);
    background-color: var(--lmnt-theme-background);
    color: var(--lmnt-theme-on-background);
}

// Element Theme Defaults - Surface
.lmnt-theme-surface-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
    background-color: var(--lmnt-theme-surface);
    color: var(--lmnt-theme-on-surface);
}

.lmnt-theme-surface-variant-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface-variant);
    background-color: var(--lmnt-theme-surface-variant);
    color: var(--lmnt-theme-on-surface);
}

// Element Theme Defaults - Primary
.lmnt-theme-primary-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-primary);
    background-color: var(--lmnt-theme-primary);
    color: var(--lmnt-theme-on-primary);
}

.lmnt-theme-primary-variant-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-primary-variant);
    background-color: var(--lmnt-theme-primary-variant);
    color: var(--lmnt-theme-on-primary-variant);
}

// Element Theme Defaults - Secondary
.lmnt-theme-secondary-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-secondary);
    background-color: var(--lmnt-theme-secondary);
    color: var(--lmnt-theme-on-secondary);
}

.lmnt-theme-secondary-variant-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-secondary-variant);
    background-color: var(--lmnt-theme-secondary-variant);
    color: var(--lmnt-theme-on-secondary-variant);
}

// Element Theme Defaults - Danger
.lmnt-theme-danger-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-danger);
    background-color: var(--lmnt-theme-danger);
    color: var(--lmnt-theme-on-danger);
}

.lmnt-theme-danger-variant-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-danger-variant);
    background-color: var(--lmnt-theme-danger-variant);
    color: var(--lmnt-theme-on-danger-variant);
}

// Element Theme Defaults - Success
.lmnt-theme-success-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-success);
    background-color: var(--lmnt-theme-success);
    color: var(--lmnt-theme-on-success);
}

.lmnt-theme-success-variant-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-success-variant);
    background-color: var(--lmnt-theme-success-variant);
    color: var(--lmnt-theme-on-success-variant);
}
// Element Theme Defaults - Dark
.lmnt-theme-dark-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-dark);
    background-color: var(--lmnt-theme-dark);
    color: var(--lmnt-theme-on-dark);
}

// Element Theme Defaults - Light
.lmnt-theme-light-bg {
    --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-light);
    background-color: var(--lmnt-theme-light);
    color: var(--lmnt-theme-on-light);
}

@each $color, $value in element.$onClassesMap {
    @include mixins.onBackgroundColorClasses($color: $color, $value: $value);
}
