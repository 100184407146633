@use '../../common/mixins';

// TODO: deprecate styling based on `.lmnt-theme-secondary-bg` in favor of variant styling
// Note: these cryptic and nested selectors are battling Material's specificity war
/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important, no-descending-specificity */
@mixin core-styles {
    @include mixins.on-secondary {
        //////////////////////
        //////  General  /////
        //////////////////////

        // Helper text
        .mdc-text-field--disabled {
            // tricky direct sibling combo here
            + .mdc-text-field-helper-line {
                .mdc-text-field-helper-text--persistent {
                    color: var(--lmnt-theme-on-secondary);
                    opacity: 0.38;
                }
            }
        }

        // Disabled
        .mdc-text-field--disabled {
            opacity: 0.38;

            // Outline
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: var(--lmnt-theme-on-secondary);
            }
        }

        //////////////////////
        //////  Filled  //////
        //////////////////////

        .mdc-text-field {
            &:not(.mdc-text-field--disabled) {
                // tricky direct sibling combo here
                + .mdc-text-field-helper-line {
                    .mdc-text-field-helper-text {
                        color: var(--lmnt-theme-on-secondary);
                    }
                }
            }

            // Ripple
            .mdc-line-ripple {
                // black ripple always works with a filled Textfield variant
                --mdc-theme-primary: #000;
            }
        }

        ////////////////////////
        //////  Outlined  //////
        ////////////////////////

        .mdc-text-field--outlined {
            // Input placeholder text
            ::placeholder {
                color: var(--lmnt-theme-on-secondary) !important;
            }

            // Input text color
            .mdc-floating-label,
            .mdc-text-field__input,
            &:not(.mdc-text-field--disabled) .mdc-floating-label,
            &:not(.mdc-text-field--disabled) .mdc-text-field__input {
                color: var(--lmnt-theme-on-secondary);
            }

            // Border
            &:not(.mdc-text-field--invalid) {
                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: var(--lmnt-theme-on-secondary);
                }

                // Focus/Hover
                &:hover,
                &:focus {
                    .mdc-notched-outline {
                        .mdc-notched-outline__leading,
                        .mdc-notched-outline__notch,
                        .mdc-notched-outline__trailing {
                            border-color: var(--lmnt-theme-on-secondary);
                        }
                    }
                }
            }
        }

        ////////////////////////
        //////  Embedded  //////
        ////////////////////////

        // Note: what Element called "embedded" Material calls "fullwidth"
        .lmnt-text-field--embedded {
            // Input placeholder text
            ::placeholder {
                color: var(--lmnt-theme-on-secondary) !important;
            }

            // Input text color
            .mdc-text-field__input {
                color: var(--lmnt-theme-on-secondary);
            }

            // Disabled
            &.mdc-text-field--disabled {
                background-color: transparent;
            }

            // Ripple Theme
            .mdc-line-ripple {
                // this works even when disabled
                --mdc-theme-primary: var(--lmnt-theme-on-secondary);

                &::before {
                    border-color: var(--lmnt-theme-on-secondary);
                    opacity: 0.67;
                }
            }

            // Ripple Focus/Hover
            &:not(.mdc-text-field--disabled):hover,
            &:not(.mdc-text-field--disabled):focus {
                .mdc-line-ripple {
                    &::before {
                        border-color: var(--lmnt-theme-on-secondary);
                        opacity: 1;
                    }
                }
            }
        }

        /////////////////////
        //////  Icons  //////
        /////////////////////

        // Trailing Icon Overrides
        .lmnt-text-field--with-icon {
            &.mdc-text-field--outlined:not(.mdc-text-field--invalid) {
                // Icon features added by Element
                .lmnt-text-field__icon {
                    color: var(--lmnt-theme-on-secondary);
                }
            }
        }
    }
}
