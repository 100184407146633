@use '../../common/element.core' as element;
@use '@material/typography';
@use '@material/elevation';

///////////////////////////////
////////// SideSheet //////////
///////////////////////////////

$side-sheet-text-color: var(--lmnt-theme-on-surface) !default;
$side-sheet-container-color: var(--lmnt-theme-surface, #fff) !default;

// max-width of the side-sheet surface
// - design guidance calls for max-width of 320px (20rem) visually
// - overflow scroll bars are managed by children nodes
$side-sheet__width: 20rem;

// media query
// - larger screen styles start at 960px (60rem)
// -- other variant styles start to work here
// - smaller screen style tweaks at 480px (30rem)
// -- white-space is adjusted slightly
$side-sheet__media-query--min-for-large-screen: 60rem;
$side-sheet__media-query--min-for-smaller-screen: 30rem;

// transition-duration
// - `transition-duration--out` should match JS `setTimout`
// - JS uses `ms` unit so
// - prefer `milliseconds` over `seconds`
$side-sheet__transition-duration--in: 150ms;
$side-sheet__transition-duration--out: 75ms;

// transition-timing-function
// - deceleration curve for re-use
$side-sheet__transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);

// z-index
// - side-sheet-scrim is one level lower than side-sheet
$side-sheet__z-index: 8;
$side-sheet__z-index--reset: auto;
$side-sheet-scrim__z-index: $side-sheet__z-index - 1;

/////////////////////////////////////////////////////
////////// SideSheet - Container / <body> //////////
/////////////////////////////////////////////////////
.lmnt {
    // required <body> or container context
    // when the side-sheet is visible:
    // - hide container overflow scrolling
    .lmnt-side-sheet-container--sheet-is-visible {
        max-height: 100vh;
        overflow: hidden;
    }

    // when the side-sheet is visible on larger screens:
    // - show container overflow scrolling
    // - modal variant ALWAYS hides container overflow scrolling
    @media only screen and (min-width: $side-sheet__media-query--min-for-large-screen) {
        .lmnt-side-sheet-container--sheet-is-visible {
            overflow: unset;

            &.lmnt-side-sheet-container--modal {
                overflow: hidden;
            }
        }
    }

    /////////////////////////////////////////////////
    ////////// SideSheet - Main Component //////////
    /////////////////////////////////////////////////

    // main <aside> for side-sheet component
    // - 56px (3.5rem) left-margin demand from design guidance
    // - 320px (20rem) visual max-width
    // - transition-duration faster when hiding
    // - all side-sheet are modal at small screen size
    .lmnt-side-sheet {
        background-color: $side-sheet-container-color;
        bottom: 0;
        box-shadow: none;
        color: $side-sheet-text-color;
        display: block;
        max-width: calc(100vw - 3.5rem);
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transition-duration: $side-sheet__transition-duration--out;
        transition-property: transform;
        transition-timing-function: $side-sheet__transition-timing-function;
        z-index: $side-sheet__z-index;
    }

    .lmnt-side-sheet--position-left {
        right: unset;
        left: 0;
        transform: translateX(-100%);
    }

    // set max-width to max-content to control
    // transition timing independent of screen size
    @media only screen and (min-width: $side-sheet__media-query--min-for-smaller-screen) {
        .lmnt-side-sheet {
            max-width: $side-sheet__width;
        }
    }

    // when the side-sheet is visible:
    // - transition the side-sheet into view
    // - transition-duration slower when showing
    .lmnt-side-sheet--is-visible {
        transform: translateX(0);
        transition-duration: $side-sheet__transition-duration--in;
    }

    // inner wrapper for side-sheet
    // - 320px (20rem) width demand from design guidance maintained here
    // - helps hold shape while transitioning between states
    // - use `body2` styles for typography
    .lmnt-side-sheet__content {
        @include typography.typography($style: body2);
        box-sizing: border-box;
        display: none;
        max-height: 100%;
        overflow-y: auto;
        padding-bottom: 1.25rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1.25rem;
        width: $side-sheet__width;

        @media only screen and (min-width: $side-sheet__media-query--min-for-large-screen) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }

    // when the side-sheet is visible:
    // - show side-sheet box-shadow
    // when the side-sheet is hiding:
    // - show side-sheet box-shadow during hide transition
    .lmnt-side-sheet--is-hiding,
    .lmnt-side-sheet--is-visible {
        @include elevation.elevation($z-value: $side-sheet__z-index);

        .lmnt-side-sheet__content {
            display: block;
        }
    }

    // headline and dismiss button container
    // - min-height and padding provide space for the dismiss button
    .lmnt-side-sheet__header {
        min-height: 3rem;
        padding-right: 3rem;
        position: relative;
    }

    // dismiss button
    .lmnt-side-sheet__dismiss {
        position: absolute;
        right: 0;
        top: 0;
    }

    // headline text
    .lmnt-side-sheet__headline {
        display: inline-block;
        padding-top: 0.6em;
    }

    ////////////////////////////////////////////////
    ////////// SideSheet - Scrim Sibling //////////
    ////////////////////////////////////////////////

    // required sibling element for scrim
    // when the side-sheet is hidden:
    // - hide the scrim
    .lmnt-side-sheet-scrim {
        background-color: rgba(element.$lmnt-theme-dark, 0.32);
        bottom: 0;
        display: block;
        left: 100%;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition-duration: $side-sheet__transition-duration--in;
        transition-property: opacity;
        transition-timing-function: $side-sheet__transition-timing-function;
        z-index: $side-sheet-scrim__z-index;
    }

    // when the side-sheet is visible:
    // - show the scrim
    .lmnt-side-sheet-scrim--is-visible {
        left: 0;
        opacity: 1;
    }

    // when the side-sheet is visible on wider screens:
    // - only show scrim on modal variant
    @media only screen and (min-width: $side-sheet__media-query--min-for-large-screen) {
        .lmnt-side-sheet-scrim {
            display: none;
        }

        .lmnt-side-sheet-scrim--modal {
            display: block;
        }
    }

    ///////////////////////////////////////////
    ////////// SideSheet - Elevated //////////
    ///////////////////////////////////////////

    // when the side-sheet is open AND elevated:
    // - requires parent to be in place for position context
    // - requires `.side-sheet-wrapper` to be in place for position parent context
    // - elevated variant covers any sibling content
    // - elevated variant scrolls away with the screen
    // - elevated variant is only as tall as parent context permits
    // - elevated variant is only as tall as sibling content permits
    @media only screen and (min-width: $side-sheet__media-query--min-for-large-screen) {
        .lmnt-side-sheet-wrapper--elevated {
            position: relative;
        }

        .lmnt-side-sheet--elevated {
            max-width: 0;
            position: absolute;
            transform: translateX(0);
            transition-property: max-width;
            z-index: $side-sheet__z-index--reset;

            &.lmnt-side-sheet--is-visible {
                max-width: $side-sheet__width;
            }
        }
    }

    ///////////////////////////////////////////
    ////////// SideSheet - Coplanar //////////
    ///////////////////////////////////////////

    // when the side-sheet is open AND coplanar:
    // - requires `.side-sheet-wrapper` to be in place for flex parent context
    // - coplanar variant shares space within parent context
    // - coplanar variant shares space with sibling content
    // - coplanar variant has no box-shadow
    @media only screen and (min-width: $side-sheet__media-query--min-for-large-screen) {
        .lmnt-side-sheet-wrapper--coplanar {
            display: flex;
            flex-direction: row;
            overflow: hidden;

            // we give a fixed order property to whatever
            // is within the wrapper that is NOT the sidesheet;
            // it allows us to arbitrarily change the component's
            // position with CSS only, regardless elements' order in markup.
            & > *:not(.lmnt-side-sheet--coplanar) {
                order: 2;
            }
        }

        .lmnt-side-sheet--coplanar {
            bottom: auto;
            box-shadow: none;
            flex-shrink: 0;
            max-width: 0;
            position: relative;
            right: auto;
            top: auto;
            transform: translateX(0);
            transition-property: max-width;
            z-index: $side-sheet__z-index--reset;
            order: 3; // the default position: Side Sheet on the right

            &.lmnt-side-sheet--is-visible {
                max-width: $side-sheet__width;
            }

            &.lmnt-side-sheet--position-left {
                left: auto;
                right: unset;
                order: 1; // Side Sheet on the left
            }

            &.lmnt-side-sheet--is-hiding,
            &.lmnt-side-sheet--is-visible {
                border-left-color: currentColor;
                border-left-style: solid;
                border-left-width: 0.063rem;

                // Side Sheet's left position border:
                &.lmnt-side-sheet--position-left {
                    border-left: none;
                    border-right-color: currentColor;
                    border-right-style: solid;
                    border-right-width: 0.063rem;
                }
            }
        }
    }
}
