@use 'sass:map';
@use '../../common/element.core' as element;
@use '@material/chips/deprecated/mdc-chips';
@use '@material/chips/deprecated/mixins' as chips;
@use './chipsVariables';

// NOTE: overrides defaults on chip and
// transparent border needed on chip otherwise outlined variant
// appears a different size
.lmnt.mdc-chip {
    background-color: var(--lmnt-chip-selected-fill-color);
    border-color: transparent;
    border-radius: var(--lmnt-chip-shape-radius);
    border-style: solid;
    border-width: 1px;

    .mdc-chip__ripple {
        border-radius: var(--lmnt-chip-ripple-shape-radius);

        &:before,
        &:after {
            border-radius: var(--lmnt-chip-ripple-before-after-shape-radius, 4px);
        }
    }

    // NOTE: custom outlined chip variant
    &.mdc-chip--outline {
        background-color: element.$white;
        border-color: var(--lmnt-theme-on-surface-stroke);
        color: var(--lmnt-chip-outlined-color);
    }

    &.lmnt-chip--disabled,
    &.lmnt-chip--disabled .mdc-chip__icon--trailing,
    &.lmnt-chip--disabled .mdc-chip__icon--leading {
        pointer-events: none;
        color: var(--lmnt-theme-on-surface-disabled);
        background-color: var(--lmnt-theme-surface-variant);

        .mdc-chip__checkmark {
            opacity: var(--lmnt-theme-emphasis-disabled);
        }

        .mdc-chip__ripple {
            background-color: var(--lmnt-theme-surface-variant);
            opacity: 0.12;

            &::before,
            &::after {
                background-color: var(--lmnt-theme-surface-variant);
            }
        }
    }
}

.lmnt.mdc-chip-set--filter,
.lmnt.mdc-chip-set--choice {
    .mdc-chip--outline.mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled),
    .mdc-chip--outline.mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover {
        border-color: rgba(
            map.get(element.$onClassesMap, 'primary-on-surface'),
            element.$emphasis-highlighted
        );
    }
}
// NOTE: custom for scrollable feature
.mdc-chip-set--input-scroll {
    flex-wrap: nowrap;
}

.mdc-chip-set-scrollable-wrapper {
    display: flex;
    overflow-x: auto;
}

.lmnt.mdc-chip-set--choice {
    .mdc-chip.lmnt-chip--disabled,
    .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading {
        pointer-events: none;
        color: var(--lmnt-theme-on-surface-disabled);
        background-color: var(--lmnt-theme-surface-variant);

        .mdc-chip__checkmark {
            opacity: var(--lmnt-theme-emphasis-disabled);
        }

        .mdc-chip__ripple {
            background-color: var(--lmnt-theme-surface-variant);
            opacity: 0.12;

            &::before,
            &::after {
                background-color: var(--lmnt-theme-surface-variant);
            }
        }
    }
    .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled),
    .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover {
        color: var(--lmnt-theme-primary-on-surface);
        background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);

        .mdc-chip__icon--leading {
            color: var(--lmnt-theme-primary-on-surface);
        }

        .mdc-chip__ripple {
            background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
            opacity: 0.12;

            &::before,
            &::after {
                background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
            }
        }
    }
}

// NOTE: filter prop needs additional styling for selected state
.lmnt.mdc-chip-set--filter {
    .mdc-chip.lmnt-chip--disabled,
    .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading {
        pointer-events: none;
        color: var(--lmnt-theme-on-surface-disabled);
        background-color: var(--lmnt-theme-surface-variant);

        .mdc-chip__checkmark {
            opacity: var(--lmnt-theme-emphasis-disabled);
        }
    }

    .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) {
        color: var(--lmnt-theme-primary-on-surface);
        background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);

        .mdc-chip__ripple {
            background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
            opacity: 0.12;

            &::before,
            &::after {
                background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
            }
        }

        .mdc-chip__checkmark-path {
            color: var(--lmnt-theme-primary-on-surface);
            stroke: var(--lmnt-theme-primary-on-surface);
        }
    }
}
