///////////////////////
//////  Banner  ///////
///////////////////////

@use '@material/banner/styles';
@use '../../common/element.core';
@use '../../common/elevation';
@use '@material/banner' as banner;

.lmnt {
    --lmnt-banner-max-width-content: 100%;
}

.lmnt-banner {
    @include banner.max-width($max-width: var(--lmnt-banner-max-width-content));

    .mdc-button:not(:disabled) {
        --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
    }

    &.lmnt-banner--drawer.lmnt-banner--top-app-bar-fixed,
    &.lmnt-banner--top-app-bar-fixed {
        margin-top: 64px;
        .lmnt-banner__inner {
            top: 64px;
        }
    }

    &.lmnt-banner--top-app-bar.lmnt-banner--top-app-bar--prominent-fixed {
        margin-top: 128px;
    }
    &.lmnt-banner--top-app-bar.lmnt-banner--top-app-bar--dense-fixed {
        margin-top: 48px;
    }
    &.lmnt-banner--top-app-bar.lmnt-banner--top-app-bar--dense-prominent-fixed {
        margin-top: 96px;
    }

    &.lmnt-banner--drawer {
        margin-top: 0;
    }

    &.lmnt-banner--fixed {
        top: 0;

        &.lmnt-banner--top-app-bar-fixed {
            margin-top: 0;

            &.lmnt-banner--top-app-bar--prominent-fixed {
                .lmnt-banner__inner {
                    top: 128px;
                }
            }

            &.lmnt-banner--top-app-bar--dense-fixed {
                .lmnt-banner__inner {
                    top: 48px;
                }
            }

            &.lmnt-banner--top-app-bar--dense-prominent-fixed {
                .lmnt-banner__inner {
                    top: 96px;
                }
            }
        }
    }

    .lmnt-banner__inner {
        width: 100%;
    }

    .lmnt-banner__content {
        position: static;
    }

    .lmnt-banner__media {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lmnt-banner__actions {
        align-self: center;
    }
}

.lmnt-banner--drawer.lmnt-banner--fixed {
    &.lmnt-banner--top-app-bar {
        position: sticky;
    }

    .lmnt-banner__inner {
        width: calc(100% - 256px);
    }
}
// ////////////////////////////////
// //////  Surface Variant  ///////
// ////////////////////////////////

.lmnt-banner--surface-variant {
    background-color: var(--lmnt-theme-surface-variant);

    &.mdc-banner .mdc-banner__fixed {
        background-color: var(--lmnt-theme-surface-variant);
    }
}

/////////////////////////
//////  Elevated  ///////
/////////////////////////

.lmnt-banner--elevated {
    border-bottom: 0;
    box-shadow: elevation.$elevation-04dp;
}

/////////////////////
//////  Airy  ///////
/////////////////////

@media only screen and (min-width: 600px) {
    .lmnt-banner--airy {
        padding-left: 28px;
        padding-right: 28px;
    }
}

@media only screen and (min-width: 720px) {
    .lmnt-banner--airy {
        padding-left: 32px;
        padding-right: 32px;
    }
}

@media only screen and (min-width: 840px) {
    .lmnt-banner--airy {
        padding-left: 48px;
        padding-right: 48px;
    }
}

@media only screen and (min-width: 1024px) {
    .lmnt-banner--airy {
        padding-left: 64px;
        padding-right: 64px;
    }
}

@media only screen and (min-width: 1440px) {
    .lmnt-banner--airy {
        padding-left: 80px;
        padding-right: 80px;
    }
}

.lmnt-banner--margin-fix {
    transition: margin-top 300ms ease;
}

@media (max-width: 599px) {
    .lmnt-banner {
        &.lmnt-banner--top-app-bar {
            margin-top: 56px;
        }

        &.lmnt-banner--fixed {
            &.lmnt-banner--top-app-bar {
                top: 56px;
                margin-top: 0;

                .lmnt-banner__inner {
                    top: 56px;
                }
            }
        }
    }
}
