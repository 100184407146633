@use '@material/radio/styles';
@use '@material/radio/mixins' as radio;
@use '../../common/mixins';

@import '../form-field/form-field';

// NOTE: Material uses "secondary" for Radios, but Element uses "primary" by default

.lmnt {
    // primary
    --lmnt-radio--primary: var(--lmnt-theme-primary-on-surface);
    --lmnt-radio--on-primary-accent: var(--lmnt-theme-on-primary);
    --lmnt-radio--on-primary-base: var(--lmnt-theme-on-primary);

    // secondary
    --lmnt-radio--secondary: var(--lmnt-theme-secondary-on-surface);
    --lmnt-radio--on-secondary-accent: var(--lmnt-theme-on-secondary);
    --lmnt-radio--on-secondary-base: var(--lmnt-theme-on-secondary);
}

.lmnt-radio--primary,
.mdc-radio.lmnt-radio, // TODO: remove in future, this is only kept to avoid breaking radio with older markup
.mdc-radio.lmnt-radio.lmnt-radio--primary {
    --mdc-theme-secondary: var(--lmnt-radio--primary);
}

.lmnt-radio--secondary,
.mdc-radio.lmnt-radio.lmnt-radio--secondary {
    --mdc-theme-secondary: var(--lmnt-radio--secondary);
}

@include mixins.on-primary {
    .mdc-radio.lmnt-radio {
        --mdc-theme-secondary: var(--lmnt-radio--on-primary-base);
        @include radio.unchecked-stroke-color(var(--lmnt-radio--on-primary-base));
    }
}

@include mixins.on-secondary {
    .mdc-radio.lmnt-radio {
        --mdc-theme-secondary: var(--lmnt-radio--on-secondary-base);
        @include radio.unchecked-stroke-color(var(--lmnt-radio--on-secondary-base));
    }
}
