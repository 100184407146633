body {
  margin: 0;
  padding: 0;
}
body::-webkit-scrollbar {
  width: 0.2em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.logingreybg {
  flex-grow: 1;
  padding: 20px;
  gap: 0 !important;
  margin-bottom: auto;
}
.greybg {
  background: #eeeeee;
  min-height: calc(100vh - 60px);
}
.greybg > div {
  display: flex;
  min-height: calc(100vh - 60px);
}
.redcolor {
  color: red;
}
.margintop10 {
  margin-top: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.textcenter {
  text-align: center;
}

.table td {
  word-break: break-word;
}

.toastmessage {
  top: 20px;
  right: 20px;
  display: flex;
  color: #fff;
  max-width: 90%;
  position: fixed;
  padding: 4px 9px;
  border-radius: 4px;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 3px solid #fff;
  /* transform: translate(-50%, 0); */
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}
.toastmessage.toasterror {
  background: #cf2d22;
}
.toastmessage.toastwarning {
  background: #d5dc00;
}
.toastmessage.toastsuccess {
  background: #3edc00;
}
.toastmessage div {
  padding: 6px;
  display: flex;
  margin: auto 0;
  flex: 1 1 auto;
  font-size: 1rem;
  -ms-flex: 1 1 auto;
  align-items: center;
  -ms-flex-align: center;
}
.toastmessage button {
  border: 0;
  color: #fff;
  font-weight: 600;
  padding: 7px 10px;
  margin: 0 0 0 6px;
  border-radius: 3px;
}
.toastmessage.toasterror button {
  background: #a51005;
}
.toastmessage.toastwarning button {
  background: #b1a300;
}
.toastmessage.toastsuccess button {
  background: #009340;
}
.blueheading {
  color: #23668c;
  margin-bottom: 0;
  font-weight: normal;
}
.blackheading {
  font-size: 1.2em;
  margin: 0;
  padding: 0;
}
button.lmnt.mdc-button.mdc-button--touch.mdc-button--outlined.lmnt-button--outlined-primary {
  color: #23668c !important;
}
.mdc-checkbox__background {
  border-color: #969696 !important;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background: #10384f !important;
  border-color: #10384f !important;
}

.searchtext {
  padding: 0 10px;
  position: relative;

  input[type="text"] {
    width: 100%;
    height: 48px;
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    background-size: 22px;
    border: 2px solid #969696;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 100000;
    list-style: none;
    position: absolute;
    background: #fff;
  }
  li {
    cursor: pointer;
    padding: 2px 5px;
    border-bottom: 1px solid #ccc;
  }
}

.lmnt-modal__title.mdc-dialog__title {
  display: none !important;
}
.documentfilter {
  display: flex;
}
.documentfilterdiv {
  width: 33.33%;
}

.documentfiltersection {
  h3 {
    padding: 0 15px;
    color: #353535;
    font-weight: 500;
    margin: 10px 0;
  }
}

.checkboxframes {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 50%;
  }
  .mdc-checkbox {
    margin: 0;
  }
}

.checkboxvaluelist {
  padding: 5px 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    color: #10384f;
    padding: 1px 7px 5px;
    border-radius: 17px;
    background: #f7fcff;
    margin: 0 10px 4px 0;
    display: inline-block;
    border: 1px solid #10384f;
  }
  span {
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    background: #004f7c;
    display: inline-block;
  }
}

.buttonframe {
  text-align: right;

  button {
    cursor: pointer;
    padding: 8px 28px;
    margin: 0 0 0 15px;
    border-radius: 4px;
    text-transform: uppercase;
  }
  button.white {
    color: #000;
    background: #fff;
    border: 1px solid #000;
  }
  button.blue {
    color: #000;
    background: #40baff;
    border: 1px solid #40baff;
  }
}

.rpv-core__inner-pages::-webkit-scrollbar,
.custommodalbody::-webkit-scrollbar {
  width: 0.2em;
}
.rpv-core__inner-pages::-webkit-scrollbar-track,
.custommodalbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.rpv-core__inner-pages::-webkit-scrollbar-thumb,
.custommodalbody::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.customsmallmodalbody p {
  font-size: 12px;
}
.customsmallmodalbody p.error {
  font-size: 11px;
  color: rgba(255, 5, 5, 1);
}

.form_section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
}
.form_section_elements {
  width: 23%;
  margin: 5px 1% 10px;
}
.form_section_elements button {
  margin: 0 10px;
}
.formsingleelm {
  margin: 10px auto 20px;
}
.btnframeleft {
  text-align: right;
  margin-bottom: 10px !important;
}
.whitebtn,
.whitebtn:hover,
.whitebtn:active {
  border: 1px solid #000000 !important;
  background: #fff !important;
  color: #000 !important;
}
.bluebtn,
.bluebtn:hover,
.bluebtn:active {
  border: 1px solid #40baff !important;
  background: #40baff !important;
  color: #fff !important;
}

.paginationFrame {
  gap: 5px;
  display: flex;
  line-height: 46px;
  justify-content: flex-end;
  padding: 0.75rem 1rem 0;

  button {
    border: 0;
    padding: 15px;
  }
}

.loaderdiv {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}

/*  PDF Viewer hiding starts  */
.rpv-toolbar__right {
  display: none !important;
}
.rpv-toolbar__center {
  justify-content: end !important;
}
.rpv-default-layout__sidebar-headers #rpv-default-layout__sidebar-tab-2 {
  display: none;
}
/*  PDF Viewer hiding ends    */

@media only screen and (max-width: 768px) {
  .form_section {
    flex-wrap: wrap;
  }
  .form_section_elements {
    width: 48%;
    margin: 5px 1% 10px;
  }
  .form_section_elements button {
    margin: 5px;
  }
}
