@use 'sass:map';

$utilityColorMap: (
    'blue': (
        surface: #c2e0ff,
        on-surface: #0b3f73
    ),
    'gray': (
        surface: #e6e6e6,
        on-surface: #474747
    ),
    'green': (
        surface: #b8f2c7,
        on-surface: #084d19
    ),
    'orange': (
        surface: #ffe6b2,
        on-surface: #593f09
    ),
    'purple': (
        surface: #dfcbf2,
        on-surface: #400b73
    ),
    'red': (
        surface: #ffd6d6,
        on-surface: #730b0b
    ),
    'teal': (
        surface: #b8f2ed,
        on-surface: #084d47
    ),
    'yellow': (
        surface: #f2f2b6,
        on-surface: #404006
    )
);

@mixin badgeClasses($color, $value) {
    .lmnt-utility-#{$color} {
        background-color: #{map.get($value, 'surface')};
        color: #{map.get($value, 'on-surface')};
    }

    :root,
    .lmnt {
        --lmnt-utility-#{$color}-surface: #{map.get($value, 'surface')};
        --lmnt-utility-#{$color}-on-surface: #{map.get($value, 'on-surface')};
    }
}

@each $color, $value in $utilityColorMap {
    @include badgeClasses($color: $color, $value: $value);
}
