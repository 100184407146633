@charset "UTF-8";
:root {
  --mdc-theme-primary: #6200ee;
  --mdc-theme-secondary: #018786;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-error: #b00020;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-on-surface: #000;
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #6200ee  !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary {
  color: #018786  !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020  !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000  !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #6200ee  !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary-bg {
  background-color: #018786  !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

/* stylelint-disable scss/media-feature-value-dollar-variable */
/* stylelint-enable scss/media-feature-value-dollar-variable */
.lmnt-theme-primary-50--bg,
.lmnt-theme-primary-50-bg {
  background-color: #f1edf8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-50 {
  color: #f1edf8;
}

.lmnt-theme-primary-100--bg,
.lmnt-theme-primary-100-bg {
  background-color: #c7b7e4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-100 {
  color: #c7b7e4;
}

.lmnt-theme-primary-200--bg,
.lmnt-theme-primary-200-bg {
  background-color: #b9a5de;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-200 {
  color: #b9a5de;
}

.lmnt-theme-primary-300--bg,
.lmnt-theme-primary-300-bg {
  background-color: #9381d1;
  color: white;
}

.lmnt-theme-primary-300 {
  color: #9381d1;
}

.lmnt-theme-primary-400--bg,
.lmnt-theme-primary-400-bg {
  background-color: #825dc4;
  color: white;
}

.lmnt-theme-primary-400 {
  color: #825dc4;
}

.lmnt-theme-primary-500--bg,
.lmnt-theme-primary-500-bg {
  background-color: #673ab7;
  color: white;
}

.lmnt-theme-primary-500 {
  color: #673ab7;
}

.lmnt-theme-primary-600--bg,
.lmnt-theme-primary-600-bg {
  background-color: #553096;
  color: white;
}

.lmnt-theme-primary-600 {
  color: #553096;
}

.lmnt-theme-primary-700--bg,
.lmnt-theme-primary-700-bg {
  background-color: #4b2b86;
  color: white;
}

.lmnt-theme-primary-700 {
  color: #4b2b86;
}

.lmnt-theme-primary-800--bg,
.lmnt-theme-primary-800-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-primary-800 {
  color: #422575;
}

.lmnt-theme-primary-900--bg,
.lmnt-theme-primary-900-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-primary-900 {
  color: #261643;
}

.lmnt-theme-secondary-50--bg,
.lmnt-theme-secondary-50-bg {
  background-color: #edf8ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-50 {
  color: #edf8ff;
}

.lmnt-theme-secondary-100--bg,
.lmnt-theme-secondary-100-bg {
  background-color: #caecff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-100 {
  color: #caecff;
}

.lmnt-theme-secondary-200--bg,
.lmnt-theme-secondary-200-bg {
  background-color: #b9e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-200 {
  color: #b9e5ff;
}

.lmnt-theme-secondary-300--bg,
.lmnt-theme-secondary-300-bg {
  background-color: #96d9ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-300 {
  color: #96d9ff;
}

.lmnt-theme-secondary-400--bg,
.lmnt-theme-secondary-400-bg {
  background-color: #74ccff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-400 {
  color: #74ccff;
}

.lmnt-theme-secondary-500--bg,
.lmnt-theme-secondary-500-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-500 {
  color: #40baff;
}

.lmnt-theme-secondary-600--bg,
.lmnt-theme-secondary-600-bg {
  background-color: #3599d1;
  color: white;
}

.lmnt-theme-secondary-600 {
  color: #3599d1;
}

.lmnt-theme-secondary-700--bg,
.lmnt-theme-secondary-700-bg {
  background-color: #2f88ba;
  color: white;
}

.lmnt-theme-secondary-700 {
  color: #2f88ba;
}

.lmnt-theme-secondary-800--bg,
.lmnt-theme-secondary-800-bg {
  background-color: #23668c;
  color: white;
}

.lmnt-theme-secondary-800 {
  color: #23668c;
}

.lmnt-theme-secondary-900--bg,
.lmnt-theme-secondary-900-bg {
  background-color: #18445d;
  color: white;
}

.lmnt-theme-secondary-900 {
  color: #18445d;
}

.lmnt-theme-ancillary-100--bg,
.lmnt-theme-ancillary-100-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-100 {
  color: #000;
}

.lmnt-theme-ancillary-200--bg,
.lmnt-theme-ancillary-200-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-200 {
  color: #000;
}

.lmnt-theme-ancillary-300--bg,
.lmnt-theme-ancillary-300-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-300 {
  color: #000;
}

.lmnt-theme-ancillary-400--bg,
.lmnt-theme-ancillary-400-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-400 {
  color: #000;
}

.lmnt-theme-ancillary-500--bg,
.lmnt-theme-ancillary-500-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-500 {
  color: #000;
}

.lmnt-theme-ancillary-600--bg,
.lmnt-theme-ancillary-600-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-600 {
  color: #000;
}

.lmnt-theme-ancillary-700--bg,
.lmnt-theme-ancillary-700-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-700 {
  color: #000;
}

.lmnt-theme-ancillary-800--bg,
.lmnt-theme-ancillary-800-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-800 {
  color: #000;
}

.lmnt-theme-ancillary-900--bg,
.lmnt-theme-ancillary-900-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-900 {
  color: #000;
}

.lmnt-theme-dataVizComparative-50--bg,
.lmnt-theme-dataVizComparative-50-bg {
  background-color: #240f67;
  color: white;
}

.lmnt-theme-dataVizComparative-50 {
  color: #240f67;
}

.lmnt-theme-dataVizComparative-100--bg,
.lmnt-theme-dataVizComparative-100-bg {
  background-color: #2d72c4;
  color: white;
}

.lmnt-theme-dataVizComparative-100 {
  color: #2d72c4;
}

.lmnt-theme-dataVizComparative-200--bg,
.lmnt-theme-dataVizComparative-200-bg {
  background-color: #89c9b7;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-200 {
  color: #89c9b7;
}

.lmnt-theme-dataVizComparative-300--bg,
.lmnt-theme-dataVizComparative-300-bg {
  background-color: #785e9f;
  color: white;
}

.lmnt-theme-dataVizComparative-300 {
  color: #785e9f;
}

.lmnt-theme-dataVizComparative-400--bg,
.lmnt-theme-dataVizComparative-400-bg {
  background-color: #3b8ec8;
  color: white;
}

.lmnt-theme-dataVizComparative-400 {
  color: #3b8ec8;
}

.lmnt-theme-dataVizComparative-500--bg,
.lmnt-theme-dataVizComparative-500-bg {
  background-color: #b7dbbc;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-500 {
  color: #b7dbbc;
}

.lmnt-theme-dataVizComparative-600--bg,
.lmnt-theme-dataVizComparative-600-bg {
  background-color: #4153af;
  color: white;
}

.lmnt-theme-dataVizComparative-600 {
  color: #4153af;
}

.lmnt-theme-dataVizComparative-700--bg,
.lmnt-theme-dataVizComparative-700-bg {
  background-color: #47a3c4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-700 {
  color: #47a3c4;
}

.lmnt-theme-dataVizComparative-800--bg,
.lmnt-theme-dataVizComparative-800-bg {
  background-color: #dfeccb;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-800 {
  color: #dfeccb;
}

.lmnt-theme-dataVizComparative-900--bg,
.lmnt-theme-dataVizComparative-900-bg {
  background-color: #6332ad;
  color: white;
}

.lmnt-theme-dataVizComparative-900 {
  color: #6332ad;
}

.lmnt-theme-dataVizSequential1-50--bg,
.lmnt-theme-dataVizSequential1-50-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-dataVizSequential1-50 {
  color: #261643;
}

.lmnt-theme-dataVizSequential1-100--bg,
.lmnt-theme-dataVizSequential1-100-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-dataVizSequential1-100 {
  color: #422575;
}

.lmnt-theme-dataVizSequential1-200--bg,
.lmnt-theme-dataVizSequential1-200-bg {
  background-color: #6540aa;
  color: white;
}

.lmnt-theme-dataVizSequential1-200 {
  color: #6540aa;
}

.lmnt-theme-dataVizSequential1-300--bg,
.lmnt-theme-dataVizSequential1-300-bg {
  background-color: #7e52d0;
  color: white;
}

.lmnt-theme-dataVizSequential1-300 {
  color: #7e52d0;
}

.lmnt-theme-dataVizSequential1-400--bg,
.lmnt-theme-dataVizSequential1-400-bg {
  background-color: #9a75dd;
  color: white;
}

.lmnt-theme-dataVizSequential1-400 {
  color: #9a75dd;
}

.lmnt-theme-dataVizSequential1-500--bg,
.lmnt-theme-dataVizSequential1-500-bg {
  background-color: #af8bef;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-500 {
  color: #af8bef;
}

.lmnt-theme-dataVizSequential1-600--bg,
.lmnt-theme-dataVizSequential1-600-bg {
  background-color: #c6a6ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-600 {
  color: #c6a6ff;
}

.lmnt-theme-dataVizSequential1-700--bg,
.lmnt-theme-dataVizSequential1-700-bg {
  background-color: #d8c1ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-700 {
  color: #d8c1ff;
}

.lmnt-theme-dataVizSequential1-800--bg,
.lmnt-theme-dataVizSequential1-800-bg {
  background-color: #e8dbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-800 {
  color: #e8dbff;
}

.lmnt-theme-dataVizSequential1-900--bg,
.lmnt-theme-dataVizSequential1-900-bg {
  background-color: #f5efff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-900 {
  color: #f5efff;
}

.lmnt-theme-dataVizSequential2--50--bg,
.lmnt-theme-dataVizSequential2--50-bg {
  background-color: #052d44;
  color: white;
}

.lmnt-theme-dataVizSequential2--50 {
  color: #052d44;
}

.lmnt-theme-dataVizSequential2--100--bg,
.lmnt-theme-dataVizSequential2--100-bg {
  background-color: #08517b;
  color: white;
}

.lmnt-theme-dataVizSequential2--100 {
  color: #08517b;
}

.lmnt-theme-dataVizSequential2--200--bg,
.lmnt-theme-dataVizSequential2--200-bg {
  background-color: #1774aa;
  color: white;
}

.lmnt-theme-dataVizSequential2--200 {
  color: #1774aa;
}

.lmnt-theme-dataVizSequential2--300--bg,
.lmnt-theme-dataVizSequential2--300-bg {
  background-color: #319bd8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--300 {
  color: #319bd8;
}

.lmnt-theme-dataVizSequential2--400--bg,
.lmnt-theme-dataVizSequential2--400-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--400 {
  color: #40baff;
}

.lmnt-theme-dataVizSequential2--500--bg,
.lmnt-theme-dataVizSequential2--500-bg {
  background-color: #75cdff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--500 {
  color: #75cdff;
}

.lmnt-theme-dataVizSequential2--600--bg,
.lmnt-theme-dataVizSequential2--600-bg {
  background-color: #97e0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--600 {
  color: #97e0ff;
}

.lmnt-theme-dataVizSequential2--700--bg,
.lmnt-theme-dataVizSequential2--700-bg {
  background-color: #a9f0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--700 {
  color: #a9f0ff;
}

.lmnt-theme-dataVizSequential2--800--bg,
.lmnt-theme-dataVizSequential2--800-bg {
  background-color: #c3fbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--800 {
  color: #c3fbff;
}

.lmnt-theme-dataVizSequential2--900--bg,
.lmnt-theme-dataVizSequential2--900-bg {
  background-color: #ddfffd;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--900 {
  color: #ddfffd;
}

.lmnt-theme--bg-linear-gradient-primary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-white-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-white-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

:root,
.lmnt {
  --lmnt-theme-on-background: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface: rgba(0, 0, 0, 0.87);
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-background: #fff;
  --lmnt-theme-surface: #fff;
  --lmnt-theme-surface-variant: #f5f5f5;
  --lmnt-theme-dark: #000;
  --lmnt-theme-light: #fff;
  --lmnt-theme-on-unknown-light: #000;
  --lmnt-theme-on-unknown-dark: #fff;
}

.lmnt {
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-secondary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-black-rgb: 0, 0, 0;
  --lmnt-white-rgb: 255, 255, 255;
  --lmnt-theme-primary: #673ab7;
  --lmnt-theme-on-primary: white;
  --lmnt-theme-primary-variant: #4b2b86;
  --lmnt-theme-on-primary-variant: white;
  --lmnt-theme-primary-on-surface: #673ab7;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-theme-secondary: #40baff;
  --lmnt-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --lmnt-theme-secondary-variant: #2f88ba;
  --lmnt-theme-on-secondary-variant: white;
  --lmnt-theme-secondary-on-surface: #23668c;
  --lmnt-theme-danger: #cf2d22;
  --lmnt-theme-on-danger: white;
  --lmnt-theme-danger-alt: #f5f5f5;
  --lmnt-theme-on-danger-alt: #cf2d22;
  --lmnt-theme-danger-variant: #b3190d;
  --lmnt-theme-on-danger-variant: white;
  --lmnt-theme-danger-on-surface: #cf2d22;
  --lmnt-theme-success: #0ea01a;
  --lmnt-theme-on-success: white;
  --lmnt-theme-success-variant: #13c545;
  --lmnt-theme-success-on-surface: #01830c;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-secondary-on-surface-rgb: 35, 102, 140;
  --lmnt-danger-on-surface-rgb: 207, 45, 34;
  --lmnt-success-on-surface-rgb: 207, 45, 34;
  --lmnt-on-primary-rgb: 255, 255, 255;
  --lmnt-on-secondary-rgb: 0, 0, 0;
  --lmnt-on-danger-rgb: 255, 255, 255;
  --lmnt-on-success-rgb: 255, 255, 255;
  --lmnt-theme-breakpoint-mobile-min: 0;
  --lmnt-theme-breakpoint-mobile-max: 599px;
  --lmnt-theme-breakpoint-tablet-sm-min: 600px;
  --lmnt-theme-breakpoint-tablet-sm-max: 719px;
  --lmnt-theme-breakpoint-tablet-lg-min: 720px;
  --lmnt-theme-breakpoint-tablet-lg-max: 839px;
  --lmnt-theme-breakpoint-desktop-sm-min: 840px;
  --lmnt-theme-breakpoint-desktop-sm-max: 1023px;
  --lmnt-theme-breakpoint-desktop-lg-min: 1024px;
  --lmnt-theme-breakpoint-desktop-lg-max: 1439px;
  --lmnt-theme-breakpoint-desktop-xl-min: 1440px;
  --lmnt-theme-breakpoint-content-max-width-md: 776px;
  --lmnt-theme-breakpoint-content-max-width-lg: 1052px;
  --lmnt-theme-breakpoint-content-max-width-xl: 1176px;
  --lmnt-theme-primary-50: #f1edf8;
  --lmnt-theme-primary-100: #c7b7e4;
  --lmnt-theme-primary-200: #b9a5de;
  --lmnt-theme-primary-300: #9381d1;
  --lmnt-theme-primary-400: #825dc4;
  --lmnt-theme-primary-500: #673ab7;
  --lmnt-theme-primary-600: #553096;
  --lmnt-theme-primary-700: #4b2b86;
  --lmnt-theme-primary-800: #422575;
  --lmnt-theme-primary-900: #261643;
  --lmnt-theme-secondary-50: #edf8ff;
  --lmnt-theme-secondary-100: #caecff;
  --lmnt-theme-secondary-200: #b9e5ff;
  --lmnt-theme-secondary-300: #96d9ff;
  --lmnt-theme-secondary-400: #74ccff;
  --lmnt-theme-secondary-500: #40baff;
  --lmnt-theme-secondary-600: #3599d1;
  --lmnt-theme-secondary-700: #2f88ba;
  --lmnt-theme-secondary-800: #23668c;
  --lmnt-theme-secondary-900: #18445d;
  --lmnt-theme-success-50: #eeffef;
  --lmnt-theme-success-100: #d3ffd7;
  --lmnt-theme-success-200: #a9f6af;
  --lmnt-theme-success-300: #6fef7a;
  --lmnt-theme-success-400: #29e539;
  --lmnt-theme-success-500: #13c545;
  --lmnt-theme-success-600: #0ea01a;
  --lmnt-theme-success-700: #01830c;
  --lmnt-theme-success-800: #075a0e;
  --lmnt-theme-success-900: #053709;
  --lmnt-theme-danger-50: #ffeaed;
  --lmnt-theme-danger-100: #ffccce;
  --lmnt-theme-danger-200: #ee9894;
  --lmnt-theme-danger-300: #e3716a;
  --lmnt-theme-danger-400: #ec5145;
  --lmnt-theme-danger-500: #ef4227;
  --lmnt-theme-danger-600: #e13727;
  --lmnt-theme-danger-700: #cf2d22;
  --lmnt-theme-danger-800: #c2261b;
  --lmnt-theme-danger-900: #b3190d;
  --lmnt-theme-ancillary-100: #000;
  --lmnt-theme-ancillary-200: #000;
  --lmnt-theme-ancillary-300: #000;
  --lmnt-theme-ancillary-400: #000;
  --lmnt-theme-ancillary-500: #000;
  --lmnt-theme-ancillary-600: #000;
  --lmnt-theme-ancillary-700: #000;
  --lmnt-theme-ancillary-800: #000;
  --lmnt-theme-ancillary-900: #000;
  --lmnt-theme-dataVizSequential1-50: #261643;
  --lmnt-theme-dataVizSequential1-100: #422575;
  --lmnt-theme-dataVizSequential1-200: #6540aa;
  --lmnt-theme-dataVizSequential1-300: #7e52d0;
  --lmnt-theme-dataVizSequential1-400: #9a75dd;
  --lmnt-theme-dataVizSequential1-500: #af8bef;
  --lmnt-theme-dataVizSequential1-600: #c6a6ff;
  --lmnt-theme-dataVizSequential1-700: #d8c1ff;
  --lmnt-theme-dataVizSequential1-800: #e8dbff;
  --lmnt-theme-dataVizSequential1-900: #f5efff;
  --lmnt-theme-dataVizSequential2--50: #052d44;
  --lmnt-theme-dataVizSequential2--100: #08517b;
  --lmnt-theme-dataVizSequential2--200: #1774aa;
  --lmnt-theme-dataVizSequential2--300: #319bd8;
  --lmnt-theme-dataVizSequential2--400: #40baff;
  --lmnt-theme-dataVizSequential2--500: #75cdff;
  --lmnt-theme-dataVizSequential2--600: #97e0ff;
  --lmnt-theme-dataVizSequential2--700: #a9f0ff;
  --lmnt-theme-dataVizSequential2--800: #c3fbff;
  --lmnt-theme-dataVizSequential2--900: #ddfffd;
  --lmnt-theme-dataVizComparative-50: #240f67;
  --lmnt-theme-dataVizComparative-100: #2d72c4;
  --lmnt-theme-dataVizComparative-200: #89c9b7;
  --lmnt-theme-dataVizComparative-300: #785e9f;
  --lmnt-theme-dataVizComparative-400: #3b8ec8;
  --lmnt-theme-dataVizComparative-500: #b7dbbc;
  --lmnt-theme-dataVizComparative-600: #4153af;
  --lmnt-theme-dataVizComparative-700: #47a3c4;
  --lmnt-theme-dataVizComparative-800: #dfeccb;
  --lmnt-theme-dataVizComparative-900: #6332ad;
  --lmnt-theme-on-surface-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-surface-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-surface-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-surface-base: black;
  --lmnt-theme-on-primary-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-primary-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-primary-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-primary-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-primary-base: white;
  --lmnt-theme-on-secondary-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-secondary-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-secondary-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-secondary-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-secondary-base: black;
  --lmnt-theme-on-success-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-success-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-success-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-success-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-success-base: white;
  --lmnt-theme-on-danger-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-danger-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-danger-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-danger-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-danger-base: white;
  --lmnt-theme-on-unknown-black-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-unknown-black-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-unknown-black-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-unknown-black-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-unknown-black-base: black;
  --lmnt-theme-on-unknown-white-active: white;
  --lmnt-theme-on-unknown-white-inactive: rgba(255, 255, 255, 0.54);
  --lmnt-theme-on-unknown-white-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-unknown-white-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-unknown-white-base: white;
  --lmnt-theme-black: #000;
  --lmnt-theme-white: #fff;
  --lmnt-theme-icon-in-component: rgba(#000, 0.54);
  --lmnt-theme-emphasis-active-black: 0.87;
  --lmnt-theme-emphasis-active: 1;
  --lmnt-theme-emphasis-completed: 0.6;
  --lmnt-theme-emphasis-disabled: 0.38;
  --lmnt-theme-emphasis-dragged: 0.08;
  --lmnt-theme-emphasis-focus: 0.12;
  --lmnt-theme-emphasis-high-black: 0.87;
  --lmnt-theme-emphasis-high: 1;
  --lmnt-theme-emphasis-highlighted: 0.24;
  --lmnt-theme-emphasis-hover--alt: 0.08;
  --lmnt-theme-emphasis-hover: 0.04;
  --lmnt-theme-emphasis-inactive: 0.6;
  --lmnt-theme-emphasis-low: 0.38;
  --lmnt-theme-emphasis-med: 0.6;
  --lmnt-theme-emphasis-pressed--alt: 0.32;
  --lmnt-theme-emphasis-pressed: 0.16;
  --lmnt-theme-emphasis-resting: 0.08;
  --lmnt-theme-emphasis-focus--alt: 0.24;
  --lmnt-theme-emphasis-selected: 0.08;
  --lmnt-theme-emphasis-stroke: 0.12;
  --lmnt-theme-emphasis-stroke-dark: 0.24;
  --lmnt-theme-emphasis-icon-in-component: 0.54;
  --lmnt-theme-emphasis-on-surface-active-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-active: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-completed: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-disabled: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-dragged: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-focus--alt: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-focus: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-high-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-high: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-highlighted: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-hover-alt: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-hover: calc(1 - 0.04);
  --lmnt-theme-emphasis-on-surface-inactive: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-low: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-med: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-pressed-alt: calc(1 - 0.32);
  --lmnt-theme-emphasis-on-surface-resting: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-selected: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-stroke: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-stroke-dark: calc(1 - 0.24);
  --mdc-typography-font-family: var(--lmnt-theme-primary-font);
  --mdc-theme-primary: var(--lmnt-theme-primary);
  --mdc-theme-on-primary: var(--lmnt-theme-on-primary);
  --mdc-theme-secondary: var(--lmnt-theme-secondary);
  --mdc-theme-on-secondary: var(--lmnt-theme-on-secondary);
  --mdc-theme-background: var(--lmnt-theme-background);
  --mdc-theme-surface: var(--lmnt-theme-surface);
  --mdc-theme-on-surface: var(--lmnt-theme-on-surface);
  --mdc-theme-error: var(--lmnt-theme-danger);
  --mdc-theme-on-error: var(--lmnt-theme-on-danger);
  --lmnt-button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --lmnt-theme--shape-radius: 4px;
  --lmnt-card-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-primary-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-primary-on-light: var(--lmnt-theme-on-unknown-black);
  --mdc-theme-text-secondary-on-background: rgba(var(--lmnt-black-rgb), 0.54);
  --mdc-theme-text-disabled-on-background: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-disabled-on-dark: var(--lmnt-theme-on-unknown-white-disabled);
  --mdc-theme-text-disabled-on-light: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-icon-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-icon-on-light: var(--lmnt-theme-on-unknown-black);
}

.mdc-typography {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-font-family, Roboto, sans-serif);
}

.mdc-typography--headline1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 6rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline1-font-size, 6rem);
  line-height: 6rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline1-line-height, 6rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline1-font-weight, 300);
  letter-spacing: -0.015625em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline1-letter-spacing, -0.015625em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline1-text-transform, inherit);
}

.mdc-typography--headline2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 3.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline2-font-size, 3.75rem);
  line-height: 3.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline2-line-height, 3.75rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline2-font-weight, 300);
  letter-spacing: -0.0083333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline2-letter-spacing, -0.0083333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline2-text-transform, inherit);
}

.mdc-typography--headline3 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline3-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 3rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline3-font-size, 3rem);
  line-height: 3.125rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline3-line-height, 3.125rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline3-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline3-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline3-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline3-text-transform, inherit);
}

.mdc-typography--headline4 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline4-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 2.125rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline4-font-size, 2.125rem);
  line-height: 2.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline4-line-height, 2.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline4-font-weight, 400);
  letter-spacing: 0.0073529412em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline4-letter-spacing, 0.0073529412em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline4-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline4-text-transform, inherit);
}

.mdc-typography--headline5 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
}

.mdc-typography--headline6 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-typography--subtitle1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
}

.mdc-typography--subtitle2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.mdc-typography--body1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-typography--body2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-typography--caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-typography--button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
}

.mdc-typography--overline {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
}

:root,
.lmnt {
  --lmnt-typography-link-color: var(--lmnt-theme-primary-variant);
  --lmnt-typography-link-font-weight: bold;
  --lmnt-typography-link-text-decoration: none;
  --lmnt-typography-link-color-on-primary: inherit;
  --lmnt-typography-link-color-on-secondary: inherit;
  --mdc-typography-headline1-letter-spacing: -0.078125rem;
  --mdc-typography-headline1-line-height: 7.25rem;
  --mdc-typography-headline2-line-height: 4.5rem;
  --mdc-typography-headline3-line-height: 3.5rem;
  --mdc-typography-headline5-line-height: 1.75rem;
  --mdc-typography-headline6-line-height: 1.5rem;
  --mdc-typography-subtitle1-letter-spacing: normal;
  --mdc-typography-subtitle1-line-height: 1.5rem;
  --lmnt-typography--subtitle1-bold-font-weight: 700;
  --mdc-typography-subtitle2-letter-spacing: normal;
  --mdc-typography-subtitle2-line-height: 1.25rem;
  --lmnt-typography--subtitle2-bold-font-weight: 700;
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-body1-line-height: 1.75rem;
  --lmnt-typography--body1-bold-font-weight: 700;
  --lmnt-typography--body2-bold-font-weight: 700;
  --mdc-typography-caption-line-height: 1rem;
  --lmnt-typography--caption-bold-font-weight: 500;
  --mdc-typography-button-line-height: 1rem;
  --mdc-typography-overline-line-height: 1rem;
}

.mdc-typography--subtitle1-bold {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  font-weight: var(--lmnt-typography--subtitle1-bold-font-weight);
}

.mdc-typography--subtitle2-bold {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  font-weight: var(--lmnt-typography--body1-bold-font-weight);
}

.mdc-typography--body1-bold {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  font-weight: var(--lmnt-typography--subtitle2-bold-font-weight);
}

.mdc-typography--body2-bold {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  font-weight: var(--lmnt-typography--body2-bold-font-weight);
}

.mdc-typography--caption-bold {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  font-weight: var(--lmnt-typography--caption-bold-font-weight);
}

.lmnt-theme-link,
a.mdc-typography,
a {
  color: var(--lmnt-typography-link-color);
  font-weight: var(--lmnt-typography-link-font-weight);
  cursor: pointer;
  text-decoration: var(--lmnt-typography-link-text-decoration);
}

.lmnt-theme-primary-bg a {
  color: var(--typography-link-color-on-primary);
}

.lmnt-theme-secondary-bg a {
  color: var(--lmnt-typography-link-color-on-secondary);
}

.lmnt {
  --lmnt-badge-padding-left: 8px;
  --lmnt-badge-padding-right: 8px;
  --lmnt-badge-padding-top: 0;
  --lmnt-badge-padding-bottom: 0;
  --lmnt-badge-min-width: 8px;
  --lmnt-badge-min-height: 8px;
  --lmnt-badge-max-height: 8px;
  --lmnt-badge-font-weight: 700;
}
.lmnt.lmnt-badge {
  align-items: center;
  border-radius: var(--lmnt-badge-shape-radius);
  display: flex;
  font-weight: var(--lmnt-badge-font-weight);
  max-height: var(--lmnt-badge-max-height);
  min-height: var(--lmnt-badge-min-height);
  min-width: var(--lmnt-badge-min-width);
  padding-bottom: var(--lmnt-badge-padding-top);
  padding-left: var(--lmnt-badge-padding-left);
  padding-right: var(--lmnt-badge-padding-right);
  padding-top: var(--lmnt-badge-padding-top);
  width: fit-content;
}

.lmnt.lmnt-badge.lmnt-badge--notification {
  --lmnt-badge-notification-border-color: var(--lmnt-theme-surface);
  --lmnt-badge-padding-left: 6px;
  --lmnt-badge-padding-right: 6px;
  --lmnt-badge-padding-top: 0;
  --lmnt-badge-padding-bottom: 0;
  --lmnt-badge-shape-radius: 12px;
  border: 2px solid var(--lmnt-badge-notification-border-color);
  padding: 0;
}
.lmnt.lmnt-badge.lmnt-badge--notification.lmnt-badge--notification-badge-counter {
  padding-left: var(--lmnt-badge-padding-left);
  padding-right: var(--lmnt-badge-padding-right);
  padding-top: var(--lmnt-badge-padding-top);
  padding-bottom: var(--lmnt-badge-padding-bottom);
  --lmnt-badge-min-height: 16px;
  --lmnt-badge-max-height: unset;
}
.lmnt.lmnt-badge.lmnt-badge--notification.lmnt-badge--notification-primary {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}
.lmnt.lmnt-badge.lmnt-badge--notification.lmnt-badge--notification-secondary {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}
.lmnt.lmnt-badge.lmnt-badge--notification.lmnt-badge--notification-danger {
  background-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-on-danger);
}
.lmnt.lmnt-badge.lmnt-badge--notification.lmnt-badge--notification-success {
  background-color: var(--lmnt-theme-success);
  color: var(--lmnt-theme-on-success);
}

.lmnt-utility-blue {
  background-color: #c2e0ff;
  color: #0b3f73;
}

:root,
.lmnt {
  --lmnt-utility-blue-surface: #c2e0ff;
  --lmnt-utility-blue-on-surface: #0b3f73;
}

.lmnt-utility-gray {
  background-color: #e6e6e6;
  color: #474747;
}

:root,
.lmnt {
  --lmnt-utility-gray-surface: #e6e6e6;
  --lmnt-utility-gray-on-surface: #474747;
}

.lmnt-utility-green {
  background-color: #b8f2c7;
  color: #084d19;
}

:root,
.lmnt {
  --lmnt-utility-green-surface: #b8f2c7;
  --lmnt-utility-green-on-surface: #084d19;
}

.lmnt-utility-orange {
  background-color: #ffe6b2;
  color: #593f09;
}

:root,
.lmnt {
  --lmnt-utility-orange-surface: #ffe6b2;
  --lmnt-utility-orange-on-surface: #593f09;
}

.lmnt-utility-purple {
  background-color: #dfcbf2;
  color: #400b73;
}

:root,
.lmnt {
  --lmnt-utility-purple-surface: #dfcbf2;
  --lmnt-utility-purple-on-surface: #400b73;
}

.lmnt-utility-red {
  background-color: #ffd6d6;
  color: #730b0b;
}

:root,
.lmnt {
  --lmnt-utility-red-surface: #ffd6d6;
  --lmnt-utility-red-on-surface: #730b0b;
}

.lmnt-utility-teal {
  background-color: #b8f2ed;
  color: #084d47;
}

:root,
.lmnt {
  --lmnt-utility-teal-surface: #b8f2ed;
  --lmnt-utility-teal-on-surface: #084d47;
}

.lmnt-utility-yellow {
  background-color: #f2f2b6;
  color: #404006;
}

:root,
.lmnt {
  --lmnt-utility-yellow-surface: #f2f2b6;
  --lmnt-utility-yellow-on-surface: #404006;
}

.lmnt.lmnt-badge.lmnt-badge--label {
  --lmnt-badge-padding-left: 8px;
  --lmnt-badge-padding-right: 8px;
  --lmnt-badge-padding-top: 0;
  --lmnt-badge-padding-bottom: 0;
  --lmnt-label-badge-icon-margin: 4px;
  --lmnt-label-badge-icon-width: 12px;
  --lmnt-label-badge-icon-height: 12px;
  --lmnt-badge-shape-radius: 20px;
  --lmnt-badge-min-height: 16px;
  --lmnt-badge-max-height: unset;
  --lmnt-label-badge-default-bg-color: var(--lmnt-theme-primary-100);
  --lmnt-label-badge-default-color: var(--lmnt-theme-primary-900);
  background-color: var(--lmnt-label-badge-default-bg-color);
  color: var(--lmnt-label-badge-default-color);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-black {
  background-color: var(--lmnt-theme-black);
  color: var(--lmnt-theme-white);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-primary-variant {
  background-color: var(--lmnt-theme-primary-variant);
  color: var(--lmnt-theme-on-primary-variant);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-primary {
  background-color: var(--lmnt-theme-primary-100);
  color: var(--lmnt-theme-primary-900);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-secondary {
  background-color: var(--lmnt-theme-secondary-100);
  color: var(--lmnt-theme-secondary-900);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-danger {
  background-color: var(--lmnt-theme-danger-100);
  color: var(--lmnt-theme-danger-900);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-success {
  background-color: var(--lmnt-theme-success-100);
  color: var(--lmnt-theme-success-900);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-blue {
  background-color: #c2e0ff;
  color: #0b3f73;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-gray {
  background-color: #e6e6e6;
  color: #474747;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-green {
  background-color: #b8f2c7;
  color: #084d19;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-orange {
  background-color: #ffe6b2;
  color: #593f09;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-purple {
  background-color: #dfcbf2;
  color: #400b73;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-red {
  background-color: #ffd6d6;
  color: #730b0b;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-teal {
  background-color: #b8f2ed;
  color: #084d47;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-yellow {
  background-color: #f2f2b6;
  color: #404006;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-trailing-icon {
  --lmnt-badge-padding-right: 6px;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-leading-icon {
  --lmnt-badge-padding-left: 6px;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-trailing-icon, .lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-leading-icon {
  display: flex;
  align-items: center;
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-trailing-icon .lmnt-badge-label__leading-icon, .lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-leading-icon .lmnt-badge-label__leading-icon {
  width: var(--lmnt-label-badge-icon-width);
  height: var(--lmnt-label-badge-icon-height);
  margin-right: var(--lmnt-label-badge-icon-margin);
}
.lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-trailing-icon .lmnt-badge-label__trailing-icon, .lmnt.lmnt-badge.lmnt-badge--label.lmnt-badge--label-leading-icon .lmnt-badge-label__trailing-icon {
  width: var(--lmnt-label-badge-icon-width);
  height: var(--lmnt-label-badge-icon-height);
  margin-left: var(--lmnt-label-badge-icon-margin);
}
.lmnt.lmnt-badge.lmnt-badge--label .lmnt-badge-label-icon {
  width: var(--lmnt-label-badge-icon-width);
  height: var(--lmnt-label-badge-icon-height);
}
.lmnt.lmnt-badge.lmnt-badge--label .lmnt-badge--label__label {
  display: flex;
}

.mdc-banner__text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-banner__graphic {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-surface, #fff);
}

.mdc-banner__graphic {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-banner__graphic {
  border-radius: 50%;
}

.mdc-banner__content,
.mdc-banner__fixed {
  min-width: 344px;
}
@media (max-width: 480px), (max-width: 344px) {
  .mdc-banner__content,
.mdc-banner__fixed {
    min-width: 100%;
  }
}

.mdc-banner__content {
  max-width: 720px;
}

.mdc-banner {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-bottom-color: rgba(0, 0, 0, 0.12);
  z-index: 1;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: border-box;
  display: none;
  flex-shrink: 0;
  height: 0;
  position: relative;
  width: 100%;
}
.mdc-banner .mdc-banner__fixed {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-banner .mdc-banner__fixed {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
@media (max-width: 480px) {
  .mdc-banner .mdc-banner__fixed {
    left: 0;
    right: 0;
  }
  .mdc-banner .mdc-banner__text {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 36px;
  }
  [dir=rtl] .mdc-banner .mdc-banner__text, .mdc-banner .mdc-banner__text[dir=rtl] {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 16px;
  }
}
@media (max-width: 480px) {
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__content {
    flex-wrap: wrap;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__graphic {
    margin-bottom: 12px;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 8px;
    padding-bottom: 4px;
  }
  [dir=rtl] .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text, .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text[dir=rtl] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 16px;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__actions {
    margin-left: auto;
  }
}

.mdc-banner--opening,
.mdc-banner--open,
.mdc-banner--closing {
  display: flex;
}

.mdc-banner--open {
  transition: height 300ms ease;
}
.mdc-banner--open .mdc-banner__content {
  transition: transform 300ms ease;
  transform: translateY(0);
}

.mdc-banner--closing {
  transition: height 250ms ease;
}
.mdc-banner--closing .mdc-banner__content {
  transition: transform 250ms ease;
}

.mdc-banner--centered .mdc-banner__content {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.mdc-banner__fixed {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: border-box;
  height: inherit;
  position: fixed;
  width: 100%;
}

.mdc-banner__content {
  display: flex;
  min-height: 52px;
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
}

.mdc-banner__graphic-text-wrapper {
  display: flex;
  width: 100%;
}

.mdc-banner__graphic {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  flex-shrink: 0;
  height: 40px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  width: 40px;
}
[dir=rtl] .mdc-banner__graphic, .mdc-banner__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-banner__icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.mdc-banner__text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 90px;
  align-self: center;
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
}
[dir=rtl] .mdc-banner__text, .mdc-banner__text[dir=rtl] {
  /* @noflip */
  margin-left: 90px;
  /* @noflip */
  margin-right: 24px;
}

.mdc-banner__actions {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  align-self: flex-end;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 8px;
  padding-top: 8px;
}
[dir=rtl] .mdc-banner__actions, .mdc-banner__actions[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-banner__primary-action:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-banner__primary-action::before, .mdc-banner__primary-action::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-banner__primary-action:hover::before, .mdc-banner__primary-action.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-banner__primary-action.mdc-ripple-upgraded--background-focused::before, .mdc-banner__primary-action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-banner__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-banner__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-banner__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-banner__secondary-action {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}
.mdc-banner__secondary-action:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-banner__secondary-action::before, .mdc-banner__secondary-action::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-banner__secondary-action:hover::before, .mdc-banner__secondary-action.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-banner__secondary-action.mdc-ripple-upgraded--background-focused::before, .mdc-banner__secondary-action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-banner__secondary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-banner__secondary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-banner__secondary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
[dir=rtl] .mdc-banner__secondary-action, .mdc-banner__secondary-action[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

.mdc-elevation--z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.lmnt {
  --lmnt-elevation--outlined-border-color: rgba(0, 0, 0, 0.12);
  --lmnt-elevation--outlined-border-color-on-primary: var(--lmnt-theme-on-primary);
  --lmnt-elevation--border-radius: 8px;
}

.lmnt-theme-elevation-00dp {
  box-shadow: none;
}

.lmnt-theme-elevation-01dp {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-02dp {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-03dp {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-04dp {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
}

.lmnt-theme-elevation-06dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
}

.lmnt-theme-elevation-08dp {
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-09dp {
  box-shadow: 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 5px 6px -3px rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-12dp {
  box-shadow: 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-16dp {
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.lmnt-theme-elevation-24dp {
  box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.lmnt-elevation--padding-standard {
  padding: 1rem;
}

.lmnt-elevation--padding-dense {
  padding: 0.5rem;
}

.lmnt-elevation--padding-airy {
  padding: 1.5rem;
}

.lmnt-elevation--rounded {
  border-radius: var(--lmnt-elevation--border-radius);
}

.lmnt-elevation--outlined {
  border-color: var(--lmnt-elevation--outlined-border-color);
  border-style: solid;
  border-width: 1px;
}

.lmnt {
  --lmnt-banner-max-width-content: 100%;
}

.lmnt-banner .mdc-banner__content {
  max-width: var(--lmnt-banner-max-width-content);
}
.lmnt-banner .mdc-button:not(:disabled) {
  --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}
.lmnt-banner.lmnt-banner--drawer.lmnt-banner--top-app-bar-fixed, .lmnt-banner.lmnt-banner--top-app-bar-fixed {
  margin-top: 64px;
}
.lmnt-banner.lmnt-banner--drawer.lmnt-banner--top-app-bar-fixed .lmnt-banner__inner, .lmnt-banner.lmnt-banner--top-app-bar-fixed .lmnt-banner__inner {
  top: 64px;
}
.lmnt-banner.lmnt-banner--top-app-bar.lmnt-banner--top-app-bar--prominent-fixed {
  margin-top: 128px;
}
.lmnt-banner.lmnt-banner--top-app-bar.lmnt-banner--top-app-bar--dense-fixed {
  margin-top: 48px;
}
.lmnt-banner.lmnt-banner--top-app-bar.lmnt-banner--top-app-bar--dense-prominent-fixed {
  margin-top: 96px;
}
.lmnt-banner.lmnt-banner--drawer {
  margin-top: 0;
}
.lmnt-banner.lmnt-banner--fixed {
  top: 0;
}
.lmnt-banner.lmnt-banner--fixed.lmnt-banner--top-app-bar-fixed {
  margin-top: 0;
}
.lmnt-banner.lmnt-banner--fixed.lmnt-banner--top-app-bar-fixed.lmnt-banner--top-app-bar--prominent-fixed .lmnt-banner__inner {
  top: 128px;
}
.lmnt-banner.lmnt-banner--fixed.lmnt-banner--top-app-bar-fixed.lmnt-banner--top-app-bar--dense-fixed .lmnt-banner__inner {
  top: 48px;
}
.lmnt-banner.lmnt-banner--fixed.lmnt-banner--top-app-bar-fixed.lmnt-banner--top-app-bar--dense-prominent-fixed .lmnt-banner__inner {
  top: 96px;
}
.lmnt-banner .lmnt-banner__inner {
  width: 100%;
}
.lmnt-banner .lmnt-banner__content {
  position: static;
}
.lmnt-banner .lmnt-banner__media {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lmnt-banner .lmnt-banner__actions {
  align-self: center;
}

.lmnt-banner--drawer.lmnt-banner--fixed.lmnt-banner--top-app-bar {
  position: sticky;
}
.lmnt-banner--drawer.lmnt-banner--fixed .lmnt-banner__inner {
  width: calc(100% - 256px);
}

.lmnt-banner--surface-variant {
  background-color: var(--lmnt-theme-surface-variant);
}
.lmnt-banner--surface-variant.mdc-banner .mdc-banner__fixed {
  background-color: var(--lmnt-theme-surface-variant);
}

.lmnt-banner--elevated {
  border-bottom: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 600px) {
  .lmnt-banner--airy {
    padding-left: 28px;
    padding-right: 28px;
  }
}
@media only screen and (min-width: 720px) {
  .lmnt-banner--airy {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media only screen and (min-width: 840px) {
  .lmnt-banner--airy {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media only screen and (min-width: 1024px) {
  .lmnt-banner--airy {
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media only screen and (min-width: 1440px) {
  .lmnt-banner--airy {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.lmnt-banner--margin-fix {
  transition: margin-top 300ms ease;
}

@media (max-width: 599px) {
  .lmnt-banner.lmnt-banner--top-app-bar {
    margin-top: 56px;
  }
  .lmnt-banner.lmnt-banner--fixed.lmnt-banner--top-app-bar {
    top: 56px;
    margin-top: 0;
  }
  .lmnt-banner.lmnt-banner--fixed.lmnt-banner--top-app-bar .lmnt-banner__inner {
    top: 56px;
  }
}
.lmnt {
  --lmnt-breadcrumbs-color-link: var(--mdc-theme-text-secondary-on-background);
  --lmnt-breadcrumbs-color-active: var(--mdc-theme-text-primary-on-background);
}

.lmnt-breadcrumbs__container {
  display: flex;
  align-items: center;
}

.lmnt-breadcrumbs__breadcrumb {
  display: flex;
  align-items: center;
  color: var(--lmnt-breadcrumbs-color-link);
}

.lmnt-breadcrumbs__breadcrumb a {
  color: unset;
  font-weight: unset;
  font-family: unset;
}

.lmnt-breadcrumbs__icon {
  pointer-events: none;
  margin: 0 16px;
}

.lmnt-breadcrumbs__active-page {
  color: var(--lmnt-breadcrumbs-color-active);
}

.lmnt.mdc-button:after,
.lmnt.mdc-button:before {
  content: unset;
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
}
.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  font-size: 1.125rem;
  height: 1.125rem;
  vertical-align: top;
  width: 1.125rem;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button .mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mdc-button:not(.mdc-button--outlined) .mdc-button__ripple {
  top: 0;
  left: 0;
}

.mdc-button--raised {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  border-style: solid;
}

.mdc-button {
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  padding: 0 8px 0 8px;
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button:disabled {
  background-color: transparent;
}
.mdc-button:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button .mdc-button__ripple::before, .mdc-button .mdc-button__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-button--unelevated {
  padding: 0 16px 0 16px;
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button--unelevated:not(:disabled) {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button--unelevated .mdc-button__ripple::before, .mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--unelevated:hover .mdc-button__ripple::before, .mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--unelevated .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-button--raised {
  padding: 0 16px 0 16px;
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button--raised:not(:disabled) {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-button--raised:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button--raised .mdc-button__ripple::before, .mdc-button--raised .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-button--outlined {
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  padding: 0 15px 0 15px;
  border-width: 1px;
}
.mdc-button--outlined:not(:disabled) {
  background-color: transparent;
}
.mdc-button--outlined:disabled {
  background-color: transparent;
}
.mdc-button--outlined:not(:disabled) {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-button--outlined:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button--outlined .mdc-button__ripple::before, .mdc-button--outlined .mdc-button__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-button--outlined:hover .mdc-button__ripple::before, .mdc-button--outlined.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button--outlined.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--outlined:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button--outlined:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--outlined:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button--outlined .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
}
.mdc-button--outlined .mdc-button__touch {
  left: -1px;
  width: calc(100% + 2 * 1px);
}

.lmnt {
  --lmnt-button-shape-radius: var(--lmnt-theme--shape-radius);
  --lmnt-button-font-style: normal;
  --lmnt-button-filled-bg: var(--lmnt-theme-primary);
  --lmnt-button-filled-text: var(--lmnt-theme-on-primary);
  --lmnt-button-outlined-text-color: var(--lmnt-theme-primary-on-surface);
  --lmnt-button-outlined-border-color: rgba(0, 0, 0, 0.12);
  --lmnt-button-text-color: var(--lmnt-theme-primary-on-surface);
  --lmnt-button-xsmall-vertical-padding: 6px;
  --lmnt-button-xsmall-horizontal-padding: 12px;
  --lmnt-button-small-vertical-padding: 8px;
  --lmnt-button-small-horizontal-padding: 14px;
  --lmnt-button-medium-horizontal-padding: 16px;
  --lmnt-button-medium-vertical-padding: 10px;
  --lmnt-button-default-horizontal-padding: 16px;
  --lmnt-button-default-vertical-padding: 10px;
  --lmnt-button-large-vertical-padding: 12px;
  --lmnt-button-large-horizontal-padding: 16px;
  --lmnt-button-xlarge-vertical-padding: 16px;
  --lmnt-button-xlarge-horizontal-padding: 14px;
  --lmnt-button-line-height: inherit;
  --lmnt-button-filled-bg--on-primary: var(--lmnt-theme-secondary);
  --lmnt-button-filled-text--on-primary: var(--lmnt-theme-on-secondary);
  --lmnt-button-outlined-text-color--on-primary: var(--lmnt-theme-on-primary);
  --lmnt-button-outlined-border-color--on-primary: var(--lmnt-theme-on-primary);
  --lmnt-button-text-color--on-primary: var(--lmnt-theme-on-primary);
  --lmnt-button-filled-bg--on-secondary: var(--lmnt-theme-primary);
  --lmnt-button-filled-text--on-secondary: var(--lmnt-theme-on-primary);
}
.lmnt.mdc-button {
  --mdc-shape-small: var(--lmnt-button-shape-radius);
  font-style: var(--lmnt-button-font-style);
  height: unset;
  line-height: var(--lmnt-button-line-height);
  padding: var(--lmnt-button-default-vertical-padding) var(--lmnt-button-default-horizontal-padding);
}
.lmnt.mdc-button.lmnt-button--xsmall {
  padding: var(--lmnt-button-xsmall-vertical-padding) var(--lmnt-button-xsmall-horizontal-padding);
}
.lmnt.mdc-button.lmnt-button--small {
  padding: var(--lmnt-button-small-vertical-padding) var(--lmnt-button-small-horizontal-padding);
}
.lmnt.mdc-button.lmnt-button--medium {
  padding: var(--lmnt-button-medium-vertical-padding) var(--lmnt-button-medium-horizontal-padding);
}
.lmnt.mdc-button.lmnt-button--large {
  padding: var(--lmnt-button-large-vertical-padding) var(--lmnt-button-large-horizontal-padding);
}
.lmnt.mdc-button.lmnt-button--xlarge {
  padding: var(--lmnt-button-xlarge-vertical-padding) var(--lmnt-button-xlarge-horizontal-padding);
}
.lmnt.mdc-button.mdc-button--outlined {
  height: unset;
}
.lmnt.mdc-button.mdc-button--raised {
  box-shadow: var(--lmnt-button-box-shadow);
  height: unset;
}
.lmnt.mdc-button.mdc-button--raised.lmnt-button--elevated {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.lmnt.mdc-button.mdc-button--raised.lmnt-button--flat {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.lmnt.mdc-button.lmnt-button--filled-primary {
  --mdc-theme-primary: var(--lmnt-button-filled-bg);
  --mdc-theme-on-primary: var(--lmnt-button-filled-text);
}
.lmnt.mdc-button.lmnt-button--filled-secondary {
  --mdc-theme-primary: var(--lmnt-theme-secondary);
  --mdc-theme-on-primary: var(--lmnt-theme-on-secondary);
}
.lmnt.mdc-button.lmnt-button--filled-danger {
  --mdc-theme-primary: #fff;
  --mdc-theme-on-primary: var(--lmnt-theme-on-danger);
  background-color: var(--lmnt-theme-danger);
}
.lmnt.mdc-button.lmnt-button--filled-danger:disabled {
  color: rgba(var(--lmnt-on-secondary-rgb), 0.38);
  background-color: rgba(var(--lmnt-on-secondary-rgb), 0.12);
}
.lmnt.mdc-button.lmnt-button--filled-success {
  --mdc-theme-primary: #fff;
  --mdc-theme-on-primary: var(--lmnt-theme-on-success);
  background-color: var(--lmnt-theme-success);
}
.lmnt.mdc-button.lmnt-button--filled-success:disabled {
  color: rgba(var(--lmnt-on-secondary-rgb), 0.38);
  background-color: rgba(var(--lmnt-on-secondary-rgb), 0.12);
}
.lmnt.mdc-button.lmnt-button--filled-on-primary {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
  --mdc-theme-on-primary: var(--lmnt-theme-primary);
}
.lmnt.mdc-button.lmnt-button--filled-on-primary.mdc-button--raised:disabled {
  color: rgba(var(--lmnt-on-primary-rgb), 0.38);
  background-color: rgba(var(--lmnt-on-primary-rgb), 0.38);
}
.lmnt.mdc-button.lmnt-button--filled-on-secondary {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
  --mdc-theme-on-primary: var(--lmnt-theme-secondary);
}
.lmnt.mdc-button.lmnt-button--filled-on-secondary.mdc-button--raised:disabled {
  color: rgba(var(--lmnt-on-secondary-rgb), 0.38);
  background-color: rgba(var(--lmnt-on-secondary-rgb), 0.12);
}
.lmnt.mdc-button.lmnt-button--filled-on-success {
  --mdc-theme-primary: var(--lmnt-theme-on-success);
  --mdc-theme-on-primary: var(--lmnt-theme-success-on-surface);
}
.lmnt.mdc-button.lmnt-button--filled-on-success.mdc-button--raised:disabled {
  color: rgba(var(--lmnt-on-success-rgb), 0.38);
  background-color: rgba(var(--lmnt-on-success-rgb), 0.38);
}
.lmnt.mdc-button.lmnt-button--filled-on-danger {
  --mdc-theme-primary: var(--lmnt-theme-on-danger);
  --mdc-theme-on-primary: var(--lmnt-theme-danger-on-surface);
}
.lmnt.mdc-button.lmnt-button--filled-on-danger.mdc-button--raised:disabled {
  color: rgba(var(--lmnt-on-danger-rgb), 0.38);
  background-color: rgba(var(--lmnt-on-danger-rgb), 0.38);
}
.lmnt.mdc-button.lmnt-button--filled-on-success {
  --mdc-theme-primary: var(--lmnt-theme-on-success);
  --mdc-theme-on-primary: var(--lmnt-theme-success-on-surface);
}
.lmnt.mdc-button.lmnt-button--filled-on-success.mdc-button--raised:disabled {
  color: rgba(var(--lmnt-on-success-rgb), 0.12);
  background-color: rgba(var(--lmnt-on-success-rgb), 0.12);
}
.lmnt.mdc-button.lmnt-button--filled-on-unknown-white {
  --mdc-theme-primary: var(--lmnt-theme-surface);
  --mdc-theme-on-primary: rgba(
      var(--lmnt-black-rgb),
      0.87
  );
}
.lmnt.mdc-button.lmnt-button--filled-on-unknown-white.mdc-button--raised .mdc-button__ripple::before, .lmnt.mdc-button.lmnt-button--filled-on-unknown-white.mdc-button--raised .mdc-button__ripple::after {
  background-color: var(--lmnt-theme-black);
}
.lmnt.mdc-button.lmnt-button--filled-on-unknown-white.mdc-button--raised:disabled {
  color: var(--lmnt-theme-on-unknown-white-disabled);
  background-color: rgba(var(--lmnt-white-rgb), 0.24);
}
.lmnt.mdc-button.lmnt-button--filled-on-unknown-black {
  --mdc-theme-primary: var(--lmnt-theme-black);
  --mdc-theme-on-primary: rgba(
      var(--lmnt-white-rgb),
      0.87
  );
}
.lmnt.mdc-button.lmnt-button--filled-on-unknown-black.mdc-button--raised .mdc-button__ripple::before, .lmnt.mdc-button.lmnt-button--filled-on-unknown-black.mdc-button--raised .mdc-button__ripple::after {
  background-color: var(--lmnt-theme-white);
}
.lmnt.mdc-button.lmnt-button--filled-on-unknown-black.mdc-button--raised:disabled {
  color: var(--lmnt-theme-on-unknown-black-disabled);
  background-color: rgba(var(--lmnt-black-rgb), 0.24);
}
.lmnt.mdc-button.lmnt-button--outlined-primary {
  --mdc-theme-primary: var(--lmnt-button-outlined-text-color);
}
.lmnt.mdc-button.lmnt-button--outlined-primary:not(:disabled) {
  border-color: var(--lmnt-button-outlined-border-color);
}
.lmnt.mdc-button.lmnt-button--outlined-secondary {
  --mdc-theme-primary: var(--lmnt-theme-secondary-on-surface);
}
.lmnt.mdc-button.lmnt-button--outlined-secondary:not(:disabled) {
  border-color: var(--lmnt-button-outlined-border-color);
}
.lmnt.mdc-button.lmnt-button--outlined-on-primary {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
  background-color: var(--lmnt-theme-primary);
}
.lmnt.mdc-button.lmnt-button--outlined-on-primary:not(:disabled) {
  border-color: var(--mdc-theme-primary);
}
.lmnt.mdc-button.lmnt-button--outlined-on-primary:disabled {
  border-color: rgba(var(--lmnt-on-primary-rgb), 0.38);
  color: rgba(var(--lmnt-on-primary-rgb), 0.38);
}
.lmnt.mdc-button.lmnt-button--outlined-on-secondary {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
  background-color: var(--lmnt-theme-secondary);
}
.lmnt.mdc-button.lmnt-button--outlined-on-secondary:not(:disabled) {
  border-color: var(--lmnt-theme-on-secondary);
}
.lmnt.mdc-button.lmnt-button--outlined-on-unknown-white {
  --mdc-theme-primary: var(--lmnt-theme-on-unknown-white-active);
}
.lmnt.mdc-button.lmnt-button--outlined-on-unknown-white:not(:disabled) {
  border-color: #fff;
}
.lmnt.mdc-button.lmnt-button--outlined-on-unknown-black {
  --mdc-theme-primary: var(--lmnt-theme-on-unknown-black-active);
}
.lmnt.mdc-button.lmnt-button--outlined-on-unknown-black:not(:disabled) {
  border-color: #000;
}
.lmnt.mdc-button.lmnt-button--text-primary {
  --mdc-theme-primary: var(--lmnt-button-text-color);
}
.lmnt.mdc-button.lmnt-button--text-danger {
  --mdc-theme-primary: var(--lmnt-theme-danger);
}
.lmnt.mdc-button.lmnt-button--text-success {
  --mdc-theme-primary: var(--lmnt-theme-success);
}
.lmnt.mdc-button.lmnt-button--text-secondary {
  --mdc-theme-primary: var(--lmnt-theme-secondary-on-surface);
}
.lmnt.mdc-button.lmnt-button--text-on-primary {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
}
.lmnt.mdc-button.lmnt-button--text-on-primary:disabled {
  color: rgba(var(--lmnt-on-primary-rgb), 0.38);
}
.lmnt.mdc-button.lmnt-button--text-on-secondary {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
}
.lmnt.mdc-button.lmnt-button--text-on-unknown-white {
  --mdc-theme-primary: var(--lmnt-theme-on-unknown-white-active);
}
.lmnt.mdc-button.lmnt-button--text-on-unknown-black {
  --mdc-theme-primary: var(--lmnt-theme-on-unknown-black-active);
}
.lmnt.mdc-button.lmnt-button--danger-primary {
  --mdc-theme-primary: var(--lmnt-theme-danger);
  --mdc-theme-on-primary: var(--lmnt-theme-on-danger);
}
.lmnt.mdc-button.lmnt-button--danger-secondary {
  --mdc-theme-primary: var(--lmnt-theme-on-danger);
  --mdc-theme-on-primary: var(--lmnt-theme-danger);
}
.lmnt.mdc-button.lmnt-button--success-primary {
  --mdc-theme-primary: var(--lmnt-theme-success);
  --mdc-theme-on-primary: var(--lmnt-theme-on-success);
}
.lmnt.mdc-button.lmnt-button--success-secondary {
  --mdc-theme-primary: var(--lmnt-theme-on-success);
  --mdc-theme-on-primary: var(--lmnt-theme-success);
}
.lmnt.mdc-button:disabled.lmnt-button--text-primary {
  color: rgba(0, 0, 0, 0.38);
}
.lmnt.mdc-button--raised:disabled.lmnt-button--danger-secondary {
  background-color: rgba(255, 255, 255, 0.24);
  color: rgba(255, 255, 255, 0.38);
}
.lmnt.mdc-button--raised:disabled.lmnt-button--success-secondary {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(255, 255, 255, 0.38);
}
.lmnt.mdc-button:disabled.lmnt-button--outlined-secondary {
  color: rgba(0, 0, 0, 0.38);
}
.lmnt.mdc-button:disabled.lmnt-button--text-secondary {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(255, 255, 255, 0.38);
}
.lmnt.lmnt-button--full-width {
  width: 100%;
}
.lmnt .lmnt-button .lmnt-icon {
  display: inline-block;
  font-size: 18px;
  height: 18px;
  margin-left: 0;
  margin-right: 8px;
  vertical-align: top;
  width: 18px;
}
.lmnt .lmnt-button .lmnt-icon {
  margin-left: -4px;
  margin-right: 8px;
}
.lmnt .lmnt-button .mdc-button__label + .lmnt-icon {
  margin-left: 8px;
  margin-right: -4px;
}
.lmnt .mdc-button__label + .lmnt-icon {
  margin-left: 0;
  margin-right: 8px;
}
.lmnt.lmnt-button-group.lmnt-button-group--dense .lmnt-button {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.lmnt.lmnt-button-group.lmnt-button-group--standard .lmnt-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.lmnt.lmnt-button-group.lmnt-button-group--airy .lmnt-button {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.lmnt.lmnt-button-group .lmnt-button:first-child {
  margin-left: 0;
}
.lmnt.lmnt-button-group .lmnt-button:last-child {
  margin-right: 0;
}

.lmnt-theme-primary-bg .lmnt.lmnt-button--filled-primary {
  --mdc-theme-primary: var(--lmnt-button-filled-bg--on-primary);
  --mdc-theme-on-primary: var(--lmnt-button-filled-text--on-primary);
}
.lmnt-theme-primary-bg .lmnt.lmnt-button--outlined-primary {
  --mdc-theme-primary: var(--lmnt-button-outlined-text-color--on-primary);
}
.lmnt-theme-primary-bg .lmnt.lmnt-button--outlined-primary:not(:disabled) {
  border-color: var(--lmnt-button-outlined-border-color--on-primary);
}
.lmnt-theme-primary-bg .lmnt.lmnt-button--outlined-secondary {
  --mdc-theme-primary: var(--lmnt-button-outlined-text-color--on-primary);
}
.lmnt-theme-primary-bg .lmnt.lmnt-button--outlined-secondary:not(:disabled) {
  border-color: var(--lmnt-button-outlined-border-color--on-primary);
}
.lmnt-theme-primary-bg .lmnt.lmnt-button--text-primary {
  --mdc-theme-primary: var(--lmnt-button-text-color--on-primary);
}

.lmnt-theme-secondary-bg .lmnt.lmnt-button--filled-primary {
  --mdc-theme-primary: var(--lmnt-button-filled-bg--on-secondary);
  --mdc-theme-on-primary: var(--lmnt-button-filled-text--on-secondary);
}
.lmnt-theme-secondary-bg .lmnt.lmnt-button--outlined-primary {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .lmnt.lmnt-button--outlined-primary:not(:disabled) {
  border-color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .lmnt.lmnt-button--text-primary {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
}

.lmnt {
  --lmnt-divider-height-baseline: 1px;
  --lmnt-divider-height-dense: 1px;
  --lmnt-divider-height-tall: 4px;
  --lmnt-divider-margin-dense: 4px;
  --lmnt-divider-margin-standard: 8px;
  --lmnt-divider-margin-airy: 16px;
}

.lmnt-divider {
  border-bottom-width: var(--lmnt-divider-height-baseline);
  border-color: currentColor;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-top-width: 0;
  height: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  width: 100%;
}

.lmnt-divider--color-important {
  border-color: currentColor !important;
}

.lmnt-divider--dense {
  border-bottom-width: var(--lmnt-divider-height-dense);
}

.lmnt-divider--tall {
  border-bottom-width: var(--lmnt-divider-height-tall);
}

.lmnt-card-divider {
  border-color: #e0e0e0;
  color: #e0e0e0;
}

.lmnt-divider--margin-dense {
  margin-bottom: var(--lmnt-divider-margin-dense);
  margin-top: var(--lmnt-divider-margin-dense);
}

.lmnt-divider--margin-standard {
  margin-bottom: var(--lmnt-divider-margin-standard);
  margin-top: var(--lmnt-divider-margin-standard);
}

.lmnt-divider--margin-airy {
  margin-bottom: var(--lmnt-divider-margin-airy);
  margin-top: var(--lmnt-divider-margin-airy);
}

.lmnt-divider--dotted {
  border-style: dotted;
}

.lmnt-divider--dashed {
  border-style: dashed;
}

.lmnt-divider--solid {
  border-style: solid;
}

.lmnt-divider--double {
  border-style: double;
}

.lmnt-divider--groove {
  border-style: groove;
}

.lmnt-divider--ridge {
  border-style: ridge;
}

.lmnt-divider--inset {
  border-style: inset;
}

.lmnt-divider--outset {
  border-style: outset;
}

.lmnt-divider--primary {
  border-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-primary);
}

.lmnt-divider--primary-variant {
  border-color: var(--lmnt-theme-primary-variant);
  color: var(--lmnt-theme-primary-variant);
}

.lmnt-divider--primary-50 {
  border-color: var(--lmnt-theme-primary-50);
  color: var(--lmnt-theme-primary-50);
}

.lmnt-divider--primary-100 {
  border-color: var(--lmnt-theme-primary-100);
  color: var(--lmnt-theme-primary-100);
}

.lmnt-divider--primary-200 {
  border-color: var(--lmnt-theme-primary-200);
  color: var(--lmnt-theme-primary-200);
}

.lmnt-divider--primary-300 {
  border-color: var(--lmnt-theme-primary-300);
  color: var(--lmnt-theme-primary-300);
}

.lmnt-divider--primary-400 {
  border-color: var(--lmnt-theme-primary-400);
  color: var(--lmnt-theme-primary-400);
}

.lmnt-divider--primary-500 {
  border-color: var(--lmnt-theme-primary-500);
  color: var(--lmnt-theme-primary-500);
}

.lmnt-divider--primary-600 {
  border-color: var(--lmnt-theme-primary-600);
  color: var(--lmnt-theme-primary-600);
}

.lmnt-divider--primary-700 {
  border-color: var(--lmnt-theme-primary-700);
  color: var(--lmnt-theme-primary-700);
}

.lmnt-divider--primary-800 {
  border-color: var(--lmnt-theme-primary-800);
  color: var(--lmnt-theme-primary-800);
}

.lmnt-divider--primary-900 {
  border-color: var(--lmnt-theme-primary-900);
  color: var(--lmnt-theme-primary-900);
}

.lmnt-divider--secondary {
  border-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-secondary);
}

.lmnt-divider--secondary-variant {
  border-color: var(--lmnt-theme-secondary-variant);
  color: var(--lmnt-theme-secondary-variant);
}

.lmnt-divider--secondary-50 {
  border-color: var(--lmnt-theme-secondary-50);
  color: var(--lmnt-theme-secondary-50);
}

.lmnt-divider--secondary-100 {
  border-color: var(--lmnt-theme-secondary-100);
  color: var(--lmnt-theme-secondary-100);
}

.lmnt-divider--secondary-200 {
  border-color: var(--lmnt-theme-secondary-200);
  color: var(--lmnt-theme-secondary-200);
}

.lmnt-divider--secondary-300 {
  border-color: var(--lmnt-theme-secondary-300);
  color: var(--lmnt-theme-secondary-300);
}

.lmnt-divider--secondary-400 {
  border-color: var(--lmnt-theme-secondary-400);
  color: var(--lmnt-theme-secondary-400);
}

.lmnt-divider--secondary-500 {
  border-color: var(--lmnt-theme-secondary-500);
  color: var(--lmnt-theme-secondary-500);
}

.lmnt-divider--secondary-600 {
  border-color: var(--lmnt-theme-secondary-600);
  color: var(--lmnt-theme-secondary-600);
}

.lmnt-divider--secondary-700 {
  border-color: var(--lmnt-theme-secondary-700);
  color: var(--lmnt-theme-secondary-700);
}

.lmnt-divider--secondary-800 {
  border-color: var(--lmnt-theme-secondary-800);
  color: var(--lmnt-theme-secondary-800);
}

.lmnt-divider--secondary-900 {
  border-color: var(--lmnt-theme-secondary-900);
  color: var(--lmnt-theme-secondary-900);
}

.lmnt-divider--danger {
  border-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-danger);
}

.lmnt-divider--danger-variant {
  border-color: var(--lmnt-theme-danger-variant);
  color: var(--lmnt-theme-danger-variant);
}

.lmnt-divider--danger-50 {
  border-color: var(--lmnt-theme-danger-50);
  color: var(--lmnt-theme-danger-50);
}

.lmnt-divider--danger-100 {
  border-color: var(--lmnt-theme-danger-100);
  color: var(--lmnt-theme-danger-100);
}

.lmnt-divider--danger-200 {
  border-color: var(--lmnt-theme-danger-200);
  color: var(--lmnt-theme-danger-200);
}

.lmnt-divider--danger-300 {
  border-color: var(--lmnt-theme-danger-300);
  color: var(--lmnt-theme-danger-300);
}

.lmnt-divider--danger-400 {
  border-color: var(--lmnt-theme-danger-400);
  color: var(--lmnt-theme-danger-400);
}

.lmnt-divider--danger-500 {
  border-color: var(--lmnt-theme-danger-500);
  color: var(--lmnt-theme-danger-500);
}

.lmnt-divider--danger-600 {
  border-color: var(--lmnt-theme-danger-600);
  color: var(--lmnt-theme-danger-600);
}

.lmnt-divider--danger-700 {
  border-color: var(--lmnt-theme-danger-700);
  color: var(--lmnt-theme-danger-700);
}

.lmnt-divider--danger-800 {
  border-color: var(--lmnt-theme-danger-800);
  color: var(--lmnt-theme-danger-800);
}

.lmnt-divider--danger-900 {
  border-color: var(--lmnt-theme-danger-900);
  color: var(--lmnt-theme-danger-900);
}

.lmnt-divider--success {
  border-color: var(--lmnt-theme-success);
  color: var(--lmnt-theme-success);
}

.lmnt-divider--success-variant {
  border-color: var(--lmnt-theme-success-variant);
  color: var(--lmnt-theme-success-variant);
}

.lmnt-divider--success-50 {
  border-color: var(--lmnt-theme-success-50);
  color: var(--lmnt-theme-success-50);
}

.lmnt-divider--success-100 {
  border-color: var(--lmnt-theme-success-100);
  color: var(--lmnt-theme-success-100);
}

.lmnt-divider--success-200 {
  border-color: var(--lmnt-theme-success-200);
  color: var(--lmnt-theme-success-200);
}

.lmnt-divider--success-300 {
  border-color: var(--lmnt-theme-success-300);
  color: var(--lmnt-theme-success-300);
}

.lmnt-divider--success-400 {
  border-color: var(--lmnt-theme-success-400);
  color: var(--lmnt-theme-success-400);
}

.lmnt-divider--success-500 {
  border-color: var(--lmnt-theme-success-500);
  color: var(--lmnt-theme-success-500);
}

.lmnt-divider--success-600 {
  border-color: var(--lmnt-theme-success-600);
  color: var(--lmnt-theme-success-600);
}

.lmnt-divider--success-700 {
  border-color: var(--lmnt-theme-success-700);
  color: var(--lmnt-theme-success-700);
}

.lmnt-divider--success-800 {
  border-color: var(--lmnt-theme-success-800);
  color: var(--lmnt-theme-success-800);
}

.lmnt-divider--success-900 {
  border-color: var(--lmnt-theme-success-900);
  color: var(--lmnt-theme-success-900);
}

.lmnt-divider--ancillary-100 {
  border-color: var(--lmnt-theme-ancillary-100);
  color: var(--lmnt-theme-ancillary-100);
}

.lmnt-divider--ancillary-200 {
  border-color: var(--lmnt-theme-ancillary-200);
  color: var(--lmnt-theme-ancillary-200);
}

.lmnt-divider--ancillary-300 {
  border-color: var(--lmnt-theme-ancillary-300);
  color: var(--lmnt-theme-ancillary-300);
}

.lmnt-divider--ancillary-400 {
  border-color: var(--lmnt-theme-ancillary-400);
  color: var(--lmnt-theme-ancillary-400);
}

.lmnt-divider--ancillary-500 {
  border-color: var(--lmnt-theme-ancillary-500);
  color: var(--lmnt-theme-ancillary-500);
}

.lmnt-divider--ancillary-600 {
  border-color: var(--lmnt-theme-ancillary-600);
  color: var(--lmnt-theme-ancillary-600);
}

.lmnt-divider--ancillary-700 {
  border-color: var(--lmnt-theme-ancillary-700);
  color: var(--lmnt-theme-ancillary-700);
}

.lmnt-divider--ancillary-800 {
  border-color: var(--lmnt-theme-ancillary-800);
  color: var(--lmnt-theme-ancillary-800);
}

.lmnt-divider--ancillary-900 {
  border-color: var(--lmnt-theme-ancillary-900);
  color: var(--lmnt-theme-ancillary-900);
}

.lmnt-divider--on-surface-stroke {
  border-color: var(--lmnt-theme-on-surface-stroke);
  color: var(--lmnt-theme-on-surface-stroke);
}

.mdc-card {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.mdc-card .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-card::after {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  pointer-events: none;
}

.mdc-card--outlined {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
.mdc-card--outlined::after {
  border: none;
}

.mdc-card__content {
  border-radius: inherit;
  height: 100%;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-card__action--button:last-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-card__primary-action::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-card__primary-action:hover::before, .mdc-card__primary-action.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.lmnt {
  --lmnt-card-shape-radius: var(--lmnt-theme--shape-radius);
  --lmnt-card-header: var(--lmnt-theme-surface);
  --lmnt-card-on-header: var(--lmnt-theme-on-surface);
}

.lmnt.lmnt-card.mdc-card {
  --mdc-shape-medium: var(--lmnt-card-shape-radius);
  --mdc-elevation: var(--lmnt-card-box-shadow);
  border-color: var(--lmnt-card-border-color);
  border-style: var(--lmnt-card-border-style);
  border-width: var(--lmnt-card-border-width);
  box-shadow: var(--lmnt-card-box-shadow);
  width: 100%;
}
.lmnt.lmnt-card.mdc-card--outlined {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: none;
}
.lmnt.lmnt-card.lmnt-card--flat {
  box-shadow: none;
  border: none;
}
.lmnt.lmnt-card.lmnt-card--raised {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: none;
}
.lmnt.lmnt-card.lmnt-card--left-top .lmnt-card-body {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}
.lmnt.lmnt-card.lmnt-card--left-center .lmnt-card-body {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.lmnt.lmnt-card.lmnt-card--left-bottom .lmnt-card-body {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
}
.lmnt.lmnt-card.lmnt-card--centered .lmnt-card-body {
  align-items: center;
  display: flex;
  justify-content: center;
}
.lmnt.lmnt-card.lmnt-card--right-top .lmnt-card-body {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}
.lmnt.lmnt-card.lmnt-card--right-center .lmnt-card-body {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.lmnt.lmnt-card.lmnt-card--right-bottom .lmnt-card-body {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}
.lmnt.lmnt-card.lmnt-card--loading .mdc-card__content .lmnt-card-body {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 50px;
}
.lmnt.lmnt-card.lmnt-theme-transparent-bg {
  background-color: transparent;
}
.lmnt.lmnt-card .lmnt-card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.lmnt.lmnt-card .lmnt-card-overlay.lmnt-card-overlay__dark {
  background-color: var(--lmnt-theme-on-unknown-black-inactive);
}
.lmnt.lmnt-card .lmnt-card-overlay.lmnt-card-overlay__light {
  background-color: var(--lmnt-theme-on-unknown-white-inactive);
}
.lmnt.lmnt-card .lmnt-card-overlay.lmnt-card-overlay__leading {
  width: 50%;
  justify-self: flex-start;
}
.lmnt.lmnt-card .lmnt-card-overlay.lmnt-card-overlay__trailing {
  width: 50%;
  justify-self: flex-end;
}
.lmnt.lmnt-card .lmnt-card__media-inner-content {
  z-index: 2;
}

.lmnt .lmnt-card-title {
  align-items: center;
  display: flex;
  min-height: 48px;
  padding: 12px 16px;
}
.lmnt .lmnt-card-title.lmnt-card-title--top-placement {
  border-top-left-radius: var(--lmnt-card-shape-radius);
  border-top-right-radius: var(--lmnt-card-shape-radius);
}
.lmnt .lmnt-card-title.lmnt-card-title--bottom-placement {
  border-bottom-left-radius: var(--lmnt-card-shape-radius);
  border-bottom-right-radius: var(--lmnt-card-shape-radius);
}
.lmnt .lmnt-card-title.lmnt-card-title--stacked {
  flex-direction: column;
  align-items: flex-start;
}
.lmnt .lmnt-card-title.lmnt-card-title--stacked .lmnt-card-title__trailingBlock,
.lmnt .lmnt-card-title.lmnt-card-title--stacked .lmnt-card-title__leadingBlock {
  width: 100%;
}
.lmnt .lmnt-card-title.lmnt-card-title--stacked .lmnt-card-title__leadingBlock {
  margin-bottom: 8px;
}
.lmnt .lmnt-card-title.lmnt-card-title--stacked .lmnt-card-title__overlineText {
  margin-bottom: 4px;
}
.lmnt .lmnt-card-title.lmnt-card-title--stacked .lmnt-card-title-secondaryText-container {
  margin-top: 4px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--avatar {
  border-radius: 50%;
  margin-right: 16px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--avatar img,
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--avatar svg {
  height: 40px;
  width: 40px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--thumbnail {
  margin-right: 16px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--thumbnail img {
  height: 40px;
  width: 40px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--icon {
  margin-right: 23px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--icon-with-background {
  margin-right: 15px;
}
.lmnt .lmnt-card-title .lmnt-card-title__leadingBlock.lmnt-card-title__leadingBlock--icon-with-background i {
  width: 24px;
}
.lmnt .lmnt-card-title .lmnt-card-title__text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  flex: 1;
}
.lmnt .lmnt-card-title .lmnt-card-title__text .lmnt-card-title__overlineText {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
}
.lmnt .lmnt-card-title .lmnt-card-title__text .lmnt-card-title__secondaryText {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: var(--lmnt-on-surface-inactive, rgba(0, 0, 0, 0.6));
}
.lmnt .lmnt-card-title .lmnt-card-title__trailingBlock.lmnt-card-title__trailingBlock--avatar {
  border-radius: 50%;
}
.lmnt .lmnt-card-title .lmnt-card-title__trailingBlock.lmnt-card-title__trailingBlock--avatar img,
.lmnt .lmnt-card-title .lmnt-card-title__trailingBlock.lmnt-card-title__trailingBlock--avatar svg {
  height: 40px;
  width: 40px;
}
.lmnt .lmnt-card-title .lmnt-card-title__trailingBlock.lmnt-card-title__trailingBlock--thumbnail img {
  height: 40px;
  width: 40px;
}
.lmnt .lmnt-card-title.lmnt-theme-primary-bg .lmnt-card-title__text .lmnt-card-title__overlineText,
.lmnt .lmnt-card-title.lmnt-theme-primary-bg .lmnt-card-title__text .lmnt-card-title__secondaryText {
  color: rgba(var(--lmnt-theme-on-primary), 0.6);
}
.lmnt .lmnt-card-title.lmnt-theme-secondary-bg .lmnt-card-title__text .lmnt-card-title__overlineText,
.lmnt .lmnt-card-title.lmnt-theme-secondary-bg .lmnt-card-title__text .lmnt-card-title__secondaryText {
  color: rgba(var(--lmnt-theme-on-secondary), 0.6);
}
.lmnt .mdc-card__content {
  display: flex;
  flex-direction: column;
}
.lmnt .mdc-card__content .lmnt-card-body {
  padding-left: 16px;
  padding-right: 16px;
  overflow: auto;
}
.lmnt .mdc-card__content .lmnt-card-body,
.lmnt .mdc-card__content .mdc-card__primary-action {
  flex-grow: 1;
}
.lmnt .lmnt-card__media--4-3::before {
  margin-top: 75%;
}
.lmnt .lmnt-card__media--4-5::before {
  margin-top: 125%;
}
.lmnt .lmnt-card__media--2-1::before {
  margin-top: 50%;
}
.lmnt .mdc-card__media-content {
  padding: 8px;
  display: flex;
}
.lmnt .mdc-card__media-content.lmnt-card__content--bottom {
  align-items: flex-end;
}
.lmnt .mdc-card__media-content.lmnt-card__content--top {
  align-items: flex-start;
}
.lmnt .lmnt-card__actions--center {
  justify-content: center;
}
.lmnt .lmnt-card__actions--trailing {
  justify-content: flex-end;
}
.lmnt .lmnt-card__filters {
  display: flex;
}
.lmnt .lmnt-card__filters.lmnt-card__filters--chips {
  justify-content: flex-start;
  padding: 8px 16px;
}
.lmnt .lmnt-card__filters.lmnt-card__filters--select {
  padding: 8px 16px;
  justify-content: center;
}
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 {
  justify-content: space-evenly;
  padding: 8px 16px;
}
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 > div,
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 > span {
  width: 50%;
}
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 > div:first-child,
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 > span:first-child {
  margin-right: 5px;
}
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 > div:last-child,
.lmnt .lmnt-card__filters.lmnt-card__filters--select-2 > span:last-child {
  margin-left: 5px;
}

.mdc-card__media.lmnt-card__leading-media:first-child {
  border-top-left-radius: var(--lmnt-card-shape-radius);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--lmnt-card-shape-radius);
  border-bottom-right-radius: 0;
}

.mdc-card__media.lmnt-card__leading-media-rtl:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: var(--lmnt-card-shape-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--lmnt-card-shape-radius);
}

.lmnt-card__leading-media--square {
  width: 50%;
}

.lmnt-card__leading-media--9-16 {
  width: 28.125%;
}

.lmnt-card__leading-media--4-5 {
  width: 50%;
}

.lmnt-card__leading-media--2-1 {
  width: 28.125%;
}

.lmnt-card-title__items {
  display: flex;
  column-gap: 0.5em;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  margin: 0;
}

.lmnt {
  --form-field-color-on-primary: var(--lmnt-theme-on-primary);
  --form-field-color-on-secondary: var(--lmnt-theme-on-secondary);
}

.mdc-input-label {
  cursor: pointer;
}

.lmnt-theme-primary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-primary);
}

.lmnt-theme-secondary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-secondary);
}

:root {
  --mdc-theme-primary:#6200ee;
  --mdc-theme-secondary:#018786;
  --mdc-theme-background:#fff;
  --mdc-theme-surface:#fff;
  --mdc-theme-error:#b00020;
  --mdc-theme-on-primary:#fff;
  --mdc-theme-on-secondary:#fff;
  --mdc-theme-on-surface:#000;
  --mdc-theme-on-error:#fff;
  --mdc-theme-text-primary-on-background:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark:white;
  --mdc-theme-text-secondary-on-dark:rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark:rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #6200ee  !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary {
  color: #018786  !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020  !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000  !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #6200ee  !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary-bg {
  background-color: #018786  !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

/* stylelint-disable scss/media-feature-value-dollar-variable */
/* stylelint-enable scss/media-feature-value-dollar-variable */
.lmnt-theme-primary-50--bg,
.lmnt-theme-primary-50-bg {
  background-color: #f1edf8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-50 {
  color: #f1edf8;
}

.lmnt-theme-primary-100--bg,
.lmnt-theme-primary-100-bg {
  background-color: #c7b7e4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-100 {
  color: #c7b7e4;
}

.lmnt-theme-primary-200--bg,
.lmnt-theme-primary-200-bg {
  background-color: #b9a5de;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-200 {
  color: #b9a5de;
}

.lmnt-theme-primary-300--bg,
.lmnt-theme-primary-300-bg {
  background-color: #9381d1;
  color: white;
}

.lmnt-theme-primary-300 {
  color: #9381d1;
}

.lmnt-theme-primary-400--bg,
.lmnt-theme-primary-400-bg {
  background-color: #825dc4;
  color: white;
}

.lmnt-theme-primary-400 {
  color: #825dc4;
}

.lmnt-theme-primary-500--bg,
.lmnt-theme-primary-500-bg {
  background-color: #673ab7;
  color: white;
}

.lmnt-theme-primary-500 {
  color: #673ab7;
}

.lmnt-theme-primary-600--bg,
.lmnt-theme-primary-600-bg {
  background-color: #553096;
  color: white;
}

.lmnt-theme-primary-600 {
  color: #553096;
}

.lmnt-theme-primary-700--bg,
.lmnt-theme-primary-700-bg {
  background-color: #4b2b86;
  color: white;
}

.lmnt-theme-primary-700 {
  color: #4b2b86;
}

.lmnt-theme-primary-800--bg,
.lmnt-theme-primary-800-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-primary-800 {
  color: #422575;
}

.lmnt-theme-primary-900--bg,
.lmnt-theme-primary-900-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-primary-900 {
  color: #261643;
}

.lmnt-theme-secondary-50--bg,
.lmnt-theme-secondary-50-bg {
  background-color: #edf8ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-50 {
  color: #edf8ff;
}

.lmnt-theme-secondary-100--bg,
.lmnt-theme-secondary-100-bg {
  background-color: #caecff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-100 {
  color: #caecff;
}

.lmnt-theme-secondary-200--bg,
.lmnt-theme-secondary-200-bg {
  background-color: #b9e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-200 {
  color: #b9e5ff;
}

.lmnt-theme-secondary-300--bg,
.lmnt-theme-secondary-300-bg {
  background-color: #96d9ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-300 {
  color: #96d9ff;
}

.lmnt-theme-secondary-400--bg,
.lmnt-theme-secondary-400-bg {
  background-color: #74ccff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-400 {
  color: #74ccff;
}

.lmnt-theme-secondary-500--bg,
.lmnt-theme-secondary-500-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-500 {
  color: #40baff;
}

.lmnt-theme-secondary-600--bg,
.lmnt-theme-secondary-600-bg {
  background-color: #3599d1;
  color: white;
}

.lmnt-theme-secondary-600 {
  color: #3599d1;
}

.lmnt-theme-secondary-700--bg,
.lmnt-theme-secondary-700-bg {
  background-color: #2f88ba;
  color: white;
}

.lmnt-theme-secondary-700 {
  color: #2f88ba;
}

.lmnt-theme-secondary-800--bg,
.lmnt-theme-secondary-800-bg {
  background-color: #23668c;
  color: white;
}

.lmnt-theme-secondary-800 {
  color: #23668c;
}

.lmnt-theme-secondary-900--bg,
.lmnt-theme-secondary-900-bg {
  background-color: #18445d;
  color: white;
}

.lmnt-theme-secondary-900 {
  color: #18445d;
}

.lmnt-theme-ancillary-100--bg,
.lmnt-theme-ancillary-100-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-100 {
  color: #000;
}

.lmnt-theme-ancillary-200--bg,
.lmnt-theme-ancillary-200-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-200 {
  color: #000;
}

.lmnt-theme-ancillary-300--bg,
.lmnt-theme-ancillary-300-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-300 {
  color: #000;
}

.lmnt-theme-ancillary-400--bg,
.lmnt-theme-ancillary-400-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-400 {
  color: #000;
}

.lmnt-theme-ancillary-500--bg,
.lmnt-theme-ancillary-500-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-500 {
  color: #000;
}

.lmnt-theme-ancillary-600--bg,
.lmnt-theme-ancillary-600-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-600 {
  color: #000;
}

.lmnt-theme-ancillary-700--bg,
.lmnt-theme-ancillary-700-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-700 {
  color: #000;
}

.lmnt-theme-ancillary-800--bg,
.lmnt-theme-ancillary-800-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-800 {
  color: #000;
}

.lmnt-theme-ancillary-900--bg,
.lmnt-theme-ancillary-900-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-900 {
  color: #000;
}

.lmnt-theme-dataVizComparative-50--bg,
.lmnt-theme-dataVizComparative-50-bg {
  background-color: #240f67;
  color: white;
}

.lmnt-theme-dataVizComparative-50 {
  color: #240f67;
}

.lmnt-theme-dataVizComparative-100--bg,
.lmnt-theme-dataVizComparative-100-bg {
  background-color: #2d72c4;
  color: white;
}

.lmnt-theme-dataVizComparative-100 {
  color: #2d72c4;
}

.lmnt-theme-dataVizComparative-200--bg,
.lmnt-theme-dataVizComparative-200-bg {
  background-color: #89c9b7;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-200 {
  color: #89c9b7;
}

.lmnt-theme-dataVizComparative-300--bg,
.lmnt-theme-dataVizComparative-300-bg {
  background-color: #785e9f;
  color: white;
}

.lmnt-theme-dataVizComparative-300 {
  color: #785e9f;
}

.lmnt-theme-dataVizComparative-400--bg,
.lmnt-theme-dataVizComparative-400-bg {
  background-color: #3b8ec8;
  color: white;
}

.lmnt-theme-dataVizComparative-400 {
  color: #3b8ec8;
}

.lmnt-theme-dataVizComparative-500--bg,
.lmnt-theme-dataVizComparative-500-bg {
  background-color: #b7dbbc;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-500 {
  color: #b7dbbc;
}

.lmnt-theme-dataVizComparative-600--bg,
.lmnt-theme-dataVizComparative-600-bg {
  background-color: #4153af;
  color: white;
}

.lmnt-theme-dataVizComparative-600 {
  color: #4153af;
}

.lmnt-theme-dataVizComparative-700--bg,
.lmnt-theme-dataVizComparative-700-bg {
  background-color: #47a3c4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-700 {
  color: #47a3c4;
}

.lmnt-theme-dataVizComparative-800--bg,
.lmnt-theme-dataVizComparative-800-bg {
  background-color: #dfeccb;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-800 {
  color: #dfeccb;
}

.lmnt-theme-dataVizComparative-900--bg,
.lmnt-theme-dataVizComparative-900-bg {
  background-color: #6332ad;
  color: white;
}

.lmnt-theme-dataVizComparative-900 {
  color: #6332ad;
}

.lmnt-theme-dataVizSequential1-50--bg,
.lmnt-theme-dataVizSequential1-50-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-dataVizSequential1-50 {
  color: #261643;
}

.lmnt-theme-dataVizSequential1-100--bg,
.lmnt-theme-dataVizSequential1-100-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-dataVizSequential1-100 {
  color: #422575;
}

.lmnt-theme-dataVizSequential1-200--bg,
.lmnt-theme-dataVizSequential1-200-bg {
  background-color: #6540aa;
  color: white;
}

.lmnt-theme-dataVizSequential1-200 {
  color: #6540aa;
}

.lmnt-theme-dataVizSequential1-300--bg,
.lmnt-theme-dataVizSequential1-300-bg {
  background-color: #7e52d0;
  color: white;
}

.lmnt-theme-dataVizSequential1-300 {
  color: #7e52d0;
}

.lmnt-theme-dataVizSequential1-400--bg,
.lmnt-theme-dataVizSequential1-400-bg {
  background-color: #9a75dd;
  color: white;
}

.lmnt-theme-dataVizSequential1-400 {
  color: #9a75dd;
}

.lmnt-theme-dataVizSequential1-500--bg,
.lmnt-theme-dataVizSequential1-500-bg {
  background-color: #af8bef;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-500 {
  color: #af8bef;
}

.lmnt-theme-dataVizSequential1-600--bg,
.lmnt-theme-dataVizSequential1-600-bg {
  background-color: #c6a6ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-600 {
  color: #c6a6ff;
}

.lmnt-theme-dataVizSequential1-700--bg,
.lmnt-theme-dataVizSequential1-700-bg {
  background-color: #d8c1ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-700 {
  color: #d8c1ff;
}

.lmnt-theme-dataVizSequential1-800--bg,
.lmnt-theme-dataVizSequential1-800-bg {
  background-color: #e8dbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-800 {
  color: #e8dbff;
}

.lmnt-theme-dataVizSequential1-900--bg,
.lmnt-theme-dataVizSequential1-900-bg {
  background-color: #f5efff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-900 {
  color: #f5efff;
}

.lmnt-theme-dataVizSequential2--50--bg,
.lmnt-theme-dataVizSequential2--50-bg {
  background-color: #052d44;
  color: white;
}

.lmnt-theme-dataVizSequential2--50 {
  color: #052d44;
}

.lmnt-theme-dataVizSequential2--100--bg,
.lmnt-theme-dataVizSequential2--100-bg {
  background-color: #08517b;
  color: white;
}

.lmnt-theme-dataVizSequential2--100 {
  color: #08517b;
}

.lmnt-theme-dataVizSequential2--200--bg,
.lmnt-theme-dataVizSequential2--200-bg {
  background-color: #1774aa;
  color: white;
}

.lmnt-theme-dataVizSequential2--200 {
  color: #1774aa;
}

.lmnt-theme-dataVizSequential2--300--bg,
.lmnt-theme-dataVizSequential2--300-bg {
  background-color: #319bd8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--300 {
  color: #319bd8;
}

.lmnt-theme-dataVizSequential2--400--bg,
.lmnt-theme-dataVizSequential2--400-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--400 {
  color: #40baff;
}

.lmnt-theme-dataVizSequential2--500--bg,
.lmnt-theme-dataVizSequential2--500-bg {
  background-color: #75cdff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--500 {
  color: #75cdff;
}

.lmnt-theme-dataVizSequential2--600--bg,
.lmnt-theme-dataVizSequential2--600-bg {
  background-color: #97e0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--600 {
  color: #97e0ff;
}

.lmnt-theme-dataVizSequential2--700--bg,
.lmnt-theme-dataVizSequential2--700-bg {
  background-color: #a9f0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--700 {
  color: #a9f0ff;
}

.lmnt-theme-dataVizSequential2--800--bg,
.lmnt-theme-dataVizSequential2--800-bg {
  background-color: #c3fbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--800 {
  color: #c3fbff;
}

.lmnt-theme-dataVizSequential2--900--bg,
.lmnt-theme-dataVizSequential2--900-bg {
  background-color: #ddfffd;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--900 {
  color: #ddfffd;
}

.lmnt-theme--bg-linear-gradient-primary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-white-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-white-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

:root,
.lmnt {
  --lmnt-theme-on-background: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface: rgba(0, 0, 0, 0.87);
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-background: #fff;
  --lmnt-theme-surface: #fff;
  --lmnt-theme-surface-variant: #f5f5f5;
  --lmnt-theme-dark: #000;
  --lmnt-theme-light: #fff;
  --lmnt-theme-on-unknown-light: #000;
  --lmnt-theme-on-unknown-dark: #fff;
}

.lmnt {
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-secondary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-black-rgb: 0, 0, 0;
  --lmnt-white-rgb: 255, 255, 255;
  --lmnt-theme-primary: #673ab7;
  --lmnt-theme-on-primary: white;
  --lmnt-theme-primary-variant: #4b2b86;
  --lmnt-theme-on-primary-variant: white;
  --lmnt-theme-primary-on-surface: #673ab7;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-theme-secondary: #40baff;
  --lmnt-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --lmnt-theme-secondary-variant: #2f88ba;
  --lmnt-theme-on-secondary-variant: white;
  --lmnt-theme-secondary-on-surface: #23668c;
  --lmnt-theme-danger: #cf2d22;
  --lmnt-theme-on-danger: white;
  --lmnt-theme-danger-alt: #f5f5f5;
  --lmnt-theme-on-danger-alt: #cf2d22;
  --lmnt-theme-danger-variant: #b3190d;
  --lmnt-theme-on-danger-variant: white;
  --lmnt-theme-danger-on-surface: #cf2d22;
  --lmnt-theme-success: #0ea01a;
  --lmnt-theme-on-success: white;
  --lmnt-theme-success-variant: #13c545;
  --lmnt-theme-success-on-surface: #01830c;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-secondary-on-surface-rgb: 35, 102, 140;
  --lmnt-danger-on-surface-rgb: 207, 45, 34;
  --lmnt-success-on-surface-rgb: 207, 45, 34;
  --lmnt-on-primary-rgb: 255, 255, 255;
  --lmnt-on-secondary-rgb: 0, 0, 0;
  --lmnt-on-danger-rgb: 255, 255, 255;
  --lmnt-on-success-rgb: 255, 255, 255;
  --lmnt-theme-breakpoint-mobile-min: 0;
  --lmnt-theme-breakpoint-mobile-max: 599px;
  --lmnt-theme-breakpoint-tablet-sm-min: 600px;
  --lmnt-theme-breakpoint-tablet-sm-max: 719px;
  --lmnt-theme-breakpoint-tablet-lg-min: 720px;
  --lmnt-theme-breakpoint-tablet-lg-max: 839px;
  --lmnt-theme-breakpoint-desktop-sm-min: 840px;
  --lmnt-theme-breakpoint-desktop-sm-max: 1023px;
  --lmnt-theme-breakpoint-desktop-lg-min: 1024px;
  --lmnt-theme-breakpoint-desktop-lg-max: 1439px;
  --lmnt-theme-breakpoint-desktop-xl-min: 1440px;
  --lmnt-theme-breakpoint-content-max-width-md: 776px;
  --lmnt-theme-breakpoint-content-max-width-lg: 1052px;
  --lmnt-theme-breakpoint-content-max-width-xl: 1176px;
  --lmnt-theme-primary-50: #f1edf8;
  --lmnt-theme-primary-100: #c7b7e4;
  --lmnt-theme-primary-200: #b9a5de;
  --lmnt-theme-primary-300: #9381d1;
  --lmnt-theme-primary-400: #825dc4;
  --lmnt-theme-primary-500: #673ab7;
  --lmnt-theme-primary-600: #553096;
  --lmnt-theme-primary-700: #4b2b86;
  --lmnt-theme-primary-800: #422575;
  --lmnt-theme-primary-900: #261643;
  --lmnt-theme-secondary-50: #edf8ff;
  --lmnt-theme-secondary-100: #caecff;
  --lmnt-theme-secondary-200: #b9e5ff;
  --lmnt-theme-secondary-300: #96d9ff;
  --lmnt-theme-secondary-400: #74ccff;
  --lmnt-theme-secondary-500: #40baff;
  --lmnt-theme-secondary-600: #3599d1;
  --lmnt-theme-secondary-700: #2f88ba;
  --lmnt-theme-secondary-800: #23668c;
  --lmnt-theme-secondary-900: #18445d;
  --lmnt-theme-success-50: #eeffef;
  --lmnt-theme-success-100: #d3ffd7;
  --lmnt-theme-success-200: #a9f6af;
  --lmnt-theme-success-300: #6fef7a;
  --lmnt-theme-success-400: #29e539;
  --lmnt-theme-success-500: #13c545;
  --lmnt-theme-success-600: #0ea01a;
  --lmnt-theme-success-700: #01830c;
  --lmnt-theme-success-800: #075a0e;
  --lmnt-theme-success-900: #053709;
  --lmnt-theme-danger-50: #ffeaed;
  --lmnt-theme-danger-100: #ffccce;
  --lmnt-theme-danger-200: #ee9894;
  --lmnt-theme-danger-300: #e3716a;
  --lmnt-theme-danger-400: #ec5145;
  --lmnt-theme-danger-500: #ef4227;
  --lmnt-theme-danger-600: #e13727;
  --lmnt-theme-danger-700: #cf2d22;
  --lmnt-theme-danger-800: #c2261b;
  --lmnt-theme-danger-900: #b3190d;
  --lmnt-theme-ancillary-100: #000;
  --lmnt-theme-ancillary-200: #000;
  --lmnt-theme-ancillary-300: #000;
  --lmnt-theme-ancillary-400: #000;
  --lmnt-theme-ancillary-500: #000;
  --lmnt-theme-ancillary-600: #000;
  --lmnt-theme-ancillary-700: #000;
  --lmnt-theme-ancillary-800: #000;
  --lmnt-theme-ancillary-900: #000;
  --lmnt-theme-dataVizSequential1-50: #261643;
  --lmnt-theme-dataVizSequential1-100: #422575;
  --lmnt-theme-dataVizSequential1-200: #6540aa;
  --lmnt-theme-dataVizSequential1-300: #7e52d0;
  --lmnt-theme-dataVizSequential1-400: #9a75dd;
  --lmnt-theme-dataVizSequential1-500: #af8bef;
  --lmnt-theme-dataVizSequential1-600: #c6a6ff;
  --lmnt-theme-dataVizSequential1-700: #d8c1ff;
  --lmnt-theme-dataVizSequential1-800: #e8dbff;
  --lmnt-theme-dataVizSequential1-900: #f5efff;
  --lmnt-theme-dataVizSequential2--50: #052d44;
  --lmnt-theme-dataVizSequential2--100: #08517b;
  --lmnt-theme-dataVizSequential2--200: #1774aa;
  --lmnt-theme-dataVizSequential2--300: #319bd8;
  --lmnt-theme-dataVizSequential2--400: #40baff;
  --lmnt-theme-dataVizSequential2--500: #75cdff;
  --lmnt-theme-dataVizSequential2--600: #97e0ff;
  --lmnt-theme-dataVizSequential2--700: #a9f0ff;
  --lmnt-theme-dataVizSequential2--800: #c3fbff;
  --lmnt-theme-dataVizSequential2--900: #ddfffd;
  --lmnt-theme-dataVizComparative-50: #240f67;
  --lmnt-theme-dataVizComparative-100: #2d72c4;
  --lmnt-theme-dataVizComparative-200: #89c9b7;
  --lmnt-theme-dataVizComparative-300: #785e9f;
  --lmnt-theme-dataVizComparative-400: #3b8ec8;
  --lmnt-theme-dataVizComparative-500: #b7dbbc;
  --lmnt-theme-dataVizComparative-600: #4153af;
  --lmnt-theme-dataVizComparative-700: #47a3c4;
  --lmnt-theme-dataVizComparative-800: #dfeccb;
  --lmnt-theme-dataVizComparative-900: #6332ad;
  --lmnt-theme-on-surface-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-surface-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-surface-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-surface-base: black;
  --lmnt-theme-on-primary-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-primary-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-primary-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-primary-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-primary-base: white;
  --lmnt-theme-on-secondary-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-secondary-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-secondary-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-secondary-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-secondary-base: black;
  --lmnt-theme-on-success-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-success-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-success-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-success-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-success-base: white;
  --lmnt-theme-on-danger-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-danger-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-danger-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-danger-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-danger-base: white;
  --lmnt-theme-on-unknown-black-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-unknown-black-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-unknown-black-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-unknown-black-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-unknown-black-base: black;
  --lmnt-theme-on-unknown-white-active: white;
  --lmnt-theme-on-unknown-white-inactive: rgba(255, 255, 255, 0.54);
  --lmnt-theme-on-unknown-white-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-unknown-white-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-unknown-white-base: white;
  --lmnt-theme-black: #000;
  --lmnt-theme-white: #fff;
  --lmnt-theme-icon-in-component: rgba(#000, 0.54);
  --lmnt-theme-emphasis-active-black: 0.87;
  --lmnt-theme-emphasis-active: 1;
  --lmnt-theme-emphasis-completed: 0.6;
  --lmnt-theme-emphasis-disabled: 0.38;
  --lmnt-theme-emphasis-dragged: 0.08;
  --lmnt-theme-emphasis-focus: 0.12;
  --lmnt-theme-emphasis-high-black: 0.87;
  --lmnt-theme-emphasis-high: 1;
  --lmnt-theme-emphasis-highlighted: 0.24;
  --lmnt-theme-emphasis-hover--alt: 0.08;
  --lmnt-theme-emphasis-hover: 0.04;
  --lmnt-theme-emphasis-inactive: 0.6;
  --lmnt-theme-emphasis-low: 0.38;
  --lmnt-theme-emphasis-med: 0.6;
  --lmnt-theme-emphasis-pressed--alt: 0.32;
  --lmnt-theme-emphasis-pressed: 0.16;
  --lmnt-theme-emphasis-resting: 0.08;
  --lmnt-theme-emphasis-focus--alt: 0.24;
  --lmnt-theme-emphasis-selected: 0.08;
  --lmnt-theme-emphasis-stroke: 0.12;
  --lmnt-theme-emphasis-stroke-dark: 0.24;
  --lmnt-theme-emphasis-icon-in-component: 0.54;
  --lmnt-theme-emphasis-on-surface-active-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-active: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-completed: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-disabled: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-dragged: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-focus--alt: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-focus: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-high-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-high: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-highlighted: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-hover-alt: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-hover: calc(1 - 0.04);
  --lmnt-theme-emphasis-on-surface-inactive: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-low: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-med: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-pressed-alt: calc(1 - 0.32);
  --lmnt-theme-emphasis-on-surface-resting: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-selected: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-stroke: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-stroke-dark: calc(1 - 0.24);
  --mdc-typography-font-family: var(--lmnt-theme-primary-font);
  --mdc-theme-primary: var(--lmnt-theme-primary);
  --mdc-theme-on-primary: var(--lmnt-theme-on-primary);
  --mdc-theme-secondary: var(--lmnt-theme-secondary);
  --mdc-theme-on-secondary: var(--lmnt-theme-on-secondary);
  --mdc-theme-background: var(--lmnt-theme-background);
  --mdc-theme-surface: var(--lmnt-theme-surface);
  --mdc-theme-on-surface: var(--lmnt-theme-on-surface);
  --mdc-theme-error: var(--lmnt-theme-danger);
  --mdc-theme-on-error: var(--lmnt-theme-on-danger);
  --lmnt-button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --lmnt-theme--shape-radius: 4px;
  --lmnt-card-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-primary-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-primary-on-light: var(--lmnt-theme-on-unknown-black);
  --mdc-theme-text-secondary-on-background: rgba(var(--lmnt-black-rgb), 0.54);
  --mdc-theme-text-disabled-on-background: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-disabled-on-dark: var(--lmnt-theme-on-unknown-white-disabled);
  --mdc-theme-text-disabled-on-light: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-icon-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-icon-on-light: var(--lmnt-theme-on-unknown-black);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  margin: 0;
}

.lmnt {
  --form-field-color-on-primary: var(--lmnt-theme-on-primary);
  --form-field-color-on-secondary: var(--lmnt-theme-on-secondary);
}

.mdc-input-label {
  cursor: pointer;
}

.lmnt-theme-primary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-primary);
}

.lmnt-theme-secondary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-secondary);
}

.mdc-checkbox {
  padding: calc((40px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((40px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
}
.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-checkbox:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}
.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((40px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((40px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - 40px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  right: calc((40px - 40px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  left: calc((40px - 40px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  width: 40px;
  /* @alternate */
  width: var(--mdc-checkbox-ripple-size, 40px);
  height: 40px;
  /* @alternate */
  height: var(--mdc-checkbox-ripple-size, 40px);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786 {
  0%, 80% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786;
}
.mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-color, GrayText);
    background-color: transparent;
  }

  .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: transparent;
    /* @alternate */
    background-color: var(--mdc-checkbox-disabled-color, transparent);
  }

  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: GrayText;
    /* @alternate */
    color: var(--mdc-checkbox-ink-color, GrayText);
  }
  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-ink-color, GrayText);
  }

  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin: calc((48px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 48px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
}
.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: calc((40px - 48px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  right: calc((40px - 48px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  left: calc((40px - 48px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  width: 48px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 48px);
  height: 48px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 48px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox {
  z-index: 0;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.lmnt {
  --lmnt-checkbox--primary: var(--lmnt-theme-primary-on-surface);
  --lmnt-checkbox--on-primary: var(--lmnt-theme-on-primary);
  --lmnt-checkbox--on-primary-ink: var(--lmnt-theme-primary-on-surface);
  --lmnt-checkbox--secondary: var(--lmnt-theme-secondary-on-surface);
  --lmnt-checkbox--on-secondary: var(--lmnt-theme-on-secondary);
  --lmnt-checkbox--on-secondary-ink: var(--lmnt-theme-secondary-on-surface);
}

.lmnt-checkbox--secondary,
.mdc-checkbox.lmnt-checkbox,
.mdc-checkbox.lmnt-checkbox.lmnt-checkbox--secondary {
  --mdc-theme-secondary: var(--lmnt-checkbox--primary);
}

.lmnt-checkbox--secondary,
.mdc-checkbox.lmnt-checkbox.lmnt-checkbox--secondary {
  --mdc-theme-secondary: var(--lmnt-checkbox--secondary);
}

.lmnt-theme-primary-bg .mdc-checkbox.lmnt-checkbox {
  --mdc-theme-secondary: var(--lmnt-checkbox--on-primary);
  --mdc-checkbox-ink-color: var(--lmnt-checkbox--on-primary-ink);
  --mdc-checkbox-unchecked-color: var(--lmnt-checkbox--on-primary);
}

.lmnt-theme-secondary-bg .mdc-checkbox.lmnt-checkbox {
  --mdc-theme-secondary: var(--lmnt-checkbox--on-secondary);
  --mdc-checkbox-ink-color: var(--lmnt-checkbox--on-secondary-ink);
  --mdc-checkbox-unchecked-color: var(--lmnt-checkbox--on-secondary);
}

:root,
.lmnt {
  --lmnt-chip-selected-fill-color: #e0e0e0;
  --lmnt-chip-ripple-shape-radius: 16px;
  --lmnt-chip-ripple-before-after-shape-radius: 50%;
  --lmnt-chip-shape-radius: 16px;
  --lmnt-chip-outlined-color: var(--lmnt-theme-black, #000000);
}

.mdc-deprecated-chip-trailing-action__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  /* @noflip */
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-deprecated-chip-trailing-action {
  border: none;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__touch {
  width: 26px;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__icon {
  fill: currentColor;
  color: inherit;
}

.mdc-deprecated-chip-trailing-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--unbounded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-chip-trailing-action__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-chip-trailing-action__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-deprecated-chip-trailing-action:hover .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action.mdc-ripple-surface--hover .mdc-deprecated-chip-trailing-action__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--background-focused .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded):focus .mdc-deprecated-chip-trailing-action__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded) .mdc-deprecated-chip-trailing-action__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded):active .mdc-deprecated-chip-trailing-action__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-deprecated-chip-trailing-action {
  color: #000;
}

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-chip__icon--trailing:hover {
  color: rgba(0, 0, 0, 0.62);
}
.mdc-chip__icon--trailing:focus {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.mdc-deprecated-chip-trailing-action__icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mdc-deprecated-chip-trailing-action {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-deprecated-chip-trailing-action, .mdc-deprecated-chip-trailing-action[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px;
}

.mdc-chip__icon--trailing {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-chip__icon--trailing, .mdc-chip__icon--trailing[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px;
}

.mdc-chip {
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  height: 32px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-width: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.mdc-chip .mdc-chip__ripple {
  border-radius: 16px;
}
.mdc-chip:hover {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px;
}
[dir=rtl] .mdc-chip.mdc-chip--selected .mdc-chip__checkmark, .mdc-chip.mdc-chip--selected .mdc-chip__checkmark[dir=rtl],
[dir=rtl] .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden),
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir=rtl] {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-chip .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-chip::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-chip:hover {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-chip .mdc-chip__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0;
}

.mdc-chip__overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mdc-chip__text {
  white-space: nowrap;
}

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
}

.mdc-chip__checkmark {
  height: 20px;
}

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-chip__primary-action:focus {
  outline: none;
}

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-chip__icon--leading,
.mdc-chip__icon--trailing {
  position: relative;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(98, 0, 238, 0.54);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #6200ee;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #6200ee);
}
.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px;
}

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
  transition: opacity 75ms linear;
  transition-delay: 80ms;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
  transition: width 0ms;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 0;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 20px;
}

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-chip .mdc-chip__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-chip .mdc-chip__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-chip.mdc-ripple-upgraded--unbounded .mdc-chip__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-chip.mdc-ripple-upgraded--foreground-activation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-chip.mdc-ripple-upgraded--foreground-deactivation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-chip .mdc-chip__ripple::before, .mdc-chip .mdc-chip__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-chip:hover .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-surface--hover .mdc-chip__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-chip.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-chip:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-chip:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-chip.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-chip .mdc-chip__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-surface--hover .mdc-chip__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.mdc-chip-set .mdc-chip {
  margin: 4px;
}
.mdc-chip-set .mdc-chip--touch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1);
}

.lmnt.mdc-chip {
  background-color: var(--lmnt-chip-selected-fill-color);
  border-color: transparent;
  border-radius: var(--lmnt-chip-shape-radius);
  border-style: solid;
  border-width: 1px;
}
.lmnt.mdc-chip .mdc-chip__ripple {
  border-radius: var(--lmnt-chip-ripple-shape-radius);
}
.lmnt.mdc-chip .mdc-chip__ripple:before, .lmnt.mdc-chip .mdc-chip__ripple:after {
  border-radius: var(--lmnt-chip-ripple-before-after-shape-radius, 4px);
}
.lmnt.mdc-chip.mdc-chip--outline {
  background-color: #fff;
  border-color: var(--lmnt-theme-on-surface-stroke);
  color: var(--lmnt-chip-outlined-color);
}
.lmnt.mdc-chip.lmnt-chip--disabled, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--trailing, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading {
  pointer-events: none;
  color: var(--lmnt-theme-on-surface-disabled);
  background-color: var(--lmnt-theme-surface-variant);
}
.lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__checkmark, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--trailing .mdc-chip__checkmark, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__checkmark {
  opacity: var(--lmnt-theme-emphasis-disabled);
}
.lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__ripple, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--trailing .mdc-chip__ripple, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__ripple {
  background-color: var(--lmnt-theme-surface-variant);
  opacity: 0.12;
}
.lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__ripple::before, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__ripple::after, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--trailing .mdc-chip__ripple::before, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--trailing .mdc-chip__ripple::after, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__ripple::before, .lmnt.mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__ripple::after {
  background-color: var(--lmnt-theme-surface-variant);
}

.lmnt.mdc-chip-set--filter .mdc-chip--outline.mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled),
.lmnt.mdc-chip-set--filter .mdc-chip--outline.mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover,
.lmnt.mdc-chip-set--choice .mdc-chip--outline.mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled),
.lmnt.mdc-chip-set--choice .mdc-chip--outline.mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover {
  border-color: rgba(103, 58, 183, 0.24);
}

.mdc-chip-set--input-scroll {
  flex-wrap: nowrap;
}

.mdc-chip-set-scrollable-wrapper {
  display: flex;
  overflow-x: auto;
}

.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled,
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading {
  pointer-events: none;
  color: var(--lmnt-theme-on-surface-disabled);
  background-color: var(--lmnt-theme-surface-variant);
}
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__checkmark,
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__checkmark {
  opacity: var(--lmnt-theme-emphasis-disabled);
}
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__ripple,
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__ripple {
  background-color: var(--lmnt-theme-surface-variant);
  opacity: 0.12;
}
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__ripple::before, .lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__ripple::after,
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__ripple::before,
.lmnt.mdc-chip-set--choice .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__ripple::after {
  background-color: var(--lmnt-theme-surface-variant);
}
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled),
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover {
  color: var(--lmnt-theme-primary-on-surface);
  background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
}
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__icon--leading,
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover .mdc-chip__icon--leading {
  color: var(--lmnt-theme-primary-on-surface);
}
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__ripple,
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover .mdc-chip__ripple {
  background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
  opacity: 0.12;
}
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__ripple::before, .lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__ripple::after,
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover .mdc-chip__ripple::before,
.lmnt.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled):hover .mdc-chip__ripple::after {
  background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
}

.lmnt.mdc-chip-set--filter .mdc-chip.lmnt-chip--disabled,
.lmnt.mdc-chip-set--filter .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading {
  pointer-events: none;
  color: var(--lmnt-theme-on-surface-disabled);
  background-color: var(--lmnt-theme-surface-variant);
}
.lmnt.mdc-chip-set--filter .mdc-chip.lmnt-chip--disabled .mdc-chip__checkmark,
.lmnt.mdc-chip-set--filter .mdc-chip.lmnt-chip--disabled .mdc-chip__icon--leading .mdc-chip__checkmark {
  opacity: var(--lmnt-theme-emphasis-disabled);
}
.lmnt.mdc-chip-set--filter .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) {
  color: var(--lmnt-theme-primary-on-surface);
  background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
}
.lmnt.mdc-chip-set--filter .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__ripple {
  background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
  opacity: 0.12;
}
.lmnt.mdc-chip-set--filter .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__ripple::before, .lmnt.mdc-chip-set--filter .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__ripple::after {
  background-color: rgba(var(--lmnt-primary-on-surface-rgb), 0.12);
}
.lmnt.mdc-chip-set--filter .mdc-chip.mdc-chip--selected:not(.lmnt-chip--disabled) .mdc-chip__checkmark-path {
  color: var(--lmnt-theme-primary-on-surface);
  stroke: var(--lmnt-theme-primary-on-surface);
}

.mdc-circular-progress__determinate-circle,
.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #6200ee;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #6200ee);
}

.mdc-circular-progress__determinate-track {
  stroke: transparent;
}

@keyframes mdc-circular-progress-container-rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
@keyframes mdc-circular-progress-color-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0.99;
  }
}
@keyframes mdc-circular-progress-color-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-left-spin {
  from {
    transform: rotate(265deg);
  }
  50% {
    transform: rotate(130deg);
  }
  to {
    transform: rotate(265deg);
  }
}
@keyframes mdc-circular-progress-right-spin {
  from {
    transform: rotate(-265deg);
  }
  50% {
    transform: rotate(-130deg);
  }
  to {
    transform: rotate(-265deg);
  }
}
.mdc-circular-progress {
  display: inline-flex;
  position: relative;
  /* @noflip */
  direction: ltr;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-circular-progress__determinate-container,
.mdc-circular-progress__indeterminate-circle-graphic,
.mdc-circular-progress__indeterminate-container,
.mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-container {
  transform: rotate(-90deg);
}

.mdc-circular-progress__indeterminate-container {
  font-size: 0;
  letter-spacing: 0;
  white-space: nowrap;
  opacity: 0;
}

.mdc-circular-progress__determinate-circle-graphic,
.mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-circle {
  transition: stroke-dashoffset 500ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-circular-progress__gap-patch {
  position: absolute;
  top: 0;
  /* @noflip */
  left: 47.5%;
  box-sizing: border-box;
  width: 5%;
  height: 100%;
  overflow: hidden;
}
.mdc-circular-progress__gap-patch .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */
  left: -900%;
  width: 2000%;
  transform: rotate(180deg);
}

.mdc-circular-progress__circle-clipper {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic {
  width: 200%;
}

.mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */
  left: -100%;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__determinate-container {
  opacity: 0;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  opacity: 1;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  animation: mdc-circular-progress-container-rotate 1568.2352941176ms linear infinite;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__spinner-layer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-1 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-2 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-3 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-4 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-left .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */
  animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */
  animation: mdc-circular-progress-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--closed {
  opacity: 0;
}

.lmnt-circular-progress--primary .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--primary .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-primary);
}

.lmnt-circular-progress--primary-variant .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--primary-variant .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-primary-variant);
}

.lmnt-circular-progress--secondary .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--secondary .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-secondary);
}

.lmnt-circular-progress--secondary-variant .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--secondary-variant .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-secondary-variant);
}

.lmnt-circular-progress--dark .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--dark .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-dark);
}

.lmnt-circular-progress--light .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--light .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-light);
}

.lmnt-circular-progress--on-primary .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--on-primary .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-on-primary);
}

.lmnt-circular-progress--on-secondary .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--on-secondary .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-on-secondary);
}

.lmnt-circular-progress--on-success .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--on-success .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-on-success);
}

.lmnt-circular-progress--on-danger .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--on-danger .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-on-danger);
}

.lmnt-circular-progress--on-unknown-dark .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--on-unknown-dark .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-light);
}

.lmnt-circular-progress--on-unknown-light .mdc-circular-progress__determinate-circle,
.lmnt-circular-progress--on-unknown-light .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-dark);
}

.lmnt-circular-progress--two-color .mdc-circular-progress__color-1 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-primary);
}
.lmnt-circular-progress--two-color .mdc-circular-progress__color-2 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-secondary);
}
.lmnt-circular-progress--two-color .mdc-circular-progress__color-3 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-primary);
}
.lmnt-circular-progress--two-color .mdc-circular-progress__color-4 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-secondary);
}

.lmnt-circular-progress--two-color-variant .mdc-circular-progress__color-1 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-primary-variant);
}
.lmnt-circular-progress--two-color-variant .mdc-circular-progress__color-2 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-secondary-variant);
}
.lmnt-circular-progress--two-color-variant .mdc-circular-progress__color-3 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-primary-variant);
}
.lmnt-circular-progress--two-color-variant .mdc-circular-progress__color-4 .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--lmnt-theme-secondary-variant);
}

.lmnt-circularProgress--fill-primary {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}

.lmnt-circularProgress--fill-secondary {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-circularProgress--fill-success {
  background-color: var(--lmnt-theme-success);
  color: var(--lmnt-theme-on-success);
}

.lmnt-circularProgress--fill-danger {
  background-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-on-danger);
}

.lmnt-datepicker {
  position: relative;
  text-align: left;
}

.lmnt {
  --lmnt-datepicker---base-color: var(--lmnt-theme-primary);
  --lmnt-datepicker--border-radius: var(--lmnt-theme--shape-radius);
  --lmnt-datepicker--border-width: 1px;
  --lmnt-datepicker--cell-border-radius: 50%;
  --lmnt-datepicker--side-content-width: var(--lmnt-datepicker--width);
  --lmnt-datepicker--width: 334px;
  --lmnt-datepicker-surface: var(--lmnt-theme-surface);
  --lmnt-datepicker__cell-height: 40px;
  --lmnt-datepicker__cell-width: 40px;
  --lmnt-datepicker__day--highlighted: var(--lmnt-theme-primary-50);
}

.lmnt-datepicker__calendar {
  background-color: var(--lmnt-datepicker-surface);
  border-color: var(--lmnt-theme-on-surface-stroke);
  border-radius: var(--lmnt-datepicker--border-radius);
  border-style: solid;
  border-width: var(--lmnt-datepicker--border-width);
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  user-select: none;
  width: var(--lmnt-datepicker--width);
  z-index: 2;
}

.lmnt-datepicker__calendar--full-width {
  max-width: unset;
  min-width: unset;
  width: 100%;
}

.lmnt-datepicker__calendar.lmnt-datepicker--floating {
  border-top-left-radius: 0;
  position: absolute;
}

.lmnt-datepicker__content {
  width: var(--lmnt-datepicker--width);
}

.lmnt-datepicker__calendar-header {
  align-items: center;
  border: unset;
  border-bottom-color: var(--lmnt-theme-on-surface-stroke);
  border-bottom-style: solid;
  border-bottom-width: var(--lmnt-datepicker--border-width);
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: center;
  margin: unset;
  padding: unset;
  padding-bottom: 12px;
  padding-top: 12px;
}

.lmnt-datepicker__calendar-header--month-year {
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 0;
}

.lmnt-select-container.lmnt-datepicker-calender__month-select {
  width: 140px;
}

.lmnt-select-container.lmnt-datepicker-calender__year-select {
  width: 98px;
}

.lmnt-datepicker__calendar-surface {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 4px;
  width: 280px;
}

.lmnt-datepicker__day-header.mdc-typography--caption {
  align-items: center;
  background-color: unset;
  border-color: transparent;
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  border-style: solid;
  border-width: 1.5px;
  box-sizing: border-box;
  color: var(--lmnt-on-surface-disabled);
  cursor: default;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: var(--lmnt-datepicker__cell-height);
  justify-content: center;
  line-height: 16px;
  margin: unset;
  padding: unset;
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: var(--lmnt-datepicker__cell-width);
}

.lmnt-datepicker__cell {
  background-color: unset;
  border-color: transparent;
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, calc(1 - 0.08));
  cursor: pointer;
  font-size: 12px;
  height: var(--lmnt-datepicker__cell-height);
  line-height: 2.5;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: var(--lmnt-datepicker__cell-width);
}

.lmnt-datepicker__cell:hover::after,
.lmnt-datepicker__cell--hover::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell:active::after,
.lmnt-datepicker__cell--active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell:focus::after,
.lmnt-datepicker__cell--focus::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-datepicker-surface);
  opacity: var(--lmnt-theme-emphasis-focus--alt);
}

.lmnt-datepicker__cell--today {
  background-color: unset;
  border-width: 1px;
  border-color: var(--lmnt-theme-on-surface-stroke);
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-on-surface-highlighted));
}

.lmnt-datepicker__cell--today:hover::after,
.lmnt-datepicker__cell--today--hover::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 40px;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-focus);
}

.lmnt-datepicker__cell--today:active::after,
.lmnt-datepicker__cell--today--active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 40px;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--today:focus::after,
.lmnt-datepicker__cell--today--focus::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 40px;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-focus--alt);
}

.lmnt-datepicker__cell--selected {
  background-color: var(--lmnt-datepicker---base-color);
  border: unset;
  color: var(--lmnt-theme-on-primary);
}

.lmnt-datepicker__cell--selected:hover::after,
.lmnt-datepicker__cell--selected--hover::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell--selected:active::after,
.lmnt-datepicker__cell--selected--active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--selected:focus::after,
.lmnt-datepicker__cell--selected--focus::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-focus--alt);
}

.lmnt-datepicker__cell--highlighted {
  background-color: var(--lmnt-datepicker__day--highlighted);
  border-radius: 0;
  border-width: 0;
}

.lmnt-datepicker__cell--highlight-start {
  border-bottom-left-radius: var(--lmnt-datepicker--cell-border-radius);
  border-top-left-radius: var(--lmnt-datepicker--cell-border-radius);
}

.lmnt-datepicker__cell--highlight-end {
  border-bottom-right-radius: var(--lmnt-datepicker--cell-border-radius);
  border-top-right-radius: var(--lmnt-datepicker--cell-border-radius);
}

.lmnt-datepicker__cell--highlighted:hover::after,
.lmnt-datepicker__cell--highlighted--hover::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-focus);
}

.lmnt-datepicker__cell--highlighted:active::after,
.lmnt-datepicker__cell--highlighted--active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--highlighted:focus::after,
.lmnt-datepicker__cell--highlighted--focus::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-datepicker---base-color);
  opacity: var(--lmnt-theme-emphasis-focus--alt);
}

.lmnt-datepicker__cell.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today:before {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border: solid 2px rgba(0, 0, 0, var(--lmnt-theme-emphasis-stroke-dark));
}

.lmnt-datepicker__cell.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today:hover::after,
.lmnt-datepicker__cell.lmnt-datepicker__cell--selected--hover.lmnt-datepicker__cell--today--hover::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 40px;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today:active::after,
.lmnt-datepicker__cell--selected--active.lmnt-datepicker__cell--today--active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--selected:focus.lmnt-datepicker__cell--today::after,
.lmnt-datepicker__cell--selected--focus.lmnt-datepicker__cell--today--focus::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-focus--alt);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected::before {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  align-items: center;
  content: attr(data-day);
  color: inherit;
  display: flex;
  justify-content: center;
  background-color: var(--lmnt-datepicker---base-color);
  border: solid 2px rgba(0, 0, 0, var(--lmnt-theme-emphasis-stroke-dark));
  color: var(--lmnt-theme-on-primary);
}

.lmnt-datepicker__cell.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected:hover::after,
.lmnt-datepicker__cell.lmnt-datepicker__cell--highlighted--hover.lmnt-datepicker__cell--selected--hover::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 40px;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-hover--alt);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected:active::after,
.lmnt-datepicker__cell--highlighted--active.lmnt-datepicker__cell--selected--active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-pressed--alt);
}

.lmnt-datepicker__cell--highlighted:focus.lmnt-datepicker__cell--selected::after,
.lmnt-datepicker__cell--highlighted--focus.lmnt-datepicker__cell--selected--focus::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  opacity: var(--lmnt-theme-emphasis-focus--alt);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today::before {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  align-items: center;
  content: attr(data-day);
  color: inherit;
  display: flex;
  justify-content: center;
  border: solid 1px var(--lmnt-theme-on-surface-stroke);
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-on-surface-highlighted));
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today::before {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  align-items: center;
  content: attr(data-day);
  color: inherit;
  display: flex;
  justify-content: center;
  background-color: var(--lmnt-datepicker---base-color);
  border: solid 1px var(--lmnt-theme-on-surface-stroke);
  color: var(--lmnt-theme-on-primary);
}

.lmnt-datepicker__cell--blank,
.lmnt-datepicker__cell--blank:hover,
.lmnt-datepicker__cell--blank:hover::after,
.lmnt-datepicker__cell--blank:active,
.lmnt-datepicker__cell--blank:active::after,
.lmnt-datepicker__cell--blank:focus,
.lmnt-datepicker__cell--blank:focus::after {
  background-color: unset;
  color: unset;
  cursor: default;
}

.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
}

.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
  height: 40px;
  left: -1px;
  top: -1px;
  width: 40px;
}

.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
}

.lmnt-datepicker__cell--today.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--today.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--today.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--today.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
}

.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:hover::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:focus::after,
.lmnt-datepicker__cell--highlighted.lmnt-datepicker__cell--selected.lmnt-datepicker__cell--today.lmnt-datepicker__cell--disabled:active::after {
  border-radius: var(--lmnt-datepicker--cell-border-radius);
  box-sizing: border-box;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--lmnt-theme-on-primary);
  cursor: default;
  opacity: var(--lmnt-theme-emphasis-on-surface-disabled);
}

.lmnt-datepicker__calendar.lmnt-datepicker__calendar--with-side-content {
  box-sizing: border-box;
  max-width: calc( var(--lmnt-datepicker--width) + var(--lmnt-datepicker--side-content-width) );
  min-width: calc( var(--lmnt-datepicker--width) + var(--lmnt-datepicker--side-content-width) );
  width: calc( var(--lmnt-datepicker--width) + var(--lmnt-datepicker--side-content-width) );
}

.lmnt-datepicker__side-content {
  max-width: var(--lmnt-datepicker--side-content-width);
  min-width: var(--lmnt-datepicker--side-content-width);
  width: var(--lmnt-datepicker--side-content-width);
}

.lmnt-datepicker--fullWidth {
  width: 100%;
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}
.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__surface-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog .mdc-dialog__close {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-dialog .mdc-dialog__close::before, .mdc-dialog .mdc-dialog__close::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-dialog .mdc-dialog__close:hover::before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused::before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}
.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}
@media (max-width: 960px) and (max-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    max-width: 560px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (max-width: 600px) and (max-height: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (min-width: 960px) and (min-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 400px);
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}
.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
[dir=rtl] .mdc-dialog__surface, .mdc-dialog__surface[dir=rtl] {
  /* @noflip */
  text-align: right;
}
@media screen and (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}
.mdc-dialog__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface::before {
    content: none;
  }
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: 0 24px 9px;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-dialog__title, .mdc-dialog__title[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  display: inline-flex;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent;
  justify-content: space-between;
  align-items: baseline;
}
.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}
.mdc-dialog--fullscreen .mdc-dialog__title {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}
.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}
.mdc-dialog__button:first-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  text-align: left;
}
.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog__surface-scrim {
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.lmnt {
  --lmnt-dialog-shape-radius: var(--lmnt-theme--shape-radius);
  --lmnt-dialog__surface--max-width: 600px;
  --lmnt-dialog__surface--min-width: 600px;
}
.lmnt .lmnt-dialog.mdc-dialog {
  z-index: 9;
}
.lmnt .lmnt-dialog.mdc-dialog .mdc-dialog__surface {
  border-radius: var(--lmnt-dialog-shape-radius);
}
.lmnt .mdc-dialog__surface {
  max-width: var(--lmnt-dialog__surface--max-width);
  min-width: var(--lmnt-dialog__surface--min-width);
}
.lmnt .lmnt .mdc-dialog__title + .mdc-dialog__content,
.lmnt .lmnt .mdc-dialog__header + .mdc-dialog__content {
  padding-top: 8px;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-bottom-left-radius: 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-deprecated-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #6200ee;
}
.mdc-drawer .mdc-deprecated-list-item--activated {
  color: rgba(98, 0, 238, 0.87);
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 0;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-large, 0);
}
.mdc-drawer .mdc-deprecated-list-item {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */
  margin-left: 256px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 256px;
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-right-width: 0;
  /* @noflip */
  border-left-width: 1px;
  /* @noflip */
  border-right-style: none;
  /* @noflip */
  border-left-style: solid;
}
.mdc-drawer .mdc-deprecated-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}
.mdc-drawer .mdc-deprecated-list-item:nth-child(1) {
  margin-top: 2px;
}
.mdc-drawer .mdc-deprecated-list-item:nth-last-child(1) {
  margin-bottom: 0;
}
.mdc-drawer .mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}
.mdc-drawer .mdc-deprecated-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer .mdc-deprecated-list-divider {
  margin: 3px 0 4px;
}
.mdc-drawer .mdc-deprecated-list-item__text,
.mdc-drawer .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}
[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}
[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  transform: translateX(0);
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}
[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}
.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
}
[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative;
}
[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed;
}
.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}
.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}
.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}
.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-drawer .mdc-list-item--selected,
.mdc-drawer .mdc-list-item--activated {
  --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}

.mdc-drawer .mdc-list-item--selected,
.mdc-drawer .mdc-list-item--activated {
  color: var(--lmnt-theme-primary-on-surface);
}
.mdc-drawer .mdc-list-item--selected .mdc-list-item__graphic,
.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
  color: var(--lmnt-theme-primary-on-surface);
}
.mdc-drawer .mdc-list-item__ripple .mdc-list-item__ripple::before,
.mdc-drawer .mdc-list-item__ripple :not(.mdc-list-item--disabled).mdc-list-item--activated,
.mdc-drawer .mdc-list-item__ripple .mdc-list-item__ripple::after {
  background-color: var(--lmnt-theme-primary);
}

.mdc-drawer__header-avatar {
  border-radius: 50%;
  height: 35px;
  margin-top: 12px;
  width: auto;
}

.mdc-drawer.lmnt-drawer--permanent:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  margin-left: 256px;
}

.lmnt-drawer--permanent {
  position: fixed;
}

.lmnt-expansion-panel__content {
  overflow: hidden;
  transition-duration: 175ms;
  transition-property: max-height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.lmnt-expansion-panel__trigger {
  cursor: pointer;
}

.lmnt.lmnt-file-upload .lmnt-file-upload__input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  height: 36px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.lmnt.lmnt-file-upload .lmnt-file-upload__file-url {
  background-color: transparent;
  border: none;
  color: var(--lmnt-theme-on-surface);
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
}
.lmnt.lmnt-file-upload.lmnt-file-upload--secondary .lmnt-file-upload__file-url {
  color: #fff;
}
.lmnt.lmnt-file-upload.lmnt-file-upload--secondary .lmnt-file-upload__file-url::placeholder {
  color: rgba(255, 255, 255, 0.87);
}

.lmnt {
  --lmnt-footer__cols-sm: 1;
  --lmnt-footer__cols-md: 3;
  --lmnt-footer__cols-lg: 3;
  --lmnt-footer__bottom-cols-sm: 1;
  --lmnt-footer__bottom-cols-md: 3;
  --lmnt-footer__bottom-cols-lg: 3;
}

.lmnt-footer {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.lmnt-footer__content-wrapper {
  padding-bottom: 64px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 64px;
  width: 100%;
}

.lmnt-footer__content {
  align-content: center;
  display: grid;
  grid-template-columns: repeat(var(--lmnt-footer__cols-lg), 1fr);
  justify-content: center;
}

.lmnt-footer__list {
  width: fit-content;
}

.lmnt-footer__list-title {
  margin: 0;
}

.lmnt-footer__list-item, .lmnt-footer__list dd:not(.lmnt-footer__list-item) {
  margin: 0;
  padding-top: 24px;
  list-style-type: none;
}

.lmnt-footer__bottom-content {
  font-size: 14px;
  padding-left: 2.5em;
}

.lmnt-footer__list dd:not(.lmnt-footer__list-item) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.lmnt-footer hr,
.lmnt-footer__divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0;
}

.lmnt-footer__bottom {
  align-items: center;
  display: grid;
  gap: 64px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  padding-left: 32px;
  padding-right: 32px;
}

.lmnt-footer__bottom-group {
  display: flex;
  flex: 1;
  font-size: 14px;
  gap: 35px;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 32px;
  white-space: nowrap;
  width: 100%;
}

.lmnt-footer__bottom-group--margin-dense {
  margin-bottom: 16px;
  margin-top: 16px;
}

.lmnt-footer__bottom-group--margin-airy {
  margin-bottom: 64px;
  margin-top: 64px;
}

.lmnt-footer__bottom-group--start {
  justify-content: start;
}

.lmnt-footer__bottom-group--end {
  justify-content: end;
}

.lmnt-footer__bottom-group--gap-none {
  gap: 0;
}

.lmnt-footer__bottom-group--gap-dense {
  gap: 8px;
}

.lmnt-footer__bottom-group--gap-standard {
  gap: 16px;
}

.lmnt-footer__bottom-group--gap-airy {
  gap: 24px;
}

@media only screen and (min-width: 0) and (max-width: 719px) {
  .lmnt-footer__content {
    grid-template-columns: repeat(var(--lmnt-footer__cols-sm), 1fr);
    justify-items: start;
  }

  .lmnt-footer__bottom {
    gap: 0;
    grid-template-columns: repeat(var(--lmnt-footer__bottom-cols-sm), 1fr);
  }

  .lmnt-footer__bottom-group {
    align-items: center;
    flex-direction: column;
    justify-items: center;
  }

  .lmnt-footer__content-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .lmnt-footer__column--sm-span-1 {
    grid-column: span 1;
  }

  .lmnt-footer__column--sm-span-2 {
    grid-column: span 2;
  }

  .lmnt-footer__column--sm-span-3 {
    grid-column: span 3;
  }

  .lmnt-footer__column--sm-span-4 {
    grid-column: span 4;
  }

  .lmnt-footer__column--sm-span-5 {
    grid-column: span 5;
  }

  .lmnt-footer__column--sm-span-6 {
    grid-column: span 6;
  }

  .lmnt-footer__column--sm-span-7 {
    grid-column: span 7;
  }

  .lmnt-footer__column--sm-span-8 {
    grid-column: span 8;
  }

  .lmnt-footer__column--sm-span-9 {
    grid-column: span 9;
  }

  .lmnt-footer__column--sm-span-10 {
    grid-column: span 10;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1439px) {
  .lmnt-footer__content {
    grid-template-columns: repeat(var(--lmnt-footer__cols-md), 1fr);
  }

  .lmnt-footer__bottom {
    gap: 0;
    grid-template-columns: repeat(var(--lmnt-footer__bottom-cols-md), 1fr);
  }

  .lmnt-footer__column--md-span-1 {
    grid-column: span 1;
  }

  .lmnt-footer__column--md-span-2 {
    grid-column: span 2;
  }

  .lmnt-footer__column--md-span-3 {
    grid-column: span 3;
  }

  .lmnt-footer__column--md-span-4 {
    grid-column: span 4;
  }

  .lmnt-footer__column--md-span-5 {
    grid-column: span 5;
  }

  .lmnt-footer__column--md-span-6 {
    grid-column: span 6;
  }

  .lmnt-footer__column--md-span-7 {
    grid-column: span 7;
  }

  .lmnt-footer__column--md-span-8 {
    grid-column: span 8;
  }

  .lmnt-footer__column--md-span-9 {
    grid-column: span 9;
  }

  .lmnt-footer__column--md-span-10 {
    grid-column: span 10;
  }
}
@media only screen and (min-width: 1440px) {
  .lmnt-footer__content {
    grid-template-columns: repeat(var(--lmnt-footer__cols-lg), 1fr);
  }

  .lmnt-footer__bottom {
    grid-template-columns: repeat(var(--lmnt-footer__bottom-cols-lg), 1fr);
  }

  .lmnt-footer__column--lg-span-1 {
    grid-column: span 1;
  }

  .lmnt-footer__column--lg-span-2 {
    grid-column: span 2;
  }

  .lmnt-footer__column--lg-span-3 {
    grid-column: span 3;
  }

  .lmnt-footer__column--lg-span-4 {
    grid-column: span 4;
  }

  .lmnt-footer__column--lg-span-5 {
    grid-column: span 5;
  }

  .lmnt-footer__column--lg-span-6 {
    grid-column: span 6;
  }

  .lmnt-footer__column--lg-span-7 {
    grid-column: span 7;
  }

  .lmnt-footer__column--lg-span-8 {
    grid-column: span 8;
  }

  .lmnt-footer__column--lg-span-9 {
    grid-column: span 9;
  }

  .lmnt-footer__column--lg-span-10 {
    grid-column: span 10;
  }
}
@media only screen and (min-width: 840px) and (max-width: 1023px) {
  .lmnt-footer-with-max-content-width {
    align-items: center;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer__content-wrapper {
    max-width: 1052px;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer_bottom-wrapper,
.lmnt-footer-with-max-content-width .lmnt-footer__bottom-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer__bottom,
.lmnt-footer-with-max-content-width .lmnt-footer__content {
    max-width: 1052px;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .lmnt-footer-with-max-content-width {
    align-items: center;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer__content-wrapper {
    max-width: 1052px;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer_bottom-wrapper,
.lmnt-footer-with-max-content-width .lmnt-footer__bottom-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer__bottom,
.lmnt-footer-with-max-content-width .lmnt-footer__content {
    max-width: 1052px;
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .lmnt-footer-with-max-content-width {
    align-items: center;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer__content-wrapper {
    max-width: 1176px;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer_bottom-wrapper,
.lmnt-footer-with-max-content-width .lmnt-footer__bottom-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .lmnt-footer-with-max-content-width .lmnt-footer__bottom,
.lmnt-footer-with-max-content-width .lmnt-footer__content {
    max-width: 1176px;
    width: 100%;
  }
}
:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }

  .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }

  .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }

  .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }

  .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }

  .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }

  .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }

  .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }

  .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }

  .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }

  .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }

  .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }

  .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }

  .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }

  .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }

  .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }

  .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }

  .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }

  .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}
.mdc-layout-grid__cell--order-2 {
  order: 2;
}
.mdc-layout-grid__cell--order-3 {
  order: 3;
}
.mdc-layout-grid__cell--order-4 {
  order: 4;
}
.mdc-layout-grid__cell--order-5 {
  order: 5;
}
.mdc-layout-grid__cell--order-6 {
  order: 6;
}
.mdc-layout-grid__cell--order-7 {
  order: 7;
}
.mdc-layout-grid__cell--order-8 {
  order: 8;
}
.mdc-layout-grid__cell--order-9 {
  order: 9;
}
.mdc-layout-grid__cell--order-10 {
  order: 10;
}
.mdc-layout-grid__cell--order-11 {
  order: 11;
}
.mdc-layout-grid__cell--order-12 {
  order: 12;
}
.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}
.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2 );
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2 );
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2 );
  }
}

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

.lmnt-layout-grid.mdc-grid-cell-wrapper-airy-tablet {
  padding: 24px 32px !important;
}

.lmnt-layout-grid.mdc-grid-cell-wrapper-none-tablet {
  padding: 0 !important;
}

.lmnt-layout-grid.mdc-grid-cell-wrapper-none-desktop {
  padding: 0 !important;
}

.lmnt-layout-grid.mdc-grid-cell-wrapper-none-phone {
  padding: 0 !important;
}

.lmnt-layout-grid__cell--horizontal-align-left {
  justify-content: flex-start;
}

.lmnt-layout-grid__cell--horizontal-align-right {
  justify-content: flex-end;
}

.lmnt-layout-grid__cell--horizontal-align-center {
  justify-content: center;
}

.lmnt-layout-grid .mdc-layout-grid__cell.lmnt-layout-grid__cell {
  display: flex;
}
.lmnt-layout-grid .mdc-layout-grid__cell.lmnt-layout-grid__cell.lmnt-layout-grid__cell--vertical-align-top {
  align-items: flex-start;
}
.lmnt-layout-grid .mdc-layout-grid__cell.lmnt-layout-grid__cell.lmnt-layout-grid__cell--vertical-align-middle {
  align-items: center;
}
.lmnt-layout-grid .mdc-layout-grid__cell.lmnt-layout-grid__cell.lmnt-layout-grid__cell--vertical-align-bottom {
  align-items: flex-end;
}

.lmnt-layout-grid--full-width {
  width: 100%;
}

.lmnt-layout-grid--full-height {
  height: 100%;
}

@media only screen and (min-width: 720px) and (max-width: 839px) {
  .lmnt-layout-grid--with-max-width {
    max-width: 1052px;
  }

  .lmnt {
    --mdc-layout-grid-gutter-tablet: 24px;
  }

  .mdc-grid-cell-wrapper-airy-tablet {
    padding: 24px 32px !important;
  }
}
@media only screen and (min-width: 840px) and (max-width: 1023px) {
  .lmnt-layout-grid--with-max-width {
    max-width: 1052px;
  }

  .mdc-grid-cell-wrapper-airy-desktop {
    padding: 24px 48px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .lmnt-layout-grid--with-max-width {
    max-width: 1052px;
  }

  .mdc-grid-cell-wrapper-airy-desktop {
    padding: 24px 64px !important;
  }
}
@media only screen and (min-width: 1440px) {
  .lmnt-layout-grid--with-max-width {
    max-width: 1176px;
  }

  .mdc-grid-cell-wrapper-airy-desktop {
    padding: 24px 80px !important;
  }
}
.lmnt.lmnt-hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  position: relative;
  z-index: 0;
}
.lmnt.lmnt-hero.lmnt-hero__align-left-top {
  align-items: flex-start;
  justify-content: flex-start;
}
.lmnt.lmnt-hero.lmnt-hero__align-left-center {
  align-items: center;
  justify-content: flex-start;
}
.lmnt.lmnt-hero.lmnt-hero__align-left-bottom {
  align-items: flex-end;
  justify-content: flex-start;
}
.lmnt.lmnt-hero.lmnt-hero__align-center-bottom {
  align-items: flex-end;
  justify-content: center;
}
.lmnt.lmnt-hero.lmnt-hero__align-center-top {
  align-items: flex-start;
  justify-content: center;
}
.lmnt.lmnt-hero.lmnt-hero__align-centered {
  align-items: center;
  justify-content: center;
}
.lmnt.lmnt-hero.lmnt-hero__align-right-top {
  align-items: flex-start;
  justify-content: flex-end;
}
.lmnt.lmnt-hero.lmnt-hero__align-right-center {
  align-items: center;
  justify-content: flex-end;
}
.lmnt.lmnt-hero.lmnt-hero__align-right-bottom {
  align-items: flex-end;
  justify-content: flex-end;
}
.lmnt.lmnt-hero .lmnt-hero-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.lmnt.lmnt-hero .lmnt-hero-overlay.lmnt-hero-overlay__dark {
  background-color: var(--lmnt-theme-on-unknown-black-inactive);
}
.lmnt.lmnt-hero .lmnt-hero-overlay.lmnt-hero-overlay__light {
  background-color: var(--lmnt-theme-on-unknown-white-inactive);
}
.lmnt.lmnt-hero .lmnt-hero-overlay.lmnt-hero-overlay__leading {
  width: 50%;
  justify-self: flex-start;
}
.lmnt.lmnt-hero .lmnt-hero-overlay.lmnt-hero-overlay__trailing {
  width: 50%;
  justify-self: flex-end;
}
.lmnt.lmnt-hero .lmnt-hero-content {
  z-index: 2;
}

.lmnt-icon-pointer {
  cursor: pointer;
}

.lmnt-icon--fill {
  border-radius: 50%;
  padding: 8px;
}

.lmnt-icon--fill-danger-alt {
  background-color: var(--lmnt-theme-danger-alt);
  color: var(--lmnt-theme-on-danger-alt);
}

.lmnt-icon--fill-danger {
  background-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-on-danger);
}

.lmnt-icon--fill-primary {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}

.lmnt-icon--fill-secondary {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-icon--color-primary {
  color: var(--lmnt-theme-primary);
}

.lmnt-icon--color-secondary {
  color: var(--lmnt-theme-secondary);
}

.lmnt-icon--color-on-dark {
  color: #fff;
}

.lmnt-icon--fill-muted {
  background-color: rgba(0, 0, 0, 0.12);
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-icon--utility-blue {
  background-color: #c2e0ff;
  color: #0b3f73;
}

.lmnt-icon--utility-gray {
  background-color: #e6e6e6;
  color: #474747;
}

.lmnt-icon--utility-green {
  background-color: #b8f2c7;
  color: #084d19;
}

.lmnt-icon--utility-orange {
  background-color: #ffe6b2;
  color: #593f09;
}

.lmnt-icon--utility-purple {
  background-color: #dfcbf2;
  color: #400b73;
}

.lmnt-icon--utility-red {
  background-color: #ffd6d6;
  color: #730b0b;
}

.lmnt-icon--utility-teal {
  background-color: #b8f2ed;
  color: #084d47;
}

.lmnt-icon--utility-yellow {
  background-color: #f2f2b6;
  color: #404006;
}

.lmnt .lmnt-icon--medium {
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.lmnt .lmnt-icon--xlarge {
  font-size: 48px;
  height: 48px;
  width: 48px;
}

.lmnt .lmnt-icon--large {
  font-size: 36px;
  height: 36px;
  width: 36px;
}

.lmnt .lmnt-icon--small {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.lmnt .lmnt-icon--xsmall {
  font-size: 12px;
  height: 12px;
  width: 12px;
}

.lmnt-icon--notification-badge {
  position: relative;
}
.lmnt-icon--notification-badge .lmnt-badge--notification {
  position: absolute;
  top: -3px;
  left: 17px;
}
.lmnt-icon--notification-badge .lmnt-badge--notification.lmnt-badge--notification-badge-counter {
  top: -6px;
  left: 15px;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover::before, .mdc-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.lmnt {
  --lmnt-icon-button-size: 40px;
  --lmnt-icon-button-padding: 8px;
}

.lmnt-icon-button.mdc-icon-button {
  width: var(--lmnt-icon-button-size);
  height: var(--lmnt-icon-button-size);
  padding: var(--lmnt-icon-button-padding);
  border-radius: 50%;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger {
  background-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-on-danger);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger::after {
  background-color: currentColor;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger-alt {
  background-color: var(--lmnt-theme-danger-alt);
  color: var(--lmnt-theme-on-danger-alt);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger-alt::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger-alt::after {
  background-color: currentColor;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-danger-alt:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-primary {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-primary::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-primary::after {
  background-color: currentColor;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-primary:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-secondary {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-secondary::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-secondary::after {
  background-color: currentColor;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--fill-secondary:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-on-dark {
  background-color: transparent;
  color: #fff;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-on-dark::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-on-dark::after {
  background-color: currentColor;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-primary {
  background-color: transparent;
  color: var(--lmnt-theme-primary);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-primary::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-primary::after {
  background-color: currentColor;
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-secondary {
  background-color: transparent;
  color: var(--lmnt-theme-secondary);
}
.lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-secondary::before, .lmnt-icon-button.mdc-icon-button.lmnt-icon-button--color-secondary::after {
  background-color: currentColor;
}

.lmnt-icon-button.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lmnt-icon-button.mdc-icon-button:disabled .material-icons-outlined,
.lmnt-icon-button.mdc-icon-button:disabled .material-icons-round,
.lmnt-icon-button.mdc-icon-button:disabled .material-icons-two-tone,
.lmnt-icon-button.mdc-icon-button:disabled .material-icons-sharp,
.lmnt-icon-button.mdc-icon-button:disabled .material-icons {
  color: rgba(0, 0, 0, 0.38);
}
.lmnt-icon-button.mdc-icon-button:disabled.lmnt-icon-button--on-dark {
  color: rgba(255, 255, 255, 0.38);
}
.lmnt-icon-button.mdc-icon-button:disabled.lmnt-icon-button--on-dark .material-icons-outlined,
.lmnt-icon-button.mdc-icon-button:disabled.lmnt-icon-button--on-dark .material-icons-round,
.lmnt-icon-button.mdc-icon-button:disabled.lmnt-icon-button--on-dark .material-icons-two-tone,
.lmnt-icon-button.mdc-icon-button:disabled.lmnt-icon-button--on-dark .material-icons-sharp,
.lmnt-icon-button.mdc-icon-button:disabled.lmnt-icon-button--on-dark .material-icons {
  color: rgba(255, 255, 255, 0.38);
}

.lmnt-icon-button--fill.mdc-icon-button.lmnt-icon-button--fill-danger-alt:disabled {
  background-color: rgba(255, 255, 255, 0.24);
  color: rgba(255, 255, 255, 0.38);
}

.mdc-icon-button.lmnt-icon-button--dense {
  width: 32px;
  height: 32px;
  padding: 4px;
}

.lmnt-icon-button--notification-badge.lmnt-badge--notification {
  position: relative;
  top: -27px;
  right: -15px;
  color: #fff;
}

.lmnt.lmnt-badge.lmnt-icon-button--notification-badge:not(:empty).lmnt-badge--notification {
  padding: unset;
  top: -30px;
  max-height: unset;
  padding-left: 6px;
  padding-right: 6px;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  -webkit-transform-origin: left top;
  /* @noflip */
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  -webkit-transform-origin: right top;
  /* @noflip */
  transform-origin: right top;
  /* @noflip */
  text-align: right;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */
  margin-left: 1px;
  /* @noflip */
  margin-right: 0px;
  content: "*";
}
[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}
.mdc-line-ripple::before {
  border-bottom-width: 1px;
  z-index: 1;
}
.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}
.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
}
.mdc-notched-outline__trailing {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
}
.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75);
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}

.lmnt .mdc-line-ripple {
  height: unset;
  opacity: unset;
  position: unset;
  transform: unset;
  transition: unset;
  width: unset;
  z-index: unset;
}

/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important, no-descending-specificity */
/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important, no-descending-specificity */
:root,
.lmnt {
  --lmnt-helper-text-color: rgba(0, 0, 0, 0.6);
  --lmnt-textfield-fill-color: whitesmoke;
  --lmnt-text-field-primary: var(--lmnt-theme-primary-on-surface);
  --lmnt-text-field-shape-radius: var(--lmnt-theme--shape-radius);
  --lmnt-filled-text-field-shape-radius: 0;
}

.mdc-text-field--filled, .lmnt .lmnt-text-field--embedded {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-text-field--filled .mdc-text-field__ripple::before, .lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after,
.lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field--filled .mdc-text-field__ripple::before, .lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-text-field--filled .mdc-text-field__ripple::after, .lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::before, .lmnt .mdc-ripple-upgraded.lmnt-text-field--embedded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after, .lmnt .mdc-ripple-upgraded.lmnt-text-field--embedded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field--filled.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after, .lmnt .mdc-ripple-upgraded--unbounded.lmnt-text-field--embedded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after, .lmnt .mdc-ripple-upgraded--foreground-activation.lmnt-text-field--embedded .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after, .lmnt .mdc-ripple-upgraded--foreground-deactivation.lmnt-text-field--embedded .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled .mdc-text-field__ripple::before, .lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after,
.lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after, .lmnt .mdc-ripple-upgraded.lmnt-text-field--embedded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #6200ee;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}
.mdc-text-field__input::-ms-clear {
  display: none;
}
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: none;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}
@media all {
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field__input:-ms-input-placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}

.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  white-space: nowrap;
}
.mdc-text-field--label-floating .mdc-text-field__affix, .mdc-text-field--no-label .mdc-text-field__affix {
  opacity: 1;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--outlined .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field__affix--prefix, .mdc-text-field__affix--prefix[dir=rtl] {
  /* @noflip */
  padding-left: 2px;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--end-aligned .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--end-aligned .mdc-text-field__affix--prefix[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field__affix--suffix, .mdc-text-field__affix--suffix[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
.mdc-text-field--end-aligned .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 2px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--end-aligned .mdc-text-field__affix--suffix[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}

.mdc-text-field--filled, .lmnt .lmnt-text-field--embedded {
  height: 56px;
}
.mdc-text-field--filled .mdc-text-field__ripple::before, .lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after,
.lmnt .lmnt-text-field--embedded .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .lmnt .lmnt-text-field--embedded:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before, .lmnt .mdc-ripple-surface--hover.lmnt-text-field--embedded .mdc-text-field__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .lmnt .mdc-ripple-upgraded--background-focused.lmnt-text-field--embedded .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before, .lmnt .lmnt-text-field--embedded:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled::before, .lmnt .lmnt-text-field--embedded::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled), .lmnt .lmnt-text-field--embedded:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before, .lmnt .lmnt-text-field--embedded:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before, .lmnt .lmnt-text-field--embedded:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after, .lmnt .lmnt-text-field--embedded .mdc-line-ripple::after {
  border-bottom-color: #6200ee;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-text-field--filled .mdc-floating-label, .lmnt .lmnt-text-field--embedded .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--filled .mdc-floating-label, [dir=rtl] .lmnt .lmnt-text-field--embedded .mdc-floating-label, .lmnt [dir=rtl] .lmnt-text-field--embedded .mdc-floating-label, .mdc-text-field--filled .mdc-floating-label[dir=rtl], .lmnt .lmnt-text-field--embedded .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--filled .mdc-floating-label--float-above, .lmnt .lmnt-text-field--embedded .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input, .lmnt .mdc-text-field--no-label.lmnt-text-field--embedded .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-floating-label, .lmnt .mdc-text-field--no-label.lmnt-text-field--embedded .mdc-floating-label {
  display: none;
}
.mdc-text-field--filled.mdc-text-field--no-label::before, .lmnt .mdc-text-field--no-label.lmnt-text-field--embedded::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__affix, .lmnt .mdc-text-field--no-label.lmnt-text-field--embedded .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field--outlined {
  height: 56px;
  overflow: visible;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-left-radius: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon {
  /* @noflip */
  padding-left: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-leading-icon {
    /* @noflip */
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /* @noflip */
  padding-right: 0;
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
    /* @noflip */
    padding-left: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-right: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /* @noflip */
  padding-left: 0;
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
    /* @noflip */
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  content: none;
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  border: none !important;
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 1;
}

.mdc-text-field--textarea {
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  transition: none;
}
.mdc-text-field--textarea .mdc-floating-label {
  top: 19px;
}
.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}
.mdc-text-field--textarea .mdc-text-field__input {
  flex-grow: 1;
  height: auto;
  min-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  padding: 0 16px;
  line-height: 1.5rem;
}
.mdc-text-field--textarea.mdc-text-field--filled::before, .lmnt .mdc-text-field--textarea.lmnt-text-field--embedded::before {
  display: none;
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--float-above, .lmnt .mdc-text-field--textarea.lmnt-text-field--embedded .mdc-floating-label--float-above {
  transform: translateY(-10.25px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--shake, .lmnt .mdc-text-field--textarea.lmnt-text-field--embedded .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-filled 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-filled {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-text-field__input, .lmnt .mdc-text-field--textarea.lmnt-text-field--embedded .mdc-text-field__input {
  margin-top: 23px;
  margin-bottom: 9px;
}
.mdc-text-field--textarea.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input, .lmnt .mdc-text-field--textarea.mdc-text-field--no-label.lmnt-text-field--embedded .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 18px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter {
  align-self: flex-end;
  padding: 0 16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::after {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: -16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::before {
  display: none;
}

.mdc-text-field__resizer {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  min-height: 56px;
  min-width: fit-content;
  /* @alternate */
  min-width: -moz-available;
  /* @alternate */
  min-width: -webkit-fill-available;
  overflow: hidden;
  resize: both;
}
.mdc-text-field--filled .mdc-text-field__resizer, .lmnt .lmnt-text-field--embedded .mdc-text-field__resizer {
  transform: translateY(-1px);
}
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field__input, .lmnt .lmnt-text-field--embedded .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field-character-counter,
.lmnt .lmnt-text-field--embedded .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateY(1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer {
  transform: translateX(-1px) translateY(-1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer, .mdc-text-field--outlined .mdc-text-field__resizer[dir=rtl] {
  transform: translateX(1px) translateY(-1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateX(1px) translateY(1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input, .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input[dir=rtl],
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter[dir=rtl] {
  transform: translateX(-1px) translateY(1px);
}

.mdc-text-field--with-leading-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon, .mdc-text-field--with-leading-icon[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, .lmnt .mdc-text-field--with-leading-icon.lmnt-text-field--embedded .mdc-floating-label {
  max-width: calc(100% - 48px);
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, [dir=rtl] .lmnt .mdc-text-field--with-leading-icon.lmnt-text-field--embedded .mdc-floating-label, .lmnt [dir=rtl] .mdc-text-field--with-leading-icon.lmnt-text-field--embedded .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label[dir=rtl], .lmnt .mdc-text-field--with-leading-icon.lmnt-text-field--embedded .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label--float-above, .lmnt .mdc-text-field--with-leading-icon.lmnt-text-field--embedded .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}

.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--with-trailing-icon, .mdc-text-field--with-trailing-icon[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label, .lmnt .mdc-text-field--with-trailing-icon.lmnt-text-field--embedded .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above, .lmnt .mdc-text-field--with-trailing-icon.lmnt-text-field--embedded .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label, .lmnt .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.lmnt-text-field--embedded .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above, .lmnt .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.lmnt-text-field--embedded .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(98, 0, 238, 0.87);
}
.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}
.mdc-text-field--focused.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-text-field--focused.mdc-text-field--outlined.mdc-text-field--textarea .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--disabled {
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}
.mdc-text-field--disabled.mdc-text-field--filled, .lmnt .mdc-text-field--disabled.lmnt-text-field--embedded {
  background-color: #fafafa;
}
.mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__ripple, .lmnt .mdc-text-field--disabled.lmnt-text-field--embedded .mdc-text-field__ripple {
  display: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  pointer-events: auto;
}

.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  text-align: left;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input,
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input,
.mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix {
  /* @noflip */
  direction: ltr;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--leading, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--leading {
  order: 1;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  order: 2;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input {
  order: 3;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  order: 4;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--trailing, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--trailing {
  order: 5;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 2px;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 16px;
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0px;
}

.lmnt .lmnt-text-field.mdc-text .lmnt-text-field-inline-container {
  display: inline-block;
}
.lmnt .lmnt-text-field--container-full-width {
  width: 100%;
}
.lmnt .lmnt-text-field {
  border-radius: var(--lmnt-filled-text-field-shape-radius);
}
.lmnt .lmnt-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled), .lmnt .lmnt-text-field.lmnt-text-field--embedded:not(.mdc-text-field--disabled) {
  background-color: var(--lmnt-textfield-fill-color);
}
.lmnt .lmnt-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text, .lmnt .lmnt-text-field.lmnt-text-field--embedded:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: var(--lmnt-helper-text-color);
}
.lmnt .lmnt-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text.mdc-text-field-helper-text--validation-msg, .lmnt .lmnt-text-field.lmnt-text-field--embedded:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text.mdc-text-field-helper-text--validation-msg {
  color: var(--lmnt-theme-danger);
}
.lmnt .lmnt-text-field.mdc-text-field--outlined .mdc-notched-outline__leading,
.lmnt .lmnt-text-field.mdc-text-field--outlined .mdc-notched-outline__trailing {
  --mdc-shape-small: var(--lmnt-text-field-shape-radius);
}
.lmnt .lmnt-text-field.mdc-text-field--outlined:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: var(--lmnt-helper-text-color);
}
.lmnt .lmnt-text-field.mdc-text-field--outlined:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text.mdc-text-field-helper-text--validation-msg {
  color: var(--lmnt-theme-danger);
}
.lmnt .lmnt-text-field.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: var(--lmnt-text-field-primary);
}
.lmnt .lmnt-text-field .mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--required) {
  /* stylelint-disable-next-line max-nesting-depth */
}
.lmnt .lmnt-text-field .mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--required) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.54);
}
.lmnt .lmnt-text-field--container-to-content-width {
  width: fit-content;
}
.lmnt .lmnt-text-field--full-width, .lmnt .lmnt-text-field--embedded {
  width: 100%;
}
.lmnt .lmnt-text-field--with-icon .lmnt-text-field__icon {
  color: var(--lmnt-theme-icon-in-component);
}
.lmnt .lmnt-text-field--with-icon.mdc-text-field--outlined {
  align-items: center;
}
.lmnt .lmnt-text-field--with-icon.mdc-text-field--invalid .lmnt-text-field__icon {
  color: var(--lmnt-theme-danger);
}
.lmnt .lmnt-text-field--with-icon .lmnt-text-field__icon-button--leading {
  margin-left: 12px;
}
.lmnt .lmnt-text-field--with-icon .lmnt-text-field__icon-button--trailing {
  margin-right: 12px;
}
.lmnt .lmnt-text-field--with-icon .mdc-text-field__icon--trailing {
  padding-right: 16px;
}
.lmnt .lmnt-text-field--embedded:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
  background-color: initial;
}
.lmnt .lmnt-text-field--dense {
  height: 40px;
}
.lmnt .lmnt-text-field--dense .mdc-text-field__input {
  height: 100%;
}
.lmnt .lmnt-text-field--dense .mdc-floating-label {
  display: none;
}
.lmnt .lmnt-text-field--dense::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .lmnt .lmnt-text-field--dense .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined {
  height: 40px;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-29.25px) scale(1);
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-26.75px) scale(0.75);
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-40px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-40px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-26.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-26.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-26.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-26.75px) scale(0.75);
  }
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon {
  height: 40px;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-29.25px) translateX(-32px) scale(1);
}
[dir=rtl] .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-29.25px) translateX(32px) scale(1);
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-26.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-26.75px) translateX(32px) scale(0.75);
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above- 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above- {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-26.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-26.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-26.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-26.75px) scale(0.75);
  }
}
[dir=rtl] .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .lmnt .lmnt-text-field--dense.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above- 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above--rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-26.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-26.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-26.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-26.75px) scale(0.75);
  }
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-19.25px) scale(1);
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-16.75px) scale(0.75);
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined--4 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined--4 {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-16.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-16.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-16.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-16.75px) scale(0.75);
  }
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 10px;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__resizer {
  min-height: 40px;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 8px;
  margin-bottom: 8px;
}
.lmnt .lmnt-text-field--dense.mdc-text-field--textarea.mdc-text-field--outlined.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}

.lmnt-text-field__input--color {
  height: 1.5em;
  width: 1.8em;
}

.lmnt-theme-primary-bg .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text--persistent {
  color: var(--lmnt-theme-on-primary);
  opacity: 0.38;
}
.lmnt-theme-primary-bg .mdc-text-field--disabled {
  opacity: 0.38;
}
.lmnt-theme-primary-bg .mdc-text-field--disabled .mdc-notched-outline__leading,
.lmnt-theme-primary-bg .mdc-text-field--disabled .mdc-notched-outline__notch,
.lmnt-theme-primary-bg .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .mdc-text-field .mdc-line-ripple {
  --mdc-theme-primary: #000;
}
.lmnt-theme-primary-bg .mdc-text-field--outlined ::placeholder {
  color: var(--lmnt-theme-on-primary) !important;
}
.lmnt-theme-primary-bg .mdc-text-field--outlined .mdc-floating-label,
.lmnt-theme-primary-bg .mdc-text-field--outlined .mdc-text-field__input, .lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__trailing, .lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):focus .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):focus .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt-theme-primary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):focus .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .lmnt-text-field--embedded ::placeholder {
  color: var(--lmnt-theme-on-primary) !important;
}
.lmnt-theme-primary-bg .lmnt-text-field--embedded .mdc-text-field__input {
  color: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .lmnt-text-field--embedded.mdc-text-field--disabled {
  background-color: transparent;
}
.lmnt-theme-primary-bg .lmnt-text-field--embedded .mdc-line-ripple {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
}
.lmnt-theme-primary-bg .lmnt-text-field--embedded .mdc-line-ripple::before {
  border-color: var(--lmnt-theme-on-primary);
  opacity: 0.67;
}
.lmnt-theme-primary-bg .lmnt-text-field--embedded:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before, .lmnt-theme-primary-bg .lmnt-text-field--embedded:not(.mdc-text-field--disabled):focus .mdc-line-ripple::before {
  border-color: var(--lmnt-theme-on-primary);
  opacity: 1;
}
.lmnt-theme-primary-bg .lmnt-text-field--with-icon.mdc-text-field--outlined:not(.mdc-text-field--invalid) .lmnt-text-field__icon {
  color: var(--lmnt-theme-on-primary);
}

.lmnt-theme-secondary-bg .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text--persistent {
  color: var(--lmnt-theme-on-secondary);
  opacity: 0.38;
}
.lmnt-theme-secondary-bg .mdc-text-field--disabled {
  opacity: 0.38;
}
.lmnt-theme-secondary-bg .mdc-text-field--disabled .mdc-notched-outline__leading,
.lmnt-theme-secondary-bg .mdc-text-field--disabled .mdc-notched-outline__notch,
.lmnt-theme-secondary-bg .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .mdc-text-field .mdc-line-ripple {
  --mdc-theme-primary: #000;
}
.lmnt-theme-secondary-bg .mdc-text-field--outlined ::placeholder {
  color: var(--lmnt-theme-on-secondary) !important;
}
.lmnt-theme-secondary-bg .mdc-text-field--outlined .mdc-floating-label,
.lmnt-theme-secondary-bg .mdc-text-field--outlined .mdc-text-field__input, .lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):hover .mdc-notched-outline .mdc-notched-outline__trailing, .lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):focus .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):focus .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt-theme-secondary-bg .mdc-text-field--outlined:not(.mdc-text-field--invalid):focus .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .lmnt-text-field--embedded ::placeholder {
  color: var(--lmnt-theme-on-secondary) !important;
}
.lmnt-theme-secondary-bg .lmnt-text-field--embedded .mdc-text-field__input {
  color: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .lmnt-text-field--embedded.mdc-text-field--disabled {
  background-color: transparent;
}
.lmnt-theme-secondary-bg .lmnt-text-field--embedded .mdc-line-ripple {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
}
.lmnt-theme-secondary-bg .lmnt-text-field--embedded .mdc-line-ripple::before {
  border-color: var(--lmnt-theme-on-secondary);
  opacity: 0.67;
}
.lmnt-theme-secondary-bg .lmnt-text-field--embedded:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before, .lmnt-theme-secondary-bg .lmnt-text-field--embedded:not(.mdc-text-field--disabled):focus .mdc-line-ripple::before {
  border-color: var(--lmnt-theme-on-secondary);
  opacity: 1;
}
.lmnt-theme-secondary-bg .lmnt-text-field--with-icon.mdc-text-field--outlined:not(.mdc-text-field--invalid) .lmnt-text-field__icon {
  color: var(--lmnt-theme-on-secondary);
}

.mdc-deprecated-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-deprecated-list:focus {
  outline: none;
}

.mdc-deprecated-list-item {
  height: 48px;
}

.mdc-deprecated-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-deprecated-list-item__graphic {
  background-color: transparent;
}

.mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text {
  opacity: 0.38;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__primary-text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-deprecated-list-item--selected,
.mdc-deprecated-list-item--activated {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-deprecated-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-deprecated-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 48px;
}
.mdc-deprecated-list-item:focus {
  outline: none;
}
.mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
.mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
[dir=rtl] .mdc-deprecated-list-item, .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--image-list .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--video-list .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0px;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-deprecated-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-deprecated-list-item__graphic, .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-deprecated-list .mdc-deprecated-list-item__graphic {
  display: inline-flex;
}

.mdc-deprecated-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-deprecated-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-deprecated-list-item[dir=rtl] .mdc-deprecated-list-item__meta, [dir=rtl] .mdc-deprecated-list-item .mdc-deprecated-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-deprecated-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-deprecated-list-item__text[for] {
  pointer-events: none;
}

.mdc-deprecated-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-deprecated-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__secondary-text {
  font-size: inherit;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 40px;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item__text {
  align-self: flex-start;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item {
  height: 64px;
}
.mdc-deprecated-list--two-line.mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  height: 72px;
}
.mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--dense .mdc-deprecated-list-item,
.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 60px;
}

.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  cursor: pointer;
}

a.mdc-deprecated-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-deprecated-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-deprecated-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-deprecated-list-divider--padded {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-deprecated-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list-divider--inset, .mdc-deprecated-list-divider--inset[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}

.mdc-deprecated-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc( (3rem - 1.5rem) / 2 ) 16px;
}

.mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
}
.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  align-items: stretch;
  cursor: pointer;
}
.mdc-list-item:focus {
  outline: none;
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}
.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive {
  cursor: auto;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-item__start {
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__end {
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
  pointer-events: none;
}
.mdc-list-item--with-two-lines .mdc-list-item__content, .mdc-list-item--with-three-lines .mdc-list-item__content {
  align-self: stretch;
}
.mdc-list-item__content[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item__overline-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text, .mdc-list-item--with-three-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before, .mdc-list-item--with-three-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after, .mdc-list-item--with-three-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-avatar.mdc-list-item, .mdc-list-item--with-leading-avatar.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-avatar .mdc-list-item__start, .mdc-list-item--with-leading-avatar .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}
.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  border-radius: 50%;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  width: 24px;
  height: 24px;
}
.mdc-list-item--with-leading-icon.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-icon.mdc-list-item, .mdc-list-item--with-leading-icon.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-icon .mdc-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 32px;
}
[dir=rtl] .mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--with-leading-icon .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-thumbnail.mdc-list-item, .mdc-list-item--with-leading-thumbnail.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-thumbnail .mdc-list-item__start, .mdc-list-item--with-leading-thumbnail .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-image.mdc-list-item, .mdc-list-item--with-leading-image.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-image .mdc-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-image .mdc-list-item__start, .mdc-list-item--with-leading-image .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-leading-image .mdc-list-item__start {
  width: 56px;
  height: 56px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-one-line {
  height: 72px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-video.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-video.mdc-list-item, .mdc-list-item--with-leading-video.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-video .mdc-list-item__start {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-video .mdc-list-item__start, .mdc-list-item--with-leading-video .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item--with-leading-video .mdc-list-item__start {
  width: 100px;
  height: 56px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-checkbox.mdc-list-item, .mdc-list-item--with-leading-checkbox.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
[dir=rtl] .mdc-list-item--with-leading-checkbox .mdc-list-item__start, .mdc-list-item--with-leading-checkbox .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-radio.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-radio.mdc-list-item, .mdc-list-item--with-leading-radio.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-radio .mdc-list-item__start {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
[dir=rtl] .mdc-list-item--with-leading-radio .mdc-list-item__start, .mdc-list-item--with-leading-radio .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
.mdc-list-item--with-leading-radio .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-switch.mdc-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-switch.mdc-list-item, .mdc-list-item--with-leading-switch.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-list-item--with-leading-switch .mdc-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-switch .mdc-list-item__start, .mdc-list-item--with-leading-switch .mdc-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-leading-switch .mdc-list-item__start {
  width: 36px;
  height: 20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-trailing-icon.mdc-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-icon.mdc-list-item, .mdc-list-item--with-trailing-icon.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-icon .mdc-list-item__end, .mdc-list-item--with-trailing-icon .mdc-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-meta.mdc-list-item, .mdc-list-item--with-trailing-meta.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  /* @noflip */
  margin-left: 28px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-meta .mdc-list-item__end, .mdc-list-item--with-trailing-meta .mdc-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 28px;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-checkbox.mdc-list-item, .mdc-list-item--with-trailing-checkbox.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-list-item--with-trailing-checkbox .mdc-list-item__end, .mdc-list-item--with-trailing-checkbox .mdc-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-radio.mdc-list-item, .mdc-list-item--with-trailing-radio.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-list-item--with-trailing-radio .mdc-list-item__end, .mdc-list-item--with-trailing-radio .mdc-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-switch.mdc-list-item, .mdc-list-item--with-trailing-switch.mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-switch .mdc-list-item__end, .mdc-list-item--with-trailing-switch .mdc-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  width: 36px;
  height: 20px;
}
.mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc( (3rem - 1.5rem) / 2 ) 16px;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider {
  height: 1px;
  padding: 0;
  background-clip: content-box;
}

.mdc-list-divider.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-divider.mdc-list-divider--with-leading-inset, .mdc-list-divider.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list-divider.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-list-divider.mdc-list-divider--with-trailing-inset, .mdc-list-divider.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset[dir=rtl],
[dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: auto;
}

.mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset, .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0px;
}

[dir=rtl] .mdc-list-divider, .mdc-list-divider[dir=rtl] {
  padding: 0;
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-deprecated-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-select {
  display: inline-flex;
  position: relative;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-select.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(98, 0, 238, 0.87);
}
.mdc-select.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #6200ee;
  /* @alternate */
  fill: var(--mdc-theme-primary, #6200ee);
}
.mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select.mdc-select--disabled + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-select.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}
@media screen and (-ms-high-contrast: active) {
  .mdc-select.mdc-select--disabled .mdc-select__selected-text {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
    fill: red;
  }
  .mdc-select.mdc-select--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select.mdc-select--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__icon {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
    color: GrayText;
  }
}
.mdc-select .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.mdc-select .mdc-select__anchor {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-select .mdc-select__anchor, .mdc-select .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor, .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
.mdc-select .mdc-select__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mdc-select .mdc-select__dropdown-icon {
  width: 24px;
  height: 24px;
}
.mdc-select .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item, .mdc-select .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic, .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 0;
}
.mdc-select__dropdown-icon {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active,
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  position: absolute;
  top: 0;
  left: 0;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-graphic {
  width: 41.6666666667%;
  height: 20.8333333333%;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 0;
  transition: opacity 75ms linear;
}
[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}
.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 0;
  transition: opacity 49.5ms linear;
}
.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 1;
  transition: opacity 100.5ms linear 49.5ms;
}

.mdc-select__anchor {
  width: 200px;
  min-width: 0;
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}
.mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-select__selected-text-container {
  display: flex;
  appearance: none;
  pointer-events: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0;
  flex-grow: 1;
  height: 28px;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.mdc-select__selected-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%;
  /* @noflip */
  text-align: left;
}
[dir=rtl] .mdc-select__selected-text, .mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #b00020;
  /* @alternate */
  fill: var(--mdc-theme-error, #b00020);
}
.mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item, .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 12px;
}

.mdc-select__menu .mdc-deprecated-list .mdc-select__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-select__menu .mdc-deprecated-list .mdc-select__icon, .mdc-select__menu .mdc-deprecated-list .mdc-select__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select--filled .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}
.mdc-select--filled .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.mdc-select--filled.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.mdc-select--filled .mdc-select__anchor {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}
.mdc-select--filled.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}
.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-select--filled:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #6200ee;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-select--filled.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-select--filled .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.mdc-select--filled.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}
.mdc-select--filled .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--filled .mdc-floating-label, .mdc-select--filled .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--outlined {
  border: none;
}
.mdc-select--outlined .mdc-select__anchor {
  height: 56px;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-56px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-left-radius: 0;
}
@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-select__anchor {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
  /* @noflip */
  padding-left: 0;
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
    /* @noflip */
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined + .mdc-select-helper-text {
    /* @noflip */
    margin-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
  /* @noflip */
  margin-left: 0;
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
    /* @noflip */
    margin-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-select--outlined .mdc-select__anchor {
  display: flex;
  align-items: baseline;
  overflow: visible;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.mdc-select--outlined .mdc-select__anchor::before {
  display: none;
}
.mdc-select--outlined .mdc-select__selected-text-container {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}
.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}
.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--outlined .mdc-floating-label, .mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-select--outlined.mdc-select--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}
.mdc-select--outlined .mdc-menu-surface {
  margin-bottom: 8px;
}
.mdc-select--outlined.mdc-select--no-label .mdc-menu-surface,
.mdc-select--outlined .mdc-menu-surface--is-open-below {
  margin-bottom: 0;
}

.mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-select__anchor .mdc-select__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-select__anchor .mdc-select__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-select__anchor.mdc-ripple-upgraded--unbounded .mdc-select__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-select__anchor.mdc-ripple-upgraded--foreground-activation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-select__anchor:hover .mdc-select__ripple::before, .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__anchor .mdc-select__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select-helper-text {
  margin: 0;
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
[dir=rtl] .mdc-select-helper-text, .mdc-select-helper-text[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--validation-msg {
  opacity: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg,
.mdc-select-helper-text--validation-msg-persistent {
  opacity: 1;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  align-self: center;
  background-color: transparent;
  fill: currentColor;
}
.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 12px;
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}

.lmnt-list {
  --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);
}
.lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-one-line {
  height: 32px;
}
.lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line, .lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line, .lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line, .lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 40px;
}
.lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-image.mdc-list-item--with-one-line, .lmnt-list.lmnt-list--dense .mdc-list-item--with-one-line.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 56px;
}
.lmnt-list .mdc-list-item .mdc-form-field {
  vertical-align: inherit;
}
.lmnt-list .mdc-list-item .mdc-checkbox--touch {
  margin: 0;
}
.lmnt-list .mdc-list-item__overline-text {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lmnt-list .mdc-list-item--with-trailing-image.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-video.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-icon.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-thumbnail.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-avatar.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-avatar.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-trailing-badge.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-icon.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-thumbnail.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-avatar.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-checkbox.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-switch.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-radio.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-avatar.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-image.mdc-list-item--with-three-lines .mdc-list-item__start,
.lmnt-list .mdc-list-item--with-leading-video.mdc-list-item--with-three-lines .mdc-list-item__start {
  align-self: center;
  margin-top: 0;
}
.lmnt-list .mdc-list-item--with-trailing-image.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-video.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-icon.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-thumbnail.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-avatar.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-avatar.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-trailing-badge.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-icon.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-thumbnail.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-avatar.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-switch.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-radio.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-avatar.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-image.mdc-list-item--with-three-lines .mdc-list-item__end,
.lmnt-list .mdc-list-item--with-leading-video.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.lmnt-list .mdc-list-item--with-leading-avatar .mdc-list-item__start img {
  height: 40px;
  width: 40px;
}
.lmnt-list .mdc-list-item--with-trailing-avatar .mdc-list-item__end {
  height: 40px;
}
.lmnt-list .mdc-list-item--with-trailing-avatar .mdc-list-item__end img {
  height: 40px;
  width: 40px;
}
.lmnt-list .mdc-list-item--with-leading-image .mdc-list-item__start img {
  height: 56px;
  width: 56px;
}
.lmnt-list .mdc-list-item--with-trailing-image .mdc-list-item__end {
  height: 56px;
}
.lmnt-list .mdc-list-item--with-trailing-image .mdc-list-item__end img {
  height: 56px;
  width: 56px;
}
.lmnt-list .mdc-list-item--with-leading-video .mdc-list-item__start {
  padding-left: 16px;
}
.lmnt-list .mdc-list-item--with-leading-video .mdc-list-item__start img {
  height: 56px;
  width: 100px;
}
.lmnt-list .mdc-list-item--with-trailing-video .mdc-list-item__end {
  height: 56px;
}
.lmnt-list .mdc-list-item--with-trailing-video .mdc-list-item__end img {
  height: 56px;
  width: 100px;
}
.lmnt-list .mdc-list-item--with-leading-thumbnail .mdc-list-item__start img {
  height: 40px;
  width: 40px;
}
.lmnt-list .mdc-list-item--with-trailing-thumbnail .mdc-list-item__end {
  height: 40px;
}
.lmnt-list .mdc-list-item--with-trailing-thumbnail .mdc-list-item__end img {
  height: 40px;
  width: 40px;
}
.lmnt-list .lmnt-list-item--wrap,
.lmnt-list .lmnt-list-item--wrap .mdc-list-item__content > * {
  white-space: normal;
}
.lmnt-list .lmnt-list-item--truncate,
.lmnt-list .lmnt-list-item--truncate .mdc-list-item__content > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lmnt-list .lmnt-list-item--no-wrap,
.lmnt-list .lmnt-list-item--no-wrap .mdc-list-item__content > * {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.lmnt-list .mdc-list-item--with-two-lines.lmnt-list-item--wrap,
.lmnt-list .mdc-list-item--with-three-lines.lmnt-list-item--wrap {
  height: auto;
}
.lmnt-list .mdc-list-item--with-two-lines .mdc-list-item__content,
.lmnt-list .mdc-list-item--with-three-lines .mdc-list-item__content {
  padding-bottom: 10px;
}
.lmnt-list .lmnt-list-item__no-hover {
  pointer-events: none;
}
.lmnt-list .lmnt-list-item__no-hover:hover, .lmnt-list .lmnt-list-item__no-hover:focus {
  background-color: inherit;
  cursor: default;
}
.lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-icon, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-thumbnail, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-avatar, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-checkbox, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-switch, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-radio, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-avatar, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-meta, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-trailing-badge, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-icon, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-thumbnail, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-avatar, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-checkbox, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-switch, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-radio, .lmnt-list .mdc-list-item--with-one-line.mdc-list-item--with-leading-avatar {
  height: 48px;
}

hr.lmnt-list-divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.lmnt-list-divider.lmnt-list-divider--with-padding {
  padding-left: 16px;
  padding-right: 16px;
}
.lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-icon, .lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-thumbnail, .lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-avatar, .lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-checkbox, .lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-switch, .lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-radio, .lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-avatar {
  padding-left: 72px;
}
.lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-image {
  padding-left: 88px;
}
.lmnt-list-divider.lmnt-list-divider--with-inset.mdc-list-divider--with-leading-video {
  padding-left: 132px;
}

.mdc-list-group__subheader.lmnt-list-group__subheader .mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
}

.mdc-menu-surface.lmnt-theme-primary-bg .mdc-list-item__overline-text,
.mdc-menu-surface.lmnt-theme-primary-bg .mdc-list-item__secondary-text,
.mdc-menu-surface.lmnt-theme-primary-bg .mdc-list-item__primary-text,
.mdc-menu-surface.lmnt-theme-secondary-bg .mdc-list-item__overline-text,
.mdc-menu-surface.lmnt-theme-secondary-bg .mdc-list-item__secondary-text,
.mdc-menu-surface.lmnt-theme-secondary-bg .mdc-list-item__primary-text,
.mdc-list.lmnt-theme-primary-bg .mdc-list-item__overline-text,
.mdc-list.lmnt-theme-primary-bg .mdc-list-item__secondary-text,
.mdc-list.lmnt-theme-primary-bg .mdc-list-item__primary-text,
.mdc-list.lmnt-theme-secondary-bg .mdc-list-item__overline-text,
.mdc-list.lmnt-theme-secondary-bg .mdc-list-item__secondary-text,
.mdc-list.lmnt-theme-secondary-bg .mdc-list-item__primary-text,
.mdc-list-item.lmnt-theme-primary-bg .mdc-list-item__overline-text,
.mdc-list-item.lmnt-theme-primary-bg .mdc-list-item__secondary-text,
.mdc-list-item.lmnt-theme-primary-bg .mdc-list-item__primary-text,
.mdc-list-item.lmnt-theme-secondary-bg .mdc-list-item__overline-text,
.mdc-list-item.lmnt-theme-secondary-bg .mdc-list-item__secondary-text,
.mdc-list-item.lmnt-theme-secondary-bg .mdc-list-item__primary-text {
  color: inherit;
}

.lmnt-nested-list {
  padding: 0;
}
.lmnt-nested-list .mdc-list-group__subheader.lmnt-list-group__subheader {
  margin-top: 0;
}
.lmnt-nested-list .mdc-list {
  padding: 0;
}
.lmnt-nested-list .mdc-list .mdc-list-item {
  padding: 0 28px;
}
.lmnt-nested-list .lmnt-list-group__subheader {
  height: fit-content;
}
.lmnt-nested-list .lmnt-list-group__category-header {
  font-weight: bold;
  margin: 12px 16px 0;
}

.lmnt.lmnt-badge.lmnt-list--label-badge {
  padding-top: 2px;
  padding-bottom: 2px;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  /* @alternate */
  max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
  max-height: calc(100vh - 32px);
  /* @alternate */
  max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right;
}
.mdc-menu-surface:focus {
  outline: none;
}
.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}
.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}
[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /* @noflip */
  transform-origin-left: top right;
  /* @noflip */
  transform-origin-right: top left;
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-menu-surface--fullwidth {
  width: 100%;
}

.mdc-menu {
  min-width: 112px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 112px);
}
.mdc-menu .mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  position: relative;
}
.mdc-menu .mdc-deprecated-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-menu .mdc-deprecated-list-divider {
  margin: 8px 0;
}
.mdc-menu .mdc-deprecated-list-item {
  user-select: none;
}
.mdc-menu .mdc-deprecated-list-item--disabled {
  cursor: auto;
}
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__text,
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}
.mdc-menu__selection-group .mdc-deprecated-list-item {
  /* @noflip */
  padding-left: 56px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-menu__selection-group .mdc-deprecated-list-item, .mdc-menu__selection-group .mdc-deprecated-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 56px;
}
.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.lmnt {
  --lmnt-menu-shape-radius: var(--lmnt-theme--shape-radius);
}
.lmnt.mdc-menu-surface.lmnt-menu-container, .lmnt.mdc-menu.lmnt-menu-container, .lmnt.mdc-menu-container.mdc-menu-surface--open {
  margin-bottom: 8px;
  margin-top: 8px;
  overflow-y: auto;
  border-radius: var(--lmnt-menu-shape-radius);
}
.lmnt.mdc-menu-surface.lmnt-menu-container.lmnt-menu-open-up, .lmnt.mdc-menu.lmnt-menu-container.lmnt-menu-open-up, .lmnt.mdc-menu-container.mdc-menu-surface--open.lmnt-menu-open-up {
  margin-bottom: 8px;
  margin-top: 0;
}
.lmnt.mdc-menu-surface.lmnt-menu-container.lmnt-menu--flush, .lmnt.mdc-menu.lmnt-menu-container.lmnt-menu--flush, .lmnt.mdc-menu-container.mdc-menu-surface--open.lmnt-menu--flush {
  margin-bottom: 0;
  margin-top: 0;
}
.lmnt.mdc-menu-surface.lmnt-menu-container.lmnt-menu-surface--fullScreen {
  --lmnt-menu-shape-radius: 0;
  --mdc-menu-max-width: 100%;
  box-shadow: none;
  height: 100%;
  left: 0 !important;
  max-height: 100% !important;
  width: 100%;
}

:root,
.lmnt {
  --lmnt-select-fill-color: var(--lmnt-textfield-fill-color);
  --lmnt-select-shape-radius: var(--lmnt-theme--shape-radius);
  --lmnt-filled-select-shape-radius: 0;
}

.lmnt-select-container {
  position: relative;
  width: 100%;
}
.lmnt-select-container .lmnt-select-inner-container {
  width: 100%;
}
.lmnt-select-container.lmnt.lmnt-select--outlined.lmnt-select--dense .lmnt.lmnt-select-multi-value-container {
  line-height: 14px;
}
.lmnt-select-container.lmnt.lmnt-select--outlined.lmnt-select--dense .lmnt-select--multi.lmnt-select--value-display-pills {
  min-height: 40px;
}
.lmnt-select-container.lmnt.lmnt-select--outlined.lmnt-select--dense .lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--has-value .mdc-select__anchor .lmnt-select-value-container,
.lmnt-select-container.lmnt.lmnt-select--outlined.lmnt-select--dense .lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--has-value .mdc-select__anchor .lmnt-select-leading-icon,
.lmnt-select-container.lmnt.lmnt-select--outlined.lmnt-select--dense .lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--has-value .mdc-select__anchor .lmnt-select-indicators-container {
  margin-bottom: 0;
}
.lmnt-select-container .mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: var(--lmnt-select-fill-color);
}

.lmnt-select-label {
  color: rgba(0, 0, 0, 0.6);
}

.lmnt-select .lmnt-select-control-container {
  width: 100%;
}
.lmnt-select .lmnt-select-indicators-container {
  align-self: center;
  height: 24px;
  margin-top: 2px;
}
.lmnt-select .lmnt-select-leading-icon {
  height: 24px;
}
.lmnt-select .lmnt.lmnt-select-value-container {
  padding-left: 0px;
  margin-left: -2px;
}
.lmnt-select .mdc-select__anchor {
  min-height: 56px;
  width: unset;
  border-radius: var(--lmnt-filled-select-shape-radius);
}
.lmnt-select .lmnt-select-dropdown-container {
  padding: 0;
  padding-right: 8px;
  margin-bottom: 4px;
}
.lmnt-select .lmnt-select-dropdown-container .mdc-select__dropdown-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}
.lmnt-select .lmnt-select-clear--icon {
  color: rgba(0, 0, 0, 0.6);
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-text .mdc-select__anchor > .lmnt-select-value-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-wrap: unset;
  overflow: hidden;
  padding: 2px 0px;
  position: relative;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-text .mdc-select__anchor.lmnt-select__anchor--extended-height > .lmnt-select-value-container {
  padding-bottom: 10px;
  padding-top: 10px;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-text.lmnt-select--fixed-height {
  height: 56px;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-text.lmnt-select--fixed-height .mdc-select__anchor {
  height: 56px;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-text.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-value-container {
  height: 56px;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--fixed-height .mdc-select__anchor {
  overflow-x: auto;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-value-container-scroll-wrapper {
  overflow-x: auto;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-value-container {
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: flex-end;
  margin-bottom: 8px;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-value-container .lmnt-select-value-container {
  flex-wrap: wrap;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-value-container .lmnt.lmnt-select-multi-value-container {
  min-width: fit-content;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-indicators-container {
  margin-left: auto;
}
.lmnt-select.lmnt-select--multi.lmnt-select--value-display-pills .mdc-select__anchor {
  align-items: center;
}

.lmnt.lmnt-select--filled.mdc-select--filled .mdc-select__anchor {
  height: unset;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor {
  height: 40px;
  display: flex;
  align-items: baseline;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor::before {
  display: none;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor .mdc-floating-label {
  display: none;
}
.lmnt.lmnt-select--filled.lmnt-select--dense.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.lmnt.lmnt-select--filled.lmnt-select--dense.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.lmnt.lmnt-select--filled.lmnt-select--dense.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.lmnt.lmnt-select--filled.lmnt-select--dense.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 60px);
}
.lmnt.lmnt-select--filled.lmnt-select--dense.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 60px / 0.75);
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-deprecated-list-item {
  height: 32px;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-select__anchor {
  min-height: 40px;
  align-items: center;
}
.lmnt.lmnt-select--filled.lmnt-select--dense .mdc-floating-label--float-above {
  transform: none;
  display: none;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi {
  min-height: 68px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .lmnt-select__anchor--extended-height {
  padding-top: 23px;
  transition: padding-top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .lmnt-select__anchor--extended-height .lmnt-select-indicators-container {
  align-self: flex-end;
  margin-bottom: 12px;
  margin-top: 0;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--fixed-height {
  height: 68px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--fixed-height .mdc-select__anchor {
  height: 68px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--fixed-height .mdc-select__anchor .lmnt-select-value-container {
  height: 68px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi .lmnt-select-label {
  top: 35px;
  transform: translateY(-50%);
  pointer-events: none;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi .mdc-select__anchor {
  min-height: 68px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-indicators-container,
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-leading-icon {
  height: 32px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-leading-icon {
  margin-top: 24px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor > .lmnt-select-value-container {
  align-self: flex-end;
  margin-bottom: 5px;
  margin-top: 5px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-indicators-container {
  margin-top: 16px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor.lmnt-select__anchor--extended-height .lmnt-select-leading-icon {
  align-self: flex-end;
  height: unset;
  margin-bottom: 12px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-text .lmnt-select__anchor--extended-height {
  padding-bottom: 8px;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-text .lmnt-select__anchor--extended-height .lmnt-select-leading-icon {
  align-self: flex-end;
}
.lmnt.lmnt-select--filled.lmnt-select--value-display-text .lmnt-select__anchor--extended-height .lmnt-select-indicators-container {
  align-self: flex-end;
}
.lmnt.lmnt-select--filled .lmnt-select-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  left: 16px;
}
.lmnt.lmnt-select--filled.lmnt-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .lmnt.lmnt-select--filled.lmnt-select--with-leading-icon .mdc-floating-label, .lmnt.lmnt-select--filled.lmnt-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.lmnt.lmnt-select--filled.lmnt-select--with-leading-icon .mdc-floating-label {
  max-width: calc(100% - 88px);
}
.lmnt.lmnt-select--filled.lmnt-select--with-leading-icon .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 88px / 0.75);
}
.lmnt.lmnt-select--filled .mdc-floating-label {
  max-width: calc(100% - 60px);
}
.lmnt.lmnt-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 60px / 0.75);
}
.lmnt.lmnt-select--filled .lmnt-select-label.mdc-floating-label--float-above {
  top: 8px;
  transform: scale(0.75);
  transition: top 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 16px;
}
.lmnt.lmnt-select--filled .lmnt-select-dropdown-container {
  margin-bottom: 0;
}

.lmnt.lmnt-select--filled .lmnt.lmnt-select-multi-value-container {
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  background-color: var(--lmnt-theme-surface);
  border-radius: var(--lmnt-chip-shape-radius);
  padding: 6px 8px 6px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 14px;
  line-height: 20px;
  margin: 2px;
}
.lmnt.lmnt-select--filled .lmnt.lmnt-select-multi-value-container .lmnt.lmnt-select-multi-value-container {
  padding: 0;
  border: none;
  margin: 0;
}
.lmnt.lmnt-select--filled .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon {
  color: var(--lmnt-theme-icon-in-component);
  font-size: 15px;
  height: 15px;
  padding-left: 4px;
  width: 15px;
}
.lmnt.lmnt-select--filled .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon:hover {
  color: rgba(0, 0, 0, 0.62);
}
.lmnt.lmnt-select--filled .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon:focus, .lmnt.lmnt-select--filled .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon:active {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt.lmnt-select--outlined {
  border-radius: var(--lmnt-select-shape-radius);
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor {
  height: 40px;
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-29.25px) scale(1);
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-26.75px) scale(0.75);
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-40px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-40px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-26.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-26.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-26.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-26.75px) scale(0.75);
  }
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.lmnt.lmnt-select--outlined.lmnt-select--dense.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 56px);
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-deprecated-list-item {
  height: 32px;
}
.lmnt.lmnt-select--outlined.lmnt-select--dense .mdc-select__anchor {
  min-height: 40px;
}
.lmnt.lmnt-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label, .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label--float-above, .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label--shake, .lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}
.lmnt.lmnt-select--outlined.lmnt-select--with-leading-icon .mdc-floating-label.mdc-floating-label--float-above {
  position: absolute;
  left: 16px;
}
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.lmnt.lmnt-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}
.lmnt.lmnt-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
.lmnt.lmnt-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.lmnt.lmnt-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.lmnt.lmnt-select--outlined .lmnt-select-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.lmnt.lmnt-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}
.lmnt.lmnt-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.lmnt.lmnt-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.lmnt.lmnt-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.lmnt.lmnt-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.lmnt.lmnt-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.lmnt.lmnt-select--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.lmnt.lmnt-select--outlined .mdc-select__anchor {
  display: flex;
  align-items: center;
  overflow: visible;
  height: unset;
  min-height: 56px;
}
.lmnt.lmnt-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.lmnt.lmnt-select--outlined .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.lmnt.lmnt-select--outlined .mdc-select__anchor::before {
  display: none;
}
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi .lmnt-select__anchor--extended-height {
  padding-top: 10px;
  transition: padding-top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi .lmnt-select__anchor--extended-height .lmnt-select-indicators-container {
  align-self: flex-end;
  margin-bottom: 12px;
}
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor {
  align-items: flex-end;
  height: unset;
}
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-indicators-container,
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-leading-icon {
  height: 32px;
}
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor > .lmnt-select-value-container {
  margin-bottom: 5px;
  margin-top: 5px;
}
.lmnt.lmnt-select--outlined.lmnt-select--value-display-pills.lmnt-select--multi.lmnt-select--has-value .mdc-select__anchor .lmnt-select-leading-icon {
  margin-bottom: 9px;
}
.lmnt.lmnt-select--outlined .lmnt-select-menu {
  top: 100%;
}
.lmnt.lmnt-select--outlined .lmnt.lmnt-select-multi-value-container {
  background-color: #e0e0e0;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  padding: 6px 8px 6px 12px;
  font-size: 14px;
  line-height: 20px;
  margin: 2px;
}
.lmnt.lmnt-select--outlined .lmnt.lmnt-select-multi-value-container .lmnt.lmnt-select-multi-value-container {
  padding: 0;
  border: none;
  margin: 0;
}
.lmnt.lmnt-select--outlined .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon {
  color: var(--lmnt-theme-icon-in-component);
  font-size: 15px;
  height: 15px;
  padding-left: 4px;
  width: 15px;
}
.lmnt.lmnt-select--outlined .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon:hover {
  color: rgba(0, 0, 0, 0.62);
}
.lmnt.lmnt-select--outlined .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon:focus, .lmnt.lmnt-select--outlined .lmnt.lmnt-select-multi-value-container .lmnt-select-multi-value-remove--icon:active {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-select-menu .lmnt-list.mdc-list {
  padding: 0;
}
.lmnt-select-menu .lmnt-select__confirmation {
  display: flex;
  justify-content: flex-end;
  padding: 8px 8px;
}
.lmnt-select-menu .lmnt-select__confirmation .lmnt-select__cancel-button {
  margin-right: 8px;
}
.lmnt-select-menu .lmnt-select__option-filter {
  display: flex;
  padding: 0 16px;
}
.lmnt-select-menu .lmnt-select__option-filter .mdc-list-item__ripple:before, .lmnt-select-menu .lmnt-select__option-filter .mdc-list-item__ripple:after {
  content: unset;
}
.lmnt-select-menu .lmnt-select__option-filter .lmnt-select__option-filter-switch {
  margin-left: auto;
}
.lmnt-select-menu .lmnt-select__select-all-checkbox {
  padding-left: 8px;
  height: 56px;
}
.lmnt-select-menu .lmnt-select__select-all-checkbox .mdc-form-field {
  vertical-align: bottom;
}
.lmnt-select-menu .lmnt-select__select-all-checkbox .mdc-form-field > label {
  padding-left: 24px;
}
.lmnt-select-menu .lmnt-select__menu-item {
  height: auto;
  padding-bottom: 12px;
  padding-top: 12px;
}
.lmnt-select-menu .lmnt-select__menu-item.lmnt-select__menu-item--has-focus {
  background-color: rgba(0, 0, 0, 0.12);
}
.lmnt-select-menu .lmnt-select-hidden-item {
  display: none;
}

.lmnt-select--is-disabled .lmnt-select-control-container {
  background-color: transparent;
}
.lmnt-select--is-disabled .lmnt-select-label {
  color: var(--lmnt-theme-on-surface-disabled, rgba(0, 0, 0, 0.38));
}
.lmnt-select--is-disabled .lmnt__single-value.lmnt__single-value--is-disabled {
  color: var(--lmnt-theme-on-surface-disabled, rgba(0, 0, 0, 0.38));
}

.lmnt-select__content {
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fff;
}

.lmnt-select__content--trailing {
  padding-bottom: 16px;
  padding-top: 8px;
}

.lmnt-select__content--leading {
  padding-bottom: 8px;
  padding-top: 16px;
}

.lmnt__menu-portal {
  transition: top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.lmnt.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.lmnt.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.lmnt.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #b00020);
}

.lmnt .mdc-notched-outline__leading,
.lmnt .mdc-notched-outline__trailing {
  --mdc-shape-small: var(--lmnt-select-shape-radius);
  padding-left: 16px;
}

.lmnt-select-container .lmnt-select-indicators-container {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: var(--lmnt-select-shape-radius);
}
.lmnt-select-container .lmnt-select-menu {
  border-radius: var(--lmnt-select-shape-radius);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  margin-top: 0;
  position: absolute;
  width: 100%;
  z-index: 11;
  box-sizing: border-box;
  left: 0;
  border: none;
  height: fit-content;
}
.lmnt-select-container .lmnt__multi-value {
  box-sizing: border-box;
  display: flex;
  min-width: 0;
}
.lmnt-select-container .lmnt__multi-value__label {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lmnt-select-container .lmnt__multi-value__remove {
  align-items: center;
  box-sizing: border-box;
  display: flex;
}
.lmnt-select-container .lmnt__group {
  padding-bottom: 8px;
  padding-top: 8px;
  box-sizing: border-box;
}
.lmnt-select-container .lmnt__menu-notice--no-options {
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 12px;
  text-align: center;
  box-sizing: border-box;
}
.lmnt-select-container .lmnt__single-value {
  color: #333333;
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  position: absolute;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
.lmnt-select-container .lmnt__single-value .lmnt__single-value--is-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.lmnt-select-container .lmnt__value-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  overflow: hidden;
  padding: 2px 8px;
  padding-left: 0px;
  position: relative;
}
.lmnt-select-container .lmnt__value-container input:read-only {
  background: 0;
  border: 0;
  font-size: inherit;
  outline: 0;
  padding: 0;
  width: 1px;
  color: transparent;
  left: -100px;
  opacity: 0;
  position: relative;
  transform: scale(0);
}

/** VUE SELECT styles */
.lmnt-vue-select.lmnt.lmnt-select.mdc-select {
  --min-height: 56px;
  --min-height-dense: 40px;
  /** FILLED SELECT CONTAINER */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect {
  min-height: var(--min-height);
  border-radius: 5px;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  color: #35495e;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__select {
  height: var(--min-height);
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  width: 40px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #999999 transparent transparent transparent;
  content: "";
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__tags {
  border: none;
  /** Hide original border */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /** ensure the option's where it should be */
  padding: 0;
  margin-bottom: 0;
  min-height: var(--min-height);
  background: transparent;
  /** CONTAINER FOR A SINGLE OPTION */
  /** CONTAINER FOR MULTIUPLE OPTIONS */
  /** Hide original placeholder */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__tags .multiselect__single {
  /** reset original container left padding */
  padding: 0 0 0 16px;
  background-color: transparent;
  margin-bottom: 0;
  width: unset;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__tags .multiselect__tags-wrap {
  padding: 0 0 0 8px;
  display: flex;
  flex-wrap: wrap;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__tags .multiselect__placeholder {
  display: none;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__tags .multiselect__input::placeholder {
  opacity: 0;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__content-wrapper {
  position: absolute;
  display: block;
  max-height: 240px;
  width: 100%;
  z-index: 50;
  border: none;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: var(--lmnt-select-fill-color);
  overflow-y: auto;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__content-wrapper .multiselect__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 8px 0;
  margin: 0;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__content-wrapper .multiselect__content .multiselect__element {
  display: flex;
  position: relative;
  height: 48px;
  align-items: center;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: 0;
  overflow: hidden;
  cursor: pointer;
  /** styling the "option items" */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__content-wrapper .multiselect__content .multiselect__element .multiselect__option {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0;
  padding-left: 0;
  line-height: unset;
  color: black;
  /** GENTLY DESTYLING SELECT OPTIONS: getting rid of the original green/red option backgrounds */
  background: unset;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__content-wrapper .multiselect__content .multiselect__element .multiselect__option.multiselect__option--highlight {
  background: rgba(0, 0, 0, 0.11);
  /** this is what makes an option _active_ */
  color: black;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect .multiselect__content-wrapper .multiselect__content .multiselect__element .multiselect__option .lmnt.mdc-list-item {
  width: 100%;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select .multiselect.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled {
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: var(--mdc-shape-small, 4px);
  background-color: var(--lmnt-theme-surface);
  /** FILLED SELECTED OPTION vertical positioning */
  /** ... for single select */
  /** ... for multiselect select */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .multiselect.lmnt-select--multi.lmnt-select--value-display-pills {
  --min-height: 68px;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .multiselect:not(.mdc-select--disabled) {
  background-color: var(--lmnt-select-fill-color);
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .mdc-select:not(.lmnt-select--value-display-pills):not(.lmnt-select--dense) .multiselect__tags {
  align-items: baseline;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .mdc-select .multiselect__tags::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .lmnt-select--multi.mdc-select {
  /* ...the filled multiselect is a bit higher, the list should be a bit lower to reveal the ripple-line */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .lmnt-select--multi.mdc-select .multiselect__tags-wrap::before {
  display: inline-block;
  width: 0;
  height: 46px;
  content: "";
  vertical-align: 0;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .lmnt-select--multi.mdc-select .multiselect__tags-wrap {
  align-items: baseline;
  /** OPTION PILLS */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .lmnt-select--multi.mdc-select .multiselect__tags-wrap .lmnt__multi-value {
  /** prevent double-click text selection */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--filled .lmnt-select--multi.mdc-select .multiselect__content-wrapper {
  margin-top: 8px;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select:not(.lmnt-select--is-disabled) {
  /** Pointer cursor */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select:not(.lmnt-select--is-disabled):hover {
  cursor: pointer;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select:not(.lmnt-select--is-disabled).mdc-select--focused {
  /** Have the label change color on focus, besides floating */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select:not(.lmnt-select--is-disabled).mdc-select--focused .mdc-floating-label {
  color: var(--lmnt-select-active-colo, #6200ee);
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select:not(.lmnt-select--is-disabled).mdc-select--focused .multiselect {
  /** Have the anchor/chevron/arrow pseudo-element of active color on focus */
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select:not(.lmnt-select--is-disabled).mdc-select--focused .multiselect .multiselect__select:before {
  border-color: var(--mdc-theme-primary, #6200ee) transparent transparent;
  color: var(--mdc-theme-primary, #6200ee);
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--dense .multiselect {
  min-height: var(--min-height-dense);
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--dense .multiselect .multiselect__select {
  height: var(--min-height-dense);
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--dense .multiselect .multiselect__tags {
  min-height: var(--min-height-dense);
  align-items: center;
}
.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--dense .multiselect .multiselect__tags .multiselect__tags-wrap::before {
  content: unset;
}

.lmnt-select--searchable .multiselect__tags input {
  /* stolen from textfield for laziness */
  appearance: none;
  background: none;
  border-radius: 0;
  border: none;
  caret-color: var(--mdc-theme-primary);
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  height: 28px;
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  padding: 0 0 0 16px;
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}

.lmnt-vue-select .lmnt-select--icon-clear {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-35%);
}

.lmnt-vue-select.lmnt-select--scroll-class .multiselect__tags {
  margin-left: 16px;
  margin-right: 16px;
  overflow-x: scroll;
  padding-left: 0;
  padding-right: 0;
  /* this is requiring way too much subtracted from the right side */
  width: calc(100% - 80px);
}

.lmnt-vue-select.lmnt.lmnt-select.mdc-select.lmnt-select--scroll-class .multiselect .multiselect__tags .multiselect__tags-wrap {
  /* min-width must be set, or else it doesn't work */
  height: 68px;
  min-width: max-content;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.lmnt.lmnt-select--scroll-class .lmnt.lmnt-select-multi-value-container {
  margin-left: 0;
  margin-right: 4px;
}

.lmnt-input-chip-set-wrapper,
.lmnt-input-chip-container {
  display: flex;
  height: auto;
}
.lmnt-input-chip-set-wrapper .mdc-input-chip-set-wrapper-scrollable,
.lmnt-input-chip-container .mdc-input-chip-set-wrapper-scrollable {
  max-width: calc(100% - 8em);
  overflow-x: auto;
  overflow-y: hidden;
}
.lmnt-input-chip-set-wrapper ::-webkit-scrollbar,
.lmnt-input-chip-container ::-webkit-scrollbar {
  width: 0;
}

.lmnt-input-chip--contained .lmnt-select-clear--icon-container {
  margin-right: 8px;
}

.lmnt-helper-text--invalid {
  color: var(--lmnt-theme-danger);
}

.lmnt-input-label {
  cursor: pointer;
}

@keyframes mdc-linear-progress-primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half, 83.67142%));
  }
  100% {
    transform: translateX(200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full, 200.611057%));
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter, 37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half, 84.386165%));
  }
  100% {
    transform: translateX(160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full, 160.277782%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-buffering {
  from {
    transform: rotate(180deg) translateX(-10px);
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half-neg, -83.67142%));
  }
  100% {
    transform: translateX(-200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full-neg, -200.611057%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter-neg, -37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half-neg, -84.386165%));
  }
  100% {
    transform: translateX(-160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full-neg, -160.277782%));
  }
}
@keyframes mdc-linear-progress-buffering-reverse {
  from {
    transform: translateX(-10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  transform: translateZ(0);
  outline: 1px solid transparent;
  overflow: hidden;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  animation: none;
  border-top: 4px solid;
}
.mdc-linear-progress__buffer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-linear-progress__buffer-dots {
  background-repeat: repeat-x;
  background-size: 10px 4px;
  flex: auto;
  transform: rotate(180deg);
  animation: mdc-linear-progress-buffering 250ms infinite linear;
}
.mdc-linear-progress__buffer-bar {
  flex: 0 1 100%;
  transition: flex-basis 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
}
.mdc-linear-progress__secondary-bar {
  visibility: hidden;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  visibility: visible;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__bar {
  /* @noflip */
  right: 0;
  /* @noflip */
  -webkit-transform-origin: center right;
  /* @noflip */
  transform-origin: center right;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__buffer-dots, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__buffer-dots {
  animation: mdc-linear-progress-buffering-reverse 250ms infinite linear;
  transform: rotate(0);
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  /* @noflip */
  right: -145.166611%;
  /* @noflip */
  left: auto;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  /* @noflip */
  right: -54.888891%;
  /* @noflip */
  left: auto;
}
.mdc-linear-progress--closed {
  opacity: 0;
}
.mdc-linear-progress--closed-animation-off .mdc-linear-progress__buffer-dots {
  animation: none;
}
.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar,
.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
  animation: none;
}

.mdc-linear-progress__bar-inner {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}

.mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6;
}

.lmnt-linear-progress--primary .mdc-linear-progress__bar-inner {
  border-color: var(--lmnt-theme-primary);
}

.lmnt-linear-progress--secondary .mdc-linear-progress__bar-inner {
  border-color: var(--lmnt-theme-secondary);
}

.lmnt-linear-progress--primary-variant .mdc-linear-progress__bar-inner {
  border-color: var(--lmnt-theme-primary-variant);
}

.lmnt-linear-progress--secondary-variant .mdc-linear-progress__bar-inner {
  border-color: var(--lmnt-theme-secondary-variant);
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover::before, .mdc-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}
.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__surface-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog .mdc-dialog__close {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-dialog .mdc-dialog__close::before, .mdc-dialog .mdc-dialog__close::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-dialog .mdc-dialog__close:hover::before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused::before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}
.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}
@media (max-width: 960px) and (max-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    max-width: 560px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (max-width: 600px) and (max-height: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (min-width: 960px) and (min-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 400px);
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}
.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
[dir=rtl] .mdc-dialog__surface, .mdc-dialog__surface[dir=rtl] {
  /* @noflip */
  text-align: right;
}
@media screen and (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}
.mdc-dialog__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface::before {
    content: none;
  }
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: 0 24px 9px;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-dialog__title, .mdc-dialog__title[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  display: inline-flex;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent;
  justify-content: space-between;
  align-items: baseline;
}
.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}
.mdc-dialog--fullscreen .mdc-dialog__title {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}
.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}
.mdc-dialog__button:first-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  text-align: left;
}
.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog__surface-scrim {
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.lmnt {
  --lmnt-modal-shape-radius: var(--lmnt-theme--shape-radius);
}

.lmnt.lmnt-modal .lmnt-modal__container .lmnt-modal__surface {
  max-width: unset;
  min-width: unset;
  border-radius: var(--lmnt-modal-shape-radius);
}
.lmnt.lmnt-modal .lmnt-modal__container.mdc-dialog--fullscreen .mdc-dialog__surface {
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  border-radius: 0;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__max {
  margin: 40px;
  width: 100%;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__max .lmnt-modal__surface {
  width: 100%;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__xl .lmnt-modal__surface {
  width: 960px;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__lg .lmnt-modal__surface {
  width: 720px;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__md .lmnt-modal__surface {
  width: 560px;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__sm .lmnt-modal__surface {
  width: 420px;
}
.lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__dialog .lmnt-modal__surface {
  min-width: 280px;
  max-height: calc(100% - 32px);
  max-width: 560px;
}
.lmnt.lmnt-modal .lmnt-modal__container .lmnt-modal__title {
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.lmnt.lmnt-modal .lmnt-modal__container .lmnt-modal__title.lmnt-modal--no-close-icon {
  padding-left: 24px;
}
.lmnt.lmnt-modal .lmnt-modal__container .lmnt-modal__title .lmnt-modal__title-text {
  margin-left: 5px;
  height: 48px;
  display: flex;
  align-items: center;
}
.lmnt.lmnt-modal .lmnt-modal__container .lmnt-modal__title .lmnt-dialog__title-actions {
  margin-left: auto;
}
.lmnt.lmnt-modal .lmnt-modal__container .lmnt-modal__title .lmnt-dialog__title-actions .lmnt-dialog__title-actions--icon-btn-group {
  display: flex;
}
.lmnt.lmnt-modal .lmnt-dialog__actions__directional {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.lmnt.lmnt-modal .lmnt-dialog__actions__directional .lmnt-modal__button:last-child {
  justify-self: flex-end;
}
.lmnt.lmnt-modal .lmnt-modal__actions--supplemental-btn {
  justify-content: flex-start;
}
.lmnt.lmnt-modal .lmnt-modal__actions--supplemental-btn .lmnt-modal_actions_button {
  margin-left: auto;
  margin-right: 16px;
}

.lmnt-modal__actions.mdc-dialog__actions {
  margin-bottom: 16px;
}

@media (max-width: 592px) {
  .lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__max .lmnt-modal__surface {
    max-width: calc(100vw - 32px);
  }
  .lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__xl .lmnt-modal__surface {
    max-width: calc(100vw - 32px);
  }
  .lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__lg .lmnt-modal__surface {
    max-width: calc(100vw - 32px);
  }
  .lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__md .lmnt-modal__surface {
    max-width: calc(100vw - 32px);
  }
  .lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__sm .lmnt-modal__surface {
    max-width: calc(100vw - 32px);
  }

  .lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions {
    flex-direction: column-reverse;
    align-items: flex-end;
    align-content: stretch;
  }
  .lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions.lmnt-modal__actions--supplemental-btn {
    flex-direction: row;
    justify-content: flex-start;
  }
  .lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions .lmnt-modal_actions_button,
.lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions .lmnt-dialog__actions__supplemental,
.lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions .mdc-touch-target-wrapper,
.lmnt.lmnt-modal_actions-mobile-stacked.mdc-dialog__actions .mdc-button {
    width: 100%;
  }
}
.lmnt.lmnt-modal-no-actions .lmnt-modal__content .lmnt.mdc-circular-progress {
  overflow: hidden;
}

@media (max-width: 592px) {
  .lmnt.lmnt-modal .lmnt-modal__container.lmnt-modal__xl .lmnt-modal__surface {
    max-width: 560px;
  }
}
.lmnt-badge--modal {
  margin-left: auto;
  margin-right: 1em;
}

.lmnt {
  --lmnt-padding--dense: 0.5rem;
  --lmnt-padding--dense-top: var(--lmnt-padding--dense);
  --lmnt-padding--dense-right: var(--lmnt-padding--dense);
  --lmnt-padding--dense-bottom: var(--lmnt-padding--dense);
  --lmnt-padding--dense-left: var(--lmnt-padding--dense);
  --lmnt-padding--standard: 1rem;
  --lmnt-padding--standard-top: var(--lmnt-padding--standard);
  --lmnt-padding--standard-right: var(--lmnt-padding--standard);
  --lmnt-padding--standard-bottom: var(--lmnt-padding--standard);
  --lmnt-padding--standard-left: var(--lmnt-padding--standard);
  --lmnt-padding--airy: 1.5rem;
  --lmnt-padding--airy-top: var(--lmnt-padding--airy);
  --lmnt-padding--airy-right: var(--lmnt-padding--airy);
  --lmnt-padding--airy-bottom: var(--lmnt-padding--airy);
  --lmnt-padding--airy-left: var(--lmnt-padding--airy);
}

.lmnt-padding--dense {
  padding-bottom: var(--lmnt-padding--dense-bottom);
  padding-left: var(--lmnt-padding--dense-left);
  padding-right: var(--lmnt-padding--dense-right);
  padding-top: var(--lmnt-padding--dense-top);
}

.lmnt-padding--standard {
  padding-bottom: var(--lmnt-padding--standard-bottom);
  padding-left: var(--lmnt-padding--standard-left);
  padding-right: var(--lmnt-padding--standard-right);
  padding-top: var(--lmnt-padding--standard-top);
}

.lmnt-padding--airy {
  padding-bottom: var(--lmnt-padding--airy-bottom);
  padding-left: var(--lmnt-padding--airy-left);
  padding-right: var(--lmnt-padding--airy-right);
  padding-top: var(--lmnt-padding--airy-top);
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}
.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__surface-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog .mdc-dialog__close {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-dialog .mdc-dialog__close::before, .mdc-dialog .mdc-dialog__close::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-dialog .mdc-dialog__close:hover::before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused::before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}
.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}
@media (max-width: 960px) and (max-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    max-width: 560px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (max-width: 600px) and (max-height: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (min-width: 960px) and (min-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 400px);
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}
.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
[dir=rtl] .mdc-dialog__surface, .mdc-dialog__surface[dir=rtl] {
  /* @noflip */
  text-align: right;
}
@media screen and (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}
.mdc-dialog__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface::before {
    content: none;
  }
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: 0 24px 9px;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-dialog__title, .mdc-dialog__title[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  display: inline-flex;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent;
  justify-content: space-between;
  align-items: baseline;
}
.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}
.mdc-dialog--fullscreen .mdc-dialog__title {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}
.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}
.mdc-dialog__button:first-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  text-align: left;
}
.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog__surface-scrim {
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.lmnt {
  --lmnt-pagination--button-color: #fff;
  --lmnt-pagination--button-color-active: var(--lmnt-theme-primary-300);
  --lmnt-pagination--button-color-hover: var(--lmnt-theme-primary-50);
  --lmnt-pagination--button-color-selected: var(--lmnt-theme-primary-400);
  --lmnt-pagination__button-height: 2.5rem;
}

.mdc-pagination-container {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  height: 60px;
  user-select: none;
  width: 100%;
}

.mdc-data-table--dense .mdc-pagination-container {
  height: 40px;
}

.mdc-data-table--tall .mdc-pagination-container {
  height: 72px;
}

.mdc-pagination-row-per-page {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.mdc-pagination-nav-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.mdc-pagination-dropdown-button {
  align-items: center;
  background-color: transparent;
  border-width: 0;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  height: 28px;
  letter-spacing: 0.4px;
  line-height: 2.34;
  outline-width: 0;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  text-align: left;
}
.mdc-pagination-dropdown-button .icon {
  font-size: 18px;
}

.mdc-pagination-dropdown-content {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  max-height: 256px;
  min-width: 120px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 100%;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 1;
}

.mdc-pagination-dropdown-content-items {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  max-height: 256px;
  min-width: 70px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 1;
}

.lmnt-pagination--number {
  background-color: var(--lmnt-pagination--button-color);
  border-color: #fff;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.063rem;
  color: black;
  cursor: pointer;
  font-size: 0.75rem;
  height: var(--lmnt-pagination__button-height);
  line-height: 2.5;
  text-align: center;
  vertical-align: middle;
  width: var(--lmnt-pagination__button-height);
}
.lmnt-pagination--number:hover, .lmnt-pagination--number:focus {
  background-color: var(--lmnt-pagination--button-color-hover);
  color: #000;
}
.lmnt-pagination--number:active {
  background-color: var(--lmnt-pagination--button-color-active);
  color: #fff;
}

.lmnt-pagination-number--selected,
.lmnt-pagination-number--selected:hover,
.lmnt-pagination-number--selected:focus {
  background-color: var(--lmnt-pagination--button-color-selected);
  color: #fff;
}

.lmnt-data-table__pagination-select {
  min-width: 90px;
}

.mdc-card {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.mdc-card .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-card::after {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  pointer-events: none;
}

.mdc-card--outlined {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
.mdc-card--outlined::after {
  border: none;
}

.mdc-card__content {
  border-radius: inherit;
  height: 100%;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-card__action--button:last-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-card__primary-action::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-card__primary-action:hover::before, .mdc-card__primary-action.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-product-card {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  background-color: var(--lmnt-theme-surface);
  color: var(--lmnt-theme-on-surface);
  max-width: 100%;
  min-width: 15rem;
  overflow: hidden;
}

.mdc-product-card__box {
  padding: 10px 10px 10px 16px;
  position: relative;
}
.mdc-product-card__box:first-child, .mdc-product-card__box:last-child {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}

.mdc-product-card__actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mdc-product-card__actions * {
  margin: 0 auto;
}

.mdc-product-card__tagline {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1;
  padding: 0.125em 0.2em;
  position: absolute;
  right: 12px;
  text-transform: uppercase;
  top: 0;
}

.mdc-product-card__avatar {
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  width: 40px;
}

.mdc-product-card__avatar-img {
  display: block;
  height: auto;
  width: 100%;
}
@supports (object-fit: cover) {
  .mdc-product-card__avatar-img {
    height: 100%;
    object-fit: cover;
  }
}

.mdc-product-card__header {
  padding-bottom: 14px;
  padding-top: 16px;
}
.mdc-product-card__header .mdc-product-card__avatar {
  position: absolute;
  right: 10px;
  top: 23px;
}

.mdc-product-card__identity {
  margin: 0;
  word-break: break-all;
}

.mdc-product-card__crop-type {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
  opacity: 0.38;
}

.mdc-product-card__sku-name {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  opacity: 0.87;
}

.mdc-product-card__subhead {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  opacity: 0.6;
}

.mdc-product-card__crop-type,
.mdc-product-card__sku-name,
.mdc-product-card__subhead {
  display: block;
}

.mdc-product-card__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
  font-size: 12px;
  line-height: 1.333;
  margin: 0;
  opacity: 0.6;
}

.mdc-product-card__feature {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 10px 16px 16px;
}
.mdc-product-card__feature .mdc-product-card__avatar {
  margin: -6px 0;
}

.mdc-product-card__editor-content :first-child {
  margin-top: 0;
}
.mdc-product-card__editor-content :last-child {
  margin-bottom: 0;
}
.mdc-product-card__editor-content ul {
  padding: 0 0 0 1.1em;
}
.mdc-product-card__editor-content table {
  width: 100%;
}
.mdc-product-card__editor-content td {
  padding: 0.5em 0;
}
.mdc-product-card__editor-content td:nth-child(odd) {
  padding-right: 1em;
}
.mdc-product-card__editor-content td:nth-child(even) {
  padding-left: 1em;
}

.mdc-radio {
  padding: 10px;
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color;
}
.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}
.mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}
.mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-radio [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-radio [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-radio .mdc-radio__background::before {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
}
.mdc-radio .mdc-radio__background::before {
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
}
.mdc-radio .mdc-radio__native-control {
  top: 0px;
  right: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
}
@media screen and (-ms-high-contrast: active) {
  .mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: GrayText;
  }
}
.mdc-radio__background {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}
.mdc-radio__background::before {
  position: absolute;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-width: 10px;
  border-style: solid;
  border-radius: 50%;
  transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 1;
}
.mdc-radio--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mdc-radio--touch .mdc-radio__native-control {
  top: -4px;
  right: -4px;
  left: -4px;
  width: 48px;
  height: 48px;
}

.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.5);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default;
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(1);
  opacity: 0.12;
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-radio .mdc-radio__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-radio .mdc-radio__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-radio.mdc-ripple-upgraded--unbounded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-radio.mdc-ripple-upgraded--foreground-activation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-radio.mdc-ripple-upgraded--foreground-deactivation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before,
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-radio .mdc-radio__ripple::before, .mdc-radio .mdc-radio__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}
.mdc-radio:hover .mdc-radio__ripple::before, .mdc-radio.mdc-ripple-surface--hover .mdc-radio__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__ripple::before, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-radio:not(.mdc-ripple-upgraded) .mdc-radio__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-radio:not(.mdc-ripple-upgraded):active .mdc-radio__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-radio.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__background::before, .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
  content: none;
}

.mdc-radio__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:root {
  --mdc-theme-primary:#6200ee;
  --mdc-theme-secondary:#018786;
  --mdc-theme-background:#fff;
  --mdc-theme-surface:#fff;
  --mdc-theme-error:#b00020;
  --mdc-theme-on-primary:#fff;
  --mdc-theme-on-secondary:#fff;
  --mdc-theme-on-surface:#000;
  --mdc-theme-on-error:#fff;
  --mdc-theme-text-primary-on-background:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark:white;
  --mdc-theme-text-secondary-on-dark:rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark:rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #6200ee  !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary {
  color: #018786  !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020  !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000  !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #6200ee  !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary-bg {
  background-color: #018786  !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

/* stylelint-disable scss/media-feature-value-dollar-variable */
/* stylelint-enable scss/media-feature-value-dollar-variable */
.lmnt-theme-primary-50--bg,
.lmnt-theme-primary-50-bg {
  background-color: #f1edf8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-50 {
  color: #f1edf8;
}

.lmnt-theme-primary-100--bg,
.lmnt-theme-primary-100-bg {
  background-color: #c7b7e4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-100 {
  color: #c7b7e4;
}

.lmnt-theme-primary-200--bg,
.lmnt-theme-primary-200-bg {
  background-color: #b9a5de;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-200 {
  color: #b9a5de;
}

.lmnt-theme-primary-300--bg,
.lmnt-theme-primary-300-bg {
  background-color: #9381d1;
  color: white;
}

.lmnt-theme-primary-300 {
  color: #9381d1;
}

.lmnt-theme-primary-400--bg,
.lmnt-theme-primary-400-bg {
  background-color: #825dc4;
  color: white;
}

.lmnt-theme-primary-400 {
  color: #825dc4;
}

.lmnt-theme-primary-500--bg,
.lmnt-theme-primary-500-bg {
  background-color: #673ab7;
  color: white;
}

.lmnt-theme-primary-500 {
  color: #673ab7;
}

.lmnt-theme-primary-600--bg,
.lmnt-theme-primary-600-bg {
  background-color: #553096;
  color: white;
}

.lmnt-theme-primary-600 {
  color: #553096;
}

.lmnt-theme-primary-700--bg,
.lmnt-theme-primary-700-bg {
  background-color: #4b2b86;
  color: white;
}

.lmnt-theme-primary-700 {
  color: #4b2b86;
}

.lmnt-theme-primary-800--bg,
.lmnt-theme-primary-800-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-primary-800 {
  color: #422575;
}

.lmnt-theme-primary-900--bg,
.lmnt-theme-primary-900-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-primary-900 {
  color: #261643;
}

.lmnt-theme-secondary-50--bg,
.lmnt-theme-secondary-50-bg {
  background-color: #edf8ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-50 {
  color: #edf8ff;
}

.lmnt-theme-secondary-100--bg,
.lmnt-theme-secondary-100-bg {
  background-color: #caecff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-100 {
  color: #caecff;
}

.lmnt-theme-secondary-200--bg,
.lmnt-theme-secondary-200-bg {
  background-color: #b9e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-200 {
  color: #b9e5ff;
}

.lmnt-theme-secondary-300--bg,
.lmnt-theme-secondary-300-bg {
  background-color: #96d9ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-300 {
  color: #96d9ff;
}

.lmnt-theme-secondary-400--bg,
.lmnt-theme-secondary-400-bg {
  background-color: #74ccff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-400 {
  color: #74ccff;
}

.lmnt-theme-secondary-500--bg,
.lmnt-theme-secondary-500-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-500 {
  color: #40baff;
}

.lmnt-theme-secondary-600--bg,
.lmnt-theme-secondary-600-bg {
  background-color: #3599d1;
  color: white;
}

.lmnt-theme-secondary-600 {
  color: #3599d1;
}

.lmnt-theme-secondary-700--bg,
.lmnt-theme-secondary-700-bg {
  background-color: #2f88ba;
  color: white;
}

.lmnt-theme-secondary-700 {
  color: #2f88ba;
}

.lmnt-theme-secondary-800--bg,
.lmnt-theme-secondary-800-bg {
  background-color: #23668c;
  color: white;
}

.lmnt-theme-secondary-800 {
  color: #23668c;
}

.lmnt-theme-secondary-900--bg,
.lmnt-theme-secondary-900-bg {
  background-color: #18445d;
  color: white;
}

.lmnt-theme-secondary-900 {
  color: #18445d;
}

.lmnt-theme-ancillary-100--bg,
.lmnt-theme-ancillary-100-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-100 {
  color: #000;
}

.lmnt-theme-ancillary-200--bg,
.lmnt-theme-ancillary-200-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-200 {
  color: #000;
}

.lmnt-theme-ancillary-300--bg,
.lmnt-theme-ancillary-300-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-300 {
  color: #000;
}

.lmnt-theme-ancillary-400--bg,
.lmnt-theme-ancillary-400-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-400 {
  color: #000;
}

.lmnt-theme-ancillary-500--bg,
.lmnt-theme-ancillary-500-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-500 {
  color: #000;
}

.lmnt-theme-ancillary-600--bg,
.lmnt-theme-ancillary-600-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-600 {
  color: #000;
}

.lmnt-theme-ancillary-700--bg,
.lmnt-theme-ancillary-700-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-700 {
  color: #000;
}

.lmnt-theme-ancillary-800--bg,
.lmnt-theme-ancillary-800-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-800 {
  color: #000;
}

.lmnt-theme-ancillary-900--bg,
.lmnt-theme-ancillary-900-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-900 {
  color: #000;
}

.lmnt-theme-dataVizComparative-50--bg,
.lmnt-theme-dataVizComparative-50-bg {
  background-color: #240f67;
  color: white;
}

.lmnt-theme-dataVizComparative-50 {
  color: #240f67;
}

.lmnt-theme-dataVizComparative-100--bg,
.lmnt-theme-dataVizComparative-100-bg {
  background-color: #2d72c4;
  color: white;
}

.lmnt-theme-dataVizComparative-100 {
  color: #2d72c4;
}

.lmnt-theme-dataVizComparative-200--bg,
.lmnt-theme-dataVizComparative-200-bg {
  background-color: #89c9b7;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-200 {
  color: #89c9b7;
}

.lmnt-theme-dataVizComparative-300--bg,
.lmnt-theme-dataVizComparative-300-bg {
  background-color: #785e9f;
  color: white;
}

.lmnt-theme-dataVizComparative-300 {
  color: #785e9f;
}

.lmnt-theme-dataVizComparative-400--bg,
.lmnt-theme-dataVizComparative-400-bg {
  background-color: #3b8ec8;
  color: white;
}

.lmnt-theme-dataVizComparative-400 {
  color: #3b8ec8;
}

.lmnt-theme-dataVizComparative-500--bg,
.lmnt-theme-dataVizComparative-500-bg {
  background-color: #b7dbbc;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-500 {
  color: #b7dbbc;
}

.lmnt-theme-dataVizComparative-600--bg,
.lmnt-theme-dataVizComparative-600-bg {
  background-color: #4153af;
  color: white;
}

.lmnt-theme-dataVizComparative-600 {
  color: #4153af;
}

.lmnt-theme-dataVizComparative-700--bg,
.lmnt-theme-dataVizComparative-700-bg {
  background-color: #47a3c4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-700 {
  color: #47a3c4;
}

.lmnt-theme-dataVizComparative-800--bg,
.lmnt-theme-dataVizComparative-800-bg {
  background-color: #dfeccb;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-800 {
  color: #dfeccb;
}

.lmnt-theme-dataVizComparative-900--bg,
.lmnt-theme-dataVizComparative-900-bg {
  background-color: #6332ad;
  color: white;
}

.lmnt-theme-dataVizComparative-900 {
  color: #6332ad;
}

.lmnt-theme-dataVizSequential1-50--bg,
.lmnt-theme-dataVizSequential1-50-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-dataVizSequential1-50 {
  color: #261643;
}

.lmnt-theme-dataVizSequential1-100--bg,
.lmnt-theme-dataVizSequential1-100-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-dataVizSequential1-100 {
  color: #422575;
}

.lmnt-theme-dataVizSequential1-200--bg,
.lmnt-theme-dataVizSequential1-200-bg {
  background-color: #6540aa;
  color: white;
}

.lmnt-theme-dataVizSequential1-200 {
  color: #6540aa;
}

.lmnt-theme-dataVizSequential1-300--bg,
.lmnt-theme-dataVizSequential1-300-bg {
  background-color: #7e52d0;
  color: white;
}

.lmnt-theme-dataVizSequential1-300 {
  color: #7e52d0;
}

.lmnt-theme-dataVizSequential1-400--bg,
.lmnt-theme-dataVizSequential1-400-bg {
  background-color: #9a75dd;
  color: white;
}

.lmnt-theme-dataVizSequential1-400 {
  color: #9a75dd;
}

.lmnt-theme-dataVizSequential1-500--bg,
.lmnt-theme-dataVizSequential1-500-bg {
  background-color: #af8bef;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-500 {
  color: #af8bef;
}

.lmnt-theme-dataVizSequential1-600--bg,
.lmnt-theme-dataVizSequential1-600-bg {
  background-color: #c6a6ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-600 {
  color: #c6a6ff;
}

.lmnt-theme-dataVizSequential1-700--bg,
.lmnt-theme-dataVizSequential1-700-bg {
  background-color: #d8c1ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-700 {
  color: #d8c1ff;
}

.lmnt-theme-dataVizSequential1-800--bg,
.lmnt-theme-dataVizSequential1-800-bg {
  background-color: #e8dbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-800 {
  color: #e8dbff;
}

.lmnt-theme-dataVizSequential1-900--bg,
.lmnt-theme-dataVizSequential1-900-bg {
  background-color: #f5efff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-900 {
  color: #f5efff;
}

.lmnt-theme-dataVizSequential2--50--bg,
.lmnt-theme-dataVizSequential2--50-bg {
  background-color: #052d44;
  color: white;
}

.lmnt-theme-dataVizSequential2--50 {
  color: #052d44;
}

.lmnt-theme-dataVizSequential2--100--bg,
.lmnt-theme-dataVizSequential2--100-bg {
  background-color: #08517b;
  color: white;
}

.lmnt-theme-dataVizSequential2--100 {
  color: #08517b;
}

.lmnt-theme-dataVizSequential2--200--bg,
.lmnt-theme-dataVizSequential2--200-bg {
  background-color: #1774aa;
  color: white;
}

.lmnt-theme-dataVizSequential2--200 {
  color: #1774aa;
}

.lmnt-theme-dataVizSequential2--300--bg,
.lmnt-theme-dataVizSequential2--300-bg {
  background-color: #319bd8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--300 {
  color: #319bd8;
}

.lmnt-theme-dataVizSequential2--400--bg,
.lmnt-theme-dataVizSequential2--400-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--400 {
  color: #40baff;
}

.lmnt-theme-dataVizSequential2--500--bg,
.lmnt-theme-dataVizSequential2--500-bg {
  background-color: #75cdff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--500 {
  color: #75cdff;
}

.lmnt-theme-dataVizSequential2--600--bg,
.lmnt-theme-dataVizSequential2--600-bg {
  background-color: #97e0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--600 {
  color: #97e0ff;
}

.lmnt-theme-dataVizSequential2--700--bg,
.lmnt-theme-dataVizSequential2--700-bg {
  background-color: #a9f0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--700 {
  color: #a9f0ff;
}

.lmnt-theme-dataVizSequential2--800--bg,
.lmnt-theme-dataVizSequential2--800-bg {
  background-color: #c3fbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--800 {
  color: #c3fbff;
}

.lmnt-theme-dataVizSequential2--900--bg,
.lmnt-theme-dataVizSequential2--900-bg {
  background-color: #ddfffd;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--900 {
  color: #ddfffd;
}

.lmnt-theme--bg-linear-gradient-primary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-white-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-white-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

:root,
.lmnt {
  --lmnt-theme-on-background: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface: rgba(0, 0, 0, 0.87);
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-background: #fff;
  --lmnt-theme-surface: #fff;
  --lmnt-theme-surface-variant: #f5f5f5;
  --lmnt-theme-dark: #000;
  --lmnt-theme-light: #fff;
  --lmnt-theme-on-unknown-light: #000;
  --lmnt-theme-on-unknown-dark: #fff;
}

.lmnt {
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-secondary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-black-rgb: 0, 0, 0;
  --lmnt-white-rgb: 255, 255, 255;
  --lmnt-theme-primary: #673ab7;
  --lmnt-theme-on-primary: white;
  --lmnt-theme-primary-variant: #4b2b86;
  --lmnt-theme-on-primary-variant: white;
  --lmnt-theme-primary-on-surface: #673ab7;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-theme-secondary: #40baff;
  --lmnt-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --lmnt-theme-secondary-variant: #2f88ba;
  --lmnt-theme-on-secondary-variant: white;
  --lmnt-theme-secondary-on-surface: #23668c;
  --lmnt-theme-danger: #cf2d22;
  --lmnt-theme-on-danger: white;
  --lmnt-theme-danger-alt: #f5f5f5;
  --lmnt-theme-on-danger-alt: #cf2d22;
  --lmnt-theme-danger-variant: #b3190d;
  --lmnt-theme-on-danger-variant: white;
  --lmnt-theme-danger-on-surface: #cf2d22;
  --lmnt-theme-success: #0ea01a;
  --lmnt-theme-on-success: white;
  --lmnt-theme-success-variant: #13c545;
  --lmnt-theme-success-on-surface: #01830c;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-secondary-on-surface-rgb: 35, 102, 140;
  --lmnt-danger-on-surface-rgb: 207, 45, 34;
  --lmnt-success-on-surface-rgb: 207, 45, 34;
  --lmnt-on-primary-rgb: 255, 255, 255;
  --lmnt-on-secondary-rgb: 0, 0, 0;
  --lmnt-on-danger-rgb: 255, 255, 255;
  --lmnt-on-success-rgb: 255, 255, 255;
  --lmnt-theme-breakpoint-mobile-min: 0;
  --lmnt-theme-breakpoint-mobile-max: 599px;
  --lmnt-theme-breakpoint-tablet-sm-min: 600px;
  --lmnt-theme-breakpoint-tablet-sm-max: 719px;
  --lmnt-theme-breakpoint-tablet-lg-min: 720px;
  --lmnt-theme-breakpoint-tablet-lg-max: 839px;
  --lmnt-theme-breakpoint-desktop-sm-min: 840px;
  --lmnt-theme-breakpoint-desktop-sm-max: 1023px;
  --lmnt-theme-breakpoint-desktop-lg-min: 1024px;
  --lmnt-theme-breakpoint-desktop-lg-max: 1439px;
  --lmnt-theme-breakpoint-desktop-xl-min: 1440px;
  --lmnt-theme-breakpoint-content-max-width-md: 776px;
  --lmnt-theme-breakpoint-content-max-width-lg: 1052px;
  --lmnt-theme-breakpoint-content-max-width-xl: 1176px;
  --lmnt-theme-primary-50: #f1edf8;
  --lmnt-theme-primary-100: #c7b7e4;
  --lmnt-theme-primary-200: #b9a5de;
  --lmnt-theme-primary-300: #9381d1;
  --lmnt-theme-primary-400: #825dc4;
  --lmnt-theme-primary-500: #673ab7;
  --lmnt-theme-primary-600: #553096;
  --lmnt-theme-primary-700: #4b2b86;
  --lmnt-theme-primary-800: #422575;
  --lmnt-theme-primary-900: #261643;
  --lmnt-theme-secondary-50: #edf8ff;
  --lmnt-theme-secondary-100: #caecff;
  --lmnt-theme-secondary-200: #b9e5ff;
  --lmnt-theme-secondary-300: #96d9ff;
  --lmnt-theme-secondary-400: #74ccff;
  --lmnt-theme-secondary-500: #40baff;
  --lmnt-theme-secondary-600: #3599d1;
  --lmnt-theme-secondary-700: #2f88ba;
  --lmnt-theme-secondary-800: #23668c;
  --lmnt-theme-secondary-900: #18445d;
  --lmnt-theme-success-50: #eeffef;
  --lmnt-theme-success-100: #d3ffd7;
  --lmnt-theme-success-200: #a9f6af;
  --lmnt-theme-success-300: #6fef7a;
  --lmnt-theme-success-400: #29e539;
  --lmnt-theme-success-500: #13c545;
  --lmnt-theme-success-600: #0ea01a;
  --lmnt-theme-success-700: #01830c;
  --lmnt-theme-success-800: #075a0e;
  --lmnt-theme-success-900: #053709;
  --lmnt-theme-danger-50: #ffeaed;
  --lmnt-theme-danger-100: #ffccce;
  --lmnt-theme-danger-200: #ee9894;
  --lmnt-theme-danger-300: #e3716a;
  --lmnt-theme-danger-400: #ec5145;
  --lmnt-theme-danger-500: #ef4227;
  --lmnt-theme-danger-600: #e13727;
  --lmnt-theme-danger-700: #cf2d22;
  --lmnt-theme-danger-800: #c2261b;
  --lmnt-theme-danger-900: #b3190d;
  --lmnt-theme-ancillary-100: #000;
  --lmnt-theme-ancillary-200: #000;
  --lmnt-theme-ancillary-300: #000;
  --lmnt-theme-ancillary-400: #000;
  --lmnt-theme-ancillary-500: #000;
  --lmnt-theme-ancillary-600: #000;
  --lmnt-theme-ancillary-700: #000;
  --lmnt-theme-ancillary-800: #000;
  --lmnt-theme-ancillary-900: #000;
  --lmnt-theme-dataVizSequential1-50: #261643;
  --lmnt-theme-dataVizSequential1-100: #422575;
  --lmnt-theme-dataVizSequential1-200: #6540aa;
  --lmnt-theme-dataVizSequential1-300: #7e52d0;
  --lmnt-theme-dataVizSequential1-400: #9a75dd;
  --lmnt-theme-dataVizSequential1-500: #af8bef;
  --lmnt-theme-dataVizSequential1-600: #c6a6ff;
  --lmnt-theme-dataVizSequential1-700: #d8c1ff;
  --lmnt-theme-dataVizSequential1-800: #e8dbff;
  --lmnt-theme-dataVizSequential1-900: #f5efff;
  --lmnt-theme-dataVizSequential2--50: #052d44;
  --lmnt-theme-dataVizSequential2--100: #08517b;
  --lmnt-theme-dataVizSequential2--200: #1774aa;
  --lmnt-theme-dataVizSequential2--300: #319bd8;
  --lmnt-theme-dataVizSequential2--400: #40baff;
  --lmnt-theme-dataVizSequential2--500: #75cdff;
  --lmnt-theme-dataVizSequential2--600: #97e0ff;
  --lmnt-theme-dataVizSequential2--700: #a9f0ff;
  --lmnt-theme-dataVizSequential2--800: #c3fbff;
  --lmnt-theme-dataVizSequential2--900: #ddfffd;
  --lmnt-theme-dataVizComparative-50: #240f67;
  --lmnt-theme-dataVizComparative-100: #2d72c4;
  --lmnt-theme-dataVizComparative-200: #89c9b7;
  --lmnt-theme-dataVizComparative-300: #785e9f;
  --lmnt-theme-dataVizComparative-400: #3b8ec8;
  --lmnt-theme-dataVizComparative-500: #b7dbbc;
  --lmnt-theme-dataVizComparative-600: #4153af;
  --lmnt-theme-dataVizComparative-700: #47a3c4;
  --lmnt-theme-dataVizComparative-800: #dfeccb;
  --lmnt-theme-dataVizComparative-900: #6332ad;
  --lmnt-theme-on-surface-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-surface-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-surface-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-surface-base: black;
  --lmnt-theme-on-primary-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-primary-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-primary-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-primary-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-primary-base: white;
  --lmnt-theme-on-secondary-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-secondary-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-secondary-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-secondary-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-secondary-base: black;
  --lmnt-theme-on-success-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-success-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-success-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-success-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-success-base: white;
  --lmnt-theme-on-danger-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-danger-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-danger-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-danger-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-danger-base: white;
  --lmnt-theme-on-unknown-black-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-unknown-black-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-unknown-black-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-unknown-black-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-unknown-black-base: black;
  --lmnt-theme-on-unknown-white-active: white;
  --lmnt-theme-on-unknown-white-inactive: rgba(255, 255, 255, 0.54);
  --lmnt-theme-on-unknown-white-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-unknown-white-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-unknown-white-base: white;
  --lmnt-theme-black: #000;
  --lmnt-theme-white: #fff;
  --lmnt-theme-icon-in-component: rgba(#000, 0.54);
  --lmnt-theme-emphasis-active-black: 0.87;
  --lmnt-theme-emphasis-active: 1;
  --lmnt-theme-emphasis-completed: 0.6;
  --lmnt-theme-emphasis-disabled: 0.38;
  --lmnt-theme-emphasis-dragged: 0.08;
  --lmnt-theme-emphasis-focus: 0.12;
  --lmnt-theme-emphasis-high-black: 0.87;
  --lmnt-theme-emphasis-high: 1;
  --lmnt-theme-emphasis-highlighted: 0.24;
  --lmnt-theme-emphasis-hover--alt: 0.08;
  --lmnt-theme-emphasis-hover: 0.04;
  --lmnt-theme-emphasis-inactive: 0.6;
  --lmnt-theme-emphasis-low: 0.38;
  --lmnt-theme-emphasis-med: 0.6;
  --lmnt-theme-emphasis-pressed--alt: 0.32;
  --lmnt-theme-emphasis-pressed: 0.16;
  --lmnt-theme-emphasis-resting: 0.08;
  --lmnt-theme-emphasis-focus--alt: 0.24;
  --lmnt-theme-emphasis-selected: 0.08;
  --lmnt-theme-emphasis-stroke: 0.12;
  --lmnt-theme-emphasis-stroke-dark: 0.24;
  --lmnt-theme-emphasis-icon-in-component: 0.54;
  --lmnt-theme-emphasis-on-surface-active-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-active: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-completed: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-disabled: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-dragged: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-focus--alt: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-focus: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-high-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-high: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-highlighted: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-hover-alt: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-hover: calc(1 - 0.04);
  --lmnt-theme-emphasis-on-surface-inactive: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-low: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-med: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-pressed-alt: calc(1 - 0.32);
  --lmnt-theme-emphasis-on-surface-resting: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-selected: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-stroke: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-stroke-dark: calc(1 - 0.24);
  --mdc-typography-font-family: var(--lmnt-theme-primary-font);
  --mdc-theme-primary: var(--lmnt-theme-primary);
  --mdc-theme-on-primary: var(--lmnt-theme-on-primary);
  --mdc-theme-secondary: var(--lmnt-theme-secondary);
  --mdc-theme-on-secondary: var(--lmnt-theme-on-secondary);
  --mdc-theme-background: var(--lmnt-theme-background);
  --mdc-theme-surface: var(--lmnt-theme-surface);
  --mdc-theme-on-surface: var(--lmnt-theme-on-surface);
  --mdc-theme-error: var(--lmnt-theme-danger);
  --mdc-theme-on-error: var(--lmnt-theme-on-danger);
  --lmnt-button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --lmnt-theme--shape-radius: 4px;
  --lmnt-card-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-primary-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-primary-on-light: var(--lmnt-theme-on-unknown-black);
  --mdc-theme-text-secondary-on-background: rgba(var(--lmnt-black-rgb), 0.54);
  --mdc-theme-text-disabled-on-background: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-disabled-on-dark: var(--lmnt-theme-on-unknown-white-disabled);
  --mdc-theme-text-disabled-on-light: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-icon-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-icon-on-light: var(--lmnt-theme-on-unknown-black);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  margin: 0;
}

.lmnt {
  --form-field-color-on-primary: var(--lmnt-theme-on-primary);
  --form-field-color-on-secondary: var(--lmnt-theme-on-secondary);
}

.mdc-input-label {
  cursor: pointer;
}

.lmnt-theme-primary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-primary);
}

.lmnt-theme-secondary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-secondary);
}

.lmnt {
  --lmnt-radio--primary: var(--lmnt-theme-primary-on-surface);
  --lmnt-radio--on-primary-accent: var(--lmnt-theme-on-primary);
  --lmnt-radio--on-primary-base: var(--lmnt-theme-on-primary);
  --lmnt-radio--secondary: var(--lmnt-theme-secondary-on-surface);
  --lmnt-radio--on-secondary-accent: var(--lmnt-theme-on-secondary);
  --lmnt-radio--on-secondary-base: var(--lmnt-theme-on-secondary);
}

.lmnt-radio--primary,
.mdc-radio.lmnt-radio,
.mdc-radio.lmnt-radio.lmnt-radio--primary {
  --mdc-theme-secondary: var(--lmnt-radio--primary);
}

.lmnt-radio--secondary,
.mdc-radio.lmnt-radio.lmnt-radio--secondary {
  --mdc-theme-secondary: var(--lmnt-radio--secondary);
}

.lmnt-theme-primary-bg .mdc-radio.lmnt-radio {
  --mdc-theme-secondary: var(--lmnt-radio--on-primary-base);
}
.lmnt-theme-primary-bg .mdc-radio.lmnt-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--lmnt-radio--on-primary-base);
}

.lmnt-theme-secondary-bg .mdc-radio.lmnt-radio {
  --mdc-theme-secondary: var(--lmnt-radio--on-secondary-base);
}
.lmnt-theme-secondary-bg .mdc-radio.lmnt-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--lmnt-radio--on-secondary-base);
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden;
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-ripple-surface::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-ripple-surface:hover::before, .mdc-ripple-surface.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded],
.mdc-ripple-upgraded--unbounded {
  overflow: visible;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after,
.mdc-ripple-upgraded--unbounded::before,
.mdc-ripple-upgraded--unbounded::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::before,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.lmnt .mdc-line-ripple {
  height: unset;
  opacity: unset;
  position: unset;
  transform: unset;
  transition: unset;
  width: unset;
  z-index: unset;
}
.lmnt .mdc-select .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.lmnt .mdc-select__dropdown-icon {
  bottom: unset;
}

.lmnt .lmnt-side-sheet-container--sheet-is-visible {
  max-height: 100vh;
  overflow: hidden;
}
@media only screen and (min-width: 60rem) {
  .lmnt .lmnt-side-sheet-container--sheet-is-visible {
    overflow: unset;
  }
  .lmnt .lmnt-side-sheet-container--sheet-is-visible.lmnt-side-sheet-container--modal {
    overflow: hidden;
  }
}
.lmnt .lmnt-side-sheet {
  background-color: var(--lmnt-theme-surface, #fff);
  bottom: 0;
  box-shadow: none;
  color: var(--lmnt-theme-on-surface);
  display: block;
  max-width: calc(100vw - 3.5rem);
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition-duration: 75ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  z-index: 8;
}
.lmnt .lmnt-side-sheet--position-left {
  right: unset;
  left: 0;
  transform: translateX(-100%);
}
@media only screen and (min-width: 30rem) {
  .lmnt .lmnt-side-sheet {
    max-width: 20rem;
  }
}
.lmnt .lmnt-side-sheet--is-visible {
  transform: translateX(0);
  transition-duration: 150ms;
}
.lmnt .lmnt-side-sheet__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  display: none;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.25rem;
  width: 20rem;
}
@media only screen and (min-width: 60rem) {
  .lmnt .lmnt-side-sheet__content {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.lmnt .lmnt-side-sheet--is-hiding,
.lmnt .lmnt-side-sheet--is-visible {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.lmnt .lmnt-side-sheet--is-hiding .lmnt-side-sheet__content,
.lmnt .lmnt-side-sheet--is-visible .lmnt-side-sheet__content {
  display: block;
}
.lmnt .lmnt-side-sheet__header {
  min-height: 3rem;
  padding-right: 3rem;
  position: relative;
}
.lmnt .lmnt-side-sheet__dismiss {
  position: absolute;
  right: 0;
  top: 0;
}
.lmnt .lmnt-side-sheet__headline {
  display: inline-block;
  padding-top: 0.6em;
}
.lmnt .lmnt-side-sheet-scrim {
  background-color: rgba(0, 0, 0, 0.32);
  bottom: 0;
  display: block;
  left: 100%;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: 150ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  z-index: 7;
}
.lmnt .lmnt-side-sheet-scrim--is-visible {
  left: 0;
  opacity: 1;
}
@media only screen and (min-width: 60rem) {
  .lmnt .lmnt-side-sheet-scrim {
    display: none;
  }
  .lmnt .lmnt-side-sheet-scrim--modal {
    display: block;
  }
}
@media only screen and (min-width: 60rem) {
  .lmnt .lmnt-side-sheet-wrapper--elevated {
    position: relative;
  }
  .lmnt .lmnt-side-sheet--elevated {
    max-width: 0;
    position: absolute;
    transform: translateX(0);
    transition-property: max-width;
    z-index: auto;
  }
  .lmnt .lmnt-side-sheet--elevated.lmnt-side-sheet--is-visible {
    max-width: 20rem;
  }
}
@media only screen and (min-width: 60rem) {
  .lmnt .lmnt-side-sheet-wrapper--coplanar {
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  .lmnt .lmnt-side-sheet-wrapper--coplanar > *:not(.lmnt-side-sheet--coplanar) {
    order: 2;
  }
  .lmnt .lmnt-side-sheet--coplanar {
    bottom: auto;
    box-shadow: none;
    flex-shrink: 0;
    max-width: 0;
    position: relative;
    right: auto;
    top: auto;
    transform: translateX(0);
    transition-property: max-width;
    z-index: auto;
    order: 3;
  }
  .lmnt .lmnt-side-sheet--coplanar.lmnt-side-sheet--is-visible {
    max-width: 20rem;
  }
  .lmnt .lmnt-side-sheet--coplanar.lmnt-side-sheet--position-left {
    left: auto;
    right: unset;
    order: 1;
  }
  .lmnt .lmnt-side-sheet--coplanar.lmnt-side-sheet--is-hiding, .lmnt .lmnt-side-sheet--coplanar.lmnt-side-sheet--is-visible {
    border-left-color: currentColor;
    border-left-style: solid;
    border-left-width: 0.063rem;
  }
  .lmnt .lmnt-side-sheet--coplanar.lmnt-side-sheet--is-hiding.lmnt-side-sheet--position-left, .lmnt .lmnt-side-sheet--coplanar.lmnt-side-sheet--is-visible.lmnt-side-sheet--position-left {
    border-left: none;
    border-right-color: currentColor;
    border-right-style: solid;
    border-right-width: 0.063rem;
  }
}

.mdc-slider__thumb {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-slider__thumb::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-slider__thumb::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-slider__thumb.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-slider__thumb.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-slider__thumb.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-slider__thumb.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-slider__thumb.mdc-ripple-upgraded::before, .mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-slider__thumb.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-slider__thumb:hover::before, .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider {
  cursor: pointer;
  height: 48px;
  margin: 0 24px;
  position: relative;
  touch-action: pan-y;
}
.mdc-slider .mdc-slider__track {
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.mdc-slider .mdc-slider__track--active,
.mdc-slider .mdc-slider__track--inactive {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}
.mdc-slider .mdc-slider__track--active {
  border-radius: 3px;
  height: 6px;
  overflow: hidden;
  top: -1px;
}
.mdc-slider .mdc-slider__track--active_fill {
  border-top: 6px solid;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  /* @noflip */
  -webkit-transform-origin: left;
  /* @noflip */
  transform-origin: left;
}
[dir=rtl] .mdc-slider .mdc-slider__track--active_fill, .mdc-slider .mdc-slider__track--active_fill[dir=rtl] {
  /* @noflip */
  -webkit-transform-origin: right;
  /* @noflip */
  transform-origin: right;
}
.mdc-slider .mdc-slider__track--inactive {
  border-radius: 2px;
  height: 4px;
  left: 0;
  top: 0;
}
.mdc-slider .mdc-slider__track--inactive::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
.mdc-slider .mdc-slider__track--active_fill {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}
.mdc-slider .mdc-slider__track--inactive {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  opacity: 0.24;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.24;
}
.mdc-slider .mdc-slider__value-indicator-container {
  bottom: 44px;
  /* @noflip */
  left: 50%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
}
.mdc-slider .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0.4, 0, 1, 1);
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 12px;
  transform: scale(0);
  transform-origin: bottom;
}
.mdc-slider .mdc-slider__value-indicator::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  bottom: -5px;
  content: "";
  height: 0;
  /* @noflip */
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
}
.mdc-slider .mdc-slider__value-indicator::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator-container {
  pointer-events: auto;
}
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(1);
}
@media (prefers-reduced-motion) {
  .mdc-slider .mdc-slider__value-indicator,
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
    transition: none;
  }
}
.mdc-slider .mdc-slider__value-indicator-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}
.mdc-slider .mdc-slider__value-indicator {
  background-color: #000;
  opacity: 0.6;
}
.mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: #000;
}
.mdc-slider .mdc-slider__value-indicator {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-slider .mdc-slider__thumb {
  display: flex;
  height: 48px;
  /* @noflip */
  left: -24px;
  outline: none;
  position: absolute;
  user-select: none;
  width: 48px;
}
.mdc-slider .mdc-slider__thumb--top {
  z-index: 1;
}
.mdc-slider .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border: 1px solid;
  box-sizing: content-box;
  border-color: #fff;
}
.mdc-slider .mdc-slider__thumb-knob {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 10px solid;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  /* @noflip */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}
.mdc-slider .mdc-slider__thumb-knob {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}
.mdc-slider .mdc-slider__thumb::before, .mdc-slider .mdc-slider__thumb::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-slider .mdc-slider__thumb:hover::before, .mdc-slider .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-slider .mdc-slider__tick-marks {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 1px;
  position: absolute;
  width: 100%;
}
.mdc-slider .mdc-slider__tick-mark--active,
.mdc-slider .mdc-slider__tick-mark--inactive {
  border-radius: 50%;
  height: 2px;
  width: 2px;
}
.mdc-slider .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}
.mdc-slider .mdc-slider__tick-mark--inactive {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  opacity: 0.6;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.6;
}
.mdc-slider.mdc-slider--disabled {
  opacity: 0.38;
  cursor: auto;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__thumb {
  pointer-events: none;
}
.mdc-slider--discrete .mdc-slider__thumb,
.mdc-slider--discrete .mdc-slider__track--active_fill {
  transition: transform 80ms ease;
}
@media (prefers-reduced-motion) {
  .mdc-slider--discrete .mdc-slider__thumb,
.mdc-slider--discrete .mdc-slider__track--active_fill {
    transition: none;
  }
}

.mdc-slider__input {
  cursor: pointer;
  left: 0;
  margin: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-slider.mdc-slider--secondary {
  --mdc-theme-primary: var(--lmnt-theme-secondary, --mdc-theme-secondary);
}

.lmnt-slider-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lmnt-slider-container .lmnt-slider-inner {
  flex-grow: 4;
}
.lmnt-slider-container .lmnt-slider-inner .mdc-slider {
  margin: 0 20px;
}

.lmnt-slider-info {
  flex-grow: 0;
}

.lmnt-slider-info--primary {
  color: var(--lmnt-theme-primary);
}

.lmnt-slider-info--secondary {
  color: var(--lmnt-theme-secondary);
}

.lmnt-slider-info-with-input {
  width: 4.375rem;
}

.lmnt-slider--disabled .lmnt-slider-info {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}
@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}

.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--open .mdc-snackbar__label,
.mdc-snackbar--open .mdc-snackbar__actions {
  visibility: visible;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__label {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 8px;
  padding-bottom: 12px;
}
[dir=rtl] .mdc-snackbar--stacked .mdc-snackbar__label, .mdc-snackbar--stacked .mdc-snackbar__label[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}
.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-snackbar__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
[dir=rtl] .mdc-snackbar__surface, .mdc-snackbar__surface[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}
.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 8px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
}
[dir=rtl] .mdc-snackbar__label, .mdc-snackbar__label[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  visibility: hidden;
}

.mdc-snackbar__action:not(:disabled) {
  color: #bb86fc;
}
.mdc-snackbar__action::before, .mdc-snackbar__action::after {
  background-color: #bb86fc;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #bb86fc);
}
.mdc-snackbar__action:hover::before, .mdc-snackbar__action.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-snackbar__action.mdc-ripple-upgraded--background-focused::before, .mdc-snackbar__action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}
.mdc-snackbar__dismiss::before, .mdc-snackbar__dismiss::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.mdc-snackbar__dismiss:hover::before, .mdc-snackbar__dismiss.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused::before, .mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 9px;
  font-size: 18px;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss svg,
.mdc-snackbar__dismiss.mdc-snackbar__dismiss img {
  width: 18px;
  height: 18px;
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.lmnt {
  --lmnt-theme-on-snackbar: var(--lmnt-theme-primary-200);
}
.lmnt.lmnt-snackbar .mdc-snackbar__action:not(:disabled) {
  color: var(--lmnt-theme-on-snackbar);
}
.lmnt.lmnt-snackbar-secondary .mdc-snackbar__action:not(:disabled) {
  color: var(--lmnt-theme-on-snackbar-variant);
}

.st-demo-group {
  gap: 12px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.st-demo-group > div {
  width: 250px;
}

.lmnt {
  --lmnt-stepper--rule: solid 1px var(--lmnt-theme-on-surface-stroke);
  --lmnt-stepper--vertical-width: 360px;
  --lmnt-stepper__button-container--width: var(--lmnt-stepper__content--width);
  --lmnt-stepper__content--width: 600px;
  --lmnt-stepper__step--dense-height: 56px;
  --lmnt-stepper__step--font-family: var(--lmnt-theme-primary-font);
  --lmnt-stepper__step--height: 64px;
  --lmnt-stepper__step--shape-radius: var(--lmnt-theme--shape-radius);
  --lmnt-stepper__step-divider--margin: 4px;
  --lmnt-stepper__step-indicator--dense-margin: 12px 12px;
  --lmnt-stepper__step-indicator--margin: 12px 16px;
  --lmnt-stepper__step-text--dense-margin-rtl: 12px 0 12px 12px;
  --lmnt-stepper__step-text--dense-margin: 12px 12px 12px 0;
  --lmnt-stepper__step-text--margin-rtl: 12px 0 12px 16px;
  --lmnt-stepper__step-text--margin: 12px 16px 12px 0;
  --lmnt-stepper__step-title--font-weight: 700;
}

.lmnt-stepper {
  align-items: center;
  border-bottom: var(--lmnt-stepper--rule);
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}

.lmnt-stepper--rtl {
  flex-direction: row-reverse;
}

.lmnt-stepper--vertical {
  align-items: flex-start;
  border-bottom: unset;
  border-right: var(--lmnt-stepper--rule);
  flex-direction: column;
  height: 100%;
  min-width: var(--lmnt-stepper--vertical-width);
  width: fit-content;
}

.lmnt-stepper--rtl.lmnt-stepper--vertical {
  align-items: flex-end;
  border-left: var(--lmnt-stepper--rule);
  border-right: unset;
}

.lmnt-stepper--un-bordered {
  border: unset;
}

.lmnt-stepper__container--vertical {
  align-items: stretch;
  display: flex;
  padding: none;
  text-align: center;
}

.lmnt-stepper__container--rtl {
  flex-direction: row-reverse;
}

.lmnt-stepper__step {
  background-color: unset;
  border: none;
  border-radius: var(--lmnt-stepper__step--shape-radius);
  color: unset;
  display: flex;
  font-family: var(--lmnt-stepper__step--font-family);
  font-weight: unset;
  height: var(--lmnt-stepper__step--height);
  margin: 0;
  padding: 0;
  text-align: unset;
}

.lmnt-stepper--rtl .lmnt-stepper__step {
  flex-direction: row-reverse;
}

.lmnt-stepper--dense .lmnt-stepper__step {
  height: var(--lmnt-stepper__step--dense-height);
}

.lmnt-stepper--non-linear .lmnt-stepper__step {
  cursor: pointer;
}

.lmnt-stepper--non-linear .lmnt-stepper__step:hover {
  background-color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-hover));
}

.lmnt-stepper--non-linear .lmnt-stepper__step:focus {
  background-color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-focus));
}

.lmnt-stepper--non-linear .lmnt-stepper__step:active {
  background-color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-pressed));
}

.lmnt-stepper__step--active {
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-active-black));
}

.lmnt-stepper__step--completed {
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-completed));
}

.lmnt-stepper__step--inactive {
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-inactive));
}

.lmnt-stepper__step--incomplete {
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-med));
}

.lmnt-stepper__step--danger {
  color: rgba(0, 0, 0, var(--lmnt-theme-emphasis-high-black));
}

.lmnt-stepper__step-indicator {
  margin: var(--lmnt-stepper__step-indicator--margin);
}

.lmnt-stepper--dense .lmnt-stepper__step-indicator {
  margin: var(--lmnt-stepper__step-indicator--dense-margin);
}

.lmnt-stepper__step-text {
  align-self: center;
  flex-wrap: nowrap;
  margin: var(--lmnt-stepper__step-text--margin);
}

.lmnt-stepper--rtl .lmnt-stepper__step-text {
  flex-direction: row-reverse;
  margin: var(--lmnt-stepper__step-text--margin-rtl);
}

.lmnt-stepper--dense .lmnt-stepper__step-text {
  margin: var(--lmnt-stepper__step-text--dense-margin);
}

.lmnt-stepper--rtl.lmnt-stepper--dense .lmnt-stepper__step-text {
  margin: var(--lmnt-stepper__step-text--dense-margin-rtl);
}

.mdc-typography--subtitle1.lmnt-stepper__step-title {
  font-weight: var(--lmnt-stepper__step-title--font-weight);
  white-space: nowrap;
}

.lmnt-stepper__step-divider {
  align-self: center;
  flex-grow: 1;
  margin: 0 var(--lmnt-stepper__step-divider--margin);
  width: auto;
}

.lmnt-stepper__content-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: none;
  width: 100%;
}

.lmnt-stepper__step-content {
  margin: 0 auto;
  text-align: center;
  width: var(--lmnt-stepper__content--width);
}

.lmnt-stepper__step-content--hidden {
  display: none;
}

.lmnt-stepper__step-content--full-width {
  box-sizing: border-box;
  width: 100%;
}

.lmnt-stepper__step-button--hidden {
  display: none;
}

.lmnt-stepper__button-container {
  border-top: var(--lmnt-stepper--rule);
}

.lmnt-stepper__button-container--un-bordered {
  border: unset;
}

.lmnt-stepper__button-container-inner {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0 auto;
  text-align: right;
  width: var(--lmnt-stepper__button-container--width);
}

.lmnt-stepper__button-container--full-width .lmnt-stepper__button-container-inner {
  box-sizing: border-box;
  width: 100%;
}

.lmnt-stepper__container--rtl .lmnt-stepper__button-container-inner {
  flex-direction: row;
}

.lmnt {
  --lmnt-stepper-indicator__active-color: var(--lmnt-theme-primary-on-surface);
  --lmnt-stepper-indicator__active-emphasis: var(--lmnt-theme-emphasis-high);
  --lmnt-stepper-indicator__inactive-emphasis: var(--lmnt-theme-emphasis-highlighted);
  --lmnt-stepper-indicator__size: 12px;
  --lmnt-stepper-indicator__margin: 8px;
}
.lmnt.lmnt-stepper-indicator__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.lmnt .lmnt-stepper-indicator__item {
  background-color: #000;
  border-radius: 50%;
  height: var(--lmnt-stepper-indicator__size);
  margin: var(--lmnt-stepper-indicator__margin);
  opacity: var(--lmnt-stepper-indicator__inactive-emphasis);
  width: var(--lmnt-stepper-indicator__size);
}
.lmnt .lmnt-stepper-indicator__item--active {
  background-color: var(--lmnt-stepper-indicator__active-color);
  opacity: var(--lmnt-stepper-indicator__Active-emphasis);
}

html,
body,
#root,
.story-wrapper {
  color: var(--lmnt-theme-on-surface);
  font-family: var(--lmnt-theme-primary-font, Roboto, sans-serif);
  height: 100%;
}

.sr-only {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.sr-only-focusable:focus, .sr-only-focusable:active {
  clip: auto;
  clip-path: none;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

:root {
  --mdc-theme-primary:#6200ee;
  --mdc-theme-secondary:#018786;
  --mdc-theme-background:#fff;
  --mdc-theme-surface:#fff;
  --mdc-theme-error:#b00020;
  --mdc-theme-on-primary:#fff;
  --mdc-theme-on-secondary:#fff;
  --mdc-theme-on-surface:#000;
  --mdc-theme-on-error:#fff;
  --mdc-theme-text-primary-on-background:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light:rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light:rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light:rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark:white;
  --mdc-theme-text-secondary-on-dark:rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark:rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark:rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #6200ee  !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary {
  color: #018786  !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020  !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000  !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff  !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white  !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5)  !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #6200ee  !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee) !important;
}

.mdc-theme--secondary-bg {
  background-color: #018786  !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

/* stylelint-disable scss/media-feature-value-dollar-variable */
/* stylelint-enable scss/media-feature-value-dollar-variable */
.lmnt-theme-primary-50--bg,
.lmnt-theme-primary-50-bg {
  background-color: #f1edf8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-50 {
  color: #f1edf8;
}

.lmnt-theme-primary-100--bg,
.lmnt-theme-primary-100-bg {
  background-color: #c7b7e4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-100 {
  color: #c7b7e4;
}

.lmnt-theme-primary-200--bg,
.lmnt-theme-primary-200-bg {
  background-color: #b9a5de;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-200 {
  color: #b9a5de;
}

.lmnt-theme-primary-300--bg,
.lmnt-theme-primary-300-bg {
  background-color: #9381d1;
  color: white;
}

.lmnt-theme-primary-300 {
  color: #9381d1;
}

.lmnt-theme-primary-400--bg,
.lmnt-theme-primary-400-bg {
  background-color: #825dc4;
  color: white;
}

.lmnt-theme-primary-400 {
  color: #825dc4;
}

.lmnt-theme-primary-500--bg,
.lmnt-theme-primary-500-bg {
  background-color: #673ab7;
  color: white;
}

.lmnt-theme-primary-500 {
  color: #673ab7;
}

.lmnt-theme-primary-600--bg,
.lmnt-theme-primary-600-bg {
  background-color: #553096;
  color: white;
}

.lmnt-theme-primary-600 {
  color: #553096;
}

.lmnt-theme-primary-700--bg,
.lmnt-theme-primary-700-bg {
  background-color: #4b2b86;
  color: white;
}

.lmnt-theme-primary-700 {
  color: #4b2b86;
}

.lmnt-theme-primary-800--bg,
.lmnt-theme-primary-800-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-primary-800 {
  color: #422575;
}

.lmnt-theme-primary-900--bg,
.lmnt-theme-primary-900-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-primary-900 {
  color: #261643;
}

.lmnt-theme-secondary-50--bg,
.lmnt-theme-secondary-50-bg {
  background-color: #edf8ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-50 {
  color: #edf8ff;
}

.lmnt-theme-secondary-100--bg,
.lmnt-theme-secondary-100-bg {
  background-color: #caecff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-100 {
  color: #caecff;
}

.lmnt-theme-secondary-200--bg,
.lmnt-theme-secondary-200-bg {
  background-color: #b9e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-200 {
  color: #b9e5ff;
}

.lmnt-theme-secondary-300--bg,
.lmnt-theme-secondary-300-bg {
  background-color: #96d9ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-300 {
  color: #96d9ff;
}

.lmnt-theme-secondary-400--bg,
.lmnt-theme-secondary-400-bg {
  background-color: #74ccff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-400 {
  color: #74ccff;
}

.lmnt-theme-secondary-500--bg,
.lmnt-theme-secondary-500-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-500 {
  color: #40baff;
}

.lmnt-theme-secondary-600--bg,
.lmnt-theme-secondary-600-bg {
  background-color: #3599d1;
  color: white;
}

.lmnt-theme-secondary-600 {
  color: #3599d1;
}

.lmnt-theme-secondary-700--bg,
.lmnt-theme-secondary-700-bg {
  background-color: #2f88ba;
  color: white;
}

.lmnt-theme-secondary-700 {
  color: #2f88ba;
}

.lmnt-theme-secondary-800--bg,
.lmnt-theme-secondary-800-bg {
  background-color: #23668c;
  color: white;
}

.lmnt-theme-secondary-800 {
  color: #23668c;
}

.lmnt-theme-secondary-900--bg,
.lmnt-theme-secondary-900-bg {
  background-color: #18445d;
  color: white;
}

.lmnt-theme-secondary-900 {
  color: #18445d;
}

.lmnt-theme-ancillary-100--bg,
.lmnt-theme-ancillary-100-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-100 {
  color: #000;
}

.lmnt-theme-ancillary-200--bg,
.lmnt-theme-ancillary-200-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-200 {
  color: #000;
}

.lmnt-theme-ancillary-300--bg,
.lmnt-theme-ancillary-300-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-300 {
  color: #000;
}

.lmnt-theme-ancillary-400--bg,
.lmnt-theme-ancillary-400-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-400 {
  color: #000;
}

.lmnt-theme-ancillary-500--bg,
.lmnt-theme-ancillary-500-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-500 {
  color: #000;
}

.lmnt-theme-ancillary-600--bg,
.lmnt-theme-ancillary-600-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-600 {
  color: #000;
}

.lmnt-theme-ancillary-700--bg,
.lmnt-theme-ancillary-700-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-700 {
  color: #000;
}

.lmnt-theme-ancillary-800--bg,
.lmnt-theme-ancillary-800-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-800 {
  color: #000;
}

.lmnt-theme-ancillary-900--bg,
.lmnt-theme-ancillary-900-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-900 {
  color: #000;
}

.lmnt-theme-dataVizComparative-50--bg,
.lmnt-theme-dataVizComparative-50-bg {
  background-color: #240f67;
  color: white;
}

.lmnt-theme-dataVizComparative-50 {
  color: #240f67;
}

.lmnt-theme-dataVizComparative-100--bg,
.lmnt-theme-dataVizComparative-100-bg {
  background-color: #2d72c4;
  color: white;
}

.lmnt-theme-dataVizComparative-100 {
  color: #2d72c4;
}

.lmnt-theme-dataVizComparative-200--bg,
.lmnt-theme-dataVizComparative-200-bg {
  background-color: #89c9b7;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-200 {
  color: #89c9b7;
}

.lmnt-theme-dataVizComparative-300--bg,
.lmnt-theme-dataVizComparative-300-bg {
  background-color: #785e9f;
  color: white;
}

.lmnt-theme-dataVizComparative-300 {
  color: #785e9f;
}

.lmnt-theme-dataVizComparative-400--bg,
.lmnt-theme-dataVizComparative-400-bg {
  background-color: #3b8ec8;
  color: white;
}

.lmnt-theme-dataVizComparative-400 {
  color: #3b8ec8;
}

.lmnt-theme-dataVizComparative-500--bg,
.lmnt-theme-dataVizComparative-500-bg {
  background-color: #b7dbbc;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-500 {
  color: #b7dbbc;
}

.lmnt-theme-dataVizComparative-600--bg,
.lmnt-theme-dataVizComparative-600-bg {
  background-color: #4153af;
  color: white;
}

.lmnt-theme-dataVizComparative-600 {
  color: #4153af;
}

.lmnt-theme-dataVizComparative-700--bg,
.lmnt-theme-dataVizComparative-700-bg {
  background-color: #47a3c4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-700 {
  color: #47a3c4;
}

.lmnt-theme-dataVizComparative-800--bg,
.lmnt-theme-dataVizComparative-800-bg {
  background-color: #dfeccb;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizComparative-800 {
  color: #dfeccb;
}

.lmnt-theme-dataVizComparative-900--bg,
.lmnt-theme-dataVizComparative-900-bg {
  background-color: #6332ad;
  color: white;
}

.lmnt-theme-dataVizComparative-900 {
  color: #6332ad;
}

.lmnt-theme-dataVizSequential1-50--bg,
.lmnt-theme-dataVizSequential1-50-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-dataVizSequential1-50 {
  color: #261643;
}

.lmnt-theme-dataVizSequential1-100--bg,
.lmnt-theme-dataVizSequential1-100-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-dataVizSequential1-100 {
  color: #422575;
}

.lmnt-theme-dataVizSequential1-200--bg,
.lmnt-theme-dataVizSequential1-200-bg {
  background-color: #6540aa;
  color: white;
}

.lmnt-theme-dataVizSequential1-200 {
  color: #6540aa;
}

.lmnt-theme-dataVizSequential1-300--bg,
.lmnt-theme-dataVizSequential1-300-bg {
  background-color: #7e52d0;
  color: white;
}

.lmnt-theme-dataVizSequential1-300 {
  color: #7e52d0;
}

.lmnt-theme-dataVizSequential1-400--bg,
.lmnt-theme-dataVizSequential1-400-bg {
  background-color: #9a75dd;
  color: white;
}

.lmnt-theme-dataVizSequential1-400 {
  color: #9a75dd;
}

.lmnt-theme-dataVizSequential1-500--bg,
.lmnt-theme-dataVizSequential1-500-bg {
  background-color: #af8bef;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-500 {
  color: #af8bef;
}

.lmnt-theme-dataVizSequential1-600--bg,
.lmnt-theme-dataVizSequential1-600-bg {
  background-color: #c6a6ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-600 {
  color: #c6a6ff;
}

.lmnt-theme-dataVizSequential1-700--bg,
.lmnt-theme-dataVizSequential1-700-bg {
  background-color: #d8c1ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-700 {
  color: #d8c1ff;
}

.lmnt-theme-dataVizSequential1-800--bg,
.lmnt-theme-dataVizSequential1-800-bg {
  background-color: #e8dbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-800 {
  color: #e8dbff;
}

.lmnt-theme-dataVizSequential1-900--bg,
.lmnt-theme-dataVizSequential1-900-bg {
  background-color: #f5efff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential1-900 {
  color: #f5efff;
}

.lmnt-theme-dataVizSequential2--50--bg,
.lmnt-theme-dataVizSequential2--50-bg {
  background-color: #052d44;
  color: white;
}

.lmnt-theme-dataVizSequential2--50 {
  color: #052d44;
}

.lmnt-theme-dataVizSequential2--100--bg,
.lmnt-theme-dataVizSequential2--100-bg {
  background-color: #08517b;
  color: white;
}

.lmnt-theme-dataVizSequential2--100 {
  color: #08517b;
}

.lmnt-theme-dataVizSequential2--200--bg,
.lmnt-theme-dataVizSequential2--200-bg {
  background-color: #1774aa;
  color: white;
}

.lmnt-theme-dataVizSequential2--200 {
  color: #1774aa;
}

.lmnt-theme-dataVizSequential2--300--bg,
.lmnt-theme-dataVizSequential2--300-bg {
  background-color: #319bd8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--300 {
  color: #319bd8;
}

.lmnt-theme-dataVizSequential2--400--bg,
.lmnt-theme-dataVizSequential2--400-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--400 {
  color: #40baff;
}

.lmnt-theme-dataVizSequential2--500--bg,
.lmnt-theme-dataVizSequential2--500-bg {
  background-color: #75cdff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--500 {
  color: #75cdff;
}

.lmnt-theme-dataVizSequential2--600--bg,
.lmnt-theme-dataVizSequential2--600-bg {
  background-color: #97e0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--600 {
  color: #97e0ff;
}

.lmnt-theme-dataVizSequential2--700--bg,
.lmnt-theme-dataVizSequential2--700-bg {
  background-color: #a9f0ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--700 {
  color: #a9f0ff;
}

.lmnt-theme-dataVizSequential2--800--bg,
.lmnt-theme-dataVizSequential2--800-bg {
  background-color: #c3fbff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--800 {
  color: #c3fbff;
}

.lmnt-theme-dataVizSequential2--900--bg,
.lmnt-theme-dataVizSequential2--900-bg {
  background-color: #ddfffd;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-dataVizSequential2--900 {
  color: #ddfffd;
}

.lmnt-theme--bg-linear-gradient-primary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(103, 58, 183, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(38, 22, 67, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-primary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(16, 9, 29, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(64, 186, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-secondary-dark-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(24, 68, 93, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-secondary-darker-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(14, 38, 52, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-black-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.8) 100%);
  color: white;
}

.lmnt-theme--bg-linear-gradient-white-to-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme--bg-linear-gradient-white-to-top {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.8) 100%);
  color: rgba(0, 0, 0, 0.87);
}

:root,
.lmnt {
  --lmnt-theme-on-background: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface: rgba(0, 0, 0, 0.87);
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-background: #fff;
  --lmnt-theme-surface: #fff;
  --lmnt-theme-surface-variant: #f5f5f5;
  --lmnt-theme-dark: #000;
  --lmnt-theme-light: #fff;
  --lmnt-theme-on-unknown-light: #000;
  --lmnt-theme-on-unknown-dark: #fff;
}

.lmnt {
  --lmnt-theme-primary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-theme-secondary-font: 'Roboto', 'Arial Narrow', helvetica, arial, sans-serif;
  --lmnt-black-rgb: 0, 0, 0;
  --lmnt-white-rgb: 255, 255, 255;
  --lmnt-theme-primary: #673ab7;
  --lmnt-theme-on-primary: white;
  --lmnt-theme-primary-variant: #4b2b86;
  --lmnt-theme-on-primary-variant: white;
  --lmnt-theme-primary-on-surface: #673ab7;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-theme-secondary: #40baff;
  --lmnt-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --lmnt-theme-secondary-variant: #2f88ba;
  --lmnt-theme-on-secondary-variant: white;
  --lmnt-theme-secondary-on-surface: #23668c;
  --lmnt-theme-danger: #cf2d22;
  --lmnt-theme-on-danger: white;
  --lmnt-theme-danger-alt: #f5f5f5;
  --lmnt-theme-on-danger-alt: #cf2d22;
  --lmnt-theme-danger-variant: #b3190d;
  --lmnt-theme-on-danger-variant: white;
  --lmnt-theme-danger-on-surface: #cf2d22;
  --lmnt-theme-success: #0ea01a;
  --lmnt-theme-on-success: white;
  --lmnt-theme-success-variant: #13c545;
  --lmnt-theme-success-on-surface: #01830c;
  --lmnt-primary-on-surface-rgb: 103, 58, 183;
  --lmnt-secondary-on-surface-rgb: 35, 102, 140;
  --lmnt-danger-on-surface-rgb: 207, 45, 34;
  --lmnt-success-on-surface-rgb: 207, 45, 34;
  --lmnt-on-primary-rgb: 255, 255, 255;
  --lmnt-on-secondary-rgb: 0, 0, 0;
  --lmnt-on-danger-rgb: 255, 255, 255;
  --lmnt-on-success-rgb: 255, 255, 255;
  --lmnt-theme-breakpoint-mobile-min: 0;
  --lmnt-theme-breakpoint-mobile-max: 599px;
  --lmnt-theme-breakpoint-tablet-sm-min: 600px;
  --lmnt-theme-breakpoint-tablet-sm-max: 719px;
  --lmnt-theme-breakpoint-tablet-lg-min: 720px;
  --lmnt-theme-breakpoint-tablet-lg-max: 839px;
  --lmnt-theme-breakpoint-desktop-sm-min: 840px;
  --lmnt-theme-breakpoint-desktop-sm-max: 1023px;
  --lmnt-theme-breakpoint-desktop-lg-min: 1024px;
  --lmnt-theme-breakpoint-desktop-lg-max: 1439px;
  --lmnt-theme-breakpoint-desktop-xl-min: 1440px;
  --lmnt-theme-breakpoint-content-max-width-md: 776px;
  --lmnt-theme-breakpoint-content-max-width-lg: 1052px;
  --lmnt-theme-breakpoint-content-max-width-xl: 1176px;
  --lmnt-theme-primary-50: #f1edf8;
  --lmnt-theme-primary-100: #c7b7e4;
  --lmnt-theme-primary-200: #b9a5de;
  --lmnt-theme-primary-300: #9381d1;
  --lmnt-theme-primary-400: #825dc4;
  --lmnt-theme-primary-500: #673ab7;
  --lmnt-theme-primary-600: #553096;
  --lmnt-theme-primary-700: #4b2b86;
  --lmnt-theme-primary-800: #422575;
  --lmnt-theme-primary-900: #261643;
  --lmnt-theme-secondary-50: #edf8ff;
  --lmnt-theme-secondary-100: #caecff;
  --lmnt-theme-secondary-200: #b9e5ff;
  --lmnt-theme-secondary-300: #96d9ff;
  --lmnt-theme-secondary-400: #74ccff;
  --lmnt-theme-secondary-500: #40baff;
  --lmnt-theme-secondary-600: #3599d1;
  --lmnt-theme-secondary-700: #2f88ba;
  --lmnt-theme-secondary-800: #23668c;
  --lmnt-theme-secondary-900: #18445d;
  --lmnt-theme-success-50: #eeffef;
  --lmnt-theme-success-100: #d3ffd7;
  --lmnt-theme-success-200: #a9f6af;
  --lmnt-theme-success-300: #6fef7a;
  --lmnt-theme-success-400: #29e539;
  --lmnt-theme-success-500: #13c545;
  --lmnt-theme-success-600: #0ea01a;
  --lmnt-theme-success-700: #01830c;
  --lmnt-theme-success-800: #075a0e;
  --lmnt-theme-success-900: #053709;
  --lmnt-theme-danger-50: #ffeaed;
  --lmnt-theme-danger-100: #ffccce;
  --lmnt-theme-danger-200: #ee9894;
  --lmnt-theme-danger-300: #e3716a;
  --lmnt-theme-danger-400: #ec5145;
  --lmnt-theme-danger-500: #ef4227;
  --lmnt-theme-danger-600: #e13727;
  --lmnt-theme-danger-700: #cf2d22;
  --lmnt-theme-danger-800: #c2261b;
  --lmnt-theme-danger-900: #b3190d;
  --lmnt-theme-ancillary-100: #000;
  --lmnt-theme-ancillary-200: #000;
  --lmnt-theme-ancillary-300: #000;
  --lmnt-theme-ancillary-400: #000;
  --lmnt-theme-ancillary-500: #000;
  --lmnt-theme-ancillary-600: #000;
  --lmnt-theme-ancillary-700: #000;
  --lmnt-theme-ancillary-800: #000;
  --lmnt-theme-ancillary-900: #000;
  --lmnt-theme-dataVizSequential1-50: #261643;
  --lmnt-theme-dataVizSequential1-100: #422575;
  --lmnt-theme-dataVizSequential1-200: #6540aa;
  --lmnt-theme-dataVizSequential1-300: #7e52d0;
  --lmnt-theme-dataVizSequential1-400: #9a75dd;
  --lmnt-theme-dataVizSequential1-500: #af8bef;
  --lmnt-theme-dataVizSequential1-600: #c6a6ff;
  --lmnt-theme-dataVizSequential1-700: #d8c1ff;
  --lmnt-theme-dataVizSequential1-800: #e8dbff;
  --lmnt-theme-dataVizSequential1-900: #f5efff;
  --lmnt-theme-dataVizSequential2--50: #052d44;
  --lmnt-theme-dataVizSequential2--100: #08517b;
  --lmnt-theme-dataVizSequential2--200: #1774aa;
  --lmnt-theme-dataVizSequential2--300: #319bd8;
  --lmnt-theme-dataVizSequential2--400: #40baff;
  --lmnt-theme-dataVizSequential2--500: #75cdff;
  --lmnt-theme-dataVizSequential2--600: #97e0ff;
  --lmnt-theme-dataVizSequential2--700: #a9f0ff;
  --lmnt-theme-dataVizSequential2--800: #c3fbff;
  --lmnt-theme-dataVizSequential2--900: #ddfffd;
  --lmnt-theme-dataVizComparative-50: #240f67;
  --lmnt-theme-dataVizComparative-100: #2d72c4;
  --lmnt-theme-dataVizComparative-200: #89c9b7;
  --lmnt-theme-dataVizComparative-300: #785e9f;
  --lmnt-theme-dataVizComparative-400: #3b8ec8;
  --lmnt-theme-dataVizComparative-500: #b7dbbc;
  --lmnt-theme-dataVizComparative-600: #4153af;
  --lmnt-theme-dataVizComparative-700: #47a3c4;
  --lmnt-theme-dataVizComparative-800: #dfeccb;
  --lmnt-theme-dataVizComparative-900: #6332ad;
  --lmnt-theme-on-surface-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-surface-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-surface-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-surface-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-surface-base: black;
  --lmnt-theme-on-primary-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-primary-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-primary-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-primary-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-primary-base: white;
  --lmnt-theme-on-secondary-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-secondary-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-secondary-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-secondary-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-secondary-base: black;
  --lmnt-theme-on-success-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-success-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-success-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-success-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-success-base: white;
  --lmnt-theme-on-danger-active: rgba(255, 255, 255, 0.87);
  --lmnt-theme-on-danger-inactive: rgba(255, 255, 255, 0.6);
  --lmnt-theme-on-danger-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-danger-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-danger-base: white;
  --lmnt-theme-on-unknown-black-active: rgba(0, 0, 0, 0.87);
  --lmnt-theme-on-unknown-black-inactive: rgba(0, 0, 0, 0.6);
  --lmnt-theme-on-unknown-black-disabled: rgba(0, 0, 0, 0.38);
  --lmnt-theme-on-unknown-black-stroke: rgba(0, 0, 0, 0.12);
  --lmnt-theme-on-unknown-black-base: black;
  --lmnt-theme-on-unknown-white-active: white;
  --lmnt-theme-on-unknown-white-inactive: rgba(255, 255, 255, 0.54);
  --lmnt-theme-on-unknown-white-disabled: rgba(255, 255, 255, 0.38);
  --lmnt-theme-on-unknown-white-stroke: rgba(255, 255, 255, 0.12);
  --lmnt-theme-on-unknown-white-base: white;
  --lmnt-theme-black: #000;
  --lmnt-theme-white: #fff;
  --lmnt-theme-icon-in-component: rgba(#000, 0.54);
  --lmnt-theme-emphasis-active-black: 0.87;
  --lmnt-theme-emphasis-active: 1;
  --lmnt-theme-emphasis-completed: 0.6;
  --lmnt-theme-emphasis-disabled: 0.38;
  --lmnt-theme-emphasis-dragged: 0.08;
  --lmnt-theme-emphasis-focus: 0.12;
  --lmnt-theme-emphasis-high-black: 0.87;
  --lmnt-theme-emphasis-high: 1;
  --lmnt-theme-emphasis-highlighted: 0.24;
  --lmnt-theme-emphasis-hover--alt: 0.08;
  --lmnt-theme-emphasis-hover: 0.04;
  --lmnt-theme-emphasis-inactive: 0.6;
  --lmnt-theme-emphasis-low: 0.38;
  --lmnt-theme-emphasis-med: 0.6;
  --lmnt-theme-emphasis-pressed--alt: 0.32;
  --lmnt-theme-emphasis-pressed: 0.16;
  --lmnt-theme-emphasis-resting: 0.08;
  --lmnt-theme-emphasis-focus--alt: 0.24;
  --lmnt-theme-emphasis-selected: 0.08;
  --lmnt-theme-emphasis-stroke: 0.12;
  --lmnt-theme-emphasis-stroke-dark: 0.24;
  --lmnt-theme-emphasis-icon-in-component: 0.54;
  --lmnt-theme-emphasis-on-surface-active-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-active: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-completed: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-disabled: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-dragged: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-focus--alt: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-focus: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-high-black: calc(1 - 0.87);
  --lmnt-theme-emphasis-on-surface-high: calc(1 - 1);
  --lmnt-theme-emphasis-on-surface-highlighted: calc(1 - 0.24);
  --lmnt-theme-emphasis-on-surface-hover-alt: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-hover: calc(1 - 0.04);
  --lmnt-theme-emphasis-on-surface-inactive: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-low: calc(1 - 0.38);
  --lmnt-theme-emphasis-on-surface-med: calc(1 - 0.6);
  --lmnt-theme-emphasis-on-surface-pressed-alt: calc(1 - 0.32);
  --lmnt-theme-emphasis-on-surface-resting: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-selected: calc(1 - 0.08);
  --lmnt-theme-emphasis-on-surface-stroke: calc(1 - 0.12);
  --lmnt-theme-emphasis-on-surface-stroke-dark: calc(1 - 0.24);
  --mdc-typography-font-family: var(--lmnt-theme-primary-font);
  --mdc-theme-primary: var(--lmnt-theme-primary);
  --mdc-theme-on-primary: var(--lmnt-theme-on-primary);
  --mdc-theme-secondary: var(--lmnt-theme-secondary);
  --mdc-theme-on-secondary: var(--lmnt-theme-on-secondary);
  --mdc-theme-background: var(--lmnt-theme-background);
  --mdc-theme-surface: var(--lmnt-theme-surface);
  --mdc-theme-on-surface: var(--lmnt-theme-on-surface);
  --mdc-theme-error: var(--lmnt-theme-danger);
  --mdc-theme-on-error: var(--lmnt-theme-on-danger);
  --lmnt-button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --lmnt-theme--shape-radius: 4px;
  --lmnt-card-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-theme-text-primary-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-primary-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-primary-on-light: var(--lmnt-theme-on-unknown-black);
  --mdc-theme-text-secondary-on-background: rgba(var(--lmnt-black-rgb), 0.54);
  --mdc-theme-text-disabled-on-background: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-disabled-on-dark: var(--lmnt-theme-on-unknown-white-disabled);
  --mdc-theme-text-disabled-on-light: var(--lmnt-theme-on-unknown-black-disabled);
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
  --mdc-theme-text-icon-on-dark: var(--lmnt-theme-on-unknown-white);
  --mdc-theme-text-icon-on-light: var(--lmnt-theme-on-unknown-black);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  margin: 0;
}

.lmnt {
  --form-field-color-on-primary: var(--lmnt-theme-on-primary);
  --form-field-color-on-secondary: var(--lmnt-theme-on-secondary);
}

.mdc-input-label {
  cursor: pointer;
}

.lmnt-theme-primary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-primary);
}

.lmnt-theme-secondary-bg .mdc-form-field.lmnt-form-field {
  --mdc-theme-text-primary-on-background: var(--form-field-color-on-secondary);
}

.mdc-switch__thumb-underlay {
  /* @noflip */
  left: -14px;
  /* @noflip */
  right: initial;
  top: -17px;
  width: 48px;
  height: 48px;
}
[dir=rtl] .mdc-switch__thumb-underlay, .mdc-switch__thumb-underlay[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: -14px;
}

.mdc-switch__native-control {
  width: 64px;
  height: 48px;
}

.mdc-switch {
  display: inline-block;
  position: relative;
  outline: none;
  user-select: none;
}
.mdc-switch.mdc-switch--checked .mdc-switch__track {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
}
.mdc-switch.mdc-switch--checked .mdc-switch__thumb {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786);
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #018786);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-theme-surface, #fff);
}

.mdc-switch__native-control {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  position: absolute;
  top: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-switch__native-control, .mdc-switch__native-control[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-switch__track {
  box-sizing: border-box;
  width: 36px;
  height: 14px;
  border: 1px solid transparent;
  border-radius: 7px;
  opacity: 0.38;
  transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb-underlay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateX(0);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 10px solid;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
}

.mdc-switch--checked .mdc-switch__track {
  opacity: 0.54;
}
.mdc-switch--checked .mdc-switch__thumb-underlay {
  transform: translateX(16px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__thumb-underlay, .mdc-switch--checked .mdc-switch__thumb-underlay[dir=rtl] {
  transform: translateX(-16px);
}
.mdc-switch--checked .mdc-switch__native-control {
  transform: translateX(-16px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__native-control, .mdc-switch--checked .mdc-switch__native-control[dir=rtl] {
  transform: translateX(16px);
}

.mdc-switch--disabled {
  opacity: 0.38;
  pointer-events: none;
}
.mdc-switch--disabled .mdc-switch__thumb {
  border-width: 1px;
}
.mdc-switch--disabled .mdc-switch__native-control {
  cursor: default;
  pointer-events: none;
}

.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::after {
  background-color: #9e9e9e;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #9e9e9e);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:hover::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-switch__thumb-underlay {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-switch__thumb-underlay::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-switch__thumb-underlay::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before, .mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}
.mdc-switch__thumb-underlay:hover::before, .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.lmnt {
  --lmnt-switch--primary: var(--lmnt-theme-primary-on-surface);
  --lmnt-switch--on-primary: var(--lmnt-theme-secondary);
  --lmnt-switch--on-primary-surface: var(--lmnt-theme-on-primary);
  --lmnt-switch--secondary: var(--lmnt-theme-secondary-on-surface);
  --lmnt-switch--on-secondary: var(--lmnt-theme-primary);
  --lmnt-switch--on-secondary-surface: var(--lmnt-theme-on-secondary);
}

.lmnt-switch--primary,
.mdc-switch.lmnt-switch,
.mdc-switch.lmnt-switch.lmnt-switch--primary {
  --mdc-theme-secondary: var(--lmnt-switch--primary);
}

.lmnt-switch--secondary,
.mdc-switch.lmnt-switch.lmnt-switch--secondary {
  --mdc-theme-secondary: var(--lmnt-theme-secondary);
}

.mdc-form-field .lmnt-switch-label {
  margin-left: 10px;
  padding-left: 0;
  user-select: none;
}

.lmnt-theme-primary-bg .mdc-switch.lmnt-switch {
  --mdc-theme-secondary: var(--lmnt-switch--on-primary);
  --mdc-theme-on-surface: var(--lmnt-switch--on-primary-surface);
}

.lmnt-theme-secondary-bg .mdc-switch.lmnt-switch {
  --mdc-theme-secondary: var(--lmnt-switch--on-secondary);
  --mdc-theme-on-surface: var(--lmnt-switch--on-secondary-surface);
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

.mdc-tab {
  height: 48px;
}

.mdc-tab--stacked {
  height: 72px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}
.mdc-tab-scroller.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #6200ee);
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #018786;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786);
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  padding-right: 24px;
  padding-left: 24px;
  min-width: 90px;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  background: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}
.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}
.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  width: 24px;
  height: 24px;
  font-size: 24px;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-tab--active .mdc-tab__icon {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
  fill: currentColor;
}
.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
}

.mdc-tab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-tab .mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-tab .mdc-tab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-tab.mdc-ripple-upgraded--unbounded .mdc-tab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-tab.mdc-ripple-upgraded--foreground-activation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-tab.mdc-ripple-upgraded--foreground-deactivation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-tab .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-tab:hover .mdc-tab__ripple::before, .mdc-tab.mdc-ripple-surface--hover .mdc-tab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__ripple::before, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-tab:not(.mdc-ripple-upgraded) .mdc-tab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-tab:not(.mdc-ripple-upgraded):active .mdc-tab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-tab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform, opacity;
}

.mdc-tab-bar--surface {
  background-color: var(--lmnt-theme-surface);
  --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}
.mdc-tab-bar--surface .mdc-tab {
  --mdc-theme-primary: var(--lmnt-theme-primary-on-surface);
}
.mdc-tab-bar--surface .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: var(--lmnt-theme-primary-on-surface);
}

.mdc-tab-bar--elevated {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.mdc-tab-bar--primary {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
  --mdc-theme-secondary: var(--lmnt-theme-secondary);
  background-color: var(--lmnt-theme-primary);
}
.mdc-tab-bar--primary .mdc-tab {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
}
.mdc-tab-bar--primary .mdc-tab .mdc-tab__icon {
  --mdc-theme-primary: var(--lmnt-theme-on-primary);
}
.mdc-tab-bar--primary .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  color: var(--lmnt-theme-on-primary);
  opacity: 0.6;
}
.mdc-tab-bar--primary .mdc-tab:not(.mdc-tab--active) .mdc-tab__icon {
  color: var(--lmnt-theme-on-primary);
  fill: currentColor;
  opacity: var(--lmnt-theme-emphasis-icon-in-component);
}
.mdc-tab-bar--primary .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: var(--lmnt-theme-on-primary);
}

.mdc-tab-bar--secondary {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
  --mdc-theme-secondary: var(--lmnt-theme-primary);
  background-color: var(--lmnt-theme-secondary);
}
.mdc-tab-bar--secondary .mdc-tab {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
}
.mdc-tab-bar--secondary .mdc-tab .mdc-tab__icon {
  --mdc-theme-primary: var(--lmnt-theme-on-secondary);
}
.mdc-tab-bar--secondary .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  color: var(--lmnt-theme-on-secondary);
  opacity: 0.6;
}
.mdc-tab-bar--secondary .mdc-tab:not(.mdc-tab--active) .mdc-tab__icon {
  color: var(--lmnt-theme-on-secondary);
  fill: currentColor;
  opacity: var(--lmnt-theme-emphasis-icon-in-component);
}
.mdc-tab-bar--secondary .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: var(--lmnt-theme-on-secondary);
}

.lmnt .mdc-tab {
  height: 48px;
}
.lmnt .mdc-tab .lmnt-tab-bar--notification-badge {
  margin-left: 14px;
}
.lmnt.mdc-tab-bar--surface .mdc-tab.mdc-button:disabled {
  color: var(--lmnt-theme-on-surface-disabled);
}
.lmnt.mdc-tab-bar--surface .mdc-tab.mdc-button:disabled .mdc-tab__text-label {
  color: var(--lmnt-theme-on-surface-disabled);
}
.lmnt.mdc-tab-bar--primary .mdc-tab.mdc-button:disabled {
  color: var(--lmnt-theme-on-primary-disabled);
}
.lmnt.mdc-tab-bar--primary .mdc-tab.mdc-button:disabled .mdc-tab__text-label {
  color: var(--lmnt-theme-on-primary-disabled);
}
.lmnt.mdc-tab-bar--secondary .mdc-tab.mdc-button:disabled {
  color: var(--lmnt-theme-on-secondary-disabled);
}
.lmnt.mdc-tab-bar--secondary .mdc-tab.mdc-button:disabled .mdc-tab__text-label {
  color: var(--lmnt-theme-on-secondary-disabled);
}

.lmnt .mdc-data-table__cell,
.lmnt .mdc-data-table__header-cell {
  border-bottom-color: unset;
  border-bottom-style: unset;
  border-bottom-width: unset;
}

.lmnt .mdc-data-table__pagination {
  border-top-color: unset;
  border-top-style: unset;
  border-top-width: unset;
  min-height: unset;
}

.lmnt .mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: none;
}

.lmnt .mdc-data-table__header-cell {
  color: unset;
}

.lmnt .mdc-data-table__pagination-total,
.lmnt .mdc-data-table__pagination-rows-per-page-label,
.lmnt .mdc-data-table__cell {
  color: unset;
}

.lmnt .mdc-data-table__row {
  height: unset;
}

.lmnt .mdc-data-table__header-row {
  height: unset;
}

.mdc-checkbox {
  padding: calc((40px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((40px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
}
.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-checkbox:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}
.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #018786));
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((40px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((40px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - 40px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  right: calc((40px - 40px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  left: calc((40px - 40px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
  width: 40px;
  /* @alternate */
  width: var(--mdc-checkbox-ripple-size, 40px);
  height: 40px;
  /* @alternate */
  height: var(--mdc-checkbox-ripple-size, 40px);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #018786;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  background-color: #018786;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786 {
  0%, 80% {
    border-color: #018786;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
    background-color: #018786;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #018786));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF01878600000000FF018786;
}
.mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF01878600000000FF018786;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-color, GrayText);
    background-color: transparent;
  }

  .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: transparent;
    /* @alternate */
    background-color: var(--mdc-checkbox-disabled-color, transparent);
  }

  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: GrayText;
    /* @alternate */
    color: var(--mdc-checkbox-ink-color, GrayText);
  }
  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-ink-color, GrayText);
  }

  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin: calc((48px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 48px) - var(--mdc-checkbox-ripple-size, 40px)) / 2);
}
.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: calc((40px - 48px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  right: calc((40px - 48px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  left: calc((40px - 48px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - var(--mdc-checkbox-touch-target-size, 48px)) / 2);
  width: 48px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 48px);
  height: 48px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 48px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox {
  z-index: 0;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover::before, .mdc-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-data-table {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  -webkit-overflow-scrolling: touch;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.mdc-data-table .mdc-data-table__header-cell:first-child {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:first-child, .mdc-data-table .mdc-data-table__header-cell:first-child[dir=rtl] {
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
  border-top-left-radius: 0;
}
.mdc-data-table .mdc-data-table__header-cell:last-child {
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:last-child, .mdc-data-table .mdc-data-table__header-cell:last-child[dir=rtl] {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
  border-top-right-radius: 0;
}

.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(98, 0, 238, 0.04);
}

.mdc-data-table__header-cell:after {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row,
.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__header-cell:after {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.mdc-data-table__row,
.mdc-data-table__pagination {
  border-top-width: 1px;
  border-top-style: solid;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__cell {
  height: 52px;
}

.mdc-data-table__pagination {
  min-height: 52px;
}

.mdc-data-table__header-cell {
  height: 56px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-data-table__header-cell--checkbox,
.mdc-data-table__cell--checkbox {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-data-table__header-cell--checkbox, .mdc-data-table__header-cell--checkbox[dir=rtl],
[dir=rtl] .mdc-data-table__cell--checkbox,
.mdc-data-table__cell--checkbox[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-data-table__sort-icon-button::before, .mdc-data-table__sort-icon-button::after {
  background-color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.6));
}
.mdc-data-table__sort-icon-button:hover::before, .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:hover::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__table-container {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.mdc-data-table__table {
  min-width: 100%;
  border: 0;
  white-space: nowrap;
  border-collapse: collapse;
  /**
   * With table-layout:fixed, table and column widths are defined by the width
   * of the first row of cells. Cells in subsequent rows do not affect column
   * widths. This results in a predictable table layout and may also speed up
   * rendering.
   */
  table-layout: fixed;
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
[dir=rtl] .mdc-data-table__cell, .mdc-data-table__cell[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-data-table__cell--numeric {
  text-align: right;
}
[dir=rtl] .mdc-data-table__cell--numeric, .mdc-data-table__cell--numeric[dir=rtl] {
  /* @noflip */
  text-align: left;
}

.mdc-data-table__cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* @noflip */
  text-align: left;
}
[dir=rtl] .mdc-data-table__header-cell, .mdc-data-table__header-cell[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-data-table__header-cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell--numeric {
  text-align: right;
}
[dir=rtl] .mdc-data-table__header-cell--numeric, .mdc-data-table__header-cell--numeric[dir=rtl] {
  /* @noflip */
  text-align: left;
}

.mdc-data-table__sort-icon-button {
  width: 28px;
  height: 28px;
  padding: 2px;
  transform: rotate(0.0001deg);
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: 0;
  transition: transform 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}
[dir=rtl] .mdc-data-table__sort-icon-button, .mdc-data-table__sort-icon-button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 4px;
}
.mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 4px;
}
[dir=rtl] .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button[dir=rtl] {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: 0;
}
.mdc-data-table__header-cell--sorted-descending .mdc-data-table__sort-icon-button {
  transform: rotate(-180deg);
}
.mdc-data-table__sort-icon-button:focus, .mdc-data-table__header-cell:hover .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  opacity: 1;
}

.mdc-data-table__header-cell-wrapper {
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
}

.mdc-data-table__header-cell--with-sort {
  cursor: pointer;
}

.mdc-data-table__sort-status-label {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  /* added line */
  width: 1px;
}

.mdc-data-table__progress-indicator {
  display: none;
  position: absolute;
  width: 100%;
}
.mdc-data-table--in-progress .mdc-data-table__progress-indicator {
  display: block;
}

.mdc-data-table__scrim {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  height: 100%;
  opacity: 0.32;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-data-table--sticky-header .mdc-data-table__header-cell {
  position: sticky;
  top: 0;
  z-index: 1;
}
.mdc-data-table--sticky-header .mdc-data-table__header-cell:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
}
.mdc-data-table--sticky-header .mdc-data-table__row:first-child {
  border-top: 0;
}

.mdc-data-table__pagination {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.mdc-data-table__pagination-trailing {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
[dir=rtl] .mdc-data-table__pagination-trailing, .mdc-data-table__pagination-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 4px;
}

.mdc-data-table__pagination-navigation {
  align-items: center;
  display: flex;
}

.mdc-data-table__pagination-button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 4px;
}
[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  transform: rotate(180deg);
}
[dir=rtl] .mdc-data-table__pagination-button, .mdc-data-table__pagination-button[dir=rtl] {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: 0;
}

.mdc-data-table__pagination-total {
  /* @noflip */
  margin-left: 14px;
  /* @noflip */
  margin-right: 36px;
  white-space: nowrap;
}
[dir=rtl] .mdc-data-table__pagination-total, .mdc-data-table__pagination-total[dir=rtl] {
  /* @noflip */
  margin-left: 36px;
  /* @noflip */
  margin-right: 14px;
}

.mdc-data-table__pagination-rows-per-page {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 22px;
  align-items: center;
  display: inline-flex;
}
[dir=rtl] .mdc-data-table__pagination-rows-per-page, .mdc-data-table__pagination-rows-per-page[dir=rtl] {
  /* @noflip */
  margin-left: 22px;
  /* @noflip */
  margin-right: 0;
}

.mdc-data-table__pagination-rows-per-page-label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 12px;
  white-space: nowrap;
}
[dir=rtl] .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__pagination-rows-per-page-label[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: 0;
}

.mdc-data-table__pagination-rows-per-page-select {
  min-width: 80px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 80px);
  margin: 8px 0;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  width: 100%;
  min-width: 80px;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  height: 36px;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-36px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-36px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.mdc-data-table__pagination-rows-per-page-select.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 56px);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-deprecated-list-item {
  height: 36px;
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #6200ee;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FF6200EE00000000FF6200EE {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #6200ee;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
    background-color: #6200ee;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF6200EE00000000FF6200EE {
  0%, 80% {
    border-color: #6200ee;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
    background-color: #6200ee;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #6200ee));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF6200EE00000000FF6200EE;
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF6200EE00000000FF6200EE;
}

:root {
  --table-bg-color: #fff;
  --table-bg-color-hover: #f5f5f5;
  --table-bg-color-striped: #eee;
  --lmnt-table__header-color: rgba(0, 0, 0, 1);
}

.lmnt-table .mdc-data-table__cell {
  height: 48px;
}
.lmnt-table .mdc-data-table__pagination {
  min-height: 48px;
}
.lmnt-table .mdc-data-table__header-cell {
  height: 52px;
}

.lmnt-table--tall .mdc-data-table__cell {
  height: 52px;
}
.lmnt-table--tall .mdc-data-table__pagination {
  min-height: 52px;
}
.lmnt-table--tall .mdc-data-table__header-cell {
  height: 56px;
}

.lmnt-table--dense .mdc-data-table__cell {
  height: 40px;
}
.lmnt-table--dense .mdc-data-table__pagination {
  min-height: 40px;
}
.lmnt-table--dense .mdc-data-table__header-cell {
  height: 44px;
}

.lmnt-table--full-width {
  width: 100%;
}

.lmnt-table__top-bar--standard-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lmnt-table__top-bar--standard-bar .lmnt-table__top-bar__title {
  padding: 16px 24px;
}
.lmnt-table__top-bar--standard-bar--action-items {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 24px;
}
.lmnt-table__top-bar--standard-bar--action-items .lmnt-text-field--embedded {
  margin-right: 16px;
}
.lmnt-table__top-bar--filter-bar {
  height: 64px;
}

.lmnt-table__sort-icon-button--visible {
  opacity: 1;
}

.lmnt-table__header-cell {
  color: var(--lmnt-table__header-color);
  font-size: 12px;
  font-weight: bold;
}

.lmnt-table--virtualized .lmnt-table__header-row {
  border-bottom-color: var(--lmnt-theme-on-surface-stroke);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lmnt-table--virtualized .mdc-data-table__row.lmnt-table__row:first-child {
  border-top-width: 0;
}

.lmnt-table__header-cell--dragging {
  border: solid 1px #ddd;
  box-shadow: 3px 3px 4px -3px rgba(0, 0, 0, 0.36);
  z-index: 8;
}

.lmnt .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: var(--table-bg-color-hover);
}

.lmnt-table__resize-handle {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23d9d9d9' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  cursor: col-resize;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.3em;
}

.lmnt-table____sticky-border {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  border-right: none;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
}

.lmnt-table__table--row-striped .mdc-data-table__row:nth-child(odd),
.lmnt-table__table--row-striped .lmnt-table__row:nth-child(odd) {
  background-color: var(--table-bg-color-striped);
}

.lmnt-table__table--column-striped .mdc-data-table__cell:nth-child(odd),
.lmnt-table__table--column-striped .lmnt-table__cell:nth-child(odd) {
  background-color: var(--table-bg-color-striped);
}

.lmnt-table__leading-fixed-cell {
  background-color: inherit;
  left: 0;
  position: sticky;
  z-index: 3 !important;
}

.lmnt-table__leading-fixed-cell .lmnt-table__cell-content::after {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
}

.lmnt-table__leading-fixed-cell--trailing-border .lmnt-table__cell-content::after {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mdc-data-table__header-row {
  background-color: var(--table-bg-color);
  z-index: 4 !important;
}
.mdc-data-table__header-row .mdc-data-table__header-cell.lmnt-table__leading-fixed-cell {
  z-index: 5 !important;
}

.lmnt-table__cell-content {
  align-items: center;
  display: flex;
  height: 100%;
}

.lmnt-table__trailing-fixed-cell {
  background-color: inherit;
  position: sticky;
  z-index: 3;
}

.mdc-data-table__row {
  background-color: var(--table-bg-color);
}

.lmnt-table--no-hover .mdc-data-table__row:hover {
  background-color: inherit;
}

.lmnt-table--no-hover .mdc-data-table__row:hover {
  background-color: inherit;
}
.lmnt-table--no-hover .lmnt-table__leading-fixed-cell,
.lmnt-table--no-hover .lmnt-table__trailing-fixed-cell {
  background-color: var(--table-bg-color);
}

.lmnt-table__leading-fixed-column-shadow {
  box-shadow: 3px 0 4px -1px rgba(0, 0, 0, 0.12);
  height: 100%;
  padding-right: 6px;
  position: sticky;
  width: 0;
  z-index: 8;
}

.lmnt-table__trailing-fixed-column-shadow {
  box-shadow: -3px 0 4px -1px rgba(0, 0, 0, 0.12);
  height: 100%;
  padding-left: 6px;
  position: sticky;
  width: 0;
  z-index: 8;
}

.lmnt-table__resize-indicator {
  cursor: col-resize;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity linear 75ms;
  width: 0;
  z-index: 3;
}
.lmnt-table__resize-indicator::after {
  background-image: url('data:image/svg+xml; utf8, <svg viewBox="0 0 3.8 50" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><line x1="1.9" y1="0" x2="1.9" y2="50" stroke="black" stroke-width="1" stroke-dasharray="2 1" /></svg>');
  background-size: 100%;
  content: "";
  height: 100%;
  left: -0.5rem;
  position: absolute;
  top: 0;
  transform: scaleX(1);
  transition: transform linear 75ms;
  width: 1rem;
}
.lmnt-table__resize-indicator.lmnt-table__resize-indicator--last {
  justify-content: flex-end;
  width: 0.4rem;
}

.lmnt-table__header-theme--primary .lmnt-table__header-cell {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}

.lmnt-table__header-theme--secondary .lmnt-table__header-cell {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-table__header-theme--primary-variant .lmnt-table__header-cell {
  background-color: var(--lmnt-theme-primary-variant);
  color: var(--lmnt-theme-on-primary-variant);
}

.lmnt-table__header-theme--secondary-variant .lmnt-table__header-cell {
  background-color: var(--lmnt-theme-secondary-variant);
  color: var(--lmnt-theme-on-secondary-variant);
}

.lmnt-table__header-divider--primary .lmnt-table__header-cell {
  border-bottom-color: var(--lmnt-theme-primary);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lmnt-table__header-divider--secondary .lmnt-table__header-cell {
  border-bottom-color: var(--lmnt-theme-secondary);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lmnt-table__header-divider--primary-variant .lmnt-table__header-cell {
  border-bottom-color: var(--lmnt-theme-primary-variant);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lmnt-table__header-divider--secondary-variant .lmnt-table__header-cell {
  border-bottom-color: var(--lmnt-theme-secondary-variant);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.lmnt-table__header-divider--tall .lmnt-table__header-cell {
  border-bottom-width: 4px;
}

.lmnt-table__cell--nestable.mdc-data-table__cell {
  padding-left: 0;
  padding-right: 0;
}

.lmnt-table--nested {
  border-width: 0;
}

.lmnt-table--fixed-width .lmnt-table__header {
  overflow: hidden;
}
.lmnt-table--fixed-width .mdc-data-table__content {
  overflow-y: auto;
}

.lmnt-table__no-content {
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.lmnt-table__hide-horizontal-scrollbar ::-webkit-scrollbar {
  background-color: transparent;
  height: 0;
  width: 0;
}

.lmnt-table__block-fixed-header {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.lmnt-table__block-fixed-header thead {
  display: block;
  flex-basis: max-content;
  flex-grow: 0;
  flex-shrink: 1;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
}
.lmnt-table__block-fixed-header thead::-webkit-scrollbar {
  background: transparent;
  display: none;
  width: 0;
}
.lmnt-table__block-fixed-header tbody {
  display: block;
  flex: 1;
  overflow-y: auto;
}
.lmnt-table__block-fixed-header tr {
  display: flex;
  width: 100%;
}
.lmnt-table__block-fixed-header tr td,
.lmnt-table__block-fixed-header tr th {
  display: block;
  flex: 1;
}

.lmnt-table__flex-fixed-header {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.lmnt-table__flex-fixed-header thead {
  display: block;
  flex-basis: max-content;
  flex-grow: 0;
  flex-shrink: 1;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
}
.lmnt-table__flex-fixed-header thead::-webkit-scrollbar {
  background: transparent;
  display: none;
  width: 0;
}
.lmnt-table__flex-fixed-header tbody {
  flex: 1;
  overflow-y: auto;
}

.tb-demo-group {
  gap: 12px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.lmnt {
  --lmnt-text-bubble--small-font-size: 16px;
  --lmnt-text-bubble--small-font-weight: 700;
  --lmnt-text-bubble--small-line-height: 24px;
  --lmnt-text-bubble--small-size: 32px;
  --lmnt-text-bubble--font-family: var(--lmnt-theme-primary-font);
  --lmnt-text-bubble--font-size: 1rem;
  --lmnt-text-bubble--font-weight: 700;
  --lmnt-text-bubble--line-height: 24px;
  --lmnt-text-bubble--size: 40px;
}

.lmnt-text-bubble {
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-family: var(--lmnt-text-bubble--font-family);
  font-size: var(--lmnt-text-bubble--font-size);
  font-weight: var(--lmnt-text-bubble--font-weight);
  height: var(--lmnt-text-bubble--size);
  justify-content: center;
  line-height: var(--lmnt-text-bubble--line-height);
  margin: 0;
  max-height: var(--lmnt-text-bubble--size);
  max-width: 40px;
  min-height: var(--lmnt-text-bubble--size);
  min-width: 40px;
  padding: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 40px;
  word-spacing: 0;
}

.lmnt-text-bubble--small {
  font-size: var(--lmnt-text-bubble--small-font-size);
  height: var(--lmnt-text-bubble--small-size);
  max-height: var(--lmnt-text-bubble--small-size);
  max-width: var(--lmnt-text-bubble--small-size);
  min-height: var(--lmnt-text-bubble--small-size);
  min-width: var(--lmnt-text-bubble--small-size);
  width: var(--lmnt-text-bubble--small-size);
}

.lmnt-text-bubble__theme-color--blue {
  background-color: #c2e0ff;
  color: #0b3f73;
}

.lmnt-text-bubble__theme-color--gray {
  background-color: #e6e6e6;
  color: #474747;
}

.lmnt-text-bubble__theme-color--green {
  background-color: #b8f2c7;
  color: #084d19;
}

.lmnt-text-bubble__theme-color--orange {
  background-color: #ffe6b2;
  color: #593f09;
}

.lmnt-text-bubble__theme-color--purple {
  background-color: #dfcbf2;
  color: #400b73;
}

.lmnt-text-bubble__theme-color--red {
  background-color: #ffd6d6;
  color: #730b0b;
}

.lmnt-text-bubble__theme-color--teal {
  background-color: #b8f2ed;
  color: #084d47;
}

.lmnt-text-bubble__theme-color--yellow {
  background-color: #f2f2b6;
  color: #404006;
}

.lmnt-text-bubble__theme-color--primary {
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}

.lmnt-text-bubble__theme-color--secondary {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-text-bubble__theme-color--danger {
  background-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-on-danger);
}

.lmnt-text-bubble__theme-color--muted {
  background-color: var(--lmnt-theme-surface-variant);
  color: var(--lmnt-on-surface-inactive);
}

.lmnt-text-bubble__theme-color--danger-alt {
  background-color: var(--lmnt-theme-surface);
  color: var(--lmnt-theme-on-danger-alt);
}

.lmnt-text-bubble__theme-color--background {
  background-color: var(--lmnt-theme-surface);
  color: var(--lmnt-on-surface-active);
}

.lmnt-text-bubble--outlined {
  background-color: unset;
  border-style: solid;
  border-width: 1px;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--blue {
  border-color: #0b3f73;
  color: #0b3f73;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--gray {
  border-color: #474747;
  color: #474747;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--green {
  border-color: #084d19;
  color: #084d19;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--orange {
  border-color: #593f09;
  color: #593f09;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--purple {
  border-color: #400b73;
  color: #400b73;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--red {
  border-color: #730b0b;
  color: #730b0b;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--teal {
  border-color: #084d47;
  color: #084d47;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--yellow {
  border-color: #404006;
  color: #404006;
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--primary {
  border-color: var(--lmnt-theme-primary-on-surface);
  color: var(--lmnt-theme-primary-on-surface);
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--secondary {
  border-color: var(--lmnt-theme-secondary-on-surface);
  color: var(--lmnt-theme-secondary-on-surface);
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--danger {
  border-color: var(--lmnt-theme-danger-on-surface);
  color: var(--lmnt-theme-danger-on-surface);
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--muted {
  border-color: var(--lmnt-on-surface-inactive);
  color: var(--lmnt-on-surface-inactive);
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--danger-alt {
  border-color: var(--lmnt-theme-on-surface-stroke);
  color: var(--lmnt-theme-on-danger-alt);
}

.lmnt-text-bubble--outlined.lmnt-text-bubble__theme-color--background {
  border-color: var(--lmnt-theme-on-surface-stroke);
  color: var(--lmnt-on-surface-active);
}

.lmnt {
  --lmnt-tooltip-border-radius: var(--lmnt-theme--shape-radius);
  --lmnt-tooltip: rgba(77, 77, 77, 0.9);
  --lmnt-tooltip-text-color: #fff;
}

.lmnt-tooltip {
  position: relative;
}

.lmnt-tooltip--block {
  display: block;
}

.lmnt-tooltip--inline {
  display: inline-block;
}

.lmnt-tooltip__text-content {
  background-color: var(--lmnt-tooltip);
  border-radius: var(--lmnt-tooltip-border-radius);
  color: var(--lmnt-tooltip-text-color);
  display: none;
  max-width: 208px;
  padding: 8px 16px;
  position: absolute;
  white-space: normal;
  width: max-content;
  z-index: 1;
}

.lmnt-tooltip__text-content[data-show] {
  display: block;
}

@media only screen and (min-width: 30rem) {
  .lmnt-tooltip__text-content {
    max-width: 224px;
    padding: 4px 8px;
  }
}
:root,
.lmnt {
  --lmnt-top-app-bar-height-sm: 64px;
  --lmnt-top-app-bar-height-lg: 64px;
}

.mdc-top-app-bar {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #6200ee);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}
.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}
.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}
.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}
.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 24px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 24px;
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
}
.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}
.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px;
}
[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }

  .mdc-top-app-bar__section {
    padding: 4px;
  }

  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 0;
  }

  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }

  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.lmnt-theme-background-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-background);
  background-color: var(--lmnt-theme-background);
  color: var(--lmnt-theme-on-background);
}

.lmnt-theme-surface-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface);
  background-color: var(--lmnt-theme-surface);
  color: var(--lmnt-theme-on-surface);
}

.lmnt-theme-surface-variant-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-surface-variant);
  background-color: var(--lmnt-theme-surface-variant);
  color: var(--lmnt-theme-on-surface);
}

.lmnt-theme-primary-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-primary);
  background-color: var(--lmnt-theme-primary);
  color: var(--lmnt-theme-on-primary);
}

.lmnt-theme-primary-variant-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-primary-variant);
  background-color: var(--lmnt-theme-primary-variant);
  color: var(--lmnt-theme-on-primary-variant);
}

.lmnt-theme-secondary-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-secondary);
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-theme-secondary-variant-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-secondary-variant);
  background-color: var(--lmnt-theme-secondary-variant);
  color: var(--lmnt-theme-on-secondary-variant);
}

.lmnt-theme-danger-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-danger);
  background-color: var(--lmnt-theme-danger);
  color: var(--lmnt-theme-on-danger);
}

.lmnt-theme-danger-variant-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-danger-variant);
  background-color: var(--lmnt-theme-danger-variant);
  color: var(--lmnt-theme-on-danger-variant);
}

.lmnt-theme-success-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-success);
  background-color: var(--lmnt-theme-success);
  color: var(--lmnt-theme-on-success);
}

.lmnt-theme-success-variant-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-success-variant);
  background-color: var(--lmnt-theme-success-variant);
  color: var(--lmnt-theme-on-success-variant);
}

.lmnt-theme-dark-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-dark);
  background-color: var(--lmnt-theme-dark);
  color: var(--lmnt-theme-on-dark);
}

.lmnt-theme-light-bg {
  --mdc-theme-text-icon-on-background: var(--lmnt-theme-on-light);
  background-color: var(--lmnt-theme-light);
  color: var(--lmnt-theme-on-light);
}

.lmnt-theme-on-background--bg,
.lmnt-theme-on-background-bg {
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
}

.lmnt-theme-on-background {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-surface--bg,
.lmnt-theme-on-surface-bg {
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
}

.lmnt-theme-on-surface {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-primary--bg,
.lmnt-theme-on-primary-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-primary {
  color: white;
}

.lmnt-theme-on-primary-variant--bg,
.lmnt-theme-on-primary-variant-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-primary-variant {
  color: white;
}

.lmnt-theme-primary-on-surface--bg,
.lmnt-theme-primary-on-surface-bg {
  background-color: #673ab7;
  color: white;
}

.lmnt-theme-primary-on-surface {
  color: #673ab7;
}

.lmnt-theme-on-secondary--bg,
.lmnt-theme-on-secondary-bg {
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
}

.lmnt-theme-on-secondary {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-secondary-variant--bg,
.lmnt-theme-on-secondary-variant-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-secondary-variant {
  color: white;
}

.lmnt-theme-secondary-on-surface--bg,
.lmnt-theme-secondary-on-surface-bg {
  background-color: #23668c;
  color: white;
}

.lmnt-theme-secondary-on-surface {
  color: #23668c;
}

.lmnt-theme-on-danger--bg,
.lmnt-theme-on-danger-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-danger {
  color: white;
}

.lmnt-theme-on-danger-alt--bg,
.lmnt-theme-on-danger-alt-bg {
  background-color: #cf2d22;
  color: white;
}

.lmnt-theme-on-danger-alt {
  color: #cf2d22;
}

.lmnt-theme-on-danger-variant--bg,
.lmnt-theme-on-danger-variant-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-danger-variant {
  color: white;
}

.lmnt-theme-danger-on-surface--bg,
.lmnt-theme-danger-on-surface-bg {
  background-color: #cf2d22;
  color: white;
}

.lmnt-theme-danger-on-surface {
  color: #cf2d22;
}

.lmnt-theme-success-on-surface--bg,
.lmnt-theme-success-on-surface-bg {
  background-color: #01830c;
  color: white;
}

.lmnt-theme-success-on-surface {
  color: #01830c;
}

.lmnt-theme-on-success--bg,
.lmnt-theme-on-success-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-success {
  color: white;
}

.lmnt-theme-on-unknown-dark--bg,
.lmnt-theme-on-unknown-dark-bg {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-unknown-dark {
  color: white;
}

.lmnt-theme-on-unknown-light--bg,
.lmnt-theme-on-unknown-light-bg {
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
}

.lmnt-theme-on-unknown-light {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt.lmnt-top-app-bar {
  transition: background-color 225ms ease;
  color: var(--lmnt-theme-on-primary);
  position: unset;
  top: 0;
  z-index: 7;
}
.lmnt.lmnt-top-app-bar .mdc-top-app-bar__row {
  height: var(--lmnt-top-app-bar-height-sm);
  transition: height 20ms linear;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__action-item--link {
  color: var(--lmnt-theme-on-primary);
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--fixed {
  position: fixed;
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary {
  background-color: var(--lmnt-theme-secondary);
  color: var(--lmnt-theme-on-secondary);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .lmnt.mdc-button,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .lmnt-top-app-bar__action-item--link,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .mdc-top-app-bar__navigation-icon,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .mdc-top-app-bar__action-item {
  color: var(--lmnt-theme-on-secondary);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .mdc-top-app-bar__navigation-icon::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .mdc-top-app-bar__navigation-icon::after,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .mdc-top-app-bar__action-item::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--secondary .mdc-top-app-bar__action-item::after {
  background-color: var(--lmnt-theme-on-secondary);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface {
  background-color: var(--lmnt-theme-surface);
  color: var(--lmnt-theme-on-surface);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .lmnt-top-app-bar__action-item--link,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .mdc-top-app-bar__navigation-icon,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .mdc-top-app-bar__action-item {
  color: var(--lmnt-theme-on-surface);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .mdc-top-app-bar__navigation-icon::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .mdc-top-app-bar__navigation-icon::after,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .mdc-top-app-bar__action-item::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--surface .mdc-top-app-bar__action-item::after {
  background-color: rgba(var(--lmnt-black-rgb), 0.6);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark {
  transition: background-color 225ms ease;
  background-color: var(--lmnt-theme-black);
  color: var(--lmnt-theme-white);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark .mdc-top-app-bar__navigation-icon,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark .mdc-top-app-bar__action-item {
  color: var(--lmnt-theme-white);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark .mdc-top-app-bar__navigation-icon::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark .mdc-top-app-bar__navigation-icon::after,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark .mdc-top-app-bar__action-item::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-dark .mdc-top-app-bar__action-item::after {
  color: var(--lmnt-theme-white);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light {
  background-color: var(--lmnt-theme-white);
  color: var(--lmnt-theme-black);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light .mdc-top-app-bar__navigation-icon,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light .mdc-top-app-bar__action-item {
  color: var(--lmnt-theme-black);
}
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light .mdc-top-app-bar__navigation-icon::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light .mdc-top-app-bar__navigation-icon::after,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light .mdc-top-app-bar__action-item::before,
.lmnt.lmnt-top-app-bar.lmnt-top-app-bar--contextual.lmnt-top-app-bar--contextual-light .mdc-top-app-bar__action-item::after {
  color: var(--lmnt-theme-black);
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  white-space: normal;
  align-self: flex-start;
  padding-top: 0.75rem;
  padding-bottom: 0;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .mdc-top-app-bar__title.mdc-top-app-bar__title--with-subtitle {
  padding-top: 0.125rem;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .lmnt-top-app-bar__logo {
  align-self: flex-start;
  padding-top: 0.25rem;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__children {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.lmnt.lmnt-top-app-bar .mdc-top-app-bar__title {
  line-height: 1.5rem;
}
.lmnt.lmnt-top-app-bar .mdc-top-app-bar__title.mdc-top-app-bar__title--with-subtitle {
  display: flex;
  flex-direction: column;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__logo {
  overflow: hidden;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__logo.lmnt-top-app-bar__logo--small {
  height: 2.5rem;
  width: 2.5rem;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__logo.lmnt-top-app-bar__logo--medium {
  height: 2.5rem;
  width: 5rem;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__logo.lmnt-top-app-bar__logo--large {
  height: 2.5rem;
  width: 7.5rem;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__logo.lmnt-top-app-bar__logo--large-square {
  height: 48px;
  width: 48px;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__action-item--link {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 3rem;
  justify-content: center;
  line-height: 1.4285714286;
  padding: 0 0.75rem;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__section--tab-actions {
  padding: 0;
}
.lmnt.lmnt-top-app-bar .lmnt-top-app-bar__section--tab-actions .mdc-tab {
  height: 64px;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--dense .lmnt-top-app-bar__section--tab-actions .mdc-tab {
  height: 48px;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  padding-top: 0.5rem;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .lmnt-top-app-bar__section--tab-actions .mdc-tab {
  height: 128px;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .lmnt-top-app-bar__section--tab-actions .mdc-tab__content {
  align-items: flex-end;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .lmnt-top-app-bar__section--tab-actions .mdc-tab__content .mdc-tab__text-label {
  padding-bottom: 1.5rem;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent .mdc-top-app-bar__section--align-end {
  align-items: flex-start;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent.mdc-top-app-bar--prominent-tabs .mdc-top-app-bar__row {
  height: 100%;
  flex-direction: column;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent.mdc-top-app-bar--prominent-tabs .lmnt-top-app-bar__title-and-actions {
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent.mdc-top-app-bar--prominent-tabs .mdc-tab {
  height: 64px;
}
.lmnt.lmnt-top-app-bar.mdc-top-app-bar--prominent.mdc-top-app-bar--prominent-tabs.mdc-top-app-bar--dense .mdc-tab {
  height: 48px;
}
.lmnt.lmnt-top-app-bar a {
  color: inherit;
}

.lmnt-top-app-bar-open-banner--fixed-adjust {
  transition: padding-top 300ms ease;
  padding-top: 0;
}

.lmnt-top-app-bar__action-item-badge {
  position: relative;
}
.lmnt-top-app-bar__action-item-badge .lmnt-top-app-bar--notification-badge.lmnt-badge--notification {
  position: absolute;
  top: 4px;
  left: -10px;
}

.lmnt-top-app-bar__logo-title-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  min-width: 0;
  order: -1;
  padding: 8px 12px;
  z-index: 1;
}

@media only screen and (min-width: 840px) and (max-width: 1023px) {
  .lmnt-top-app-bar-with-max-content-width {
    align-items: center;
  }
  .lmnt-top-app-bar-with-max-content-width .lmnt-top-app-bar__content {
    max-width: 1052px;
    width: 100%;
  }

  .lmnt-top-app-bar-fixed-adjust-with-max-content-width {
    max-width: 1052px;
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .lmnt-top-app-bar-with-max-content-width {
    align-items: center;
  }
  .lmnt-top-app-bar-with-max-content-width .lmnt-top-app-bar__content {
    max-width: 1052px;
    width: 100%;
  }

  .lmnt-top-app-bar-fixed-adjust-with-max-content-width {
    max-width: 1052px;
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1440px) {
  .lmnt-top-app-bar-with-max-content-width {
    align-items: center;
  }
  .lmnt-top-app-bar-with-max-content-width .lmnt-top-app-bar__content {
    max-width: 1176px;
    width: 100%;
  }

  .lmnt-top-app-bar-fixed-adjust-with-max-content-width {
    max-width: 1176px;
    width: 100%;
    margin: 0 auto;
  }
}
.lmnt-theme-primary {
  color: var(--lmnt-theme-primary);
}

.lmnt-theme-on-primary {
  color: var(--lmnt-theme-on-primary);
}

.lmnt-theme-primary-variant {
  color: var(--lmnt-theme-primary-variant);
}

.lmnt-theme-on-primary-variant {
  color: var(--lmnt-theme-on-primary-variant);
}

.lmnt-theme-primary-on-surface {
  color: var(--lmnt-theme-primary-on-surface);
}

.lmnt-theme-secondary {
  color: var(--lmnt-theme-secondary);
}

.lmnt-theme-on-secondary {
  color: var(--lmnt-theme-on-secondary);
}

.lmnt-theme-secondary-variant {
  color: var(--lmnt-theme-secondary-variant);
}

.lmnt-theme-on-secondary-variant {
  color: var(--lmnt-theme-on-secondary-variant);
}

.lmnt-theme-secondary-on-surface {
  color: var(--lmnt-theme-secondary-on-surface);
}

.lmnt-theme-background {
  color: var(--lmnt-theme-background);
}

.lmnt-theme-on-background {
  color: var(--lmnt-theme-on-background);
}

.lmnt-theme-surface {
  color: var(--lmnt-theme-surface);
}

.lmnt-theme-on-surface {
  color: var(--lmnt-theme-on-surface);
}

.lmnt-theme-surface-variant {
  color: var(--lmnt-theme-surface-variant);
}

.lmnt-theme-danger {
  color: var(--lmnt-theme-danger);
}

.lmnt-theme-on-danger {
  color: var(--lmnt-theme-on-danger);
}

.lmnt-theme-danger-on-surface {
  color: var(--lmnt-theme-danger-on-surface);
}

.lmnt-theme-danger-variant {
  color: var(--lmnt-theme-danger-variant);
}

.lmnt-theme-light {
  color: var(--lmnt-theme-light);
}

.lmnt-theme-on-unknown-light {
  color: var(--lmnt-theme-on-unknown-light);
}

.lmnt-theme-dark {
  color: var(--lmnt-theme-dark);
}

.lmnt-theme-on-unknown-dark {
  color: var(--lmnt-theme-on-unknown-dark);
}

.lmnt-theme-primary-font {
  font-family: var(--lmnt-theme-primary-font);
}

.lmnt-theme-secondary-font {
  font-family: var(--lmnt-theme-secondary-font);
}

body {
  color: var(--lmnt-theme-on-surface);
  font-family: var(--lmnt-theme-primary-font, Roboto, sans-serif);
  background-color: var(--lmnt-theme-background);
}

*:focus-visible {
  outline: none;
}

.lmnt-theme-primary-50--bg,
.lmnt-theme-primary-50-bg {
  background-color: #f1edf8;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-50 {
  color: #f1edf8;
}

.lmnt-theme-primary-100--bg,
.lmnt-theme-primary-100-bg {
  background-color: #c7b7e4;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-100 {
  color: #c7b7e4;
}

.lmnt-theme-primary-200--bg,
.lmnt-theme-primary-200-bg {
  background-color: #b9a5de;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-primary-200 {
  color: #b9a5de;
}

.lmnt-theme-primary-300--bg,
.lmnt-theme-primary-300-bg {
  background-color: #9381d1;
  color: white;
}

.lmnt-theme-primary-300 {
  color: #9381d1;
}

.lmnt-theme-primary-400--bg,
.lmnt-theme-primary-400-bg {
  background-color: #825dc4;
  color: white;
}

.lmnt-theme-primary-400 {
  color: #825dc4;
}

.lmnt-theme-primary-500--bg,
.lmnt-theme-primary-500-bg {
  background-color: #673ab7;
  color: white;
}

.lmnt-theme-primary-500 {
  color: #673ab7;
}

.lmnt-theme-primary-600--bg,
.lmnt-theme-primary-600-bg {
  background-color: #553096;
  color: white;
}

.lmnt-theme-primary-600 {
  color: #553096;
}

.lmnt-theme-primary-700--bg,
.lmnt-theme-primary-700-bg {
  background-color: #4b2b86;
  color: white;
}

.lmnt-theme-primary-700 {
  color: #4b2b86;
}

.lmnt-theme-primary-800--bg,
.lmnt-theme-primary-800-bg {
  background-color: #422575;
  color: white;
}

.lmnt-theme-primary-800 {
  color: #422575;
}

.lmnt-theme-primary-900--bg,
.lmnt-theme-primary-900-bg {
  background-color: #261643;
  color: white;
}

.lmnt-theme-primary-900 {
  color: #261643;
}

.lmnt-theme-secondary-50--bg,
.lmnt-theme-secondary-50-bg {
  background-color: #edf8ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-50 {
  color: #edf8ff;
}

.lmnt-theme-secondary-100--bg,
.lmnt-theme-secondary-100-bg {
  background-color: #caecff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-100 {
  color: #caecff;
}

.lmnt-theme-secondary-200--bg,
.lmnt-theme-secondary-200-bg {
  background-color: #b9e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-200 {
  color: #b9e5ff;
}

.lmnt-theme-secondary-300--bg,
.lmnt-theme-secondary-300-bg {
  background-color: #96d9ff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-300 {
  color: #96d9ff;
}

.lmnt-theme-secondary-400--bg,
.lmnt-theme-secondary-400-bg {
  background-color: #74ccff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-400 {
  color: #74ccff;
}

.lmnt-theme-secondary-500--bg,
.lmnt-theme-secondary-500-bg {
  background-color: #40baff;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-secondary-500 {
  color: #40baff;
}

.lmnt-theme-secondary-600--bg,
.lmnt-theme-secondary-600-bg {
  background-color: #3599d1;
  color: white;
}

.lmnt-theme-secondary-600 {
  color: #3599d1;
}

.lmnt-theme-secondary-700--bg,
.lmnt-theme-secondary-700-bg {
  background-color: #2f88ba;
  color: white;
}

.lmnt-theme-secondary-700 {
  color: #2f88ba;
}

.lmnt-theme-secondary-800--bg,
.lmnt-theme-secondary-800-bg {
  background-color: #23668c;
  color: white;
}

.lmnt-theme-secondary-800 {
  color: #23668c;
}

.lmnt-theme-secondary-900--bg,
.lmnt-theme-secondary-900-bg {
  background-color: #18445d;
  color: white;
}

.lmnt-theme-secondary-900 {
  color: #18445d;
}

.lmnt-theme-danger-50--bg,
.lmnt-theme-danger-50-bg {
  background-color: #ffeaed;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-danger-50 {
  color: #ffeaed;
}

.lmnt-theme-danger-100--bg,
.lmnt-theme-danger-100-bg {
  background-color: #ffccce;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-danger-100 {
  color: #ffccce;
}

.lmnt-theme-danger-200--bg,
.lmnt-theme-danger-200-bg {
  background-color: #ee9894;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-danger-200 {
  color: #ee9894;
}

.lmnt-theme-danger-300--bg,
.lmnt-theme-danger-300-bg {
  background-color: #e3716a;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-danger-300 {
  color: #e3716a;
}

.lmnt-theme-danger-400--bg,
.lmnt-theme-danger-400-bg {
  background-color: #ec5145;
  color: white;
}

.lmnt-theme-danger-400 {
  color: #ec5145;
}

.lmnt-theme-danger-500--bg,
.lmnt-theme-danger-500-bg {
  background-color: #ef4227;
  color: white;
}

.lmnt-theme-danger-500 {
  color: #ef4227;
}

.lmnt-theme-danger-600--bg,
.lmnt-theme-danger-600-bg {
  background-color: #e13727;
  color: white;
}

.lmnt-theme-danger-600 {
  color: #e13727;
}

.lmnt-theme-danger-700--bg,
.lmnt-theme-danger-700-bg {
  background-color: #cf2d22;
  color: white;
}

.lmnt-theme-danger-700 {
  color: #cf2d22;
}

.lmnt-theme-danger-800--bg,
.lmnt-theme-danger-800-bg {
  background-color: #c2261b;
  color: white;
}

.lmnt-theme-danger-800 {
  color: #c2261b;
}

.lmnt-theme-danger-900--bg,
.lmnt-theme-danger-900-bg {
  background-color: #b3190d;
  color: white;
}

.lmnt-theme-danger-900 {
  color: #b3190d;
}

.lmnt-theme-success-50--bg,
.lmnt-theme-success-50-bg {
  background-color: #eeffef;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-success-50 {
  color: #eeffef;
}

.lmnt-theme-success-100--bg,
.lmnt-theme-success-100-bg {
  background-color: #d3ffd7;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-success-100 {
  color: #d3ffd7;
}

.lmnt-theme-success-200--bg,
.lmnt-theme-success-200-bg {
  background-color: #a9f6af;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-success-200 {
  color: #a9f6af;
}

.lmnt-theme-success-300--bg,
.lmnt-theme-success-300-bg {
  background-color: #6fef7a;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-success-300 {
  color: #6fef7a;
}

.lmnt-theme-success-400--bg,
.lmnt-theme-success-400-bg {
  background-color: #29e539;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-success-400 {
  color: #29e539;
}

.lmnt-theme-success-500--bg,
.lmnt-theme-success-500-bg {
  background-color: #13c545;
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-success-500 {
  color: #13c545;
}

.lmnt-theme-success-600--bg,
.lmnt-theme-success-600-bg {
  background-color: #0ea01a;
  color: white;
}

.lmnt-theme-success-600 {
  color: #0ea01a;
}

.lmnt-theme-success-700--bg,
.lmnt-theme-success-700-bg {
  background-color: #01830c;
  color: white;
}

.lmnt-theme-success-700 {
  color: #01830c;
}

.lmnt-theme-success-800--bg,
.lmnt-theme-success-800-bg {
  background-color: #075a0e;
  color: white;
}

.lmnt-theme-success-800 {
  color: #075a0e;
}

.lmnt-theme-success-900--bg,
.lmnt-theme-success-900-bg {
  background-color: #053709;
  color: white;
}

.lmnt-theme-success-900 {
  color: #053709;
}

.lmnt-theme-ancillary-100--bg,
.lmnt-theme-ancillary-100-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-100 {
  color: #000;
}

.lmnt-theme-ancillary-200--bg,
.lmnt-theme-ancillary-200-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-200 {
  color: #000;
}

.lmnt-theme-ancillary-300--bg,
.lmnt-theme-ancillary-300-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-300 {
  color: #000;
}

.lmnt-theme-ancillary-400--bg,
.lmnt-theme-ancillary-400-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-400 {
  color: #000;
}

.lmnt-theme-ancillary-500--bg,
.lmnt-theme-ancillary-500-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-500 {
  color: #000;
}

.lmnt-theme-ancillary-600--bg,
.lmnt-theme-ancillary-600-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-600 {
  color: #000;
}

.lmnt-theme-ancillary-700--bg,
.lmnt-theme-ancillary-700-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-700 {
  color: #000;
}

.lmnt-theme-ancillary-800--bg,
.lmnt-theme-ancillary-800-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-800 {
  color: #000;
}

.lmnt-theme-ancillary-900--bg,
.lmnt-theme-ancillary-900-bg {
  background-color: #000;
  color: white;
}

.lmnt-theme-ancillary-900 {
  color: #000;
}

.lmnt-theme-on-surface-active {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-surface-inactive {
  color: rgba(0, 0, 0, 0.6);
}

.lmnt-theme-on-surface-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.lmnt-theme-on-surface-stroke {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.lmnt-theme-on-surface-base {
  color: black;
}

.lmnt-theme-on-primary-active {
  color: rgba(255, 255, 255, 0.87);
}

.lmnt-theme-on-primary-inactive {
  color: rgba(255, 255, 255, 0.6);
}

.lmnt-theme-on-primary-disabled {
  color: rgba(255, 255, 255, 0.38);
}

.lmnt-theme-on-primary-stroke {
  border-color: rgba(255, 255, 255, 0.12);
  border-bottom-color: rgba(255, 255, 255, 0.12);
}

.lmnt-theme-on-primary-base {
  color: white;
}

.lmnt-theme-on-secondary-active {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-secondary-inactive {
  color: rgba(0, 0, 0, 0.6);
}

.lmnt-theme-on-secondary-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.lmnt-theme-on-secondary-stroke {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.lmnt-theme-on-secondary-base {
  color: black;
}

.lmnt-theme-on-unknown-black-active {
  color: rgba(0, 0, 0, 0.87);
}

.lmnt-theme-on-unknown-black-inactive {
  color: rgba(0, 0, 0, 0.6);
}

.lmnt-theme-on-unknown-black-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.lmnt-theme-on-unknown-black-stroke {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.lmnt-theme-on-unknown-black-base {
  color: black;
}

.lmnt-theme-on-unknown-white-active {
  color: white;
}

.lmnt-theme-on-unknown-white-inactive {
  color: rgba(255, 255, 255, 0.54);
}

.lmnt-theme-on-unknown-white-disabled {
  color: rgba(255, 255, 255, 0.38);
}

.lmnt-theme-on-unknown-white-stroke {
  border-color: rgba(255, 255, 255, 0.12);
  border-bottom-color: rgba(255, 255, 255, 0.12);
}

.lmnt-theme-on-unknown-white-base {
  color: white;
}

.lmnt-theme-on-danger-active {
  color: rgba(255, 255, 255, 0.87);
}

.lmnt-theme-on-danger-inactive {
  color: rgba(255, 255, 255, 0.6);
}

.lmnt-theme-on-danger-disabled {
  color: rgba(255, 255, 255, 0.38);
}

.lmnt-theme-on-danger-stroke {
  border-color: rgba(255, 255, 255, 0.12);
  border-bottom-color: rgba(255, 255, 255, 0.12);
}

.lmnt-theme-on-danger-base {
  color: white;
}

.lmnt-theme-on-success-active {
  color: rgba(255, 255, 255, 0.87);
}

.lmnt-theme-on-success-inactive {
  color: rgba(255, 255, 255, 0.6);
}

.lmnt-theme-on-success-disabled {
  color: rgba(255, 255, 255, 0.38);
}

.lmnt-theme-on-success-stroke {
  border-color: rgba(255, 255, 255, 0.12);
  border-bottom-color: rgba(255, 255, 255, 0.12);
}

.lmnt-theme-on-success-base {
  color: white;
}