.footer {
  width: 100%;
  gap: 0.5rem;
  height: 36px;
  display: flex;
  margin-top: auto;
  background: #fff;
  flex-direction: row;
  justify-content: center;

  a,
  button {
    height: 35px;
    border: none;
    color: #000;
    font-size: 90%;
    display: block;
    cursor: pointer;
    line-height: 34px;
    font-weight: normal;
    text-decoration: none;
    background: transparent;
  }
  // &_ctn {
  //   gap: 0.75rem;
  //   display: flex;
  //   padding: 0 50px;
  //   flex-direction: column;
  // }
}
